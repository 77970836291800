import React from 'react';

const SolicitationFormSelect = ({
  items, label, selected, onChange,
}) => (
  <div className="select-custom">
    <select name="orderby" className="form-control" defaultValue={selected} onChange={onChange}>
      {selected === '' && <option value="" disabled>{label}</option>}
      {items.map(item => (
        <option key={item.value} value={item.value}>{item.label}</option>
      ))}
    </select>
  </div>
);

export default SolicitationFormSelect;
