import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

import CheckoutPaymentDocument from './CheckoutPaymentDocument';

import ValidatorsHelper from '../../../helpers/validators';
import Pagseguro from '../../../app/services/pagseguro';

class CheckoutPaymentOnlineDebit extends React.Component {
  constructor() {
    super();

    this.state = {
      bankName: '',
      banks: [],
    };
  }

  componentDidMount = async () => {
    const onlineDebit = await Pagseguro.getPaymentMethods('ONLINE_DEBIT');

    if (onlineDebit) {
      const banks = Object.keys(onlineDebit.options).reduce((acum, key) => {
        if (onlineDebit.options[key].status === 'AVAILABLE') {
          acum.push({
            name: onlineDebit.options[key].name,
            displayName: `${onlineDebit.options[key].code} - ${onlineDebit.options[key].displayName}`,
          });
        }

        return acum;
      }, [
        {
          name: '',
          displayName: '',
        },
      ]);

      this.setState({ banks: banks || [] });
    }
  }

  renderBankOptions = (banks = []) => {
    if (!banks.length) {
      return (<option key="loading" value="">{I18n.t('loading')}</option>);
    }

    return banks.map(bank => (
      <option key={bank.name} value={bank.name}>{bank.displayName}</option>
    ));
  }

  render() {
    const { bankName, banks } = this.state;
    const { payementType, document } = this.props;
    const type = 'debit';
    const isSelected = payementType === type;

    return (
      <React.Fragment>
        <button type="button" className="payment-menu" onClick={() => this.props.selectPayementType(type)}>
          {I18n.t('debitOn')}
          <i className={`fas fa-arrow-${isSelected ? 'down' : 'right'}`} />
        </button>
        <div className={`card-payment ${isSelected ? '' : 'card-display-none'}`}>
          <div className="row">
            <div className="col-12">
              <div>
                <span>
                  <Translate value="bank" />
                </span>
                <div className="select-custom">
                  <select
                    name="orderby"
                    className="form-control"
                    defaultValue=""
                    onChange={e => this.setState({ bankName: e.target.value })}
                  >
                    {this.renderBankOptions(banks)}
                  </select>
                </div>
              </div>
              <div className="mt-2">
                <CheckoutPaymentDocument setDocument={this.props.setDocument} document={document} />
              </div>
              <button
                type="button"
                className="button-action btn btn-secondary mt-2"
                disabled={(!ValidatorsHelper.validDocument(document.type, document.value)) || !bankName}
                onClick={() => this.props.setPayment(type, { card: { bankName }, document })}
              >
                <Translate value="confirm" />
              </button>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}

export default CheckoutPaymentOnlineDebit;
