/* eslint-disable no-empty */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';

import { Translate, I18n } from 'react-redux-i18n';
import CheckoutResume from './CheckoutResume';
import CheckoutAddress from './CheckoutAddress';
import PaymentComponent from './payment/CheckoutPayment';
import CheckoutFinalize from './CheckoutFinalize';

import StringHelper from '../../helpers/string';

import {
  finalizePurchase, getPagSeguroSessionId,
} from '../../app/store/actions/purchases';
import { getCart, setObservation } from '../../app/store/actions/cart';
import InputSize from '../../data/inputs';
import PriceUtils from '../../app/utils/price';
import Pagseguro from '../../app/services/pagseguro';
import { getUserCards } from '../../app/store/actions/auth';

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visiblePayment: false,
      visibleAddress: true,
      visibleResume: false,
      visibleEnd: false,
      payment: {},
      address: {},
      message: '',
      saveCard: false,
    };
  }

  async componentDidMount() {
    const { settings = {} } = this.props;
    const { paymentService = '' } = settings;
    try {
      if (paymentService === 'pagseguro') {
        await Pagseguro.initProcess();
        if (!Pagseguro.hasSessionId()) {
          const sessionId = await this.props.getPagSeguroSessionId();
          if (sessionId) Pagseguro.setSessionId(sessionId);
        }
      }
    } catch (e) { }

    await this.props.getUserCards();
  }

  componentWillUnmount() {
    const { settings = {} } = this.props;
    const { paymentService = '' } = settings;
    if (paymentService === 'pagseguro') Pagseguro.endProcess();
  }

  setPayment = async (type, { card, document } = {}, saveCard = false) => {
    await this.setState({
      payment: { type, card, document }, visiblePayment: false, visibleResume: true, saveCard,
    });
  };

  setAddress = async (address) => {
    const { payment } = this.state;

    await this.setState({
      visibleAddress: false,
      visiblePayment: !payment.type,
      visibleResume: !!payment.type,
      address,
    });
  };

  changeAddress = async () => {
    await this.setState({
      visibleAddress: true,
      visibleEnd: false,
      visiblePayment: false,
      visibleResume: false,
      address: {},
      ticket: null,
    });
  };

  changePayment = async () => {
    const { payment } = this.state;
    delete payment.type;

    await this.setState({
      visibleAddress: false,
      visibleEnd: false,
      visiblePayment: true,
      visibleResume: false,
      payment,
    });
  };

  finalize = async () => {
    const {
      address,
      payment,
      saveCard,
    } = this.state;
    const { settings = {} } = this.props;
    const { paymentService = '' } = settings;

    let document;
    if (payment.document) {
      document = {
        ...payment.document,
        value: StringHelper.keepOnlyNumber(payment.document.value),
      };
    }

    try {
      const paymentInfos = {};
      if (paymentService === 'pagseguro') paymentInfos.paymentService = paymentService;

      const ticket = await this.props.finalize(
        address, payment.type, payment.card, document, saveCard, paymentInfos,
      );

      if (ticket.redirectUrl) {
        window.location = ticket.redirectUrl;
      } else {
        await this.setState({
          visibleEnd: true,
          visibleResume: false,
          payment: {},
          address: {},
          ticket,
        });
        this.props.setObservation('');
      }
    } catch (err) {
      try {
        const validError = !!(err.response && err.response.data && err.response.data.error);

        if (validError && err.response.data.error.code === 900) {
          this.props.getCart();
          await this.setState({
            message: `${I18n.t('cartChanged')} (CODE: ${err.response.data.error.code})`,
          });
        } else if (validError && err.response.data.error.code === 907) {
          await this.setState({
            message: `${I18n.t('noLimit')} (CODE: ${err.response.data.error.code})`,
          });
        } else if (validError && err.response.data.error.code === 1003) {
          await this.setState({
            message: `${I18n.t('spotItemsError')} (CODE: ${err.response.data.error.code})`,
          });
        } else if (validError && [503, 903, 909].includes(err.response.data.error.code)) {
          await this.setState({
            message: `${I18n.t('errorPurchaseContact')} (CODE: ${err.response.data.error.code})`,
          });
        } else if (validError && err.response.data.error.code === 904) {
          await this.setState({
            message: `${I18n.t('errorInvalidDoc')} (CODE: ${err.response.data.error.code})`,
          });
        } else if (validError && err.response.data.error.code === 902) {
          let minimumPurchase = this.props.minimumPrice || 1.00;
          minimumPurchase = PriceUtils.getPriceText(minimumPurchase, true, this.props.settings.language);
          await this.setState({
            message: `${I18n.t('errorMinimumPrice').replace('{?}', minimumPurchase)} (CODE: ${err.response.data.error.code})`,
          });
        } else if (validError && err.response.data.error.code === 905) {
          await this.setState({
            message: `${I18n.t('errorInvalidCard')} (CODE: ${err.response.data.error.code})`,
          });
        } else if (validError && err.response.data.error.code === 906) {
          await this.setState({
            message: `${I18n.t('errorLocation')} (CODE: ${err.response.data.error.code})`,
          });
        } else if (validError && err.response.data.error.code === 500) {
          await this.setState({
            message: `${I18n.t('errorLocationOrClient')} (CODE: ${err.response.data.error.code})`,
          });
        } else if (validError && err.response.data.error.code === 101) {
          await this.setState({
            message: `${I18n.t('errorForbidden')} (CODE: ${err.response.data.error.code})`,
          });
        } else if (validError && err.response.data.error.code === 1004) {
          const sizeObs = this.props.settings && this.props.settings.companyTag === 'nortel'
            ? InputSize.observationNortel.max : InputSize.observation.max;
          await this.setState({
            message: `${I18n.t('errorMaxObservation').replace('{?}', sizeObs)} (CODE: ${err.response.data.error.code})`,
          });
        } else if (validError && err.response.data.error.code === 504) {
          await this.setState({
            message: `${I18n.t('errorGkoClientNotFound')} (CODE: ${err.response.data.error.code})`,
          });
        } else if (validError && err.response.data.error.code === 107) {
          const detailB = err.response.data.error.details ? err.response.data.error.details : '';
          await this.setState({
            message: `Erro Braspag - (${detailB || ''})`,
          });
        } else {
          const details = (validError && err.response.data.error.details) || err.message;
          await this.setState({
            message: I18n.t('errorTryLater') + (details ? ` (${details})` : ''),
          });
        }
      } catch (e) {
        const validError = err.response && err.response.data && err.response.data.error;
        await this.setState({
          message: I18n.t('errorTryLater') + (validError ? ` (CODE: ${err.response.data.error.code})` : ''),
        });
      }
    }
  };

  render() {
    const {
      minimumPrice, cartItems, pricePermission,
    } = this.props;
    const {
      payment, visiblePayment, visibleAddress, address,
      visibleEnd, visibleResume, ticket, message,
    } = this.state;

    const valueTotal = cartItems.reduce((total, item) => total + item.product.price.total, 0);

    return (
      <div className="container checkout">

        <ul className="checkout-progress-bar">
          <li className="active clickable" onClick={() => {!visibleEnd && this.changeAddress()}} style={{cursor: true}} >
            <span><Translate value="address" /></span>
          </li>
          <li className={visiblePayment || visibleEnd || visibleResume ? 'active clickable' : ''} onClick={() => {(visiblePayment || visibleEnd || visibleResume) && !visibleEnd && this.changePayment()}} >
            <span><Translate value="payment" /></span>
          </li>
          <li className={visibleResume || visibleEnd ? 'active' : ''}>
            <span><Translate value="summary" /></span>
          </li>
          <li className={visibleEnd ? 'active' : ''}>
            <span><Translate value="finalize" /></span>
          </li>
        </ul>

        <div className="row">
          {!visibleEnd && (
            <div className={`col-xl-${visibleResume ? '12' : '4 col-lg-12'}`}>
              <CheckoutResume
                address={address}
                payment={payment}
                cartItems={cartItems}
                changeAddress={this.changeAddress}
                changePayment={this.changePayment}
                visibleResume={visibleResume}
              />
              <p className="color-red">{message}</p>
              {visibleResume && (
                <div style={{ width: '100%', textAlign: 'center', margin: '0 auto 40px' }}>
                  {!pricePermission
                    || minimumPrice <= valueTotal ? (
                      <button
                        type="button"
                        className="button-action btn btn-secondary"
                        onClick={() => this.finalize()}
                      >
                        <Translate value="finalizeOrder" />
                      </button>
                    ) : (
                      <p>
                        <Translate value="minimumPayment" />
                        {' '}
                        {minimumPrice}
                        {' '}
                        <Translate value="minimumPayment2" />
                      </p>
                    )}
                </div>
              )}

            </div>
          )}
          {!visibleResume && (
            <div className={`col-xl-${visibleEnd ? '12' : '8'} order-xl-first`}>
              {visibleAddress && <CheckoutAddress setAddress={this.setAddress} />}
              {visiblePayment && (
                <PaymentComponent
                  valueTotal={valueTotal}
                  setPayment={this.setPayment}
                  payment={payment}
                />
              )}
              {visibleEnd && <CheckoutFinalize ticket={ticket} companyTag={this.props.settings && this.props.settings.companyTag} />}
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  minimumPrice: state.contracts.selected.clientDetails && state.contracts.selected.clientDetails.minimumPurchase > 0
    ? Number(state.contracts.selected.clientDetails.minimumPurchase)
    : Number(state.settings.minimumPurchase),
  pricePermission: state.contracts.selected && state.contracts.selected.pricePermission,
  settings: state.settings,
  parcel: state,
});

const mapDispatchToProps = dispatch => ({
  finalize: (location, type, card, document, saveCard = false, paymentInfos = {}) => dispatch(
    finalizePurchase(location, type, card, document, saveCard, paymentInfos),
  ),
  getPagSeguroSessionId: () => getPagSeguroSessionId(dispatch),
  getCart: () => dispatch(getCart()),
  getUserCards: () => dispatch(getUserCards()),
  setObservation: text => dispatch(setObservation(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
