/* eslint-disable import/prefer-default-export */
import { addLoading, removeLoading } from './loading';
import ReportServer from '../../server/reports';

export const getProductsReports = () => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    await ReportServer.getProductsReportsPdf(clientId);
  } finally {
    dispatch(removeLoading());
  }
};
