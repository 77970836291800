import React from 'react';
import InputMask from 'react-input-mask';

const SolicitationFormInputMask = ({
  mask, label, value, onChange,
}) => (
  <InputMask
    mask={mask}
    value={value}
    onChange={onChange}
  >
    {inputProps => (
      <input
        {...inputProps}
        type="text"
        className="form-control"
        placeholder={label}
        required
      />
    )}
  </InputMask>
);

export default SolicitationFormInputMask;
