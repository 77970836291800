import React from 'react';
import ReactPaginate from 'react-paginate';
import { I18n } from 'react-redux-i18n';

const Paginate = ({
  pageCount, forcePage, onPageChange, marginPagesDisplayed = 1, pageRangeDisplayed = 5,
}) => (
  <React.Fragment>
    {(pageCount || 0) > 0 && (
    <ReactPaginate
      previousLabel={I18n.t('before')}
      nextLabel={I18n.t('next')}
      pageCount={pageCount}
      marginPagesDisplayed={marginPagesDisplayed}
      pageRangeDisplayed={pageRangeDisplayed}
      forcePage={forcePage}
      onPageChange={onPageChange}
      containerClassName="pagination"
      pageClassName="page-item"
      pageLinkClassName="page-link"
      activeClassName="active"
      breakClassName="page-item break"
      breakLinkClassName="page-link"
      previousClassName="hide"
      nextClassName="hide"
    />
    )}
  </React.Fragment>
);

export default Paginate;
