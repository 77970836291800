import React from 'react';
import { connect } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import ContractsList from '../components/contracts/ContractsList';

const ContractsPage = ({ contracts = [] }) => (
  <main className="main">
    <div className="container">
      <Breadcrumb items={[{
        value: I18n.t('contract'),
      }]}
      />
      <ContractsList contracts={contracts} />
    </div>
  </main>
);

const mapStateToProps = state => ({
  contracts: state.contracts.list,
});

export default connect(mapStateToProps)(ContractsPage);
