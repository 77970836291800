import React from 'react';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import RegisterForm from '../components/register';

const RegisterPage = () => (
  <main className="main">
    <Breadcrumb items={[{
      value: I18n.t('accessRequest'),
    }]}
    />
    <RegisterForm />
  </main>
);
export default RegisterPage;
