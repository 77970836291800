import React from 'react';

import CartTable from './CartTable';
import CartOrderBy from './CartOrderBy';
import CartResume from './CartResume';

import CartUtil from '../../app/utils/cart';

const CartProducts = ({
  cartItems, cartOrderBy, setView, companyTag,
}) => (
  <div className="container">
    <div className="row">
      <div className="col-lg-8">
        <CartOrderBy cartOrderBy={cartOrderBy} />
        <CartTable cartItems={cartItems} />
      </div>

      <div className="col-lg-4">
        <CartResume
          hasItems={cartItems.length > 0}
          subTotal={CartUtil.getCartTotal(cartItems, true, undefined, undefined, companyTag)}
          st={CartUtil.getCartSt(cartItems, companyTag)}
          setView={setView}
        />
      </div>
    </div>
  </div>
);

export default CartProducts;
