/* eslint-disable no-undef */
/* eslint-disable no-empty */
import { initPagSeguroProcess, endPagSeguroProcess } from '../store/actions/purchases';

const waitForScript = (resolve, valueToRet, maxTimeWait = 2000, timerAcum = 0) => {
  if (timerAcum >= maxTimeWait) {
    resolve(false);
  } else if (typeof PagSeguroDirectPayment !== 'undefined') {
    resolve(true);
  } else {
    setTimeout(() => waitForScript(resolve, valueToRet, maxTimeWait, timerAcum + 250), 250);
  }
};

const initProcess = async () => {
  try {
    const inited = await initPagSeguroProcess();

    return new Promise(resolve => waitForScript(resolve, inited));
  } catch (e) { }

  return false;
};

const endProcess = () => {
  try {
    endPagSeguroProcess();
    return true;
  } catch (e) { }

  return false;
};

const setSessionId = (sessionId) => {
  try {
    PagSeguroDirectPayment.setSessionId(sessionId);
    PagSeguroDirectPayment.hasSessionId = true;

    return true;
  } catch (e) { }

  return false;
};

const hasSessionId = () => PagSeguroDirectPayment.hasSessionId;

const getSenderHash = async () => {
  try {
    return new Promise((resolve) => {
      try {
        PagSeguroDirectPayment.onSenderHashReady((response) => {
          try {
            if (!response || response.status === 'error') {
              return resolve(false);
            }
            return resolve(response.senderHash);
          } catch (err) { return resolve(false); }
        });
      } catch (err) { return resolve(false); }
    });
  } catch (e) { }

  return false;
};

const getBrand = async (card = {}) => {
  try {
    return new Promise((resolve) => {
      try {
        PagSeguroDirectPayment.getBrand({
          cardBin: parseInt(card.number.substring(0, 6), 10),
          success: (res) => {
            const validCard = res && res.brand && res.brand.name;
            if (validCard) {
              resolve(res.brand.name);
              return;
            }

            return false;
          },
          error: () => resolve(false),
          complete: () => false,
        });
      } catch (err) { return resolve(false); }
    });
  } catch (e) { }

  return false;
};

const getInstallments = async (amount, installments, brand) => {
  try {
    return new Promise((resolve) => {
      try {
        PagSeguroDirectPayment.getInstallments({
          amount,
          maxInstallmentNoInterest: installments,
          brand,
          success: (res) => {
            const validInstallments = res && res.installments && res.installments[brand];
            if (validInstallments) {
              return resolve(res.installments[brand]);
            }

            return resolve(false);
          },
          error: () => resolve(false),
          complete: () => false,
        });
      } catch (err) { return resolve(false); }
    });
  } catch (e) { }

  return false;
};

// BOLETO, ONLINE_DEBIT, CREDIT_CARD
const getPaymentMethods = async (paymentMethod = '') => {
  try {
    return new Promise((resolve) => {
      try {
        PagSeguroDirectPayment.getPaymentMethods({
          success: (res) => {
            const validInstallments = res && res.paymentMethods;
            if (validInstallments) {
              if (paymentMethod && res.paymentMethods[paymentMethod]) {
                return resolve(res.paymentMethods[paymentMethod]);
              }

              if (!paymentMethod) {
                return resolve(res.paymentMethods);
              }
            }

            return resolve(false);
          },
          error: () => resolve(false),
          complete: () => false,
        });
      } catch (err) { return resolve(false); }
    });
  } catch (e) { }

  return false;
};

const createCardToken = async (card = {}, brandName) => {
  try {
    return new Promise(async (resolve) => {
      try {
        const splittedExpirationDate = card.expirationDate.split('/');
        PagSeguroDirectPayment.createCardToken({
          cardNumber: card.number,
          brand: brandName,
          cvv: card.securityCode.replace(/[^0-9]/g, ''),
          expirationMonth: splittedExpirationDate[0],
          expirationYear: splittedExpirationDate[1],
          success: (res) => {
            const validTokenCard = res && res.card && res.card.token;
            if (validTokenCard) {
              return resolve(res.card.token);
            }

            return resolve(false);
          },
          error: () => resolve(false),
          complete: () => false,
        });
      } catch (err) { return resolve(false); }
    });
  } catch (e) { }

  return false;
};

export default {
  initProcess,
  endProcess,
  setSessionId,
  getBrand,
  hasSessionId,
  getInstallments,
  getPaymentMethods,
  getSenderHash,
  createCardToken,
};
