import React from 'react';
import { connect } from 'react-redux';

import HeaderTopRightPrivate from './HeaderTopRightPrivate';
import HeaderTopRightPublic from './HeaderTopRightPublic';

const HeaderTopRight = ({
  isAuthenticated, user, contracts, punchOutData, panelPermission,
}) => (
  <div className="header-right">
    {isAuthenticated ? (
      <HeaderTopRightPrivate
        user={user}
        contracts={contracts}
        isPunchOut={punchOutData}
        panelPermission={panelPermission}
      />
    ) : (
      <HeaderTopRightPublic />
    )}
  </div>
);

const mapStateToProps = state => ({
  punchOutData: state.punchOut,
  isAuthenticated: state.auth.authenticated,
  user: state.auth.user,
  contracts: state.contracts,
  panelPermission: state.contracts.selected && state.contracts.selected.panelPermission,
});

export default connect(mapStateToProps)(HeaderTopRight);
