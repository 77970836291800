import { I18n } from 'react-redux-i18n';

const keepOnlyNumber = value => value.replace(/\D/g, '');

const countDecimals = (value) => {
  if (Math.floor(value) === value || !value) return 0;

  const decs = value.toString().split('.')[1];

  if (decs[decs.length - 1] === '0') return 0;
  return decs.length;
};

function cepMask(cep) {
  const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;

  if (re.test(cep)) {
    return cep.replace(re, '$1.$2-$3');
  }
  return I18n.t('invalidCep');
}

export default {
  keepOnlyNumber,
  countDecimals,
  cepMask,
};
