import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';

export class MultipleCarousel extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      index: 0,
      previous: false,
      next: true,
      visibles: 1,
      dimensions: {
        md: 992,
        sm: 768,
        xs: 576,
      },
    };
  }

  async componentDidMount() {
    const { visibles } = this.state;
    const { start } = this.props;

    if (start !== undefined) await this.setState(() => ({ ...this.updateState(start, visibles) }));

    this.setItemsVisibles();
    window.addEventListener('resize', this.setItemsVisibles);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.setItemsVisibles);
  }

  setItemsVisibles = async () => {
    const { index, visibles, dimensions } = this.state;
    const {
      xs = 1, sm = 2, md = 3, lg = 4,
    } = this.props;

    const pageWidth = window.innerWidth;

    let newVisibles = lg;
    if (pageWidth < dimensions.xs) newVisibles = xs;
    else if (pageWidth < dimensions.sm) newVisibles = sm;
    else if (pageWidth < dimensions.md) newVisibles = md;

    if (newVisibles !== visibles) {
      await this.setState(() => ({ visibles: newVisibles, ...this.updateState(index, newVisibles) }));
    }
  }

  previousItem = async () => {
    const { index, visibles } = this.state;
    await this.setState(() => ({ ...this.updateState(index - 1, visibles) }));
  }

  nextItem = async () => {
    const { index, visibles } = this.state;
    await this.setState(() => ({ ...this.updateState(index + 1, visibles) }));
  }

  updateState = (index, visibles) => {
    const { items = [] } = this.props;

    index = Math.max(Math.min(index, items.length - visibles), 0);

    return {
      previous: index !== 0,
      next: index < items.length - visibles,
      index,
    };
  }

  handleTouchStart = (event) => {
    this.hasMoved = false;
    this.startX = event.touches[0].clientX;
    this.startY = event.touches[0].clientY;
  }

  handleTouchMove = (event) => {
    if (!this.hasMoved) {
      const moveDistance = 40;
      const moveX = event.touches[0].clientX;
      const moveY = event.touches[0].clientY;
      if (Math.abs(this.startX - moveX) > Math.abs(this.startY - moveY) * 2) {
        if (this.startX > moveX + moveDistance) {
          this.nextItem();
          this.hasMoved = true;
        } else if (this.startX < moveX - moveDistance) {
          this.previousItem();
          this.hasMoved = true;
        }
      }
    }
  }

  render() {
    const {
      previous, next, index, visibles,
    } = this.state;
    const { items = [], arrows = false } = this.props;

    const itemWidth = 100 / visibles;

    const needArrows = (previous || next) && arrows;

    const cItems = items.map((element, key) => ({ key, element }));

    return (
      <div className="carousel">
        {needArrows && (
          <div className="button-action">
            <button type="button" className="button" disabled={!previous} onClick={this.previousItem}>
              <FontAwesomeIcon icon={faAngleLeft} />
            </button>
          </div>
        )}
        <div className={`items${needArrows ? ' btn_actived' : ''}`}>
          <div
            className="inner"
            style={{
              width: `${itemWidth * items.length}%`,
              left: `-${itemWidth * index}%`,
            }}
            onTouchStart={this.handleTouchStart}
            onTouchMove={this.handleTouchMove}
          >
            {cItems.map(item => (
              <div
                key={item.key}
                className="item"
                style={{
                  width: `${100 / items.length}%`,
                }}
              >
                {item.element}
              </div>
            ))}
          </div>
        </div>
        {needArrows && (
          <div className="button-action">
            <button type="button" className="button" disabled={!next} onClick={this.nextItem}>
              <FontAwesomeIcon icon={faAngleRight} />
            </button>
          </div>
        )}
      </div>
    );
  }
}

export default MultipleCarousel;
