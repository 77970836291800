import React from 'react';
import { Translate } from 'react-redux-i18n';

import LandingFeaturesItem from './LandingFeaturesItem';

const LandingFeatures = () => (
  <div className="landing-features" id="features">
    <div className="content">
      <h2><Translate value="features" /></h2>
      <div className="row">
        <div className="col-md-4">
          <LandingFeaturesItem
            image="feature-1"
            title="Privacidade"
            text="Lorem Ipsum is simply dummytext of the printing and typesetting industry."
          />
        </div>
        <div className="col-md-4">
          <LandingFeaturesItem
            image="feature-2"
            title="Segurança"
            text="Lorem Ipsum is simply dummytext of the printing and typesetting industry."
          />
        </div>
        <div className="col-md-4">
          <LandingFeaturesItem
            image="feature-3"
            title="Conectado"
            text="Lorem Ipsum is simply dummytext of the printing and typesetting industry."
          />
        </div>
      </div>
    </div>
  </div>
);


export default LandingFeatures;
