import newRegistersRequests from '../../server/newRegister';
import { addLoading, removeLoading } from './loading';

export const buscaCep = cep => async (dispatch) => {
  dispatch(addLoading());
  try {
    const address = await newRegistersRequests.buscaCep(cep);
    return address;
  } finally {
    dispatch(removeLoading());
  }
};

export const checkRegister = (email, cnpj, type) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const res = await newRegistersRequests.checkRegister(email, cnpj, type);
    return res;
  } finally {
    dispatch(removeLoading());
  }
};

export const newRegister = data => async (dispatch) => {
  dispatch(addLoading());
  try {
    const res = await newRegistersRequests.createNewRegister(data);
    return res;
  } finally {
    dispatch(removeLoading());
  }
};
