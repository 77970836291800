import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import inputSize from '../../data/inputs';

export class ProductsFiltersSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: this.props.text,
    };
  }

  setSuggest = (text) => {
    this.setState({ text });
    this.props.onClick({ text });
  }

  handleKeyPressSearch = (event) => {
    if(event.key === 'Enter'){
      this.props.onClick({ text: this.state.text })      
    }
  }

  render() {
    const { suggest } = this.props;
    return (
      <div className="widget">

        <h3 className="widget-title">
          {I18n.t('search')}
        </h3>

        <div className="collapse show" id="widget-body-1">
          <div className="widget-body">
            <div className="filter-text">
              <input
                type="search"
                className="form-control"
                placeholder={I18n.t('searchTerm')}
                value={this.state.text}
                maxLength={inputSize.maxDefault}
                onChange={e => this.setState({ text: e.target.value })}
                onKeyPress={this.handleKeyPressSearch}
              />
            </div>
            {suggest.length > 0 && (
              <div className="suggest">
                <span><Translate value="didYouMean" /></span>
                <div>
                  {suggest.map((item, index) => (
                    <div
                      key={item.value || index}
                      className="suggest-search"
                      onClick={() => this.setSuggest(item)}
                      onKeyDown={() => this.setSuggest(item)}
                      role="textbox"
                      tabIndex="0"
                    >
                      {item}
                    </div>
                  ))
                  }
                </div>
              </div>
            )}
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => this.props.onClick({ text: this.state.text })}
            >
              <Translate value="search" />

            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsFiltersSection;
