import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import inputSize from '../../data/inputs';

export class ProductsFiltersOrdination extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { selected, onChange } = this.props;
    return (
      <div className="widget">

        <h3 className="widget-title">
          {I18n.t('ordenation')}
        </h3>

        <div className="collapse show" id="widget-body-1">
          <div className="widget-body">
            <div className="toolbox-item toolbox-sort">
              <div className="select-custom">
                <select name="orderby" 
                  className="form-control" 
                  onChange={(e) => onChange({['sort_selected']: e.target.value})}>                    
                  <option value="" selected={selected == "" ? true : false}>{I18n.t('all')}</option>
                  <option value="highlighted" selected={selected == "highlighted" ? true : false}>{I18n.t('highlighted')}</option>
                  <option value="price" selected={selected == "price" ? true : false}>{I18n.t('orderPriceLowToHigh')}</option>
                  <option value="price-desc" selected={selected == "price-desc" ? true : false}>{I18n.t('orderPriceHighToLow')}</option>
                </select>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProductsFiltersOrdination;
