import React from 'react';
import { connect } from 'react-redux';
import queryString from 'query-string';

import { I18n } from 'react-redux-i18n';
import { getProductsSearch } from '../app/store/actions/products';

import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import ProductsList from '../components/productsList/ProductsList';
import ProductsListPagination from '../components/productsList/ProductsListPagination';
import ProductsFilters from '../components/productsFilters/ProductsFilters';


import { history } from '../routers/AppRouter';

import ROUTES from '../data/routes';

export class ProductsPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      breadcrumbItens: [{
        value: I18n.t('product'), click: () => this.resetSearch()
      }] 
    };
  }

  async componentDidMount() {    
    await this.updateItems();
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.contractId !== this.props.contractId) {
      await this.updateItems();
    }
  }

  getSearch = () => {
    const params = queryString.parse(this.props.location.search);

    return {
      text: params.text,
      categories: params.categories && params.categories.split(';').map(c => Number(c)),
      brands: params.brands && params.brands.split(';'),      
      pdmsModifiers: params.pdmsModifiers && params.pdmsModifiers.split(';'),
      pdmsParticulars: params.pdmsParticulars && params.pdmsParticulars.split(';'),
      type: params.type,
      page: params.page || 1,
      sort_selected: params.sort_selected,
    };
  }

  updateSearch = (newSearch) => {
    const searchTerms = {
      ...this.getSearch(),
      ...newSearch,
    };

    if (!newSearch.page) searchTerms.page = 1;

    const urlParameters = Object.entries(searchTerms).map(e => e
      .map(a => (Array.isArray(a) ? a.join(';') : a)).join('=')).join('&');
    
    history.push(`${ROUTES.PRODUCTS}?${urlParameters}`);
  }

  resetSearch = () => {
    history.push(ROUTES.PRODUCTS);
  }

  updateItems = async () => {
    const searchTerms = this.getSearch();
    const needResetFilters = Number(searchTerms.page) === 1 || !this.props.filters;
    await this.props.getProducts(this.getSearch(), needResetFilters);
  }

  handlePageClick = (data) => {
    const { selected: pageIndex } = data;
    this.updateSearch({ page: pageIndex + 1 });
  }

  setBreadcrumbItens = (index, value, click) => {
    const { breadcrumbItens } = this.state;
    
    breadcrumbItens.splice(index);
    if (!breadcrumbItens[index] || (breadcrumbItens[index] && breadcrumbItens[index].value != value)) {
      breadcrumbItens[index] = {value: value, click: click};      
      this.state.breadcrumbItens =  breadcrumbItens;
    } else {
      this.state.breadcrumbItens =  breadcrumbItens;
    }
  }

  render() {
    const { products = [], filters = {}, suggest = [] } = this.props;
    const { breadcrumbItens } = this.state;

    return (
      <main className="main">
        <Breadcrumb items={ breadcrumbItens }
        />
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="container">
                <ProductsList products={products} />
                <ProductsListPagination
                  page={this.getSearch().page}
                  pages={filters.pages}
                  total={filters.total}
                  handlePageClick={this.handlePageClick}
                />
              </div>
            </div>
            <aside className="sidebar-shop col-lg-4 order-lg-first">
              <ProductsFilters
                filters={filters}
                search={this.getSearch()}
                updateSearch={this.updateSearch}
                resetSearch={this.resetSearch}
                setBreadcrumbItens={this.setBreadcrumbItens}
                suggest={suggest}
              />
            </aside>
          </div>
        </div>

      </main>
    );
  }
}

const mapStateToProps = state => ({
  contractId: state.contracts.selected && state.contracts.selected.client.id,
  products: state.products.list,
  suggest: state.products.suggest,
  filters: state.products.filters,
});

const mapDispatchToProps = dispatch => ({
  getProducts: (search, resetFilter) => dispatch(getProductsSearch(search, resetFilter)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPage);
