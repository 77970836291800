import React from 'react';
import Swal from 'sweetalert2';

import UploadFilesComponent from '../upload/UploadFilesComponent';
import CartImportErrors from '../upload/CartImportErrors';
import CartImportSplider from './CartImportSplider';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { uploadProductsList, getProductListModel } from '../../app/store/actions/cart';

import ROUTES from '../../data/routes';

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      errors: [],
    };
  }
    
  sendFile = async (file) => {    
    await this.setState({ errors: {} });
    Swal.fire({
      position: 'top-end',
      icon: 'info',
      title: I18n.t('cartImportUploadWaitMessage'),
      showConfirmButton: false,
      timer: 10000
    })
    const result = await this.props.uploadProductsList(file);
    Swal.close();
    await this.setState({  totalAdded: result.totalAdded || 0, errors: (result && result.errors) || [] });
  }

  goBack = () => {
    history.push(ROUTES.CART_IMPORT);
  }

  render() {
    const { errors, totalAdded } = this.state;
    const { cartItems, cartOrderBy, cartLoaded, companyTag } = this.props;

    return (
      <div>
        <div className="info-boxes-container">
          <div className="container">
            <div className="info-box">
              <div className="info-box-content">
                <h4><Translate value="cartImportDownloadInstruction1" /></h4>
                <p><Translate value="cartImportDownloadInstruction2" /></p>
                <button
                  className="btn btn-block btn-sm btn-info"
                  type="button"
                  onClick={() => this.props.getProductListModel()}              
                >
                  <Translate value="cartImportDownloadInstruction3" />
                </button>
              </div>          
            </div>
          </div>            
        </div>
        <div>
          <center>
            <table style={{paddingBottom: '0px', marginLeft: '10%', marginRight: '10%'}}>
              <tbody>
                <tr>
                  <td style={{width:'45%'}}><CartImportSplider></CartImportSplider></td>
                  <td style={{paddingLeft: '50px', verticalAlign: 'middle', lineHeight: '20px'}}>
                    <b><h4><Translate value="followTheSteps" /></h4></b>
                    <p>1 - <Translate value="cartImportStep1" /></p>
                    <p>2 - <Translate value="cartImportStep2" /></p>
                    <p>3 - <Translate value="cartImportStep3" /></p>
                    <p>4 - <Translate value="cartImportStep4" /></p>
                  </td>
                </tr>
              </tbody>
            </table>
          </center>
        </div>
        <br />
        <div className="container">
          <center>
            <span><Translate value="cartImportUploadInstruction" /></span>
            <UploadFilesComponent
                sendFile={this.sendFile}
                itemsErrors={<CartImportErrors totalAdded={totalAdded} errors={errors} />}
              />
          </center>
        </div>
      </div>
    
    )
   
  }
}

const mapDispatchToProps = dispatch => ({
  uploadProductsList: file => dispatch(uploadProductsList(file)),
  getProductListModel: () => dispatch(getProductListModel()),
});

export default connect(null, mapDispatchToProps)(Cart);
