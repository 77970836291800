import React from 'react';

import { Translate } from 'react-redux-i18n';
import Paginate from '../elements/Paginate';

import ProductsData from '../../data/products';


const ProductsListPagination = ({
  page = 0, pages, total = 0, handlePageClick,
}) => (
  <nav className="toolbox toolbox-pagination">
    <div className="toolbox-item toolbox-show">
      <div>
        <Translate value="showing" />
        {` ${Math.min((ProductsData.limit * (page - 1)) + 1, total)}`}
        {`-${Math.min(ProductsData.limit * page, total)} `}
        <Translate value="of" />
        {` ${total} `}
        <Translate value="results" />
      </div>
    </div>

    <Paginate
      pageCount={pages}
      forcePage={page - 1}
      onPageChange={handlePageClick}
    />
  </nav>
);

export default ProductsListPagination;
