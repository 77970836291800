import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';

class StepCompanyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: props.company.name,
      hasIe: false,
      ie: props.company.ie,
      purpose: props.company.purpose,
      business: props.company.business,
      error: '',
      message: '',
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      name, ie, purpose,
      business,
    } = this.state;

    const company = {
      name,
      ie: ie.trim(),
      purpose,
      business,
    };

    await this.props.setCompany(company);
    this.props.handleNext();
  };

  setValue = async (attr, value) => {
    await this.setState({
      error: '',
      [`${attr}`]: value,
    });
  }

  getInput = (label, attr, value, disabled = true, required = true, minLength = 1, maxLength = 100) => (
    <input
      type="text"
      className="form-control"
      placeholder={label}
      minLength={minLength}
      maxLength={maxLength}
      value={value}
      onChange={e => this.setValue(attr, e.target.value)}
      required={required}
      disabled={disabled}
    />
  );

  getSelect = (items, label, selected, onChange) => (
    <div className="select-custom">
      <select name="orderby" className="form-control" defaultValue={selected} onChange={onChange} required>
        {selected === '' && <option value="" disabled>{label}</option>}
        {items.map(item => (
          <option key={item.id} value={item.id}>{item.name}</option>
        ))}
      </select>
    </div>
  );

  selectCheckItem = (id, checked) => {
    this.setState({
      ie: '',
      [`${id}`]: checked,
    });
  };

  getCheckbox = (check, name, label, disabled = false) => (
    <div
      className="form-check"
      style={{ paddingLeft: '2rem' }}
    >
      <input
        onChange={e => this.selectCheckItem(e.target.id, e.target.checked)}
        type="checkbox"
        className="form-check-input"
        checked={!!check}
        id={`${name}`}
        style={{ marginLeft: '-2rem', marginTop: '.8rem' }}
        disabled={disabled}
      />
      <span>
        {`${label}`}
        <small>{` (${I18n.t('obsIe')})`}</small>
      </span>
    </div>
  );

  render() {
    const {
      name, hasIe, ie, purpose,
      business, error, message,
    } = this.state;

    const {
      purposes, selecBusiness,
    } = this.props;

    return (
      <form onSubmit={this.onSubmit} className="col-md-8">


        {this.getInput(I18n.t('companyName'), 'name', name, false, true, 2, 100)}
        {this.getCheckbox(hasIe, 'hasIe', I18n.t('hasIe'))}
        {this.getInput(I18n.t('stateRegistration'), 'ie', ie, !hasIe, false, 0, 19)}
        {this.getSelect(purposes, I18n.t('purpose'), purpose, e => this.setValue('purpose', e.target.value))}
        {this.getSelect(
          selecBusiness,
          I18n.t('business'),
          business, e => this.setValue('business', e.target.value),
        )}

        {error ? <p className="error-p">{error}</p> : ''}

        <div className="row justify-content-center margin-top-20 margin-bottom-20 form-footer">
          <button
            type="button"
            onClick={this.props.handleBack}
            className="backButton btn btn-light"
          >
            <Translate value="return" />
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            variant="contained"
          >
            <Translate value="next" />
          </button>
        </div>

        {message ? <p className="error-p">{message}</p> : ''}
      </form>
    );
  }
}

const mapStateToProps = state => ({
  companyTag: state.settings.companyTag,
});

export default connect(mapStateToProps)(StepCompanyForm);
