import ProductsServer from '../../server/products';
import { addLoading, removeLoading } from './loading';

import ProductsData from '../../../data/products';

export const resetProducts = () => ({
  type: 'PRODUCTS_RESET',
});

export const setPricing = (listType, products) => ({
  type: 'PRODUCTS_PRICING_SET',
  listType,
  products,
});

export const setList = (products, filters, suggest) => ({
  type: 'PRODUCTS_LIST_SET',
  products,
  filters,
  suggest,
});

export const getProductsPricing = products => async (dispatch, getState) => {
  const selectedContract = getState().contracts.selected;
  if (selectedContract) {
    const { client = {}, userId } = selectedContract;
    const pricing = await ProductsServer.getPricing(client.id, userId, products.map(p => p.id));
    products = products.map((item) => {
      const price = pricing.find(p => p.id === item.id);
      if (price) {
        if (!item.price.isContract) item.price = { ...item.price, ...price };
        else item.price = { ...item.price, hasPrice: true };
      } else {
        item.price = {
          contactUs: true,
        };
      }
      return { ...item };
    });
  }
  return products;
};

export const getPricing = (listType, products) => async (dispatch) => {
  products = await dispatch(getProductsPricing(products));
  dispatch(setPricing(listType, products));
};

const getProductsSearchItems = async (clientId, searchOptions) => {
  if (clientId) return ProductsServer.getClientSearch(clientId, searchOptions);
  return ProductsServer.getSearch(searchOptions);
};

const getProductsFilter = async (clientId, searchOptions) => {
  if (clientId) return ProductsServer.getClientFilters(clientId, searchOptions);
  return ProductsServer.getFilters(searchOptions);
};

export const getProductsSearch = (search = {}, resetFilter = false) => async (dispatch, getState) => {
  const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
  let products = [];
  let filters = [];
  let suggest = [];

  dispatch(addLoading());
  try {
    let mainCategories;
    let subCategories;
    if (search.categories) {
      mainCategories = search.categories
        .filter(categoryId => getState().settings.categories.find(c => c.id === categoryId));
      subCategories = search.categories.filter(categoryId => mainCategories.indexOf(categoryId) === -1);
    }

    const page = search.page || 1;
    const searchOptions = {
      skip: (page - 1) * ProductsData.limit,
      limit: ProductsData.limit,
      text: search.text,
      mainCategories,
      subCategories,
      brands: search.brands,
      pdmsModifiers: search.pdmsModifiers,
      pdmsParticulars: search.pdmsParticulars,
      type: search.type,
      sort_selected: search.sort_selected ||  '',
    };

    [{ products, suggest }, filters] = await Promise.all([
      getProductsSearchItems(clientId, searchOptions),
      resetFilter && getProductsFilter(clientId, searchOptions),
    ]);
    
    dispatch(setList(products, filters, search.text ? suggest : []));
  } finally {
    dispatch(removeLoading());
    if (clientId) dispatch(getPricing('list', products));
  }
};

export const setFeatured = products => ({
  type: 'PRODUCTS_FEATURED_SET',
  products,
});

export const getProductsFeatured = force => async (dispatch, getState) => {
  if (force || !getState().products.featured) {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    const products = clientId
      ? await ProductsServer.getClientFeatured(clientId)
      : await ProductsServer.getFeatured();

    dispatch(setFeatured(products));
    if (clientId) dispatch(getPricing('featured', products));
  }
};

export const getProductsSimilar = productId => async (dispatch, getState) => {
  const { selected } = getState().contracts;

  if (!selected) return [];

  const clientId = selected.client && selected.client.id;
  const { similarityPermission } = selected;

  if (!similarityPermission || !clientId) return [];

  return ProductsServer.getClientSimilar(clientId, productId);
};

export const getProductsCrossSelling = productId => async (dispatch, getState) => {
  const { selected = {} } = getState().contracts;

  if (!selected) return [];

  const clientId = selected.client && selected.client.id;
  const { crossSellingPermission } = selected;

  if (!crossSellingPermission || !clientId) return [];

  return ProductsServer.getClientCrossSelling(clientId, productId);
};

export const setPromotions = products => ({
  type: 'PRODUCTS_PROMOTIONS_SET',
  products,
});

export const getProductsPromotions = force => async (dispatch, getState) => {
  const clientId = getState().contracts.selected && getState().contracts.selected.client.id;

  if (clientId && (force || !getState().products.promotions)) {
    const products = await ProductsServer.getClientPromotions(clientId);
    dispatch(setPromotions(products));
  }
};

export const getProductDetails = (productId, code) => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    const product = clientId
      ? await ProductsServer.getClientDetails(clientId, productId, code)
      : await ProductsServer.getDetails(productId);

    if (product.price && product.price.value) {
      product.price.hasPrice = true;
    } else {
      product.price = {
        contactUs: true,
      };
    }

    return product;
  } finally {
    dispatch(removeLoading());
  }
};
