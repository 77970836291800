import React from 'react';
import { Translate } from 'react-redux-i18n';

import PriceComponent from '../../price/Price';
import PriceTaxComponent from '../../price/PriceTax';

const CartsToApproveCart = (props) => {
  const { cart = {}, pricePermission } = props;
  const {
    user, products = [], cartId, type,
  } = cart;

  cart.cartToApprove = false;
  if (cart.approver2) {
    if (cart.approver1 && cart.status1 === 'open') {
      cart.cartToApprove = true;
    }
  }

  const renderCartInfos = () => {
    if (type === 'canceled') {
      return (
        <button
          type="button"
          className="btn btn-primary margin-bottom-10"
          onClick={() => props.copyCartCanceled(cartId)}
        >
          <Translate value="copyOrder" />
        </button>
      );
    }
    return (
      <div>
        {cart.hasLimit ? (
          <div>
            <button
              data-toggle="modal"
              data-target={`#cartrep-${cart.cartId}`}
              type="button"
              className="btn btn-light margin-bottom-10"
            >
              <Translate value="decline" />
            </button>
            {cart.cartToApprove ? (
              <button
                type="button"
                className="btn btn-primary margin-bottom-10"
                data-toggle="modal"
                data-target={`#cartapp-${cart.cartId}`}
              >
                <Translate value="approve" />
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary margin-bottom-10"
                onClick={() => props.setCart(cart)}
              >
                <Translate value="visualizeCart" />
              </button>
            )}
          </div>
        ) : (
          <div className="limit-warning">
            <Translate value="limitCartWarning" />
          </div>
        )}
      </div>
    );
  };

  return (
    <React.Fragment>
      <div className={`col-4 gradient-background-cart${!cart.hasLimit || type === 'canceled' ? ' not-allow' : ''}`}>
        <i className="fas fa-shopping-cart cart-approve-logo" />
        <p className="name">{user.name}</p>
        <p>{user.email}</p>
      </div>
      <div className="col-8 main-cart-approve">
        <h3>
          {'Código: #'}
          {cartId}
        </h3>
        <div className="col-12 overflow-auto-cart">

          {products.map(product => (
            <p className="cart-items-approve" key={product.id}>
              {'- '}
              {product.quantity}
              {' x '}
              {product.name}
              {pricePermission && ' - '}
              {pricePermission && <strong><PriceComponent value={Number(product.price.value) || 0} /></strong>}
            </p>
          ))}
        </div>

        {pricePermission && <PriceTaxComponent products={products} />}

        <div className="row btn-cart-approve-row">
          {renderCartInfos()}

          <div
            className="modal fade"
            id={`cartapp-${cart.cartId}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div style={{ width: '400px' }} className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    style={{
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                    className="modal-title"
                    id={`cartapp-${cart.cartId}`}
                  >
                    <Translate value="approveCart" />
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Translate value="approveCartConfirm" />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => props.approveCart(cart.cartId)}
                    className="btn btn-primary margin-bottom-10"
                    data-dismiss="modal"
                  >
                    <Translate value="approve" />
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div
            className="modal fade"
            id={`cartrep-${cart.cartId}`}
            tabIndex="-1"
            role="dialog"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div style={{ width: '400px' }} className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header">
                  <h5
                    style={{
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                    className="modal-title"
                    id={`cartrep-${cart.cartId}`}
                  >
                    <Translate value="declineCart" />
                  </h5>
                  <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <Translate value="declineCartConfirm" />
                </div>
                <div className="modal-footer">
                  <button
                    type="button"
                    onClick={() => props.removeCart(cart.cartId)}
                    className="btn btn-danger"
                    style={{ backgroundColor: '#b93744' }}
                    data-dismiss="modal"
                  >
                    <Translate value="decline" />
                  </button>
                </div>
              </div>
            </div>
          </div>


        </div>
      </div>
    </React.Fragment>
  );
};

export default CartsToApproveCart;
