/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const RctSwal = withReactContent(Swal);

import { cartUpdateItem, cartRemoveItem } from '../../app/store/actions/cart';

import NumberHelper from '../../helpers/number';
import ProductsUtil from '../../app/utils/products';
import inputSize from '../../data/inputs';
import PriceComponent from '../price/Price';
import CartAmountButton from './CartAmountButton';
import { isXpedCharacterValid } from '../../app/utils/cart';
import validator from '../../helpers/validators';

export class CartTableItem extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: props.item.total,
      multipleQuantity: props.item.product.multipleQuantity,
      xped: props.item.xped,
    };
  }

  formatTotal = value => value && Number(value).toFixed(NumberHelper.countDecimals(this.state.multipleQuantity));

  textTruncate = (str, length, ending) => {
    if (length == null) {
      length = 45;
    }
    if (ending == null) {
      ending = '...';
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    }
    return str;
  };

  handleSetAmount = async (value) => {
    if (value === '') await this.setState({ amount: '' });
    else if (!isNaN(value)) {
      if (NumberHelper.countDecimals(this.state.multipleQuantity) > 0) {
        value = Number(value.toString().replace(/\D+/g, ''))
        / (10 ** NumberHelper.countDecimals(this.state.multipleQuantity));
      }

      await this.setState({ amount: value });
    }

    if (this.props.companyTag === 'chile') {
      await this.setState({ prevTime: new Date().getTime() });
      var _this = this;
      setTimeout(function() {
        var currentTime = new Date().getTime();
        if (currentTime - _this.state.prevTime >= 1500) {
          if (_this.state.amount % _this.state.multipleQuantity === 0) {
            _this.updateProductToCart();
          } else {
            RctSwal.fire({
              html: `<h3>${I18n.t('sellMultipleNeeds') + ' ' + _this.state.multipleQuantity}</h3>`,
              width: 'auto',
              padding: '30px 40px 10px 40px',
            });
          }
        }
      }, 1500)
    }
  };

  handleSetXped = async (value) => {
    if (isXpedCharacterValid(value)) {
      await this.setState({ xped: value });
    }
  };

  updateProductToCart = async () => {
    const { amount, xped } = this.state;
    const { item = {}, allowSellSpotItensWithoutStock } = this.props;
    if (item.product) {
      if (validator.isSpotProductAmountInvalid(
        amount, item.product.stock, allowSellSpotItensWithoutStock, item.product.isSpot, item.product.price.isContract,
      )) return;
      await this.props.updateItem(item.product.id, item.product.clientCode, item.product.code, amount, xped);
    }
  };

  removeProductToCart = async () => {
    const { item = {} } = this.props;
    if (item.product) await this.props.removeItem(item.product.id, item.product.clientCode);
  };

  floatSafeModulus(val, step) {
    const valDecCount = (val.toString().split('.')[1] || '').length;
    const stepDecCount = (step.toString().split('.')[1] || '').length;
    const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
    const valInt = parseInt(Number(val).toFixed(decCount).replace('.', ''));
    const stepInt = parseInt(Number(step).toFixed(decCount).replace('.', ''));

    return (((valInt % stepInt) + stepInt) % stepInt) / Math.pow(10, decCount);
  }

  render() {
    const { amount = {}, xped = '' } = this.state;
    const {
      item = {}, pricePermission, stockPermission, leadTimePermission,
      taxesCart, taxName = 'ST', allowSellSpotItensWithoutStock,
      opicCodePermission, soneparCodePermission, soneparCodeOff,
      companyName, xpedPermission, companyTag,
    } = this.props;

    const isAmountInvalid = validator.isSpotProductAmountInvalid(
      amount, item.product.stock, allowSellSpotItensWithoutStock, item.product.isSpot, item.product.price.isContract,
    ) || this.floatSafeModulus(amount, this.state.multipleQuantity) !== 0;

    return (
      <React.Fragment>
        <tr className="product-row">
          {companyTag == 'chile' && 
          <td style={{paddingLeft: '0.5rem', paddingRight: '0.5rem'}}>
              {item.product.seqCart}
          </td>}
          <td>
            <div className="product-col">

              <figure className="product-image-container">
                <Link to={ProductsUtil.getUrl(item.product)} className="product-image">
                  <img
                    src={item.product.image || '/images/product-image-placeholder.png'}
                    alt={`${item.product.name} Product img`}
                  />
                </Link>

                {item.product.price
                  && item.product.price.isContract
                  && item.product.price.type === 'contract'
                  && <span className="product-label"><Translate value="contract" /></span>}

                {item.product.price
                  && item.product.price.isContract
                  && item.product.price.type === 'agreement'
                  && <span className="product-label label-agreement"><Translate value="agreement" /></span>}

              </figure>

              <div className="xped-col">
                <h2 className="product-title">
                  <Link to={ProductsUtil.getUrl(item.product)}>{this.textTruncate(item.product.name)}</Link>
                  <br />
                  {companyTag != 'chile' && taxesCart && <i>{`ICMS: ${(item.product.price && item.product.price.icms) || 0}%`}</i>}
                  {stockPermission && (
                    <div className="product-tags">
                      <span className={`product-label label-stock${!item.product.stock ? ' label-sale' : ''}`}>
                        {ProductsUtil.getStockAvailable(item.product.stock)}
                      </span>
                    </div>
                  )}
                  {leadTimePermission && (!item.product.stock || item.product.stock < amount) && (
                    <div className="product-tags">
                      <span className="product-label label-time">
                        {ProductsUtil.getStockTime(item.product.leadTime)}
                      </span>
                    </div>
                  )}
                </h2>
                {xpedPermission
                  && (
                  <div className="xped-input-col">
                    <span className="xped-title">
                      {' '}
                      <span
                        className="tooltip-xped"
                        title={I18n.t('xpedTooltipText')}
                      >
                        (?)
                      </span>
                      {' '}
                      xPed:

                    </span>
                    <input
                      className="input-xped form-control"
                      type="text"
                      maxLength={inputSize.xped.max}
                      value={this.state.xped}
                      onChange={e => this.handleSetXped(e.target.value)}
                    />

                    {xped && xped !== item.xped && (
                    <button
                      className="btn-move"
                      type="button"
                      onClick={this.updateProductToCart}
                    >
                      <Translate value="change" />
                    </button>
                    )}
                  </div>
)
                }
              </div>
              <div className="cod-item-col">
                {item.product.clientCode && (
                  <td style={{ padding: 0, border: 0 }}>
                    <React.Fragment>
                      <b>
                        <span>{I18n.t('clientCode2')}</span>
                      </b>
                      <br />
                      <span>{item.product.clientCode}</span>
                    </React.Fragment>
                  </td>
                )}

                {opicCodePermission && item.product.code && (
                  <td style={{ padding: 0, paddingTop: 5 }}>
                    <React.Fragment>
                      <b>
                        <span>{`${I18n.t('code2').replace(':', '')} ${companyName}:`}</span>
                      </b>
                      <br />
                      <span>{item.product.code}</span>
                    </React.Fragment>
                  </td>
                )}

                {soneparCodePermission && (!soneparCodeOff && item.product.id) && (
                  <td style={{ padding: 0, paddingTop: 5 }}>
                    <React.Fragment>
                      <b>
                        <span>{I18n.t('soneparCode')}</span>
                      </b>
                      <br />
                      <span>{item.product.id}</span>
                    </React.Fragment>
                  </td>
                )}
              </div>
            </div>
          </td>
          {pricePermission && (
            <td>
              <b>
                <PriceComponent value={item.product.price.value + (item.product.price.icmsST || 0)} />
              </b>
              {taxesCart && (
                <React.Fragment>
                  <br />
                  <Translate value="unityPrefix" />
                  {' '}
                  <PriceComponent value={item.product.price.value} />
                  <br />
                  {`${taxName}: `}
                  <PriceComponent value={item.product.price.icmsST} force />
                </React.Fragment>
              )}
            </td>
          )}
          <td style={companyTag == 'chile' ? { paddingLeft: '0.5rem', paddingRight: '0.5rem'} : {}}>
            <input
              className={`vertical-quantity${companyTag == 'chile'? '-electra' : ''} form-control ${isAmountInvalid ? 'invalid-amount-input' : ''}`}
              type="text"
              maxLength={inputSize.maxDefault}
              value={this.formatTotal(amount)}
              onChange={e => this.handleSetAmount(e.target.value)}
            />
            <CartAmountButton
              item={item}
              amount={amount}
              allowSellSpotItensWithoutStock={allowSellSpotItensWithoutStock}
              updateProductToCart={this.updateProductToCart}
              removeProductToCart={this.removeProductToCart}
              isAmountInvalid={isAmountInvalid}
              multipleQuantity={this.state.multipleQuantity}
            />
          </td>
          {pricePermission && (
            <td>
              <b>
                <PriceComponent value={
                  companyTag === 'nortel'
                    ? ((item.total * item.product.price.value) + (item.product.price.icmsST || 0))
                    : (amount * (item.product.price.value + (item.product.price.icmsST || 0)))
                  }
                />
              </b>
              {taxesCart && (
                <React.Fragment>
                  <br />
                  <Translate value="unityPrefix" />
                  {' '}
                  <PriceComponent value={
                      companyTag === 'nortel'
                        ? (item.total * item.product.price.value)
                        : amount * item.product.price.value
                    }
                  />
                  <br />
                  {`${taxName}: `}
                  <PriceComponent
                    force
                    value={
                      companyTag === 'nortel'
                        ? item.product.price.icmsST
                        : amount * item.product.price.icmsST
                    }
                  />
                </React.Fragment>
              )}
            </td>
          )}
        </tr>

      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  taxesCart: state.settings && !state.settings.taxsCartOff,
  taxName: state.settings && state.settings.taxName,
  selectedContract: state.contracts.selected,
  companyName: state.settings.name,
  companyTag: state.settings.companyTag,
  xpedPermission: state.settings.companyTag != 'chile',
  allowSellSpotItensWithoutStock: state.contracts.selected
    && state.contracts.selected.clientDetails.allowSellSpotItensWithoutStock,
});

const mapDispatchToProps = dispatch => ({
  updateItem: (productId, productClientCode, productCode = '', total, xped = '') => dispatch(cartUpdateItem(productId,
    productClientCode, productCode, total, xped)),
  removeItem: (productId, productClientCode) => dispatch(cartRemoveItem(productId, productClientCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartTableItem);
