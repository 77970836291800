export default {
  minPassword: 6,
  maxPassword: 50,
  maxDefault: 100,
  cvvLength: 4,
  max: 255,
  limitPerPage: 20,
  clientName: {
    max: 100,
  },
  cep: {
    sizes: [5, 8],
  },
  address: {
    max: 100,
    min: 2,
  },
  province: {
    max: 50,
    min: 2,
  },
  city: {
    max: 50,
    min: 2,
  },
  state: {
    max: 50,
    min: 2,
  },
  country: {
    max: 50,
    min: 2,
  },
  name: {
    max: 200,
  },
  firstName: {
    max: 100,
  },
  lastName: {
    max: 100,
  },
  phone: {
    min: 10,
    max: 11,
  },
  observation: {
    min: 0,
    max: 2000,
  },
  observationNortel: {
    min: 0,
    max: 75,
  },
  xped: {
    min: 0,
    max: 15,
  },
};
