import React from 'react';

import HeaderMiddleLeft from './HeaderMiddleLeft';
import HeaderMiddleCenter from './HeaderMiddleCenter';
import HeaderMiddleRight from './HeaderMiddleRight';

const HeaderMiddle = () => (
  <div className="header-middle">
    <div className="container">
      <HeaderMiddleLeft />
      <HeaderMiddleCenter />
      <HeaderMiddleRight />
    </div>
  </div>
);

export default HeaderMiddle;
