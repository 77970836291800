import React from 'react';
import DatePicker from 'react-date-picker';
import { Translate, I18n } from 'react-redux-i18n';

import SolicitationFormInput from '../elements/input';
import SolicitationFormInputMask from '../elements/inputMask';
import SolicitationFormSelect from '../elements/select';

class StepUserForm extends React.Component {
  onSubmit = async (e) => {
    e.preventDefault();

    const {
      firstName, lastName, role, sector, phone, birthday,
    } = this.props.user;

    if (firstName && lastName && role && sector && phone && birthday) {
      await this.props.handleFinalize();
    }
  };

  setValue = async (attr, value) => {
    const { user } = this.props;
    user[attr] = value;
    await this.props.setUser(user);
  }

  getInput = (label, attr, value, minLength, maxLength) => (
    <SolicitationFormInput
      label={label}
      value={value}
      minLength={minLength}
      maxLength={maxLength}
      onChange={e => this.setValue(attr, e.target.value)}
    />
  )

  render() {
    const { roles = [], sectors = [], user } = this.props;
    const {
      firstName, lastName, role, sector, phone, birthday,
    } = user;

    const rolesItems = roles.map(item => ({ value: item.id, label: item.name }));
    const sectorsItems = sectors.map(item => ({ value: item.id, label: item.name }));

    return (
      <form onSubmit={this.onSubmit}>

        {this.getInput(I18n.t('firstName'), 'firstName', firstName, 2, 100)}
        {this.getInput(I18n.t('lastName'), 'lastName', lastName, 2, 100)}

        <SolicitationFormInputMask
          label={I18n.t('phone')}
          value={phone}
          mask="(99) 9999-99999"
          onChange={e => this.setValue('phone', e.target.value)}
        />

        <SolicitationFormSelect
          label={I18n.t('job')}
          items={rolesItems}
          selected={role}
          onChange={e => this.setValue('role', e.target.value)}
        />
        <SolicitationFormSelect
          label={I18n.t('sector')}
          items={sectorsItems}
          selected={sector}
          onChange={e => this.setValue('sector', e.target.value)}
        />

        <p className="margin-bottom-0"><Translate value="birthday" /></p>
        <DatePicker
          className="form-control date-picker-div-1"
          onChange={e => this.setValue('birthday', e)}
          value={birthday}
          required
        />

        <div className="row justify-content-center margin-top-20 margin-bottom-20 form-footer">
          <button
            type="button"
            onClick={this.props.handleBack}
            className="backButton btn btn-light"
          >
            <Translate value="return" />
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            variant="contained"
          >
            <Translate value="finalize" />
          </button>
        </div>
      </form>
    );
  }
}

export default StepUserForm;
