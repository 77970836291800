import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUndoAlt, faSortAlphaDownAlt, faSortAlphaDown, faSortNumericDown } from '@fortawesome/free-solid-svg-icons';

const ProductsFiltersSection = ({
  title, items = [], selected, onClick, clickAttr, basicAttrs = {}, reset, sortOnClick, sortByClickIcon
}) => (
  <div className="widget">
    <h3 className="widget-title">
      <div
        onClick={() => {}}
        onKeyDown={() => {}}
        role="presentation"
      >
        {title}
        <div
          onClick={reset}
          onKeyDown={reset}
          role="presentation"
          className="reset"
        >
          {reset && selected && (<FontAwesomeIcon icon={faUndoAlt} />)}
        </div>

        <div
          onClick={sortOnClick}
          onKeyDown={sortOnClick}
          role="presentation"
          className="reset pb-5"
        >          
          {sortByClickIcon === 1 ? ( <FontAwesomeIcon icon={faSortNumericDown} /> ) : null}
          {sortByClickIcon === 2 ? ( <FontAwesomeIcon icon={faSortAlphaDown} /> ) : null}
          {sortByClickIcon >= 3 ? ( <FontAwesomeIcon icon={faSortAlphaDownAlt} /> ) : null}
        </div>
      </div>
    </h3>

    <div className="collapse show" id="widget-body-1">
      <div className="widget-body">
        <ul className="cat-list scrollbar">
          {items.map(item => (
            <li key={item.id}>
              <div
                onClick={() => onClick({ ...basicAttrs, [clickAttr]: [item.id] })}
                onKeyDown={() => onClick({ ...basicAttrs, [clickAttr]: [item.id] })}
                role="presentation"
                className={selected && selected !== item.id ? 'none' : ''}
              >
                {item.value}
                {item.total && (<span>{item.total}</span>)}
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </div>
);

export default ProductsFiltersSection;
