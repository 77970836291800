function setTitle(title) {
  document.title = title;
  document.querySelector('meta[property="og:title"]').setAttribute('content', title);
}
function getTitle() {
  return document.title;
}

function setDescription(description) {
  document.querySelector('meta[property="og:description"]').setAttribute('content', description);
}
function getDescription() {
  return document.querySelector('meta[property="og:description"]').getAttribute('content');
}

function setImage(imageUrl) {
  document.querySelector('meta[property="og:image"]').setAttribute('content', imageUrl);
}
function getImage() {
  return document.querySelector('meta[property="og:image"]').getAttribute('content');
}

function setOgSitename(value) {
  document.querySelector('meta[property="og:site_name"]').setAttribute('content', value);
}
function getOgSitename() {
  return document.querySelector('meta[property="og:site_name"]').getAttribute('content');
}

function setHardDescription(value) {
  document.querySelector('meta[name="description"]').setAttribute('content', value);
}
function getHardDescription() {
  return document.querySelector('meta[name="description"]').getAttribute('content');
}

function setHardKeywords(value) {
  document.querySelector('meta[name="keywords"]').setAttribute('content', value);
}
function getHardKeywords() {
  return document.querySelector('meta[name="keywords"]').getAttribute('content');
}

export default {
  setTitle,
  setDescription,
  setImage,
  setOgSitename,
  setHardDescription,
  setHardKeywords,
  getTitle,
  getDescription,
  getImage,
  getOgSitename,
  getHardDescription,
  getHardKeywords,
};
