export default {
  add: 'Agregar',
  aboutUs: 'Acerca de Nosotros',
  accessRequest: 'Solicitud de Acceso',
  accountInformation: 'Información de la Cuenta',
  activeDocuments: 'Pedidos Activos',
  addCard: 'Agregar una nueva tarjeta',
  address: 'Dirección',
  address2: 'Dirección:',
  addressToSend: 'Dirección de entrega',
  agreement: 'Acuerdo',
  approve: 'Aprobar',
  approveCart: 'Aprobar Carrito',
  approveCartConfirm: '¿Realmente desea aprobar el carrito?',
  approved: 'Aprobado',
  available: 'Disponible',
  availability: 'Disponibilidad:',
  authorized: 'Autorizado',
  all: 'Todos',
  allCategories: 'Todas Categorías',
  bank: 'Banco',
  bankSlip: 'Boleto',
  bankSlipText1: 'Imprima el boleto y pague en el banco',
  bankSlipText2: 'o pague en intenet usando el código de barra',
  bankSlipText3: 'el plazo de validez del boleto es de 2 días hábiles',
  bankSlipVisualize: 'Clique para visualizar el boleto',
  beingPrepared: 'Pedido siendo preparado',
  before: 'Anterior',
  billing: 'Credito',
  billing2: 'Facturación',
  billed: 'Cantidad facturada:',
  birthday: 'Fecha de Cumpleaños:',
  brands: 'Marcas',
  buyDate: 'Fecha de compra',
  buyDate2: 'Fecha de compra:',
  buySolicitation: 'Procesar compra',
  cancel: 'Cancelar',
  canceled: 'Cancelado',
  categories: 'Categorías',
  cardInvalid: 'Tarjeta inválida, aceptamos sólo Visa, Mastercard y Elo.',
  cardName: 'Nombre que aparece en la tarjeta',
  cardNumber: 'Número de la tarjeta',
  cardNumberInvalid: 'Número de tarjeta inválido',
  cart: 'Carrito',
  cartEmpty: 'Carrito vacio',
  cartSolicitationText: 'Su carrito de compra fue enviado para un responsable',
  cartEstimateText: 'Su solicitud de presupuesto ha sido enviada.',
  cartChanged: 'Atención... Los ítems de su carrito fueron modificados,'
    + ' por favor revise los ítems antes de finalizar el pedido.',
  cartToApprove: 'Carritos para aprobar:',
  cartProducts: 'Productos en el carrito',
  cartOrderByDateAsc: 'Ordenar por fecha de inserción ascendente',
  cartOrderByDateDesc: 'Ordenar por fecha de inserción descendente',
  cartOrderByNameAsc: 'Ordenar por nombre ascendente',
  cartOrderByNameDesc: 'Ordenar por nombre descendente',
  captured: 'Realizado',
  currentCartWillChange: 'Su carrito actual será reemplazado, ¿le gustaría continuar?',
  city: 'Ciudad',
  change: 'Cambiar',
  changeAddress: 'Cambiar Dirección',
  changePayment: 'Cambiar Forma de Pago',
  changePasswordIsNotPossible: 'No es posible cambiar su contraseña,'
    + ' usted no ha solicitado una recuperación de contraseña.',
  company: 'Empresa',
  companyData: 'Datos de la Empresa',
  contact: 'Contáctenos',
  contactUs: 'Contáctenos',
  continueBuy: 'Continuar Comprando',
  confirm: 'Confirmar',
  confirmed: 'Confirmado',
  confirmPassword: 'Confirmar Contraseña',
  confirmNewPassword: 'Confirmar nueva contraseña',
  contract: 'Convenio',
  contractNotFound: 'Disculpe, no hay convenios encontrados.',
  contractProducts: 'Productos de Convenio',
  consultUs: 'Consúltenos',
  consultUsTime: 'Plazo: CONSÚLTENOS',
  copyOrder: 'Copiar Pedido',
  country: 'País',
  csv: 'CSV',
  checkoutContact: 'Nuestro equipo recibirá los productos solicitados y entrará en contacto con los precios.',
  credit: 'Tarjeta de Crédito',
  crossSelling: 'Cross Selling',
  crossSellingProduct: 'Productos Destacados',
  cleanCart: 'Limpiar Carrito',
  cleanFilters: 'Limpiar todos los filtros',
  clientCode: 'Código del Cliente:',
  clientCode2: 'Código Cliente:',
  clientDescription: 'Descripción del Cliente',
  code: 'Código: #',
  code2: 'Código:',
  codeSphere: 'Código de pedido interno:',
  codeSphere2: '| Código de pedido interno:',
  codeSolicitation: 'Código de la Solicitación:',
  cnpj: 'RUT',
  cpf: 'CPF',
  cep: 'Código Postal',
  currentPassword: 'Contraseña Actual',
  cvv: 'CVV',
  cvvInvalid: 'CVV inválido',
  days: 'Días',
  discount: 'Descuento',
  description: 'Descripción',
  debit: 'Débito',
  debitOn: 'Débito en línea',
  decline: 'Rechazar',
  declineCart: 'Rechazar carrito',
  declineCartConfirm: '¿Realmente desea rechazar el carrito?',
  deleteCard: 'Borrar Tarjeta',
  delivered: 'Pedido entregado',
  delivered2: 'Entregado',
  deliveryAllCountry: 'Entrega en todo Chile',
  deliveryAllCountryText: 'Tenemos la estructura necesaria para '
    + 'atenderlo de la mejor forma, donde quiera que usted esté.',
  deliveryType: 'TIPO DE ENTREGA',
  defaultAddress: 'Dirección predeterminada',
  details: 'Ver Detalles del Pedido',
  details2: 'Detalles',
  didYouMean: '¿Querías decir?',
  downloadApp: '¡Descargue nuestro App gratis!',
  downloadPdf: 'Ficha Técnica - PDF',
  documentList: 'Pedidos y presupuestos realizados:',
  documentList2: 'Pedidos y Presupuestos',
  documentStatus: 'Estado del Pedido',
  documentStatus2: '| Estado del Pedido:',
  emailCnpjMessage: 'E-mail ya registrado con ese RUT',
  emailCnpjMessage2: 'E-mail no relacionado con ese RUT',
  emailCnpjMessage3: 'Cliente no relacionado com ese RUT',
  entry: 'Entrar',
  error: '¡ERROR!',
  errorOcurred: 'Disculpe ocurrio un error',
  errorOcurred2: 'Disculpe ocurrio un error.',
  errorTryLater: 'Ocurrio un error, intente más tarde.',
  emailCnpj: 'E-mail/RUT',
  emailCnpjCpf: 'E-mail/RUT',
  emailPlaceholder: 'Ingrese su e-mail',
  estimate: 'Cotización',
  estimateSolicitation: 'Solicitar Cotización',
  estimateTime: 'Plazo de Entrega',
  especification: 'Especificaciones:',
  expirationDateInvalid: 'Fecha de expiración inválida',
  expirationMonthInvalid: 'Mes de expiración inválido, debe ser entre 01 y 12',
  expirationYearInvalid: 'Año de expiración inválido',
  expirationPlaceholder: 'MM/AA',
  exit: 'Salir',
  features: 'Características',
  finalize: 'Finalizar',
  finalize2: 'Finaliza',
  finalizeIn: 'Finaliza en',
  finalized: 'Finalizado',
  finalizeBuy: 'Finalizar Compra',
  finalizeOrder: 'Finalizar Pedido',
  firstName: 'Primer Nombre',
  fileGenerate: 'Generar Archivo',
  flagInvalid: 'No aceptamos esta bandera de tarjeta',
  forgotPassword: '¿Olvidó su contraseña? Ingrese su e-mail.',
  forgotPassword2: 'Olvidó la contraseña',
  generateSummary: 'Generar informe de pedidos',
  generateContract: 'Generar informe de productos por contrato',
  general: 'General',
  home: 'Inicio',
  howWorks: 'Como Funciona',
  immediateTime: 'Plazo Máximo: INMEDIATO',
  insertNewPassword: 'Ingrese su nueva contraseña.',
  inTransport: 'En transporte',
  insertEmail: 'Ingrese su e-mail',
  insertCnpj: 'Ingrese su RUT',
  invalidEmail: 'E-mail inválido',
  invalidCNPJ: '¡RUT inválido!',
  invalidAmount: 'CANTIDAD AÑADIDA NO EN STOCK',
  invalidAmount2: 'La cantidad agregada no está disponible en stock, el artículo tiene ',
  invalidAmount3: 'en stock Si desea una mayor cantidad, consulte disponibilidad con nuestras televentas: ',
  invalidCep: '¡Código Postal inválido!',
  invoiceIssued: 'Faturado',
  installmentsNumber: 'Número de cuotas:',
  invoiceIssuedPartially: 'Parcialmente Faturado',
  items: 'Ítems',
  json: 'JSON',
  job: 'Cargo',
  lastName: 'Apellido',
  punchOut: 'Punch Out',
  punchOutTitle: 'ENTER a través de Punch Out',
  punchOutText: 'iniciar sesión a través de punch out',
  buyPunchOut: 'finalizá lo punch out',
  limitCartWarning: 'Usuario sin permiso para ver este carrito',
  loading: 'Cargando...',
  login: 'Iniciar sesión',
  loginForgotPassword: '¿Olvido su contraseña?',
  loginButton: 'ENTRAR',
  loginText: 'Si usted ya tiene una cuenta con nosotros, inicie sesión.',
  loginTitle: 'ENTRAR',
  loginRegisterSolicitation: 'Registrarse',
  loginError: '¡ERROR!',
  maxTime: 'Plazo Máximo:',
  mySolicitations: 'Mis solicitaciones:',
  minimumOf: 'Mínimo de',
  minimumPayment: 'Mínimo de $',
  minimumPayment2: 'para finalizar la compra',
  myInformations: 'Mis Informaciones',
  needHelp: '¿Necesita ayuda?',
  needHelpText: 'Nuestra postventa está siempre disponible para aclarar las dudas de su pedido.',
  navigation: 'Navegación',
  newPassword: 'Nueva contraseña',
  next: 'Próximo',
  newApp: 'Nuevo App para una experiencia única.',
  notAuthorized: 'No autorizado',
  noneDocument: 'Ningún pedido/presupuesto realizado.',
  normalDelivery: 'Entrega normal',
  noLimit: 'No se puede finalizar la compra. Por favor, póngase en contacto con el administrador de'
    + ' su contrato / cuenta para ver el saldo disponible.',
  observationText: 'Agregar comentarios',
  observationTextNortel: 'Agregar notas a lo credito (límite de 75 caracteres)',
  pageNotFound: '¡Página no encontrada!',
  panel: 'Panel',
  password: 'Contraseña',
  passwordDifferents: 'Las contraseñas son diferentes.',
  passwordPlaceholder: 'Ingrese su contraseña',
  passwordPlaceholder2: 'Confirme su contraseña',
  passwordErrorMessage1: 'La contraseña actual debe ser mayor o igual a 6 caracteres y menor que 50.',
  // passwordErrorMessage2: 'La contraseña debe ser mayor o igual a ',
  // passwordErrorMessage3: ' caracteres y menor que ',
  // Política de contraseñas ↓
  passworderrorMessage: 'la contraseña ingresada no cumple con la política de contraseñas',
  passwordErrorMinChar: 'la contraseña debe contener al menos 12 caracteres',
  passwordErrorNumber: 'la contraseña debe contener dígitos (0-9)',
  passwordErrorUpperCase: 'la contraseña debe contener al menos una letra mayúscula (A-Z)',
  passwordErrorSpecialChar: 'la contraseña debe contener al menos un carácter especial (!@#)',
  passwordErrorNotEqual: 'la contraseña debe ser la misma',
  passwordSuccessChanged: 'Contraseña cambiada con éxito.',
  passwordInvalid: 'La contraseña actual es inválida.',
  passwordChange: 'Cambiar Contraseña',
  passwordInsert: 'Ingrese su nueva contraseña.',
  passwordIndicatorTitle: 'Política de contraseñas',
  passwordIndicatorSubTitle: 'La contraseña debe contener',
  passwordIndicatorMinChar: 'Al menos 12 caracteres',
  passwordIndicatorNumber: 'Dígitos (0-9)',
  passwordIndicatorUpperCase: 'Al menos una letra mayúscula (A-Z)',
  passwordIndicatorSpecialChar: 'Al menos un carácter especial (!@#)',
  passwordIndicatorPasswordEqual: 'Contraseñas iguales',
  payWithBankSlip: 'Pagar con Boleto',
  paid: 'Pagado',
  payment: 'Pago',
  paymentTerm: 'Pago',
  paymentType: 'Forma de Pago',
  paymentType2: 'Forma de Pago:',
  paymentMade: 'Pago Confirmado',
  personalData: 'Datos Personales',
  phone: 'Teléfono:',
  pdf: 'PDF',
  product: 'Producto',
  products: 'Productos',
  productType: 'Tipo de producto',
  productBought: 'producto(s) comprado(s)',
  productInformation: 'Información del Producto',
  productsPromotion: 'Productos en Promoción',
  productsSimilar: 'Productos Alternativos',
  province: 'Comuna',
  price: 'Precio',
  unPrice: 'Precio Unitario',
  price2: 'Precio:',
  privacyPolicy: 'Política de privacidad',
  quantity: 'Cant.',
  quantity2: 'Cantidad:',
  quality: 'Garantia de Calidad',
  qualityText: 'Trabajamos solamente con productos de las mejores marcas del mercado.',
  readToSend: 'Pedido listo para enviar',
  recoverPassword: 'Recuperar Contraseña',
  recoverPasswordResponseMsg: 'Si el usuario es válido, se le enviará una nueva contraseña por correo electrónico.',
  requestLimitExceeded: '¡Límite de solicitud excedido! Por favor, inténtelo de nuevo más tarde.',
  registerSolicitation: 'Solicitación de Registro',
  remove: 'Remover',
  return: 'Volver',
  returnHome: 'Volver para Inicio',
  results: 'Resultados',
  requestAccess: 'Solicitar Acceso',
  requestAccessText: '¿Su empresa ya tiene un registro? Solicitar la liberación de su acceso',
  report: 'Reporte',
  reports: 'Informes',
  saveCardText: 'Guardar datos de la tarjeta para futuras compras',
  search: 'Buscar',
  searchProduct: 'Buscar por producto...',
  searchTerm: 'Buscar por palabra...',
  sector: 'Sector',
  selectAddressToTake: 'Seleccione la dirección de retiro:',
  selectAddressToSend: 'Seleccione la dirección de entrega:',
  selectDeliveryType: 'Seleccione el tipo de entrega:',
  selectPaymentMethod: 'Seleccione la forma de pago:',
  sellMultiple: 'Múltiplo de Venta:',
  sellMultipleNeeds: 'La cantidad debe ser múltiplo de',
  showing: 'Mostrando',
  soneparCode: 'Código Sonepar:',
  securityCode: 'Código de seguridad:',
  st: 'IVA',
  state: 'Ciudad',
  spot: 'Spot',
  spotlight: 'Destacados',
  spotlightProducts: 'Productos Destacados',
  subTotal: 'Subtotal',
  subCategories: 'Subcategorías',
  summary: 'Resumen',
  successBuy: 'Su compra fue realizada con éxito',
  successRegister: 'Acceso solicitado con éxito',
  successRegisterText: 'Le enviaremos un e-mail una vez que su acceso esté habilitado..',
  successSolicitation: 'Solicitación enviada con éxito',
  successSolicitationText: 'Le enviaremos un email cuando su solicitación sea aprobada.',
  storeTake: 'Retirar en la tienda',
  spotItemsError: '"No se pueden comprar más artículos que la cantidad disponible"',
  orderMade: 'Pedido realizado',
  orderDetail: 'Detalles del pedido',
  orderCodeOptional: 'Orden de Compra (Opcional)',
  orderMadeBy: 'Pedido realizado por: ',
  orderSolicitedBy: 'Pedido solicitado por: ',
  of: 'de',
  take: 'Retirar:',
  thanks: 'Gracias',
  toBuy: 'para finalizar la compra',
  total: 'Total',
  total2: 'Total:',
  totalCartDetails: '(Valor + %{tax})',
  timeDelivered: 'Tiempo de entrega',
  transportCompany: 'transportista',
  transportDocument: 'transportista RUT',
  undefined: 'Indefinido',
  userBlocked: 'Usuario está bloqueado.',
  userHasNoActiveContract: 'Usuario inactivo',
  userNotFound: 'Usuario no encontrado.',
  userInvalidPassword: 'Contraseña invalida.',
  userInvalid: 'Usuario o contraseña inválida.',
  userTerms: 'Términos de uso',
  unavailable: 'NO DISPONIBLE',
  unityPrefix: 'Un:',
  visualizeCart: 'Ir al Carrito',
  visualizeDetail: 'Ver detalles',
  xml: 'XLM',
  welcome: 'Bienvenido',
  withOutContract: 'Sin Convenio',
  withoutStock: 'A consultar',
  wishToCopyOrder: '¿Desea realmente copiar el pedido?',
  wishToApproveCart: '¿Desea realmente ver el carrito?',
  wishToDeleteCard: '¿Realmente quieres eliminar la tarjeta de',
  workingDays: 'días hábiles',
  xpedTooltipText: 'Ingrese su número de orden de compra. Estos datos se mostrarán en la factura xPed.'
    + ' El campo está limitado a 15 caracteres y solo se permiten letras, números,'
    + ' guiones (-), subrayado (_), coma (,) y barra diagonal (/ \\) ',
  awaitingApproval: 'En espera de aprobación',
  disapproved: 'Desaprobado',
  awaitingNf: 'En espera de NF',
  totalBilled: 'Total facturado',
  parcialBilled: 'Partially Billed',
  filterBy: 'Filtrar por:',
  attention: 'Atencion',
  selectDefaultAddress: '¿Desea establecer la dirección elegida por defecto?',
  yes: 'Si',
  no: 'No',
  invalidItemsCart: 'No se puede realizar el pago ya que hay artículos en el carrito que están agotados',
  cantAddToCart: 'No se puede agregar este artículo al carrito porque no hay stock',
  picking: 'Separando',
  deliveredToCarrier: 'Entregar el transportista',
  outForDelivery: '  Fuera para entrega',
  outForDeliveryPrev: 'Prev:',
  availableCustumer: 'Retiro disponible',
  done: 'Efectuado',
  errorPurchaseContact: 'Error de pago, contacte con la administración de la tienda.',
  errorInvalidDoc: 'Documento inválido, validar RUT informado.',
  errorMinimumPrice: 'El carrito no puede ser inferior a {?}',
  errorInvalidCard: 'Datos de la tarjeta no válidos, valide nuevamente.',
  errorLocation: 'Dirección no encontrada.',
  errorLocationOrClient: 'Dirección o cliente no encontrado.',
  errorForbidden: 'No se puede finalizar la compra. Póngase en contacto con su administrador de cuenta/contrato.',
  errorMaxObservation: 'El campo de observación no puede superar los caracteres {?}.',
  errorGkoClientNotFound: 'Dirección no encontrada, comuníquese con la administración de la tienda.',
  errorPurchaseNotFinished: 'Error de pago, contacte con la administración de la tienda.',
  importList: 'Importar Lista',
  successFile: 'Archivo cargado exitosamente',
  invalidFile: 'Archivo inválido',
  dragAndDrop: 'Haga clic aquí para cargar lo archivo.',
  dragAndDropCartErrorTitle: 'Los siguientes productos no han sido encontrados:',
  line: 'Línea',
  send: 'Enviar',
  cartImportStep1: 'Descargar la hoja de plantilla',
  cartImportStep2: 'Ingrese los productos deseados y la cantidad',
  cartImportStep3: 'Después de informar todos los productos deseados, importe la hoja de cálculo en el área a continuación',
  cartImportStep4: 'Haga clic en Enviar',
  cartImportUploadInstruction: 'Sube la hoja de cálculo en formato XLS:',
  cartImportDownloadInstruction1: 'IMPORTA TU HOJA DE PRODUCTO',
  cartImportDownloadInstruction2: 'Descargue la plantilla haciendo clic en el enlace a continuación:',
  cartImportDownloadInstruction3: 'Descargar plantilla de hoja de cálculo',
  cartImportUploadWaitMessage: 'Por favor, espere unos segundos mientras se realiza la carga',
  offer: 'Oferta',
  awaitPayment: 'Aguardando Pagamento',
  highlighted: 'Destacados', 
  orderPriceLowToHigh: "Precio: de más bajo a más alto",
  orderPriceHighToLow: "Precio: de más alto a más bajo",
  ordenation: 'Ordenar por',
  clickToSupport: 'Click de Soporte',
  supportChannel: 'CANAL DE SOPORTE',
  AddedToCart: 'Agregado al carrito',
  Seq: 'N°', 
  goToCart: 'Ir Al Carrito',
  continueShopping: 'Seguir Comprando',
  close: 'Cerrar',
  lot:"LOTES/OFERTAS",
  toBackStore: 'Volver a la tienda online',
  detailsEstimate: 'Ver Detalles de la Cotización',
  followTheSteps: 'INSTRUCCIONES PARA IMPORTAR LISTA',
  noAggregatedProductsToCart: "Han sido agregados al carrito {?} productos.",
  goToCartNow: "Ir al carrito ahora",
  offers: 'Ofertas',
  clearanceProducts: "Productos en Liquidación",
};
