import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';

const FooterMiddleLeft = ({ address, phone }) => (
  <div className="widget">
    <h4 className="widget-title"><Translate value="contact" /></h4>
    <ul className="contact-info">
      <li>
        <span className="contact-info-label"><Translate value="address2" /></span>
        {address}
      </li>
      <li>
        <span className="contact-info-label"><Translate value="phone" /></span>
        {phone}
      </li>
    </ul>
  </div>
);

const mapStateToProps = state => ({
  address: state.settings.address,
  phone: state.settings.phone,
});

export default connect(mapStateToProps)(FooterMiddleLeft);
