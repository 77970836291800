import React from 'react';
import { Translate } from 'react-redux-i18n';

import PriceComponent from '../../price/Price';
import PriceTaxComponent from '../../price/PriceTax';

const MySolicitationsCart = (props) => {
  const { cart = {}, pricePermission } = props;
  const { products = [], cartId } = cart;

  return (
    <React.Fragment>
      <div className="col-4 gradient-background-cart">
        <i style={{ fontSize: '70px' }} className="fas fa-shopping-cart cart-approve-logo" />
      </div>
      <div className="col-8 main-cart-approve">
        <h3>
          <Translate value="code" />
          {cartId}
        </h3>
        <div className="col-12 overflow-auto-cart">

          {products.map(product => (
            <p className="cart-items-approve" key={product.id}>
              {'- '}
              {product.quantity}
              {' x '}
              {product.name}
              {pricePermission && ' - '}
              {pricePermission && <strong><PriceComponent value={Number(product.price.value) || 0} /></strong>}
            </p>
          ))}
        </div>

        {pricePermission && <PriceTaxComponent products={products} />}

        <div className="row btn-cart-approve-row" />
      </div>
    </React.Fragment>
  );
};

export default MySolicitationsCart;
