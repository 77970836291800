import React from 'react';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';

import ROUTES from '../../data/routes';

const PanelSidebar = ({ cartUsersPermission, purchasePermission }) => (
  <aside className="sidebar col-lg-3">
    <div className="widget widget-dashboard">
      <ul className="list">
        {/* <li>
          <NavLink activeClassName="active" to={ROUTES.PANEL}>Geral</NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to={ROUTES.PANEL_ACCOUNT}>Informações da conta</NavLink>
        </li>
       */}
        {cartUsersPermission && (
        <li>
          <NavLink
            activeClassName="active"
            to={ROUTES.PANEL_CARTS_TO_APPROVE}
          >
            <Translate value="cartToApprove" />
          </NavLink>
        </li>
        )}
        {!purchasePermission && (
        <li>
          <NavLink
            activeClassName="active"
            to={ROUTES.PANEL_CARTS_SOLICITED}
          >
            <Translate value="mySolicitations" />
          </NavLink>
        </li>
        )}
        <li>
          <NavLink activeClassName="active" to={ROUTES.ORDER_LIST}>
            <Translate value="documentList2" />
            :
          </NavLink>
        </li>
        <li>
          <NavLink activeClassName="active" to={ROUTES.REPORT}>
            <Translate value="report" />
            :
          </NavLink>
        </li>
      </ul>
    </div>
  </aside>
);

const mapStateToProps = state => ({
  cartUsersPermission: state.contracts.selected && state.contracts.selected.cartUsersPermission,
  purchasePermission: state.contracts.selected && state.contracts.selected.purchasePermission,
});

export default connect(mapStateToProps)(PanelSidebar);
