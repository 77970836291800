import React, { Fragment } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckMoving, faStoreAlt } from '@fortawesome/free-solid-svg-icons';
import { Translate } from 'react-redux-i18n';

export default function DeliveryTypeCard({ isClient }) {
  return (
    <Fragment>
      {isClient
        ? (
          <span className="freight-title">
            <FontAwesomeIcon icon={faStoreAlt} className="freight-icon" />
            <Translate value="storeTake" />
          </span>
        )
        : (
          <span className="freight-title">
            <FontAwesomeIcon icon={faTruckMoving} className="freight-icon" />
            <Translate value="normalDelivery" />
          </span>
        )}
    </Fragment>
  );
}
