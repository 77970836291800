import React from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';

import Header from '../components/header/Header';

const PublicRoute = ({
  isStarted,
  component: Component,
  noHeader,
  ...rest
}) => {
  if (isStarted) {
    return (
      <React.Fragment>
        {!noHeader && <Header />}
        <Route
          {...rest}
          component={props => (
            <Component {...props} />
          )}
        />
      </React.Fragment>
    );
  }
  return (null);
};

const mapStateToProps = state => ({
  isStarted: state.activities.started,
  isAuthenticated: state.auth.authenticated,
});

export default connect(mapStateToProps)(PublicRoute);
