import CardRequests from '../../server/card';
import AuthRequests from '../../server/auth';
import { addLoading, removeLoading } from './loading';

export const setCards = cards => ({
  type: 'CARDS_SET',
  cards,
});

export const resetCards = () => ({
  type: 'RESET_CARDS',
});

export const saveCards = cards => (dispatch) => {
  dispatch(setCards(cards));
};
export const cleanCards = () => (dispatch) => {
  dispatch(resetCards());
};

export const deleteCard = card => async (dispatch) => {
  dispatch(addLoading());

  try {
    await CardRequests.deleteCard(card);
    const { cards = [] } = await AuthRequests.getUser();
    dispatch(setCards(cards));
  } finally {
    dispatch(removeLoading());
  }
};
