import RestService from '../services/rest';

async function finalize(clientId,
  cartId, price, type, card, document, location, clientNumber, freight,
  punchoutUserId = null, observation, saveCard = false, paymentInfos = {}) {
  const punchoutUrl = punchoutUserId ? `&punchout=${punchoutUserId}` : '';
  const userUrl = cartId ? `&cart=${cartId}` : '';
  return RestService.postAuthenticated(`purchases/finalize?client=${clientId}${userUrl}${punchoutUrl}`, {
    price,
    type,
    card,
    document,
    location,
    clientNumber,
    freight,
    observation,
    saveCard,
    paymentInfos,
  });
}

async function estimate(clientId, cartId, punchoutUserId = null, price = null) {
  const punchoutUrl = punchoutUserId ? `&punchout=${punchoutUserId}` : '';
  const userUrl = cartId ? `&cart=${cartId}` : '';
  return RestService.postAuthenticated(`purchases/estimate?client=${clientId}${userUrl}${punchoutUrl}`, { price });
}

async function initPagSeguroProcess() {
  return RestService.getAuthenticated('pagseguro/initPaymentProcess');
}

async function getPagSeguroSessionId() {
  return RestService.getAuthenticated('pagseguro/getSessionId');
}

async function getPurchaseFile(clientId, id, type = 'pdf') {
  const response = await RestService
    .getAuthenticated(`purchases/document?client=${clientId}&id=${id}&type=${type}`, {
      'Content-Type': 'application/pdf',
      Accept: `application/${type}`,
    }, {
      responseType: 'arraybuffer',
    });
  const url = window.URL.createObjectURL(new Blob([response], {type: "application/pdf"}));
  window.open(url);
}

export default {
  finalize,
  estimate,
  initPagSeguroProcess,
  getPagSeguroSessionId,
  getPurchaseFile,
};
