/* eslint-disable max-len */
import moment from 'moment-timezone';

import RestService from '../services/rest';

function getCart(clientId, punchoutUserId) {
  const punchoutUrl = punchoutUserId ? `&punchout=${punchoutUserId}` : '';
  return RestService.getAuthenticated(`cart?client=${clientId}${punchoutUrl}`);
}

function getCartInformation(productId) {
  return RestService.getRest(`cartInformation?productId=${productId}`);
}

function updateCart(clientId, cartId, productId, productClientCode, productCode = '', quantity, punchoutUserId, xped = '', isItemCache = false) {
  const userUrl = cartId ? `&cart=${cartId}` : '';
  const punchoutUrl = punchoutUserId ? `&punchout=${punchoutUserId}` : '';
  return RestService.postAuthenticated(`cart?client=${clientId}${userUrl}${punchoutUrl}`, {
    productId,
    productClientCode,
    productCode,
    quantity,
    xped,
    isItemCache,
  });
}

function getFreight(clientId, cartId, punchoutUserId) {
  const userUrl = cartId ? `&cart=${cartId}` : '';
  const punchoutUrl = punchoutUserId ? `&punchout=${punchoutUserId}` : '';
  return RestService.getAuthenticated(`cart/freight?client=${clientId}${userUrl}${punchoutUrl}`);
}

function cartSolicitation(clientId, observation) {
  return RestService.postAuthenticated(`cart/solicitation?client=${clientId}`, { observation });
}

function resetCart(clientId, cartId, punchoutUserId) {
  const userUrl = cartId ? `&cart=${cartId}` : '';
  const punchoutUrl = punchoutUserId ? `&punchout=${punchoutUserId}` : '';
  return RestService.postAuthenticated(`cart/reset?client=${clientId}${userUrl}${punchoutUrl}`);
}

function getCartsToApprove(clientId, filterBy = null) {
  return RestService.getAuthenticated(`cart/approve/list?client=${clientId}&type=${filterBy}`);
}

function removeCart(clientId, cartId, punchoutUserId) {
  const punchoutUrl = punchoutUserId ? `&punchout=${punchoutUserId}` : '';
  return RestService.postAuthenticated(`cart/approve/delete?client=${clientId}&cart=${cartId}${punchoutUrl}`);
}

function approveCart(clientId, cartId, punchoutUserId) {
  const punchoutUrl = punchoutUserId ? `&punchout=${punchoutUserId}` : '';
  return RestService.postAuthenticated(`cart/approve/approve?client=${clientId}&cart=${cartId}${punchoutUrl}`);
}

function getCartSolicitations(clientId, punchoutUserId) {
  const punchoutUrl = punchoutUserId ? `&punchout=${punchoutUserId}` : '';
  return RestService.getAuthenticated(`cart/solicitations?client=${clientId}${punchoutUrl}`);
}

function copyCart(clientId, purchaseId, punchoutUserId) {
  const punchoutUrl = punchoutUserId ? `&punchout=${punchoutUserId}` : '';
  return RestService.postAuthenticated(`cart/copy?client=${clientId}&purchase=${purchaseId}${punchoutUrl}`);
}

function copyCartCanceled(clientId, cartId) {
  return RestService.postAuthenticated(`cart/copy/canceled?client=${clientId}&cartId=${cartId}`);
}

async function getCartFile(clientId, cartId, type = 'pdf', punchoutUserId, company) {
  const cartIdUrl = cartId ? `&cart=${cartId}` : '';
  const punchoutUrl = punchoutUserId ? `&punchout=${punchoutUserId}` : '';
  const response = await RestService
    .getAuthenticated(`cart/document?client=${clientId}&type=${type}${cartIdUrl}${punchoutUrl}`, {
      'Content-Type': 'application/json',
      Accept: `application/${type}`,
    }, {
      responseType: 'arraybuffer',
    });
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  let filePath = `carrinho-${moment().toISOString()}.${type}`;

  if (company == 'chile') {
    filePath = `Documento de cotización checkbuy-${moment().format('YYYY-MM-DD H_mm_ss')}.pdf`;
  }

  link.setAttribute('download', filePath);
  document.body.appendChild(link);
  link.click();
}

async function updateAddress(clientId, userId, cep, nameAbbr) {
  return RestService.postAuthenticated(`users/address?clientId=${clientId}`, {
    userId,
    cep,
    nameAbbr,
  });
}

async function uploadProductsList(cartId, clientId, file) {
  const fd = new FormData();
  fd.append('file', file);

  return RestService.postAuthenticated(`cart/products/import?cartId=${cartId}&client=${clientId}`, fd, {
    'Content-Type': 'multipart/form-data',
  });
}

async function getXlsxProductListModel() {
  const response = await RestService.getAuthenticated('cart/products/model/xlsx', {
    method: 'GET',    
    headers: {
        'Accept': 'vnd.ms-excel'
    },
  }, {responseType: 'arraybuffer'});
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  // works in IE11
  if (typeof window.navigator.msSaveBlob === 'function') {
    window.navigator.msSaveBlob(
      response.data,
      `Modelo_Importacao_Carrinho.xlsx`
    );
  } else {
    link.setAttribute('download', `Modelo_Importacao_Carrinho.xlsx`);
    document.body.appendChild(link);
    link.click();
  }
}

export default {
  getCart,
  updateCart,
  getFreight,
  resetCart,
  cartSolicitation,
  getCartsToApprove,
  removeCart,
  approveCart,
  getCartSolicitations,
  copyCart,
  copyCartCanceled,
  getCartFile,
  updateAddress,
  getCartInformation,
  uploadProductsList,
  getXlsxProductListModel,
};
