import React from 'react';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import PanelSidebar from '../components/panel/PanelSidebar';
import PanelAccount from '../components/panel/account/PanelAccount';

const PanelAccountPage = () => (
  <main className="main">
    <div className="container">
      <Breadcrumb items={[{
        value: I18n.t('panel'),
      }, {
        value: I18n.t('accountInformation'),
      }]}
      />
      <div className="row">
        <PanelSidebar />
        <PanelAccount />
      </div>
    </div>
  </main>
);

export default PanelAccountPage;
