import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { Translate } from 'react-redux-i18n';
import CartTableItem from './CartTableItem';

import { cartCleanItems, cartGetFile } from '../../app/store/actions/cart';

import ROUTES from '../../data/routes';

export class CartTable extends React.Component {
  cleanProducts = async () => {
    await this.props.cleanItems();
  }

  render() {
    const {
      cartItems, pricePermission, leadTimePermission, stockPermission, clientDetails,
      opicCodePermission, soneparCodePermission, soneparCodeOff, companyTag,
    } = this.props;
    const {
      fileEmailCsv, fileEmailPdf, fileEmailXml, fileEmailJson,
    } = clientDetails;

    return (
      <div className="cart-table-container">
        <table className="table table-cart">
          <thead>
            <tr>
              {companyTag == 'chile' && <th style={{minWidth: '0.5rem', paddingLeft: '0.5rem', paddingRight: '0.5rem'}}><Translate value="Seq" /></th>}
              <th><Translate value="product" /></th>
              {pricePermission && <th className="price-col"><Translate value="unPrice" /></th>}
              <th className="qty-col"><Translate value="quantity" /></th>
              {pricePermission && <th><Translate value="subTotal" /></th>}
            </tr>
          </thead>
          <tbody>
            {cartItems.map(item => (
              <CartTableItem
                key={item.product.id}
                item={item}
                pricePermission={pricePermission}
                leadTimePermission={leadTimePermission}
                stockPermission={stockPermission}
                opicCodePermission={opicCodePermission}
                soneparCodePermission={soneparCodePermission}
                soneparCodeOff={soneparCodeOff}
              />
            ))}
          </tbody>

          <tfoot>
            <tr>
              <td colSpan="4" className="clearfix">
                <div className="float-left">
                  <Link
                    to={ROUTES.PRODUCTS}
                    className="btn btn-outline-secondary"
                  >
                    <Translate value="continueBuy" />
                  </Link>
                </div>

                {(fileEmailCsv || fileEmailJson
                || fileEmailPdf || fileEmailXml) && (
                <div className="dropdown-top-menu">
                  <button
                    className="btn btn-outline-secondary"
                    type="button"
                  >
                    <Translate value="fileGenerate" />
                  </button>
                  <div className="dropdown-top-menu-content min-width-136">
                    {fileEmailPdf && (
                    <button
                      className="clean-button col-12 btn-top-menu text-left blue-theme"
                      type="button"
                      onClick={() => this.props.getFile('pdf')}
                    >
                      <Translate value="pdf" />
                    </button>
                    )}
                    {fileEmailCsv && (
                    <button
                      className="clean-button col-12 btn-top-menu text-left blue-theme"
                      type="button"
                      onClick={() => this.props.getFile('csv')}
                    >
                      <Translate value="csv" />
                    </button>
                    )}
                    {fileEmailXml && (
                    <button
                      className="clean-button col-12 btn-top-menu text-left blue-theme"
                      type="button"
                      onClick={() => this.props.getFile('xml')}
                    >
                      <Translate value="xml" />
                    </button>
                    )}
                    {fileEmailJson && (
                    <button
                      className="clean-button col-12 btn-top-menu text-left blue-theme"
                      type="button"
                      onClick={() => this.props.getFile('json')}
                    >
                      <Translate value="json" />
                    </button>
                    )}
                  </div>
                </div>
                )}

                <div className="float-right">
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-clear-cart"
                    onClick={this.cleanProducts}
                  >
                    <Translate value="cleanCart" />
                  </button>
                </div>
              </td>
            </tr>
          </tfoot>
        </table>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  pricePermission: state.contracts.selected && state.contracts.selected.pricePermission,
  leadTimePermission: state.contracts.selected && state.contracts.selected.leadTimePermission,
  stockPermission: !state.contracts.selected || (state.contracts.selected && state.contracts.selected.stockPermission),
  clientDetails: state.contracts.selected && state.contracts.selected.clientDetails,
  opicCodePermission: state.contracts.selected && state.contracts.selected.opicCodePermission,
  soneparCodePermission: state.contracts.selected && state.contracts.selected.soneparCodePermission,
  soneparCodeOff: !!state.settings.soneparCodeOff,
  companyTag: state.settings.companyTag,
});

const mapDispatchToProps = dispatch => ({
  cleanItems: () => dispatch(cartCleanItems()),
  getFile: type => dispatch(cartGetFile(type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartTable);
