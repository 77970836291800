export default {
  FORGOT_PASSWORD: '/forgot-password',
  CHANGE_PASSWORD: '/change-password',
  CHANGE_PASSWORD_RECOVERY: '/change-password-recovery',
  HOME: '/',
  LOGIN: '/login',
  PUNCH_OUT: '/punchout',
  PUNCH_OUT_CHECKOUT: '/punchout-checkout',
  SOLICITATION: '/solicitation',
  CART: '/cart',
  CHECKOUT: '/checkout',
  LANDING: '/landing',
  PRODUCTS: '/products',
  PRODUCTS_ID: '/products/:id/:code',
  CONTRACTS: '/contratos',
  PANEL: '/panel/general',
  PANEL_ACCOUNT: '/panel/account',
  PANEL_CARTS_TO_APPROVE: '/carts/approve',
  PANEL_CARTS_SOLICITED: '/carts/solicitation',
  ORDER_LIST: '/order/list',
  ORDER_DETAILS: '/order/details',
  REPORT: '/report',
  USER_TERMS: '/user-terms',
  PRIVACY_POLICY: '/privacy-policy',
  ABOUT: '/about',
  REGISTER: '/register',
  NEW_REGISTER: '/new-register',
  CART_IMPORT: '/cart-import',
};
