import RestService from '../services/rest';

async function authenticate(email, password) {
  return RestService.postRest('oauth/token', {
    username: email,
    password,
    grant_type: 'password',
  });
}

async function changePasswordRecovery(password) {
  return RestService.putAuthenticated('users/password/recovery', {
    password,
  });
}

async function changePassword(password, oldPassword) {
  return RestService.putAuthenticated('users/password', {
    password, oldPassword,
  });
}


async function refreshToken(token) {
  return RestService.postRest('oauth/token', {
    refresh_token: token,
    grant_type: 'refresh_token',
  });
}

async function logout(aToken, rToken) {
  return RestService.postAuthenticated('oauth/revoke', {
    access_token: aToken,
    refresh_token: rToken,
  });
}

async function getUser() {
  return RestService.getAuthenticated('users/details');
}

export default {
  authenticate,
  refreshToken,
  logout,
  getUser,
  changePassword,
  changePasswordRecovery,
};
