import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';
import AddressCard from './CheckoutAddressCard';
import ModalDefaultAddress from './CheckoutAddressModal';
import { updateDefaultAddress } from '../../app/store/actions/cart';

class CheckoutAddress extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      freightShow: '',
      showModal: false,
      location: '',
    };
  }

  setFreight = (location, freight) => {
    this.props.setAddress({
      nameAbbr: location.nameAbbr, cep: location.cep, hasTax: location.hasTax, freight,
    });
  };

  setFreightCard = (location) => {
    const { freightOn, freightType } = this.props;
    if (freightOn && freightType === 'fob') this.setState({ freightShow: location.cep });
    else this.setFreight(location);
  };

  render() {
    const {
      locations = [], clientLocation, clientWithdraw, defaultAddress,
    } = this.props;
    const { freightShow } = this.state;

    return (
      <div className="checkout-payment">
        <React.Fragment>
          <h2 className="step-title">
            <Translate value="selectDeliveryType" />
          </h2>

          <ModalDefaultAddress
            showModal={this.state.showModal}
            handleCloseModal={() => this.setState({ showModal: false })}
            handleUpdateDefaultAddress={
              () => this.props.updateDefaultAddress(this.state.location.cep, this.state.location.nameAbbr)
            }
            handleSetFreightCart={() => this.setFreightCard(this.state.location)}
          />

          <div className="padding-left-3rem">
            {defaultAddress && (
              <React.Fragment>
                <AddressCard
                  address={defaultAddress}
                  isDefault
                  handleCardClick={() => this.setFreightCard(defaultAddress)}
                  freightShow={freightShow}
                  setFreight={this.setFreight}
                />
                <hr style={{
                  margin: '1.5rem auto 2.2rem',
                }}
                />
              </React.Fragment>
            )}
            {locations.map((location) => {
              if (!defaultAddress || location.cep !== defaultAddress.cep) {
                return (
                  <AddressCard
                    address={location}
                    handleCardClick={() => {
                      if (locations.length > 1) {
                        this.setState({
                          showModal: true,
                          location,
                        });
                      } else {
                        this.setFreightCard(location);
                      }
                    }}
                    freightShow={freightShow}
                    setFreight={this.setFreight}
                  />
                );
              }
              return null;
            })}
          </div>
        </React.Fragment>

        {clientLocation && clientWithdraw && (
          <React.Fragment>
            <h2 className="step-title"><Translate value="selectAddressToTake" /></h2>
            <div className="padding-left-3rem">
              <AddressCard
                address={clientLocation}
                isWithdraw
                handleCardClick={() => this.props.setAddress({ isClient: true })}
              />
            </div>
          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  locations: state.contracts.selected && state.contracts.selected.client
    && state.contracts.selected.client.locations,
  clientLocation: state.contracts.selected && state.contracts.selected.clientDetails
    && state.contracts.selected.clientDetails.location,
  freightOn: state.settings.freightOn,
  freightType: state.contracts && state.contracts.selected.clientDetails.freight,
  clientWithdraw: state.contracts && state.contracts.selected.clientDetails.clientWithdraw,
  defaultAddress: state.contracts && state.contracts.selected && state.contracts.selected.defaultAddress,
});

const mapDispatchToProps = dispatch => ({
  updateDefaultAddress: (cep, nameAbbr) => dispatch(updateDefaultAddress(cep, nameAbbr)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutAddress);
