import React from 'react';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import ROUTES from '../../data/routes';
import { purchaseGetFile } from '../../app/store/actions/purchases';

const CartEstimate = (props) => (
  <div className="checkout-finalize">
    <i className="fas fa-check-circle" />
    <h2><Translate value="thanks" /></h2>
    <p><Translate value="cartEstimateText" /></p>

    {props.companyTag === 'chile' ?
        <button
          className="btn btn-primary margin-bottom-10"
          type="button"
          onClick={() => props.getFile(props.lastPurchaseTicket.id)}
        >
          <Translate value="detailsEstimate"/>
        </button> 
      :
      null
    }
    {props.companyTag === 'chile' ?
        <div>
          <Link to={ROUTES.PRODUCTS} className="btn btn-primary margin-bottom-60">
            <Translate value={'toBackStore'}/>
          </Link>
        </div>
      : null}
  </div>
);

const mapStateToProps = state => ({  
  lastPurchaseTicket: state.purchase && state.purchase.lastPurchaseTicket,
  companyTag: state.settings && state.settings.companyTag,
});

const mapDispatchToProps = dispatch => ({
  getFile: (id) => dispatch(purchaseGetFile(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartEstimate);
