import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';

const LandingHeader = ({ name, playStore, appleStore }) => (
  <div className="landing-header">
    <div className="content">
      <div className="top">
        <div className="logo">{name}</div>
        <div className="links">
          <a href="#features">
            <Translate value="features" />
          </a>
          <a href="#how-works">
            <Translate value="howWorks" />
          </a>
        </div>
      </div>

      <div className="middle">

        <div className="store-buttons">
          <h1><Translate value="newApp" /></h1>
          {playStore && (
          <a href={playStore} rel="noopener noreferrer" target="_blank">
            <img src="/images/googleplay.png" alt="Google Play" />
          </a>
          )}
          {appleStore && (
          <a href={appleStore} rel="noopener noreferrer" target="_blank">
            <img src="/images/applestore.png" alt="Apple Store" />
          </a>
          )}
        </div>

        <div className="phone">
          <img src="/images/landing/phone.png" alt="Phone" />
        </div>
      </div>

    </div>
  </div>
);

const mapStateToProps = state => ({
  name: state.settings.name,
  playStore: state.settings.playStore,
  appleStore: state.settings.appleStore,
});

export default connect(mapStateToProps)(LandingHeader);
