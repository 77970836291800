
import React from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import isEmail from 'validator/lib/isEmail';
import { validate } from 'rut.js';

import ValidatorsHelper from '../../../helpers/validators';
import StringHelper from '../../../helpers/string';

import { check, checkRut } from '../../../app/store/actions/solicitation';
import inputSize from '../../../data/inputs';

class StepBasicForm extends React.Component {
  state = {
    emailError: '',
    cnpjError: '',
    error: '',
  }

   onSubmit = async (e) => {
     e.preventDefault();

     const { cnpj, email, rut } = this.props;
     if (rut.length > 0) {
       if (!isEmail(email)) {
         await this.setState({ emailError: I18n.t('invalidEmail') });
       } else if (!validate(rut)) {
         await this.setState({ cnpjError: I18n.t('invalidCNPJ') });
       } else {
         try {
           const client = await this.props.checkRut(email, rut);
           if (client) await this.props.setClient(client, true);
           else this.props.handleNext();
         } catch (err) {
           if (err.response && err.response.data && err.response.data.error) {
             if (err.response.data.error.code === 404) {
               await this.setState({ error: I18n.t('emailCnpjMessage') });
             } else if (err.response.data.error.code === 301) {
               await this.setState({ error: I18n.t('emailCnpjMessage') });
             }
           }
         }
       }
     }

     if (!isEmail(email)) {
       await this.setState({ emailError: I18n.t('invalidEmail') });
     } else if (!ValidatorsHelper.validarCNPJ(cnpj)) {
       await this.setState({ cnpjError: I18n.t('invalidCNPJ') });
     } else if (cnpj && email) {
       try {
         const client = await this.props.check(email, cnpj);
         if (client) this.props.setClient(client, true);
         else this.props.handleNext();
       } catch (err) {
         if (err.response && err.response.data && err.response.data.error) {
           if (err.response.data.error.code === 404) {
             await this.setState({ error: I18n.t('emailCnpjMessage') });
           } else if (err.response.data.error.code === 301) {
             await this.setState({ error: I18n.t('emailCnpjMessage') });
           }
         }
       }
     }
   }

   setValue = async (attr, value) => {
     await this.setState({ [`${attr}Error`]: '' });
     await this.props.setValue(attr, value);
   }

   render() {
     const { emailError, cnpjError, error } = this.state;
     const {
       email, cnpj, documentType, rut,
     } = this.props;

     return (
       <form onSubmit={this.onSubmit}>
         <div>
           <input
             type="email"
             className="form-control"
             placeholder={I18n.t('insertEmail')}
             value={email}
             maxLength={inputSize.max}
             onChange={e => this.setValue('email', e.target.value)}
             required
           />
           {emailError ? <p className="error-p">{emailError}</p> : ''}

           {documentType !== 'B'
             ? (
               <InputMask
                 mask="99.999.999/9999-99"
                 value={cnpj}
                 onChange={e => this.setValue('cnpj', e.target.value)}
               >
                 {inputProps => (
                   <input
                     {...inputProps}
                     type="text"
                     className="form-control"
                     placeholder={I18n.t('insertCnpj')}
                     required
                   />
                 )}
               </InputMask>
             )
             : (
               <InputMask
                 mask="99999999-9"
                 value={rut}
                 onChange={e => this.setValue('rut', e.target.value)}
               >
                 {inputProps => (
                   <input
                     {...inputProps}
                     type="text"
                     className="form-control"
                     placeholder={I18n.t('insertCnpj')}
                     required
                   />
                 )}
               </InputMask>
             )
          }
           {cnpjError ? <p className="error-p">{cnpjError}</p> : ''}
           {error ? <p className="error-p">{error}</p> : ''}
         </div>

         <div className="row justify-content-center margin-top-20 margin-bottom-20 form-footer">
           <button
             type="submit"
             disabled
             className="backButton btn btn-light"
           >
             <Translate value="return" />
           </button>

           <button
             type="submit"
             className="btn btn-primary"
             variant="contained"
           >
             <Translate value="next" />
           </button>
         </div>
       </form>
     );
   }
}

const mapDispatchToProps = dispatch => ({
  check: (email, cnpj) => dispatch(check(email, StringHelper.keepOnlyNumber(cnpj))),
  checkRut: (email, rut) => dispatch(checkRut(email, rut)),
});

const mapStateToProps = state => ({
  solicitationCheck: state.check,
  documentType: state.settings.documentType,
});

export default connect(mapStateToProps, mapDispatchToProps)(StepBasicForm);
