import React from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import OrderAction from '../../app/store/actions/panel';
import StringHelper from '../../helpers/string';
import { addLoading } from '../../app/store/actions/loading';
import { estimatePurchase } from '../../app/store/actions/purchases';

class PunchOutCheckout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
    };
  }

  async componentDidMount() {
    try {
      const ticket = await this.props.finalize();
      await this.props.getOrderDetails(ticket.id, this.props.clientId);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error && err.response.data.error.code === 900) {
        this.setState({
          message: I18n.t('cartChanged'),
        });
      } else if (err.response && err.response.data && err.response.data.error && err.response.data.error.code === 907) {
        this.setState({
          message: I18n.t('noLimit'),
        });
      } else {
        this.setState({
          message: I18n.t('errorTryLater'),
        });
      }
    }
  }

  componentDidUpdate() {
    if (this.props && this.props.orderDetails) {
      this.formPunchout.submit();
    }
  }

  getOriginValue = (origin = 0) => {
    let value;
    const originZero = [0, 3, 4, 5, 8];
    value = originZero.includes(Number(origin)) ? 0 : null;
    const originTwo = [1, 2, 6, 7];
    value = originTwo.includes(Number(origin)) ? 2 : value;
    return value;
  };

  getProductDescription = (product) => {
    if (product) {
      return StringHelper.htmlEntities(product.purchaseProduct.clientDescription)
      || StringHelper.htmlEntities(product.purchaseProduct.description);
    }
    return '';
  };

  render() {
    const { punchOut, orderDetails } = this.props;
    const { message } = this.state;

    return (
      <main className="main">
        {message && (
          <div className="alert alert-warning alert-dismissible fade show" role="alert">
            {` ${message}`}
          </div>
        )}
        <form
          ref={(el) => { this.formPunchout = el; }}
          action={punchOut && punchOut.hookUrl}
          method="post"
        >
          {orderDetails && orderDetails.items.map(
            (item, index) => (
              <React.Fragment key={index.toString()}>
                <input
                  type="hidden"
                  name="~caller"
                  value="CTLG"
                />
                <input
                  type="hidden"
                  name="~OkCode"
                  value="ADDI"
                />
                <input
                  type="hidden"
                  name="~TARGET"
                  value=""
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-DESCRIPTION[${index + 1}]`}
                  value={this.getProductDescription(item)}
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-QUANTITY[${index + 1}]`}
                  value={parseFloat(item.amount).toFixed(2)}
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-UNIT[${index + 1}]`}
                  value={item.purchaseProduct.customerMeasureUnit || item.purchaseProduct.unitISO || item.purchaseProduct.unit}
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-PRICE[${index + 1}]`}
                  value={item.price}
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-CURRENCY[${index + 1}]`}
                  value="BRL"
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-LEADTIME[${index + 1}]`}
                  value={item.purchaseProduct.leadTime}
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-VENDORMAT[${index + 1}]`}
                  value={item.purchaseProduct.productClientCode}
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-MANUFACTMAT[${index + 1}]`}
                  value="000"
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-MANUFACTCODE[${index + 1}]`}
                  value={item.classFiscal}
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-MATGROUP[${index + 1}]`}
                  value="200000"
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-CUST_FIELD1[${index + 1}]`}
                  value={item.st ? 'R2' : 'R1'}
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-CUST_FIELD2[${index + 1}]`}
                  value={orderDetails.client.freight && orderDetails.client.freight.toUpperCase()}
                />
                <input
                  type="hidden"
                  name={`NEW_ITEM-CUST_FIELD3[${index + 1}]`}
                  value={this.getOriginValue(item.origin)}
                />
              </React.Fragment>
            ),
          )}
        </form>
      </main>
    );
  }
}

const mapStateToProps = state => ({
  clientId: state.contracts.selected.client.id,
  orderDetails: state.panel.orderDetails,
  punchOut: state.punchOut,
});

const mapDispatchToState = dispatch => ({
  finalize: () => dispatch(estimatePurchase()),
  getOrderDetails: (id, clientId) => dispatch(OrderAction.getOrderDetails(id, clientId)),
  addLoading: () => dispatch(addLoading()),
});

export default connect(mapStateToProps, mapDispatchToState)(PunchOutCheckout);
