import React from 'react';
import { connect } from 'react-redux';

import { Translate } from 'react-redux-i18n';
import PriceComponent from './Price';
import CartUtils from '../../app/utils/cart';

class PriceTaxComponent extends React.PureComponent {
  render() {
    const {
      products = [], taxesCart, taxName, taxType, companyTag,
    } = this.props;

    const items = products.map(item => ({
      total: item.quantity,
      product: item,
    }));

    const subTotal = CartUtils.getCartTotal(items, true, undefined, undefined, companyTag);
    const tax = CartUtils.getCartTax(items, taxType, undefined, companyTag);
    const total = subTotal + tax;

    return (
      <React.Fragment>

        {taxesCart && (
        <div className="row btn-cart-approve-row">
          <p className="total-cart-approve">
            <Translate value="subTotal" />
            {' '}
            <strong>
              <PriceComponent value={subTotal} force />
            </strong>
          </p>
        </div>
        )}

        {taxesCart && (
        <div className="row btn-cart-approve-row">
          <p className="total-cart-approve">
            {`${taxName}: `}
            <strong>
              <PriceComponent value={tax} force />
            </strong>
          </p>
        </div>
        )}

        <div className="row btn-cart-approve-row">
          <p className="total-cart-approve margin-bottom-10">
            <Translate value="total2" />
            {' '}
            <strong>
              <PriceComponent value={total} force />
            </strong>
          </p>
        </div>
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  taxName: state.settings && state.settings.taxName,
  taxType: state.settings && state.settings.taxType,
  taxesCart: state.settings && !state.settings.taxsCartOff,
  companyTag: state.settings && state.settings.companyTag,
});

export default connect(mapStateToProps)(PriceTaxComponent);
