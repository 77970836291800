import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Translate, I18n } from 'react-redux-i18n';

import StepBasicForm from './steps/basicForm';
import StepClientForm from './steps/clientForm';
import StepUserForm from './steps/userForm';

import StringHelper from '../../helpers/string';

import { solicitation } from '../../app/store/actions/solicitation';

class SolicitationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.cleanData();
  }

  cleanData = () => ({
    activeStep: 0,
    finalize: false,
    lockedClient: false,
    basic: {
      cnpj: '',
      email: '',
      rut: '',
    },
    client: {
      name: '',
      address: '',
      province: '',
      city: '',
      state: '',
      country: '',
      cep: '',
    },
    user: {
      firstName: '',
      lastName: '',
      role: '',
      sector: '',
      phone: '',
      birthday: '',
    },
  })

  setBasic = async (attr, value) => {
    const { basic } = this.state;
    basic[attr] = value;
    await this.setState({ basic });
  };

  setClient = async (client, locked) => {
    await this.setState({ activeStep: 1, client, lockedClient: locked });
  };

  setUser = async (user) => {
    await this.setState({ activeStep: 2, user });
  };

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    if (activeStep === 1) this.setState(this.cleanData());
    else this.setState({ activeStep: activeStep - 1 });
  };

  handleFinalize = async () => {
    const { basic, client, user } = this.state;
    const { cnpj, email, rut } = basic;
    const {
      name, address, province, city, state, country, cep,
    } = client;
    const {
      firstName, lastName, role, sector, phone, birthday,
    } = user;

    try {
      await this.props.solicitation({
        cnpj: StringHelper.keepOnlyNumber(cnpj),
        rut,
        email,
        name,
        address,
        province,
        city,
        state,
        country,
        cep: StringHelper.keepOnlyNumber(cep),
        firstName,
        lastName,
        birthday: moment(birthday).format('YYYY-MM-DD'),
        role: role && Number(role),
        sector: sector && Number(sector),
        phone: StringHelper.keepOnlyNumber(phone),
      });

      await this.setState({ finalize: true, activeStep: 3, error: '' });
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        await this.setState({ error: `${I18n.t('code2')} ${err.response.data.error.code}` });
      }
    }
  }

  getStepContent = () => {
    const {
      activeStep, lockedClient,
      basic, client, user,
    } = this.state;
    const { roles, sectors } = this.props;

    switch (activeStep) {
      case 0:
        return (
          <StepBasicForm
            email={basic.email}
            cnpj={basic.cnpj}
            rut={basic.rut}
            setValue={this.setBasic}
            setClient={this.setClient}
            handleNext={this.handleNext}
          />
        );
      case 1:
        return (
          <StepClientForm
            client={client}
            locked={lockedClient}
            setClient={this.setClient}
            handleNext={this.handleNext}
            handleBack={this.handleBack}
          />
        );
      case 2:
        return (
          <StepUserForm
            roles={roles}
            sectors={sectors}
            user={user}
            setUser={this.setUser}
            handleBack={this.handleBack}
            handleFinalize={this.handleFinalize}
          />
        );
      default:
        return I18n.t('errorOcurred');
    }
  }

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const { activeStep, finalize, error } = this.state;

    return (
      <div className="login container">
        <div className="row">
          <div className="root">
            <h2 className="h2-solicitation"><Translate value="registerSolicitation" /></h2>
            <div className="align-wizzard-center">
              <ul className="checkout-progress-bar">
                <li className={activeStep >= 0 ? 'active' : ''}>
                  <span><Translate value="emailCnpj" /></span>
                </li>
                <li className={activeStep >= 1 ? 'active' : ''}>
                  <span><Translate value="companyData" /></span>
                </li>
                <li className={activeStep >= 2 ? 'active' : ''}>
                  <span><Translate value="personalData" /></span>
                </li>
              </ul>
            </div>

          </div>
          <div className="col-md-6 offset-md-3">
            <main style={{ marginBottom: '40px' }} className="main">
              <div>
                {finalize ? (
                  <div className="row justify-content-center">
                    <div className="col-8 text-align-center margin-20">
                      <i className="fas fa-check-circle solicitation-icon-f" />
                      <h3><Translate value="successSolicitation" /></h3>
                      <p><Translate value="successSolicitationText" /></p>
                    </div>
                  </div>
                ) : (
                  <div className="instruction">
                    {this.getStepContent()}
                  </div>
                )}
                {error && (
                <p>
                  <Translate value="errorOcurred" />
                  <br />
                  {error}
                </p>
                )}
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  solicitation: data => dispatch(solicitation(data)),
});

const mapStateToProps = state => ({
  roles: state.settings.contact && state.settings.contact.roles,
  sectors: state.settings.contact && state.settings.contact.sectors,
});


export default connect(mapStateToProps, mapDispatchToProps)(SolicitationForm);
