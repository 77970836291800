import React from 'react';
import { connect } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import PunchOutCheckout from '../components/punchOut/PunchOutCheckout';
import PunchOutCheckoutNortel2 from '../components/punchOut/PunchOutCheckoutNortel2';
import PunchOutCheckoutNortel3 from '../components/punchOut/PunchOutCheckoutNortel3';
import PunchOutCheckoutNortel4 from '../components/punchOut/PunchOutCheckoutNortel4';
import PunchOutCheckoutNortel5 from '../components/punchOut/PunchOutCheckoutNortel5';
import PunchOutCheckoutNortel6 from '../components/punchOut/PunchOutCheckoutNortel6';

const PunchOutCheckoutPage = ({ punchoutRule }) => {
  if (punchoutRule === 'NORTEL3') {
    return (
      <main className="main">
        <Breadcrumb items={[{
          value: I18n.t('punchOut'),
        }]}
        />
        <PunchOutCheckoutNortel3 />
      </main>
    );
  }
  if (punchoutRule === 'NORTEL2') {
    return (
      <main className="main">
        <Breadcrumb items={[{
          value: I18n.t('punchOut'),
        }]}
        />
        <PunchOutCheckoutNortel2 />
      </main>
    );
  }
  if (punchoutRule === 'NORTEL4') {
    return (
      <main className="main">
        <Breadcrumb items={[{
          value: I18n.t('punchOut'),
        }]}
        />
        <PunchOutCheckoutNortel4 />
      </main>
    );
  }
  if (punchoutRule === 'NORTEL5') {
    return (
      <main className="main">
        <Breadcrumb items={[{
          value: I18n.t('punchOut'),
        }]}
        />
        <PunchOutCheckoutNortel5 />
      </main>
    );
  }
  if (punchoutRule === 'NORTEL6') {
    return (
      <main className="main">
        <Breadcrumb items={[{
          value: I18n.t('punchOut'),
        }]}
        />
        <PunchOutCheckoutNortel6 />
      </main>
    );
  }

  return (
    <main className="main">
      <Breadcrumb items={[{
        value: I18n.t('punchOut'),
      }]}
      />
      <PunchOutCheckout />
    </main>
  );
};

const mapStateToProps = state => ({
  punchoutRule: state.contracts.selected && state.contracts.selected.clientDetails.punchoutRule,
});

export default connect(mapStateToProps)(PunchOutCheckoutPage);
