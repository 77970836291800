import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';

import ROUTES from '../../../data/routes';

const FooterMiddleRight = ({
  playStore, appleStore, termsUrl, privacyPolicy, about,
}) => (
  <div className="row">
    <div className="col-md-4">
      <div className="widget">
        <h4 className="widget-title"><Translate value="navigation" /></h4>

        <div className="row">
          <div className="col-sm-12">
            <ul className="links">
              <li>
                <Link to={ROUTES.HOME}><Translate value="home" /></Link>
              </li>
              {about && (
                <li>
                  <Link to={ROUTES.ABOUT}><Translate value="aboutUs" /></Link>
                </li>
              )}
              {termsUrl && (
              <li>
                <Link to={ROUTES.USER_TERMS}><Translate value="userTerms" /></Link>
              </li>
              )}
              {privacyPolicy && (
              <li>
                <Link to={ROUTES.PRIVACY_POLICY}><Translate value="privacyPolicy" /></Link>
              </li>
              )}
            </ul>
          </div>
        </div>
      </div>
    </div>

    <div className="col-md-8">
      <div className="widget">
        <h4 className="widget-title"><Translate value="downloadApp" /></h4>

        <div className="row">
          {playStore && (
          <div className="col-sm-6">
            <ul className="links">
              <li>
                <a href={playStore} rel="noopener noreferrer" target="_blank">
                  <img src="/images/googleplay.png" alt="Google Play" />
                </a>
              </li>
            </ul>
          </div>
          )}
          {appleStore && (
          <div className="col-sm-6">
            <ul className="links">
              <li>
                <a href={appleStore} rel="noopener noreferrer" target="_blank">
                  <img src="/images/applestore.png" alt="Apple Store" />
                </a>
              </li>
            </ul>
          </div>
          )}
        </div>
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  playStore: state.settings.playStore,
  appleStore: state.settings.appleStore,
  termsUrl: state.settings.termsUrl,
  privacyPolicy: state.settings.privacyPolicy,
  about: state.settings.about,
});

export default connect(mapStateToProps)(FooterMiddleRight);
