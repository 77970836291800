import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';

import ROUTES from '../../data/routes';
import { purchaseGetFile } from '../../app/store/actions/purchases';
import PURCHASES from '../../data/purchases';

class CheckoutFinalize extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      ticket = {}, companyTag=''
    } = this.props;

    return (
    <div className="checkout-finalize">
      <i className="fas fa-check-circle" />
      <h2><Translate value="thanks" /></h2>
      <p><Translate value="successBuy" /></p>
      {ticket.paymentBankSlipUrl
        && (
        <button
          type="button"
          className="btn-ticket"
          onClick={() => window.open(ticket.paymentBankSlipUrl, '_blank', 'width=500,height=500')}
        >
          <i className="fas fa-barcode" />
          <p><Translate value="bankSlipVisualize" /></p>
        </button>
        )}
      {ticket.paymentOnlineDebitUrl
        && (
          <button
            type="button"
            className="btn btn btn-primary"
            onClick={
              () => window.open(ticket.paymentOnlineDebitUrl, '_blank', 'width=500,height=500')
            }
          >
            <Translate value="debitOn" />
          </button>
        )}
      {companyTag === 'chile' ?
        <button
          className="btn btn-primary margin-bottom-10"
          type="button"
          onClick={() => this.props.getFile(ticket.id)}
        >
          <Translate value={ ticket && ticket.paymentType !== PURCHASES.paymentType.ESTIMATE ? "details" : "detailsEstimate"} />
        </button> 
      :
        <Link to={ROUTES.ORDER_LIST} className="btn btn-primary margin-bottom-60">
          <Translate value="details" />
        </Link>
      }
      
      {companyTag === 'chile' ?
        <div>
          <Link to={ROUTES.PRODUCTS} className="btn btn-primary margin-bottom-60">
            <Translate value={'toBackStore'}/>
          </Link>
        </div>
      : null}

    </div>
    )
  }
}

const mapDispatchToProps = dispatch => ({
  getFile: (id) => dispatch(purchaseGetFile(id)),
});

export default connect(null, mapDispatchToProps)(CheckoutFinalize);
