import PurchaseData from '../../data/purchases';
import { I18n } from 'react-redux-i18n';

function getStatus(status) {
  switch (status) {
    case PurchaseData.orderStatus.CONFIRMED:
      return 0;
    case PurchaseData.orderStatus.PROCESSED:
    case PurchaseData.orderStatus.PAID:
    case PurchaseData.orderStatus.FINISHED:
    case PurchaseData.orderStatus.CANCELED:
    case PurchaseData.orderStatus.INVOICES_ISSUED_PARTIALLY:
      return 1;
    case PurchaseData.orderStatus.PICKING:
      return 2;
    case PurchaseData.orderStatus.INVOICES_ISSUED:
      return 3;
    case PurchaseData.orderStatus.ON_CARRIAGE:
      return 4;
    case PurchaseData.orderStatus.DELIVERED:
      return 5;
    default:
      return 0;
  }
}
function paymentStatus(payment) {
  if (payment === PurchaseData.paymentStatus.INDEFINITE) return I18n.t('undefined');
  if (payment === PurchaseData.paymentStatus.AUTHORIZED) return I18n.t('authorized');
  if (payment === PurchaseData.paymentStatus.NOT_AUTHORIZED) return I18n.t('notAuthorized');
  if (payment === PurchaseData.paymentStatus.CANCELED) return I18n.t('canceled');
  if (payment === PurchaseData.paymentStatus.CAPTURED) return I18n.t('captured');

  // PAGSEGURO

  if (payment === PurchaseData.paymentStatusPagSeguro.AWAIT_PAYMENT) return I18n.t('awaitPayment');
  if (payment === PurchaseData.paymentStatusPagSeguro.PAID) return I18n.t('authorized');
  if (payment === PurchaseData.paymentStatusPagSeguro.RETURNED) return I18n.t('notAuthorized');
  if (payment === PurchaseData.paymentStatusPagSeguro.CANCELED) return I18n.t('canceled');
  if (payment === PurchaseData.paymentStatusPagSeguro.DEBITED) return I18n.t('captured');
}


export default {
  getStatus,
  paymentStatus,
};
