import StorageService from '../../services/storage';
import { getCart, mergeCart } from './cart';

import { resetProducts } from './products';

export const setContracts = (contracts, index = 0) => ({
  type: 'CONTRACTS_SET',
  contracts,
  index,
});

export const selectSet = index => ({
  type: 'SELECT_CONTRACT_SET',
  index,
});

export const resetContracts = () => ({
  type: 'RESET_CONTRACTS',
});

export const saveContracts = contracts => (dispatch) => {
  let index = 0;
  try { index = StorageService.get('contract_client_index'); } catch (err) { index = 0; }
  index = index || 0;
  if (index >= contracts.length) index = 0;

  dispatch(resetProducts());
  dispatch(setContracts(contracts, index));

  try { StorageService.set('contract_client_index', index); } catch (err) { /* empty */ }

  dispatch(getCart());
};

export const selectContract = index => async (dispatch) => {
  dispatch(resetProducts());
  dispatch(selectSet(index));
  StorageService.set('contract_client_index', index);
  await dispatch(getCart());
  dispatch(mergeCart());
};

export const cleanContracts = () => (dispatch) => {
  dispatch(resetProducts());
  dispatch(resetContracts());
};
