/* eslint-disable no-useless-constructor */
import React, { PureComponent } from 'react';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import { getProductsReports } from '../../../app/store/actions/reports';

class Reports extends PureComponent {
  render = () => (
    <div className="col-lg-9">
      <div className="row">
        <h2 className="step-title">
          <Translate value="reports" />
        </h2>
      </div>

      <div className="row mt-3">
        <button
          type="button"
          className="btn btn-secondary justify-content-end btn-list-download"
          onClick={() => this.props.getDocumentPdf()}
        >
          <Translate value="generateContract" />
        </button>
      </div>
    </div>
  );
}

const mapDispatchToProps = dispatch => ({
  getDocumentPdf: () => dispatch(getProductsReports()),
});

export default connect(null, mapDispatchToProps)(Reports);
