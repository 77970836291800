import React from 'react';
import { Translate } from 'react-redux-i18n';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCar, faHeadset } from '@fortawesome/free-solid-svg-icons';
import { faCalendarCheck } from '@fortawesome/free-regular-svg-icons';

const HomeBoxesInfo = () => (
  <div className="info-boxes-container">
    <div className="container">
      <div className="info-box">
        <FontAwesomeIcon icon={faCar} />

        <div className="info-box-content">
          <h4><Translate value="deliveryAllCountry" /></h4>
          <p><Translate value="deliveryAllCountryText" /></p>
        </div>
      </div>

      <div className="info-box">
        <FontAwesomeIcon icon={faCalendarCheck} />

        <div className="info-box-content">
          <h4><Translate value="quality" /></h4>
          <p><Translate value="qualityText" /></p>
        </div>
      </div>

      <div className="info-box">
        <FontAwesomeIcon icon={faHeadset} />

        <div className="info-box-content">
          <h4><Translate value="needHelp" /></h4>
          <p><Translate value="needHelpText" /></p>
        </div>
      </div>
    </div>
  </div>
);

export default HomeBoxesInfo;
