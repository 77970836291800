import StringHelper from './string';

const validarCNPJ = (cnpj) => {
  const verifierDigit = (numbers) => {
    let index = 2;
    const reverse = numbers.split('').reduce((buffer, number) => [parseInt(number, 10)].concat(buffer), []);

    const sum = reverse.reduce((buffer, number) => {
      buffer += number * index;
      index = (index === 9 ? 2 : index + 1);
      return buffer;
    }, 0);

    const mod = sum % 11;
    return (mod < 2 ? 0 : 11 - mod);
  };

  const stripped = cnpj.replace(/[^\d]+/g, '');

  if (stripped === '') return false;

  // CNPJ must be defined
  if (!stripped) { return false; }

  // CNPJ must have 14 chars
  if (stripped.length !== 14) { return false; }

  // CNPJ can't be blacklisted
  if ([
    '00000000000000',
    '11111111111111',
    '22222222222222',
    '33333333333333',
    '44444444444444',
    '55555555555555',
    '66666666666666',
    '77777777777777',
    '88888888888888',
    '99999999999999',
  ].indexOf(stripped) >= 0) { return false; }

  let numbers = stripped.substr(0, 12);
  numbers += verifierDigit(numbers);
  numbers += verifierDigit(numbers);

  return numbers.substr(-2) === stripped.substr(-2);
};

const validarCPF = (cpf) => {
  cpf = cpf.replace(/[^\d]+/g, '');
  if (cpf === '' || !(/^\d+$/.test(cpf))) return false;
  if (cpf.length !== 11 || cpf === '00000000000' || cpf === '11111111111' || cpf === '22222222222'
    || cpf === '33333333333' || cpf === '44444444444' || cpf === '55555555555' || cpf === '66666666666'
    || cpf === '77777777777' || cpf === '88888888888' || cpf === '99999999999') { return false; }
  let add = 0;
  for (let i = 0; i < 9; i += 1) { add += parseInt(cpf.charAt(i), 10) * (10 - i); }
  let rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) { rev = 0; }
  if (rev !== parseInt(cpf.charAt(9), 10)) { return false; }
  add = 0;
  for (let i = 0; i < 10; i += 1) { add += parseInt(cpf.charAt(i), 10) * (11 - i); }
  rev = 11 - (add % 11);
  if (rev === 10 || rev === 11) { rev = 0; }
  if (rev !== parseInt(cpf.charAt(10), 10)) { return false; }
  return true;
};


const validDocument = (type, value) => {
  const valueNumbers = StringHelper.keepOnlyNumber(value);
  switch (type) {
    case 'cnpj':
      return validarCNPJ(valueNumbers);
    case 'cpf':
      return validarCPF(valueNumbers);
    default:
  }
};

const isSpotProductAmountInvalid = (
  newAmount, productStock = 0, isAllowedSellSpotProductsWithoutStock, isSpotProduct, isContractProduct,
) => {
  if (
    Number(newAmount) > productStock
    && !isAllowedSellSpotProductsWithoutStock
    && isSpotProduct
    && !isContractProduct) return true;
  return false;
};

export default {
  validDocument,
  validarCNPJ,
  validarCPF,
  isSpotProductAmountInvalid,
};
