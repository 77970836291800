import panelRequests from '../../server/panel';
import { addLoading, removeLoading } from './loading';

export const setOrderList = orderList => ({
  type: 'PANEL_ORDER_LIST',
  orderList,
});

export const setOrderDetails = orderDetails => ({
  type: 'PANEL_ORDER_DETAILS',
  orderDetails,
});

const getOrderList = (data, filterBy) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const orderList = await panelRequests.getOrderList(data, filterBy);
    dispatch(setOrderList(orderList));
  } finally {
    dispatch(removeLoading());
  }
};

const getOrderDetails = (id, clientId) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const orderDetails = await panelRequests.getOrderDetails(id, clientId);
    dispatch(setOrderDetails(orderDetails));
  } finally {
    dispatch(removeLoading());
  }
};

const getConsumption = () => async (dispatch, getState) => {
  const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
  dispatch(addLoading());
  try {
    await panelRequests.getConsumption(clientId);
  } finally {
    dispatch(removeLoading());
  }
};

export default {
  getOrderList,
  getOrderDetails,
  getConsumption,
};
