import RestService from '../services/rest';

function getSearchOptions(searchOptions) {
  let url = `limit=${searchOptions.limit}&skip=${searchOptions.skip}`;
  if (searchOptions.text) url += `&text=${searchOptions.text}`;
  if (searchOptions.type) url += `&type=${searchOptions.type}`;
  if (searchOptions.mainCategories) url += `&mainCategories=${searchOptions.mainCategories.join(';')}`;
  if (searchOptions.subCategories) url += `&subCategories=${searchOptions.subCategories.join(';')}`;
  if (searchOptions.brands) url += `&brands=${searchOptions.brands.join(';')}`;
  if (searchOptions.pdmsModifiers) url += `&pdmsModifiers=${searchOptions.pdmsModifiers.join(';')}`;
  if (searchOptions.pdmsParticulars) url += `&pdmsParticulars=${searchOptions.pdmsParticulars.join(';')}`;
  if (searchOptions.brands) url += `&brands=${searchOptions.brands.join(';')}`;
  if (searchOptions.sort_selected) url += `&sort_selected=${searchOptions.sort_selected}`;
  return url;
}

async function getFeatured() {
  return RestService.getRest('products/spotlight');
}

async function getClientFeatured(clientId) {
  return RestService.getAuthenticated(`products/client/spotlight?client=${clientId}`);
}

async function getClientSimilar(clientId, productId) {
  return RestService.getAuthenticated(`products/client/similar?client=${clientId}&product=${productId}`);
}

async function getClientCrossSelling(clientId, productId) {
  return RestService.getAuthenticated(`products/client/cross-selling?client=${clientId}&product=${productId}`);
}

async function getClientPromotions(clientId) {
  return RestService.getAuthenticated(`products/client/promotions?client=${clientId}`);
}

async function getSearch(searchOptions) {
  let url = 'products/search/elastic?';
  url += getSearchOptions(searchOptions);
  return RestService.getRest(url);
}

async function getClientSearch(clientId, searchOptions) {
  let url = `products/client/search/elastic?client=${clientId}&`;
  url += getSearchOptions(searchOptions);
  return RestService.getAuthenticated(url);
}

async function getFilters(searchOptions) {
  let url = 'products/filters?';
  url += getSearchOptions(searchOptions);
  return RestService.getRest(url);
}

async function getClientFilters(clientId, searchOptions) {
  let url = `products/client/filters?client=${clientId}&`;
  url += getSearchOptions(searchOptions);
  return RestService.getAuthenticated(url);
}

async function getDetails(productId) {
  return RestService.getRest(`products/details?product=${productId}`);
}

async function getClientDetails(clientId, productId, code) {
  const clientCode = code && code !== '0' ? `&code=${code}` : '';
  return RestService.getAuthenticated(`products/client/details?client=${clientId}&product=${productId}${clientCode}`);
}

async function getPricing(clientId, userId, productIds) {
  return RestService.getAuthenticated(`products/pricing?client=${clientId}&user=${userId}&products=${productIds.join(';')}`);
}

export default {
  getFeatured,
  getClientFeatured,
  getClientSimilar,
  getClientCrossSelling,
  getClientPromotions,
  getSearch,
  getClientSearch,
  getFilters,
  getClientFilters,
  getDetails,
  getClientDetails,
  getPricing,
};
