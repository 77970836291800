import React from 'react';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from '@fortawesome/free-solid-svg-icons';

import ROUTES from '../../data/routes';

const Breadcrumb = ({ items }) => (
  <nav aria-label="breadcrumb" className="breadcrumb-nav">
    <div className="container">
      <ol className="breadcrumb">
        <li className="breadcrumb-item"><Link to={ROUTES.HOME}><FontAwesomeIcon icon={faHome} /></Link></li>
        {items.map((item, index) => (
          <li
            key={item.value || index}
            className={`breadcrumb-item${index + 1 === items.length ? ' active' : ''}`}
            onClick={item.click ? item.click : null}
          >
            {item.link ? (
              <Link to={item.link}>{item.value}</Link>
            ) : item.click ? <Link to="#">{item.value}</Link> : item.value}
          </li>
        ))}
      </ol>
    </div>
  </nav>
);

export default Breadcrumb;
