import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';

import CheckoutResumePrice from './CheckoutResumePrice';
import CartResumeItem from './CartResumeItem';
import { setObservation } from '../../app/store/actions/cart';
import inputs from '../../data/inputs';
import DeliveryTypeCard from '../delivery/DeliveryTypeCard';

function getPaymentLabel(type) {
  switch (type) {
    case 'credit':
      return I18n.t('credit');
    case 'debit':
      return I18n.t('debit');
    case 'bank_slip':
      return I18n.t('bankSlip');
    case 'estimate':
      return I18n.t('estimate');
    case 'billing':
      return I18n.t('billing2');
    default:
      return '';
  }
}

function getObservationByCompany(empName) {
  switch (empName) {
    case 'nortel':
      return I18n.t('observationTextNortel');
    default:
      return I18n.t('observationText');
  }
}

function getObservationMinMax(empName) {
  switch (empName) {
    case 'nortel':
      return inputs.observationNortel;
    default:
      return inputs.observation;
  }
}

const CheckoutResume = (props) => {
  const {
    selectedContract, cartItems, payment, address, clientNumber,
    pricePermission, deliveryDays, observation, visibleResume, isClient, companyTag,
  } = props;

  const company = companyTag || '';
  const inputObs = getObservationMinMax(company);

  const paymentTerms = selectedContract.clientDetails && selectedContract.clientDetails.paymentTermsLabel
    && selectedContract.clientDetails.paymentTermsLabel.description;

  return (
    <div className="order-summary">
      <h3><Translate value="summary" /></h3>
      {address
        && (
          <div>
            <h4>
              <div className="checkout-resume">
                <Translate value="deliveryType" />
              </div>
            </h4>
            <div className="delivery-type">
              <DeliveryTypeCard isClient={isClient} />
            </div>
            <p className="margin-bottom-0">{address.name}</p>
            <p className="margin-bottom-0">{address.address}</p>
            <p className="margin-bottom-0">{address.province}</p>
            <p className="margin-bottom-0">{`${address.city} - ${address.state}`}</p>
            <p className="margin-bottom-0">{address.cep}</p>
            <p className="margin-bottom-0">{address.country}</p>
            <button
              type="button"
              className="button-action btn btn-secondary"
              style={{ margin: '10px 0' }}
              onClick={() => props.changeAddress()}
            >
              <Translate value="changeAddress" />
            </button>
          </div>
        )
      }

      {/* Prazo de entrega removido temporariamente
      {(address && deliveryDays) ? (
        <div>
          <h4>
            <div className="checkout-resume">
              <Translate value="estimateTime" />
            </div>
          </h4>
          <p>
            {deliveryDays}
            {' '}
            <Translate value="workingDays" />
          </p>
        </div>
      ) : ''} */}

      {payment && payment.type && (
        <div>
          <h4>
            <div className="checkout-resume">
              <Translate value="paymentType" />
            </div>
          </h4>
          {getPaymentLabel(payment.type)}
          {paymentTerms && payment.type === 'billing' ? ` - ${paymentTerms}` : ''}
          <br />
          <button
            type="button"
            className="button-action btn btn-secondary"
            style={{ margin: '10px 0' }}
            onClick={() => props.changePayment()}
          >
            <Translate value="changePayment" />
          </button>
        </div>
      )}


      {clientNumber && (
        <div>
          <h4>
            <div className="checkout-resume">
              <Translate value="orderCodeOptional" />
            </div>
          </h4>
          <p>{clientNumber}</p>
        </div>
      )}

      <h4>
        <div className="checkout-resume">
          {cartItems.length}
          {' '}
          {I18n.t('cartProducts')}
        </div>
      </h4>
      <table className="table table-mini-cart">
        <tbody>
          {cartItems.map(item => (
            <CartResumeItem
              item={item}
              visibleResume={visibleResume}
              pricePermission={pricePermission}
            />
          ))}
        </tbody>
        {pricePermission && (
          <CheckoutResumePrice
            cartItems={cartItems}
            hasTax={address && address.hasTax}
          />
        )}
      </table>
      <div>
        <h4 className="observation-title">
          <div className="checkout-resume">{getObservationByCompany(company)}</div>
        </h4>
        <textarea
          className="form-control border-radius-4 observation"
          rows={4}
          value={observation}
          onChange={text => props.setObservation(text.target.value)}
          minLength={inputObs.min}
          maxLength={inputObs.max}
        />
      </div>
    </div>
  );
};

const mapStateToProps = (state, ownProps) => ({
  selectedContract: state.contracts.selected,
  clientNumber: state.cart.clientNumber,
  observation: state.cart.observation,
  address: ownProps.address && (
    ownProps.address.isClient ? state.contracts.selected.clientDetails.location
      : state.contracts.selected.client.locations
        .find(a => a.nameAbbr === ownProps.address.nameAbbr && a.cep === ownProps.address.cep)
  ),
  pricePermission: state.contracts.selected && state.contracts.selected.pricePermission,
  deliveryDays: Number(state.settings.deliveryDays),
  isClient: ownProps.address.isClient,
  companyTag: state.settings.companyTag,
});

const mapDispatchToProps = dispatch => ({
  setObservation: text => dispatch(setObservation(text)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CheckoutResume);
