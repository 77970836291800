/* eslint-disable no-undef */
/* eslint-disable no-empty */
/* eslint-disable no-plusplus */
export const appendScript = (scriptToAppend) => {
  const script = document.createElement('script');
  script.src = scriptToAppend;
  script.async = false;
  document.body.appendChild(script);
};

export const removeScript = (scriptToremove) => {
  try {
    const allsuspects = document.getElementsByTagName('script');
    for (let i = allsuspects.length; i >= 0; i--) {
      if (allsuspects[i] && allsuspects[i].getAttribute('src') !== null
        && allsuspects[i].getAttribute('src').indexOf(`${scriptToremove}`) !== -1) {
        allsuspects[i].parentNode.removeChild(allsuspects[i]);
      }
    }
  } catch (e) {}
};

export const loadScript = (scriptName) => {
  removeScript(scriptName);
  appendScript(scriptName);
};

export const scrollToTopAnimated = (timer = 900) => {
  try {
    const funStop = () => {
      $('html, body').stop();
      $(window).off('mousewheel', funStop);
    };

    $(window).on('mousewheel', funStop);

    $('HTML, BODY').animate({
      scrollTop: 0,
    }, timer, () => {
      $(window).off('mousewheel', funStop);
    });
  } catch (e) {}
};

export const scrollToElement = (el = 'HTML, BODY') => {
  $(el).animate({
    scrollTop: 0,
  }, 300);
};

export default {};
