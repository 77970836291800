import React from 'react';
import { connect } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import { getFreight } from '../../../app/store/actions/cart';
import PriceComponent from '../../price/Price';


class Freight extends React.PureComponent {
  state = {
    message: '',
  }

  async componentDidMount() {
    try {
      await this.props.getFreight();
    } catch (err) {
      const validError = !!(err.response && err.response.data && err.response.data.error);
      const details = validError && err.response.data.error.details;
      await this.setState({ message: `${I18n.t('errorOcurred')} - ${details}` });
    }
  }

  render() {
    const { message } = this.state;
    const {
      location, freights,
    } = this.props;

    return (
      <ul className="list-group margin-bottom-20">
        <li className="list-group-item active margin-bottom-5 theme-background">Selecione o frete</li>

        {freights && freights.map(freight => (
          <button
            type="button"
            className="list-group-item card-payment margin-bottom-10"
            onClick={() => this.props.setFreight(location, freight)}
          >
            <div className="row address-sub-card">
              {`${I18n.t('transportCompany')}: ${freight.shippingCompanyName}`}
            </div>
            <div className="row address-sub-card">
              {`${I18n.t('transportDocument')}: ${freight.shippingCompanyDoc}`}
            </div>
            <div className="row address-sub-card">
              {`${I18n.t('timeDelivered')}: ${freight.deadline} ${I18n.t('days')}`}
            </div>
            <div className="row address-sub-card">
              <span className="price-freight">
                <PriceComponent value={freight.value} />
              </span>
            </div>
          </button>
        ))}

        {message && <p className="color-red">{message}</p>}

      </ul>
    );
  }
}

const mapStateToProps = state => ({
  freights: state.cart && state.cart.freight,
});

const mapDispatchToProps = dispatch => ({
  getFreight: () => dispatch(getFreight()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Freight);
