import React from 'react';
import { Switch } from 'react-router-dom';

import PublicRoute from './PublicRoute';
import PrivateRoute from './PrivateRoute';

import HomePage from '../pages/Home';
import LoginPage from '../pages/Login';
import NotFoundPage from '../pages/NotFound';

import LandingPage from '../pages/Landing';

import ProductPage from '../pages/Product';
import ProductsPage from '../pages/Products';

import CartPage from '../pages/Cart';
import CartImportPage from '../pages/CartImport';
import CheckoutPage from '../pages/Checkout';

import ForgotPasswordPage from '../pages/ForgotPassword';
import SolicitationPage from '../pages/Solicitation';
import RegisterPage from '../pages/Register';
import NewRegisterPage from '../pages/NewRegister';

import ContractsPage from '../pages/Contracts';
import PanelPage from '../pages/Panel';
import PanelAccountPage from '../pages/PanelAccount';
import OrderListPage from '../pages/PanelOrderList';
import CartsToApprovePage from '../pages/PanelCartsToApprove';
import OrderDetailsPage from '../pages/PanelOrderDetails';
import ReportPage from '../pages/Report';
import PrivacyPolicyPage from '../pages/PrivacyPolicy';
import UserTermsPage from '../pages/UserTerms';
import changePasswordRecoveryPage from '../pages/ChangePasswordRecovery';
import ChangePasswordPage from '../pages/ChangePassword';
import MySolicitationsPage from '../pages/MySolicitations';
import AboutPage from '../pages/About';
import ROUTES from '../data/routes';
import PunchOutPage from '../pages/PunchOut';
import PunchOutCheckoutPage from '../pages/PunchOutCheckout';

const Content = () => (
  <Switch>
    <PublicRoute path={ROUTES.HOME} component={HomePage} exact />
    <PublicRoute path={ROUTES.LOGIN} component={LoginPage} exact />
    <PublicRoute path={ROUTES.PUNCH_OUT} component={PunchOutPage} noHeader exact />
    <PublicRoute path={ROUTES.PUNCH_OUT_CHECKOUT} component={PunchOutCheckoutPage} exact />
    <PublicRoute path={ROUTES.FORGOT_PASSWORD} component={ForgotPasswordPage} exact />
    <PublicRoute path={ROUTES.SOLICITATION} component={SolicitationPage} exact />
    <PublicRoute path={ROUTES.REGISTER} component={RegisterPage} exact />
    <PublicRoute path={ROUTES.NEW_REGISTER} component={NewRegisterPage} exact />
    <PrivateRoute path={ROUTES.CART} component={CartPage} exact />
    <PrivateRoute path={ROUTES.CHANGE_PASSWORD_RECOVERY} component={changePasswordRecoveryPage} exact />
    <PrivateRoute path={ROUTES.CHANGE_PASSWORD} component={ChangePasswordPage} exact />
    <PrivateRoute path={ROUTES.CHECKOUT} component={CheckoutPage} exact />
    <PublicRoute path={ROUTES.LANDING} component={LandingPage} noHeader exact />
    <PublicRoute path={ROUTES.PRODUCTS_ID} component={ProductPage} />
    <PublicRoute path={ROUTES.PRODUCTS} component={ProductsPage} exact />
    <PublicRoute path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicyPage} exact />
    <PrivateRoute path={ROUTES.CONTRACTS} component={ContractsPage} exact />
    <PrivateRoute path={ROUTES.PANEL} component={PanelPage} exact />
    <PrivateRoute path={ROUTES.PANEL_ACCOUNT} component={PanelAccountPage} exact />
    <PrivateRoute path={ROUTES.PANEL_CARTS_TO_APPROVE} component={CartsToApprovePage} exact />
    <PrivateRoute path={ROUTES.ORDER_LIST} component={OrderListPage} exact />
    <PrivateRoute path={ROUTES.ORDER_DETAILS} component={OrderDetailsPage} exact />
    <PrivateRoute path={ROUTES.REPORT} component={ReportPage} exact />
    <PrivateRoute path={ROUTES.PANEL_CARTS_SOLICITED} component={MySolicitationsPage} exact />
    <PrivateRoute path={ROUTES.CART_IMPORT} component={CartImportPage} exact />
    <PublicRoute path={ROUTES.USER_TERMS} component={UserTermsPage} exact />
    <PublicRoute path={ROUTES.ABOUT} component={AboutPage} exact />    
    <PublicRoute component={NotFoundPage} />
  </Switch>
);

export default Content;
