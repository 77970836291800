import RestService from '../services/rest';

async function createRegister(data) {
  return RestService.postRest('registers', data);
}

async function check(email, cnpj, type) {
  return RestService.getRest(`registers/check/?email=${email}&cnpj=${cnpj}&type=${type}`);
}

async function checkRut(email, rut) {
  return RestService.getRest(`registers/check/?email=${email}&rut=${rut}`);
}

export default {
  createRegister,
  check,
  checkRut,
};
