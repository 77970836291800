const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PURCHASE_LAST_TICKET':
      state.lastPurchaseTicket = action.ticket;
      return { ...state };
    default:
      return state;
  }
};
