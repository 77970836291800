import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import { logout } from '../../../app/store/actions/auth';
import { selectContract } from '../../../app/store/actions/contracts';

import { history } from '../../../routers/AppRouter';

import ROUTES from '../../../data/routes';

export class HeaderTopRightPrivate extends React.Component {
  userLogout = async () => {
    try {
      await this.props.logout();
    } finally {
      history.push(ROUTES.HOME);
    }
  }

  changePage = () => {
    history.push(ROUTES.CHANGE_PASSWORD);
  }

  selectContract = async (index) => {
    await this.props.selectContract(index);
    if (history.location.pathname !== ROUTES.HOME) history.push(ROUTES.HOME);
  }

  render() {
    const {
      user, contracts = {}, isPunchOut = false, panelPermission = false,
    } = this.props;
    return (
      <div className="header-right">

        <div className="welcome-msg">
          <div className="header-dropdown">
            <div className="select-contract">
              {contracts.selected ? (contracts.selected.clientDetails.nickname
                || contracts.selected.client.name) : I18n.t('withOutContract')}
            </div>
            <div className="header-menu">
              <ul>
                {(contracts.list || []).map(contract => (
                  <li key={contract.index}>
                    <div
                      className="contract-option"
                      onClick={() => this.selectContract(contract.index)}
                      onKeyDown={() => this.selectContract(contract.index)}
                      role="presentation"
                    >
                      {contract.pendingSolicitations ? (
                        <span className="header-solicitations-indicator">{contract.pendingSolicitations}</span>
                      ) : ''}
                      {contract.clientDetails.nickname || contract.client.name}
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>

        <p className="welcome-msg">
          <Translate value="welcome" />
          {' '}
          {user.name}
          {'!'}
        </p>

        {panelPermission && (
        <div className="header-dropdown dropdown-expanded">
          <div className="header-menu">
            <ul>
              <li>
                <div className="panel">
                  {contracts.selected && contracts.selected.pendingSolicitations ? (
                    <span className="header-solicitations-indicator">{contracts.selected.pendingSolicitations}</span>
                  ) : ''}
                  <Link to={ROUTES.ORDER_LIST} className="login-link"><Translate value="panel" /></Link>
                </div>
              </li>
              <li />
            </ul>
          </div>
        </div>
        )}

        {!isPunchOut && (
          <div className="dropdown-top-menu">
            <i className="fas fa-cog" />
            <div className="dropdown-top-menu-content">
              <button
                className="clean-button col-12 btn-top-menu text-left"
                type="button"
                onClick={this.changePage}
              >
                <Translate value="passwordChange" />
              </button>
              <button
                className="clean-button col-12 btn-top-menu text-left"
                type="button"
                onClick={this.userLogout}
              >
                <Translate value="exit" />
              </button>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  selectContract: index => dispatch(selectContract(index)),
});

export default connect(undefined, mapDispatchToProps)(HeaderTopRightPrivate);
