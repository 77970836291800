import AnalyticsServer from '../server/analytics';

function uuidv4() {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0;// eslint-disable-line
    const v = c === 'x' ? r : (r & 0x3 | 0x8);// eslint-disable-line
    return v.toString(16);
  });
}

function initialize() {
  const w = Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
  const h = Math.max(document.documentElement.clientHeight, window.innerHeight || 0);


  window.analyticsData = {
    clientId: uuidv4(),
    location: document.URL,
    screenColors: `${screen.colorDepth}-bit`,
    screenResolution: `${screen.width}x${screen.height}`,
    title: document.title,
    viewportSize: `${w}x${h}`,
    encoding: document.characterSet,
    language: navigator.language || navigator.userLanguage,
  };
}

async function sendPage(page) {
  const data = {
    ...window.analyticsData,
    page,
  };

  await AnalyticsServer.sendPage(data);
}

export default {
  initialize,
  sendPage,
};
