import React from 'react';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import PanelSidebar from '../components/panel/PanelSidebar';
import OrderList from '../components/panel/orderList/OrderList';

const OrderListPage = () => (
  <main className="main">
    <div className="container">
      <Breadcrumb items={[{
        value: I18n.t('panel'),
      }, {
        value: I18n.t('documentList2'),
      }]}
      />
      <div className="row">
        <PanelSidebar />
        <OrderList />
      </div>
    </div>
  </main>
);

export default OrderListPage;
