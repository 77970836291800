import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';

import ROUTES from '../data/routes';

const NotFoundPage = () => (
   <div className="container">
    <br/>
    <br/>
    <br/>
    <br/>
    <h2><Translate value="pageNotFound" /></h2>
    <p><Link to={ROUTES.HOME}><Translate value="returnHome" /></Link></p>
    <br/>
    <br/>
    <br/>
    <br/>
  </div>
);

export default NotFoundPage;
