import React from 'react';
import { I18n } from 'react-redux-i18n';
import { connect } from 'react-redux';
import OrderAction from '../../app/store/actions/panel';
import StringHelper from '../../helpers/string';
import { addLoading } from '../../app/store/actions/loading';
import { estimatePurchase } from '../../app/store/actions/purchases';

const { create } = require('xmlbuilder2');

class PunchOutCheckoutNortel5 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      message: null,
    };
  }

  async componentDidMount() {
    try {
      const ticket = await this.props.finalize();
      await this.props.getOrderDetails(ticket.id, this.props.clientId);
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error && err.response.data.error.code === 900) {
        this.setState({
          message: I18n.t('cartChanged'),
        });
      } else if (err.response && err.response.data && err.response.data.error && err.response.data.error.code === 907) {
        this.setState({
          message: I18n.t('noLimit'),
        });
      } else {
        this.setState({
          message: I18n.t('errorTryLater'),
        });
      }
    }
  }

  componentDidUpdate() {
    if (this.props && this.props.orderDetails) {
      this.formPunchout.submit();
    }
  }

  getProductDescription = (product) => {
    if (product) {
      return StringHelper.htmlEntities(product.purchaseProduct.product.descriptionShort)
        || StringHelper.htmlEntities(product.purchaseProduct.clientDescription)
      || StringHelper.htmlEntities(product.purchaseProduct.description);
    }
    return '';
  };

  getProductLongDescription = (product) => {
    if (product) {
      return StringHelper.htmlEntities(product.purchaseProduct.product.descriptionLarge)
      || StringHelper.htmlEntities(product.purchaseProduct.product.descriptionShort);
    }
    return '';
  };

  createResponse = (orderDetails, punchOut) => {
    const ItemIn = orderDetails.items.map(item => ([
      {
        '@quantity': parseFloat(item.amount).toFixed(2),
        '#': {
          ItemID: {
            SupplierPartID: item.purchaseProduct.code,
            SupplierPartAuxiliaryID: item.purchaseProduct.soneparId,
          },
          ItemDetail: {
            UnitPrice: {
              Money: [
                { '@currency': 'BRL', '#': item.price },
              ],
            },
            Description: [
              {
                '@lang@@xml': 'pt-BR',
                '#': this.getProductLongDescription(item) || this.getProductDescription(item),
              },
            ],
            UnitOfMeasure: item.purchaseProduct.customerMeasureUnit || item.purchaseProduct.unitISO || item.purchaseProduct.unit,
            Classification: [
              { '@domain': 'UNSPSC', '#': item.classFiscal },
            ],
            ManufacturerName: item.purchaseProduct.product.marca,
            LeadTime: item.purchaseProduct.leadTime,
          },
        },
      },
    ]));

    function getRandomInt(min, max) {
      min = Math.ceil(min);
      max = Math.floor(max);
      return Math.floor(Math.random() * (max - min)) + min;
    }

    const payLoadID = `${new Date().getTime()}.${getRandomInt(1000, 5000)}.${getRandomInt(5000, 9999)}@nortel.com.br`;

    const object = {
      cXML: [
        {
          '@payloadID': payLoadID,
          '@xml:lang': 'pt-BR',
          '@timestamp': new Date().toISOString(),
          '@version:': '1.2.0.14',
          '#': {
            Header: {
              From: {
                Credential: [
                  { '@domain': 'NetworkID', '#': { Identity: 'AN01049042593-T' } },
                ],
              },
              To: {
                Credential: [
                  { '@domain': 'NetworkID', '#': { Identity: orderDetails.client.punchoutVendorId } },
                ],
              },
              Sender: {
                Credential: [
                  {
                    '@domain': 'NetworkID',
                    '#': {
                      Identity: orderDetails.client.punchoutVendorId,
                      SharedSecret: 'ARIBA123',
                    },
                  },
                ],
                UserAgent: punchOut.userAgent,
              },
            },
            Message: {
              PunchOutOrderMessage: {
                BuyerCookie: punchOut.buyerCookie,
                PunchOutOrderMessageHeader: [
                  {
                    '@operationAllowed': 'create',
                    '#': {
                      Total: {
                        Money: [
                          { '@currency': 'BRL', '#': orderDetails.value },
                        ],
                      },
                    },
                  },
                ],
                ItemIn,
              },
            },
          },
        },
      ],
    };
    return create(object)
      .dec({ encoding: 'UTF-8' })
      .dtd({ name: 'cXML', sysID: 'http://xml.cxml.org/schemas/cXML/1.2.014/cXML.dtd' })
      .end({ pretty: true });
  }

  render() {
    const { punchOut, orderDetails } = this.props;
    const { message } = this.state;

    return (
      <main className="main">
        {message && (
          <div className="alert alert-warning alert-dismissible fade show" role="alert">
            {` ${message}`}
          </div>
        )}
        {orderDetails && (
          <form
            ref={(el) => { this.formPunchout = el; }}
            action={punchOut && punchOut.hookUrl}
            method="POST"
          >
            <React.Fragment key="1">
              <input
                type="hidden"
                name="cxml-urlencoded"
                value={this.createResponse(orderDetails, punchOut)}
              />
            </React.Fragment>
          </form>
        )}
      </main>
    );
  }
}

const mapStateToProps = state => ({
  clientId: state.contracts.selected.client.id,
  orderDetails: state.panel.orderDetails,
  punchOut: state.punchOut,
});

const mapDispatchToState = dispatch => ({
  finalize: () => dispatch(estimatePurchase()),
  getOrderDetails: (id, clientId) => dispatch(OrderAction.getOrderDetails(id, clientId)),
  addLoading: () => dispatch(addLoading()),
});

export default connect(mapStateToProps, mapDispatchToState)(PunchOutCheckoutNortel5);
