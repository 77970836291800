import React from 'react';

const HomeSliderBanner = ({ banner }) => {
  const BannerElement = <div className="image" style={{ backgroundImage: `url('${banner.imageUrl}')` }} />;
  if (!banner.redirectUrl) return BannerElement;
  return (
    <a href={banner.redirectUrl}>
      {BannerElement}
    </a>
  );
};

export default HomeSliderBanner;
