import React from 'react';
import { connect } from 'react-redux';

import { getSettingLong } from '../app/store/actions/settings';

class AboutPage extends React.PureComponent {
  state = {
  }
  async componentDidMount() {
    const { companyTag } = this.props;
    if (companyTag === 'chile') {
      const aboutLong = await this.props.getSettingLong('about');
      if (aboutLong) {
        this.setState({aboutLong});
      }
    }
  }
  render() {
    const { about, companyTag } = this.props;
    const { aboutLong } = this.state;
    return (
      <div className="row justify-content-center margin-40">
        {companyTag === 'chile' ? 
        (aboutLong
        && <div dangerouslySetInnerHTML={{ __html: aboutLong }} />) // eslint-disable-line
        :
        (about
          && <div dangerouslySetInnerHTML={{ __html: about }} />) // eslint-disable-line
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  about: state.settings.about,
  companyTag: state.settings.companyTag,
});

const mapDispatchToProps = dispatch => ({
  getSettingLong: (tag) => dispatch(getSettingLong(tag)),  
});


export default connect(mapStateToProps, mapDispatchToProps)(AboutPage);
