
import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import ROUTES from '../../data/routes'

class CartImportErrors extends PureComponent {
  render() {
    const { totalAdded, errors = [] } = this.props;

    if (!errors.length && !totalAdded) return null; 

    return (
      <div className="items-errors">
        <hr/>
        {totalAdded > 0 &&
          <div>              
            <h4>{I18n.t('noAggregatedProductsToCart').replace('{?}', totalAdded)} <Link style={{color: 'blue'}} to={ ROUTES.CART } className="sorter-btn"><Translate value="goToCartNow"/></Link></h4>
            <br/>
          </div>
        }

        {errors.length > 0 &&
          <h5><Translate value="dragAndDropCartErrorTitle" /></h5>}

        {errors.map(error => (
          <React.Fragment key={error.code}>
            <p>              
              <Translate value="line" />
              {' '}
              {error.line}
              {': '}

              {error.message}

            </p>
          </React.Fragment>
        ))}
      </div>
    );
  }
}

export default CartImportErrors;
