/* eslint-disable max-len */
import CARDS from '../../data/cards';

function getCardsName(card) {
  // const auraRegEx = /^(5078\d{2})(\d{2})(\d{11})$/;
  const jcbRegRex = /^(?:2131|1800|35\d{3})\d{11}$/;
  const visaRegEx = /^(?:4[0-9]{12}(?:[0-9]{3})?)$/;
  const mastercardRegEx = /^(?:5[1-5][0-9]{14})$/;
  const eloRegEx = /^(401178|401179|431274|438935|451416|457393|457631|457632|504175|627780|636297|636369|(506699|5067[0-6]\d|50677[0-8])|(50900\d|5090[1-9]\d|509[1-9]\d{2})|65003[1-3]|(65003[5-9]|65004\d|65005[0-1])|(65040[5-9]|6504[1-3]\d)|(65048[5-9]|65049\d|6505[0-2]\d|65053[0-8])|(65054[1-9]|6505[5-8]\d|65059[0-8])|(65070\d|65071[0-8])|65072[0-7]|(65090[1-9]|65091\d|650920)|(65165[2-9]|6516[6-7]\d)|(65500\d|65501\d)|(65502[1-9]|6550[3-4]\d|65505[0-8]))[0-9]{10,12}/;

  const cardNum = card.replace(/ /g, '');
  const amexpRegEx = /^(?:3[47][0-9]{13})$/;
  const discovRegEx = /^(?:6011\d{12})|(?:65\d{14})$/;
  const dinersRegEx = /^3(?:0[0-5]|[68][0-9])[0-9]{11}/;
  const hipercardRegEx = /^(606282\d{10}(\d{3})?)|(3841\d{15})/;

  if (visaRegEx.test(cardNum)) {
    return CARDS.name.visa;
  } if (mastercardRegEx.test(cardNum)) {
    return CARDS.name.mastercard;
  } if (eloRegEx.test(cardNum)) {
    return CARDS.name.elo;
  } if (amexpRegEx.test(cardNum)) {
    return CARDS.name.amex;
  } if (dinersRegEx.test(cardNum)) {
    return CARDS.name.diners;
  } if (hipercardRegEx.test(cardNum)) {
    return CARDS.name.hipercard;
  }
  //  if (auraRegEx.test(cardNum)) {
  //   return CARDS.name.aura;
  // }
  if (jcbRegRex.test(cardNum)) {
    return CARDS.name.jcb;
  } if (discovRegEx.test(cardNum)) {
    return CARDS.name.discover;
  }

  return CARDS.name.invalid;
}

function getCardsUrl(cardName) {
  if (CARDS.name.visa === cardName) {
    return CARDS.image.visa;
  } if (CARDS.name.mastercard === cardName) {
    return CARDS.image.mastercard;
  } if (CARDS.name.elo === cardName) {
    return CARDS.image.elo;
  } if (CARDS.name.amex === cardName) {
    return CARDS.image.amex;
  } if (CARDS.name.diners === cardName) {
    return CARDS.image.diners;
  } if (CARDS.name.hipercard === cardName) {
    return CARDS.image.hipercard;
  } if (CARDS.name.aura === cardName) {
    return CARDS.image.aura;
  } if (CARDS.name.jcb === cardName) {
    return CARDS.image.jcb;
  } if (CARDS.name.discover === cardName) {
    return CARDS.image.discover;
  }

  return CARDS.image.invalid;
}

function getInstallmentsTotal(maxInstallments, minValue, value) {
  let installments = Math.floor(value / minValue);
  if (installments > maxInstallments) installments = maxInstallments;
  return installments || 1;
}

export default {
  getCardsName,
  getCardsUrl,
  getInstallmentsTotal,
};
