import React from 'react';
import { Translate } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import ROUTES from '../../data/routes';

const CartEmpty = () => (
  <div className="checkout-finalize">
    <i className="gray fas fa-shopping-cart" />
    <h2><Translate value="cartEmpty" /></h2>
    <br />
    <Link
      to={ROUTES.PRODUCTS}
      className="btn btn-outline-secondary"
    >
      <Translate value="continueBuy" />
    </Link>
  </div>
);
export default CartEmpty;
