import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';

class StepTaxBenefitsForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      naoContribIcms: props.taxBenefits.naoContribIcms,
      icmsIsento: props.taxBenefits.icmsIsento,
      icmsDiferido: props.taxBenefits.icmsDiferido,
      pisCofins: props.taxBenefits.pisCofins,
      ipi: props.taxBenefits.ipi,
      proEmprego: props.taxBenefits.proEmprego,
      error: '',
      message: '',
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      naoContribIcms, icmsIsento, icmsDiferido,
      pisCofins, ipi, proEmprego,
    } = this.state;

    const taxBenefits = {
      naoContribIcms,
      icmsIsento,
      icmsDiferido,
      pisCofins,
      ipi,
      proEmprego,
    };

    await this.props.setTaxBenefits(taxBenefits);

    this.props.handleNext();
  };

  selectCheckItem = (id, checked) => {
    this.setState({
      error: '',
      [`${id}`]: checked,
    });
  };

  getCheckbox = (check, name, label, disabled = false) => (
    <div
      className="form-check"
      style={{ paddingLeft: '20rem' }}
    >
      <input
        onChange={e => this.selectCheckItem(e.target.id, e.target.checked)}
        type="checkbox"
        className="form-check-input"
        checked={!!check}
        id={`${name}`}
        style={{ marginLeft: '-2rem', marginTop: '.8rem' }}
        disabled={disabled}
      />
      <span>
        {`${label}`}
      </span>
    </div>
  );

  render() {
    const {
      error, message, naoContribIcms,
      icmsIsento, icmsDiferido, pisCofins,
      ipi, proEmprego,
    } = this.state;

    const { state } = this.props;

    return (
      <form onSubmit={this.onSubmit}>

        {this.getCheckbox(naoContribIcms, 'naoContribIcms', I18n.t('naoContribIcms'))}
        {this.getCheckbox(icmsIsento, 'icmsIsento', I18n.t('icmsIsento'))}
        {this.getCheckbox(icmsDiferido, 'icmsDiferido', I18n.t('icmsDiferido'))}
        {this.getCheckbox(pisCofins, 'pisCofins', I18n.t('pisCofins'))}
        {this.getCheckbox(ipi, 'ipi', I18n.t('ipi'))}
        {this.getCheckbox(proEmprego, 'proEmprego', I18n.t('proEmprego'), state !== 'SC')}

        {error ? <p className="error-p">{error}</p> : ''}

        <div className="row justify-content-center margin-top-20 margin-bottom-20 form-footer">
          <button
            type="button"
            onClick={this.props.handleBack}
            className="backButton btn btn-light"
          >
            <Translate value="return" />
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            variant="contained"
          >
            <Translate value="next" />
          </button>
        </div>

        {message ? <p className="error-p">{message}</p> : ''}
      </form>
    );
  }
}

export default StepTaxBenefitsForm;
