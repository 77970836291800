import solicitationsRequests from '../../server/solicitation';
import { addLoading, removeLoading } from './loading';

export const recoverPassword = email => async (dispatch) => {
  dispatch(addLoading());

  try {
    return await solicitationsRequests.recoverPassword(email);
  } catch (err) {
    return err;
  } finally {
    dispatch(removeLoading());
  }
};


export const check = (email, cnpj) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const res = await solicitationsRequests.check(email, cnpj);
    return res;
  } finally {
    dispatch(removeLoading());
  }
};

export const checkRut = (rut, cnpj) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const res = await solicitationsRequests.checkRut(rut, cnpj);
    return res;
  } finally {
    dispatch(removeLoading());
  }
};

export const solicitation = data => async (dispatch) => {
  dispatch(addLoading());
  try {
    await solicitationsRequests.createSolicitation(data);
  } finally {
    dispatch(removeLoading());
  }
};
