import React from 'react';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import OrderDetails from '../components/panel/orderDetails/OrderDetails';

class OrderDetailPage extends React.PureComponent {
  render() {
    return (
      <main className="main">
        <div className="container">
          <Breadcrumb items={[{
            value: I18n.t('panel'),
          }, {
            value: I18n.t('orderDetail'),
          }]}
          />
          <div className="row justify-content-center">
            <OrderDetails props={this.props} />
          </div>
        </div>
      </main>
    );
  }
}

export default OrderDetailPage;
