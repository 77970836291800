import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import CheckoutPaymentCards from './CheckoutPaymentCards';
import CheckoutPaymentBankSlip from './CheckoutPaymentBankSlip';
import CheckoutPaymentBilling from './CheckoutPaymentBilling';
import CheckoutPaymentOnlineDebit from './CheckoutPaymentOnlineDebit';

class PaymentComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      payementType: 'credit',
      document: {
        type: 'cpf',
        value: '',
      },
    };


    if (props.payment.type) {
      this.state.document = props.payment.document;
      this.state.payementType = props.payment.type;
    }
  }

  selectPayementType = async (type) => {
    await this.setState({ payementType: type });
  }

  set = async (type) => {
    await this.setState({ payementType: type });
  }


  setDocument = (document) => {
    this.setState({ document });
  }

  render() {
    const { payementType, document } = this.state;
    const {
      cards,
      valueTotal,
      selectedContract,
      pricePermission,
      purchaseCreditPermission,
      purchaseDebitPermission,
      purchaseBankSlipPermission,
      purchaseBillingPermission,
      billetOff,
      payment,
      settings = {}
    } = this.props;

    const { paymentService = '' } = settings;

    return (
      <div className="checkout-payment">
        <h2 className="step-title"><Translate value="selectPaymentMethod" /></h2>
        <div className="padding-left-3rem margin-top-1rem">
          <div id="main-tabs-payments">
            {pricePermission && purchaseCreditPermission && (
            <CheckoutPaymentCards
              values={payment.type === 'credit' && payment.card}
              payementType={payementType}
              selectPayementType={this.selectPayementType}
              type="credit"
              document={document}
              valueTotal={valueTotal}
              cards={cards.filter(card => card.type === 'credit')}
              setDocument={this.setDocument}
              setPayment={this.props.setPayment}
            />
            )}
            {pricePermission && purchaseDebitPermission && (paymentService === 'pagseguro' ? (
              <CheckoutPaymentOnlineDebit
                payementType={payementType}
                document={document}
                selectPayementType={this.selectPayementType}
                setDocument={this.setDocument}
                setPayment={this.props.setPayment}
              />
            ) : (
              <CheckoutPaymentCards
                values={payment.type === 'debit' && payment.card}
                payementType={payementType}
                selectPayementType={this.selectPayementType}
                type="debit"
                document={document}
                valueTotal={valueTotal}
                cards={cards.filter(card => card.type === 'debit')}
                setDocument={this.setDocument}
                setPayment={this.props.setPayment}
              />
            ))}
            {pricePermission && purchaseBankSlipPermission && !billetOff && (
              <CheckoutPaymentBankSlip
                payementType={payementType}
                document={document}
                selectPayementType={this.selectPayementType}
                setDocument={this.setDocument}
                setPayment={this.props.setPayment}
              />
            )}
            {purchaseBillingPermission && (
            <CheckoutPaymentBilling
              payementType={payementType}
              selectPayementType={this.selectPayementType}
              setPayment={this.props.setPayment}
              selectedContract={selectedContract}
            />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  selectedContract: state.contracts.selected,
  payments: state,
  cards: state.cards.list,
  pricePermission: state.contracts.selected && state.contracts.selected.pricePermission,
  purchaseBankSlipPermission: state.contracts.selected && state.contracts.selected.purchaseBankSlipPermission,
  purchaseBillingPermission: state.contracts.selected && state.contracts.selected.purchaseBillingPermission,
  purchaseCreditPermission: state.contracts.selected && state.contracts.selected.purchaseCreditPermission,
  purchaseDebitPermission: state.contracts.selected && state.contracts.selected.purchaseDebitPermission,
  billetOff: state.settings.billetOff,
  settings: state.settings,
});

export default connect(mapStateToProps)(PaymentComponent);
