const initialState = {
  list: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CARDS_SET': {
      return {
        list: action.cards,
      };
    }
    case 'RESET_CARDS':
      return { ...initialState };
    default:
      return state;
  }
};
