import React from 'react';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import LoginForm from '../components/login/LoginForm';

const LoginPage = () => (
  <main className="main">
    <Breadcrumb items={[{
      value: I18n.t('login'),
    }]}
    />
    <LoginForm />
  </main>
);

export default LoginPage;
