import React from 'react';
import { Translate } from 'react-redux-i18n';

export default function ProductAmountErrorModal({ showModal, handleCloseModal }) {
  return (
    <div className="dashboard-content">
      <div className={!showModal && 'card-display-none'}>
        <button
          type="button"
          onClick={handleCloseModal}
          className="outer-modal-div"
        />
        <div className="modal-div-cart">
          <div className="invalid-product-modal-body">
            <h2 className="h3-modal-div"><Translate value="attention" /></h2>
            <h3 className="h3-modal-div">
              <Translate value="cantAddToCart" />

            </h3>
            <button
              type="button"
              className="btn btn-primary"
              onClick={handleCloseModal}
            >
              <Translate value="Ok" />
            </button>
          </div>

        </div>
      </div>
    </div>
  );
}
