import React from 'react';
import { Translate } from 'react-redux-i18n';

const CartSolicitation = () => (
  <div className="checkout-finalize">
    <i className="fas fa-check-circle" />
    <h2><Translate value="thanks" /></h2>
    <p><Translate value="cartSolicitationText" /></p>
  </div>
);
export default CartSolicitation;
