import React from 'react';
import { connect } from 'react-redux';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import Checkout from '../components/checkout/Checkout';

import ROUTES from '../data/routes';

const CheckoutPage = ({ cartItems }) => (
  <main className="main">
    <Breadcrumb items={[{
      link: ROUTES.CART,
      value: I18n.t('cart'),
    }, {
      value: I18n.t('finalizeBuy'),
    }]}
    />
    <Checkout cartItems={cartItems} />
  </main>
);

const mapStateToProps = state => ({
  cartItems: state.cart.items,
});

export default connect(mapStateToProps)(CheckoutPage);
