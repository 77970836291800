import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';

import CheckoutPaymentCard from './CheckoutPaymentCard';
import CheckoutPaymentCardNew from './CheckoutPaymentCardNew';

import CardsUtils from '../../../app/utils/cards';
import { deleteCard } from '../../../app/store/actions/cards';

class CheckoutPaymentCards extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      newVisible: props.cards.length === 0 || (props.values && props.values.number),
    };
  }

  toggleNewCard = async () => {
    const { newVisible } = this.state;
    await this.setState({ newVisible: !newVisible });
  }

  render() {
    const { newVisible } = this.state;
    const {
      values, payementType, type, cards, document, installmentsTotal,
    } = this.props;
    const isSelected = payementType === type;

    const label = type === 'credit' ? I18n.t('credit') : I18n.t('debit');

    return (
      <React.Fragment>
        <button
          type="button"
          onClick={() => this.props.selectPayementType(type)}
          className="payment-menu"
        >
          {label}
          <i className={`fas fa-arrow-${isSelected ? 'down' : 'right'}`} />
        </button>
        <div className={`row margin-bottom-card-20 ${isSelected && !newVisible ? '' : 'card-display-none'}`}>
          {cards.map(card => (
            <CheckoutPaymentCard
              key={card.id}
              card={card}
              type={type}
              installmentsTotal={installmentsTotal}
              setPayment={this.props.setPayment}
              deleteCard={this.props.deleteCard}
            />
          ))}

          <div className="col-12">
            <button
              type="button"
              className="card-payment"
              onClick={this.toggleNewCard}
            >
              <Translate value="addCard" />
            </button>
          </div>

        </div>
        <div className={isSelected && newVisible ? '' : 'card-display-none'}>
          <CheckoutPaymentCardNew
            values={values}
            type={type}
            installmentsTotal={installmentsTotal}
            setPayment={this.props.setPayment}
            canBack={cards.length > 0}
            document={document}
            back={this.toggleNewCard}
            setDocument={this.props.setDocument}
          />
        </div>
      </React.Fragment>
    );
  }
}


const mapStateToProps = (state, ownProps) => ({
  installmentsTotal: CardsUtils.getInstallmentsTotal(
    Number(state.settings.maximumPayemntInstallmentTimes),
    Number(state.settings.minimumPayemntInstallmentValue),
    ownProps.valueTotal,
  ),
});

const mapDispatchToProps = dispatch => ({
  deleteCard: (card) => dispatch(deleteCard(card)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutPaymentCards);
