const initialState = {
  started: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'ACTIVITIES_VALUE_SET':
      state[action.attr] = action.value;
      return { ...state };
    default:
      return state;
  }
};
