const initialState = {
  list: [],
  selected: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CONTRACTS_SET': {
      const contracts = action.contracts.map((contract, index) => ({ ...contract, index }));
      return {
        list: contracts,
        selected: contracts[action.index],
      };
    }
    case 'SELECT_CONTRACT_SET':
      state.selected = state.list[action.index];
      return { ...state };
    case 'RESET_CONTRACTS':
      return { ...initialState };
    default:
      return state;
  }
};
