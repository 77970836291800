/* eslint-disable max-len */
export default {
  add: 'Adicionar',
  aboutUs: 'Sobre nós',
  accessRequest: 'Solicitação de Acesso',
  accountInformation: 'Informações da Conta',
  activeDocuments: 'Documentos Ativos',
  addCard: 'Adicionar um novo cartão',
  address: 'Endereço',
  address2: 'Endereço:',
  addressToSend: 'Endereço de envio',
  agreement: 'Acordo',
  approve: 'Aprovar',
  approveCart: 'Aprovar Carrinho',
  approveCartConfirm: 'Realmente deseja aprovar o carrinho?',
  approved: 'Aprovado',
  available: 'Disponível',
  availability: 'Disponibilidade:',
  authorized: 'Autorizado',
  all: 'Todos',
  allCategories: 'Todas Categorias',
  bank: 'Banco',
  bankSlip: 'Boleto',
  bankSlipText1: 'Imprima o boleto e pague no banco',
  bankSlipText2: 'ou pague pela internet utilizando o código de barras do boleto',
  bankSlipText3: 'o prazo de validade do boleto é de 2 dias útil',
  bankSlipVisualize: 'Clique para visualizar o boleto',
  beingPrepared: 'Pedido sendo preparado',
  before: 'Anterior',
  billing: 'Faturar',
  billing2: 'Faturamento',
  billed: 'Quantidade faturada:',
  birthday: 'Data de Aniversário:',
  brands: 'Marcas',
  buyDate: 'Data da compra',
  buyDate2: 'Data da compra:',
  buySolicitation: 'Solicitar Compra',
  business: 'Ramo de Atividade',
  cancel: 'Cancelar',
  canceled: 'Cancelado',
  categories: 'Categorias',
  cardInvalid: 'Cartão inválido, aceitamos apenas Visa, Master Card e Elo.',
  cardName: 'Nome Impresso no cartão',
  cardNumber: 'Número do cartão',
  cardNumberInvalid: 'Numero do cartão inválido',
  cart: 'Carrinho',
  cartEmpty: 'Carrinho vazio',
  cartSolicitationText: 'Seu carrinho foi enviado para um responsável',
  cartEstimateText: 'Sua solicitação de orçamento foi enviada.',
  cartChanged: 'Atenção... Os itens do seu carrinho foram alterados,'
    + 'por favor confira os itens antes de finalizar o pedido.',
  cartToApprove: 'Carrinhos para aprovar:',
  cartProducts: 'produtos no carrinho',
  cartOrderByDateAsc: 'Ordenar por Data de Inserção Crescente',
  cartOrderByDateDesc: 'Ordenar por Data de Inserção Decrescente',
  cartOrderByNameAsc: 'Ordenar por Nome Crescente',
  cartOrderByNameDesc: 'Ordenar por Nome Decrescente',
  captured: 'Efetivado',
  currentCartWillChange: 'Seu carrinho atual será substituído, deseja continuar?',
  city: 'Cidade',
  change: 'Alterar',
  changeAddress: 'Alterar Entrega',
  changePayment: 'Alterar pagamento',
  changePasswordIsNotPossible: 'Não é possível alterar a senha, você não solicitou uma recuperação de senha.',
  company: 'Empresa',
  companyName: 'Razão Social',
  companyData: 'Dados da Empresa',
  complement: 'Complemento',
  contact: 'Entre em contato',
  contactData: 'Dados do Contato',
  contactUs: 'Fale Conosco',
  continueBuy: 'Continuar Comprando',
  confirm: 'Confirmar',
  confirmed: 'Confirmado',
  confirmPassword: 'Confirmar Senha',
  confirmNewPassword: 'Confirmar nova senha',
  contract: 'Contrato',
  contractNotFound: 'Desculpe, nenhum contrado encontrado.',
  contractProducts: 'Produtos de Contrato',
  consultUs: 'Consulte-nos',
  consultUsTime: 'Prazo: CONSULTE-NOS',
  consumption: 'Uso Consumo',
  copyOrder: 'Copiar Pedido',
  country: 'País',
  csv: 'CSV',
  checkoutContact: 'Nossa equipe receberá os produtos requesitados e entrará em contato com os preços.',
  credit: 'Crédito',
  crossSelling: 'Cross Selling',
  crossSellingProduct: 'Produtos em Destaque',
  cleanCart: 'Limpar Carrinho',
  cleanFilters: 'Limpar todos os filtros',
  clientCode: 'Código do Cliente:',
  clientCode2: 'Código Cliente:',
  clientDescription: 'Descrição do Cliente',
  code: 'Código: #',
  code2: 'Código:',
  codeSphere: 'Código Pedido Interno:',
  codeSphere2: '| Código Pedido Interno:',
  codeSolicitation: 'Código da Solicitação:',
  cnpj: 'CNPJ',
  cpf: 'CPF',
  cep: 'CEP',
  currentPassword: 'Senha Atual',
  cvv: 'CVV',
  cvvInvalid: 'CVV inválido',
  days: 'Dias',
  discount: 'Desconto',
  description: 'Descrição',
  debit: 'Débito',
  debitOn: 'Débito online',
  decline: 'Reprovar',
  declineCart: 'Reprovar carrinho',
  declineCartConfirm: 'Realmente deseja reprovar o carrinho?',
  deleteCard: 'Excluir Cartão',
  delivered: 'Pedido entregue',
  delivered2: 'Entregue',
  deliveryAllCountry: 'Entrega em todo o Brasil',
  deliveryAllCountryText: 'Temos a estrutura necessária para te atender da melhor forma, onde quer que você esteja.',
  details: 'Ver Detalhes do Pedido',
  details2: 'Detalhes',
  deliveryType: 'TIPO DE ENTREGA',
  defaultAddress: 'Endereço padrão',
  didYouMean: 'Você quis dizer?',
  district: 'Bairro',
  downloadApp: 'Baixe grátis o App!',
  downloadPdf: 'Baixar Datasheet - PDF',
  documentList: 'Pedidos e orçamentos realizados:',
  documentList2: 'Pedidos e Orçamentos',
  documentStatus: 'Status do Documento',
  documentStatus2: '| Status do Documento:',
  emailCnpjMessage: 'E-mail já cadastrado com esse CNPJ',
  emailCnpjMessage2: 'E-mail não relacionado com esse CNPJ',
  emailCnpjMessage3: 'Cliente não cadastrado com esse CNPJ',
  emailCnpjMessage4: 'E-mail e CNPJ/CPF já cadastrados!',
  entry: 'Entrar',
  error: 'ERRO!',
  errorOcurred: 'Desculpe ocorreu um erro',
  errorOcurred2: 'Desculpe, ocorreu um erro.',
  errorTryLater: 'Ocorreu um erro, tente mais tarde.',
  emailCnpj: 'E-mail/CNPJ',
  emailCnpjCpf: 'E-mail/Documento',
  emailPlaceholder: 'Digite o seu e-mail',
  errorNewRegister: 'Cadastro solicitado com sucesso!',
  errorNewRegisterText: 'Enviaremos um e-mail assim que seu cadastro estiver ativado.',
  estimate: 'Orçamento',
  estimateSolicitation: 'Pedir um Orçamento',
  estimateTime: 'Prazo de Entrega',
  especification: 'Especificações:',
  expirationDateInvalid: 'Data de expiração inválida',
  expirationMonthInvalid: 'Mês de de expiração inválida, entre (01 e 12)',
  expirationYearInvalid: 'Ano de expiração inválido',
  expirationPlaceholder: 'MM/AA',
  exit: 'Sair',
  features: 'Características',
  finalize: 'Finalizar',
  finalize2: 'Finaliza',
  finalizeIn: 'Finaliza em',
  finalized: 'Finalizado',
  finalizeBuy: 'Finalizar Compra',
  finalizeOrder: 'Finalizar Pedido',
  firstName: 'Primeiro Nome',
  fileGenerate: 'Gerar Arquivo',
  flagInvalid: 'Bandeira não aceita',
  forgotPassword: 'Esqueceu sua senha? Digite seu email.',
  forgotPassword2: 'Esqueceu a senha',
  generateSummary: 'Gerar Relatório de Pedidos',
  generateContract: 'Gerar Relatório Produtos de Contrato',
  general: 'Geral',
  hasIe: 'Possui Inscrição Estadual?',
  home: 'Home',
  howWorks: 'Como Funciona',
  icmsDiferido: 'ICMS Diferido',
  icmsIsento: 'ICMS Isento',
  immediateTime: 'Prazo Máximo: IMEDIATA',
  industrialization: 'Industrialização',
  insertNewPassword: 'Digite sua nova senha.',
  inTransport: 'Em transporte',
  insertEmail: 'Digite o seu e-mail',
  insertCnpj: 'Digite o seu CNPJ',
  insertCpf: 'Digite o seu CPF',
  invalidEmail: 'E-mail inválido',
  invalidCNPJ: 'CNPJ inválido',
  invalidCPF: 'CPF inválido',
  invalidCep: 'CEP inválido!',
  invalidAmount: 'QUANTIDADE ADICIONADA INDISPONÍVEL EM ESTOQUE',
  invalidAmount2: 'Quantidade adicionada não possui disponibilidade em estoque, o item possui ',
  invalidAmount3: 'em estoque. Caso queira uma quantidade maior verifique a disponibilidade com o nosso televendas: ',
  invoiceIssued: 'Faturado',
  invoiceIssuedPartially: 'Parcialmente Faturado',
  installmentsNumber: 'Número de parcelas:',
  ipi: 'IPI',
  items: 'Itens',
  json: 'JSON',
  job: 'Cargo',
  lastName: 'Sobrenome',
  punchOut: 'Punch Out',
  punchOutTitle: 'ENTRAR via Punch Out',
  punchOutText: 'fazendo login via punch out',
  buyPunchOut: 'finalizar punch out',
  limitCartWarning: 'Usuário sem permissão para ver esse carrinho',
  loading: 'Carregando...',
  login: 'Login',
  loginForgotPassword: 'Esqueceu a senha?',
  loginButton: 'ENTRAR',
  loginText: 'Se você já tem uma conta conosco, faça o login.',
  loginTitle: 'ENTRAR',
  loginRegisterSolicitation: 'Solicite um Cadastro',
  loginNewRegister: 'Cadastro',
  loginError: 'ERRO!',
  maxTime: 'Prazo Máximo:',
  mySolicitations: 'Minhas solicitações:',
  minimumOf: 'Mínimo de',
  minimumPayment: 'Mínimo de R$',
  minimumPayment2: 'para finalizar a compra',
  myInformations: 'Minhas Informações',
  name: 'Nome',
  naoContribIcms: 'Não Contribuinte do ICMS',
  needHelp: 'Precisa de ajuda?',
  needHelpText: 'Nosso pós-vendas estará sempre à disposição para esclarecer dúvidas sobre seu pedido.',
  navigation: 'Navegação',
  newPassword: 'Nova senha',
  next: 'Próximo',
  newApp: 'Novo App para uma experiência única.',
  notAuthorized: 'Não autorizado',
  noneDocument: 'Nenhum pedido/orçamento realizado.',
  noLimit: 'Não foi possível finalizar a compra. Por favor entre em contato com o administrador de '
    + 'seu contrato/conta para consultar o saldo disponível.',
  normalDelivery: 'Entrega normal',
  number: 'Número',
  obsIe: 'Inserir sem pontuação',
  observationText: 'Adicionar observações na nota fiscal',
  observationTextNortel: 'Adicionar observações na nota fiscal (limite 75 caracteres)',
  pageNotFound: 'Página não encontrada!',
  panel: 'Painel',
  password: 'Senha',
  passwordDifferents: 'As senhas são diferentes.',
  passwordPlaceholder: 'Digite a sua senha',
  passwordPlaceholder2: 'Confirme a sua senha',
  passwordErrorMessage1: 'A senha atual deve ser maior ou igual a 6 caracteres e menor que 50.',
  // passwordErrorMessage2: 'A senha deve ser maior ou igual a ',
  // passwordErrorMessage3: ' caracteres e menor que ',
  // Política de Senha ↓
  passworderrorMessage: 'A senha informada não atende a política de senha',
  passwordErrorMinChar: 'deve conter no mínimo 9 caracteres',
  passwordErrorNumber: 'deve conter dígitos (0-9)',
  passwordErrorUpperCase: 'deve conter no mínimo uma letra maiúscula (A-Z)',
  passwordErrorSpecialChar: 'deve conter no mínimo um caracter especial (!@#)',
  passwordErrorNotEqual: 'as senhas devem ser iguais',
  passwordSuccessChanged: 'Senha alterada com sucesso.',
  passwordInvalid: 'A senha atual é inválida.',
  passwordChange: 'Alterar Senha',
  passwordInsert: 'Digite sua nova senha.',
  passwordIndicatorTitle: 'Política de Senha',
  passwordIndicatorSubTitle: 'A senha deverá conter',
  passwordIndicatorMinChar: 'No mínimo 9 caracteres',
  passwordIndicatorNumber: 'Dígitos (0-9)',
  passwordIndicatorUpperCase: 'Pelo menos uma letra maiúscula (A-Z)',
  passwordIndicatorSpecialChar: 'Pelo menos um caracter especial (!@#)',
  passwordIndicatorPasswordEqual: 'Senhas iguais',
  payWithBankSlip: 'Pagar com Boleto',
  paid: 'Pago',
  payment: 'Pagamento',
  paymentTerm: 'Faturar',
  paymentType: 'Forma de pagamento',
  paymentType2: 'Tipo de Pagamento:',
  paymentMade: 'Pagamento confirmado',
  personalData: 'Dados Pessoais',
  phone: 'Telefone:',
  phone2: 'Telefone',
  pisCofins: 'PIS/COFINS',
  pdf: 'PDF',
  purpose: 'Finalidade',
  product: 'Produto',
  products: 'Produtos',
  productType: 'Tipo de produto',
  productBought: 'produto(s) comprado(s)',
  productInformation: 'Informações do Produto',
  productsPromotion: 'Produtos em Promoção',
  productsSimilar: 'Produtos Similares',
  province: 'Bairro',
  price: 'Preço',
  unPrice: 'Preço Unitário',
  price2: 'Preço:',
  privacyPolicy: 'Politica de privacidade',
  proEmprego: 'Pró-Emprego (Somente SC)',
  quantity: 'Qtd',
  quantity2: 'Qtd:',
  quality: 'Qualidade Garantida',
  qualityText: 'Trabalhamos somente com produtos das melhores marcas do mercado.',
  readToSend: 'Pedido pronto para envio',
  recoverPassword: 'Recuperar Senha',
  recoverPasswordResponseMsg: 'Se o usuário for válido, será encaminhado por e-mail uma nova senha.',
  requestLimitExceeded: 'Limite de solicitação excedido! Por favor tente novamente mais tarde.',
  registerSolicitation: 'Solicitação de Cadastro',
  remove: 'Remover',
  return: 'Voltar',
  returnHome: 'Voltar para Home',
  resale: 'Revenda',
  results: 'Resultados',
  requestAccess: 'Solicitar Acesso',
  requestAccessText: 'Sua Empresa já possui cadastro? Solicite a liberação de seu acesso',
  report: 'Relatório',
  reports: 'Relatórios',
  search: 'Buscar',
  saveCardText: 'Salvar dados do cartão para futuras compras',
  searchProduct: 'Buscar por produto...',
  searchTerm: 'Pesquisar por termo...',
  sector: 'Setor',
  selectAddressToTake: 'Endereço para retirar:',
  selectAddressToSend: 'Endereço de envio:',
  selectDeliveryType: 'Selecione o tipo de entrega:',
  selectPaymentMethod: 'Selecione a forma de pagamento:',
  sellMultiple: 'Multiplo Venda:',
  sellMultipleNeeds: 'Quantidade deve ser multiplo de ',
  showing: 'Mostrando',
  soneparCode: 'Código Sonepar:',
  st: 'ST',
  securityCode: 'Código de segurança:',
  state: 'Estado',
  stateRegistration: 'Inscrição Estadual',
  street: 'Rua',
  spot: 'Spot',
  spotlight: 'Destaque',
  spotlightProducts: 'Produtos em Destaque!',
  subTotal: 'Subtotal',
  subCategories: 'Sub Categorias',
  summary: 'Resumo',
  successBuy: 'Sua compra foi efetuado com sucesso',
  successNewRegister: 'Cadastro criado com sucesso!',
  successNewRegisterText: 'Seu cadastro já esta liberado! Acesse a pagina de Login.',
  successRegister: 'Acesso solicitado com sucesso!',
  successRegisterText: 'Enviaremos um e-mail assim que seu acesso estiver ativado.',
  successSolicitation: 'Solicitação enviada com sucesso',
  successSolicitationText: 'Enviaremos um e-mail assim que sua solicitação for aprovada.',
  storeTake: 'Retirar na loja',
  spotItemsError: 'Não é possível comprar mais itens do que a quantidade disponívl',
  of: 'de',
  orderMade: 'Pedido realizado',
  orderDetail: 'Detalhes do documento',
  orderCodeOptional: 'Código Ordem Cliente (Opcional)',
  orderMadeBy: 'Pedido realizado por: ',
  orderSolicitedBy: 'Pedido solicitado por: ',
  take: 'Retirar:',
  taxBenefits: 'Benefícios Fiscais',
  thanks: 'Obrigado',
  timeDelivered: 'Tempo de entrega',
  toBuy: 'para finalizar a compra',
  total: 'Total',
  total2: 'Total:',
  totalCartDetails: '(Valor + %{tax})',
  transportCompany: 'Transportadora',
  transportDocument: 'Transportadora CNPJ',
  undefined: 'Indefinido',
  userBlocked: 'Usuário está bloqueado.',
  userHasNoActiveContract: 'Usuário inativo',
  userNotFound: 'Usuário não encontrado.',
  userInvalidPassword: 'Senha inválida.',
  userInvalid: 'Usuário ou senha inválida.',
  userTerms: 'Termos de uso',
  unityPrefix: 'Un:',
  unavailable: 'INDISPONÍVEL',
  visualizeCart: 'Ver Carrinho',
  visualizeDetail: 'Ver detalhes',
  xml: 'XML',
  welcome: 'Bem vindo',
  withOutContract: 'Sem Contrato',
  withoutStock: 'Sem estoque',
  wishToCopyOrder: 'Deseja realmente copiar o pedido?',
  wishToApproveCart: 'Deseja realmente ver o carrinho?',
  wishToDeleteCard: 'Deseja realmente excluir o Cartão de',
  workingDays: 'dias úteis',
  xpedTooltipText: 'Insira o número de sua ordem de compra. Este dado será exibido no xPed da Nota Fiscal. '
    + ' O campo é limitado a 15 caracteres sendo permitido apenas letras,'
    + ' números, hífen (-), underline(_), vírgula (,) e barra (/\\).”',
  awaitingApproval: 'Aguardando aprovação',
  disapproved: 'Reprovado',
  awaitingNf: 'Aguardando NF',
  totalBilled: 'Faturado Total',
  parcialBilled: 'Faturado Parcial',
  filterBy: 'Filtrar por:',
  attention: 'Atenção',
  selectDefaultAddress: 'Deseja configurar o endereço escolhido como padrão?',
  yes: 'Sim',
  no: 'Não',
  invalidItemsCart: 'Não é possível finalizar a compra pois há itens no carrinho sem estoque.',
  cantAddToCart: 'Não é possivel adicionar este item no carrinho, pois não tem estoque',
  picking: 'Em Separação',
  deliveredToCarrier: 'Entregue a Transportadora',
  outForDelivery: 'Saiu para Entrega',
  outForDeliveryPrev: 'Prev:',
  availableCustumer: 'Disponível Retirada',
  done: 'Efetuado',
  errorPurchaseContact: 'Erro no pagamento, entrar em contato com a administração da loja.',
  errorInvalidDoc: 'Documento inválido, favor validar CNPJ/CPF informado.',
  errorMinimumPrice: 'O carrinho não pode ser menor que {?}',
  errorInvalidCard: 'Dados do cartão inválido, favor validar novamente.',
  errorLocation: 'Endereço não localizado.',
  errorLocationOrClient: 'Endereço ou Cliente não localizado.',
  errorForbidden: 'Não foi possível finalizar a compra. Por favor entre em contato com o administrador de seu contrato/conta.',
  errorMaxObservation: 'O campo de observação não pode ultrapassar {?} caracteres.',
  errorGkoClientNotFound: 'Endereço não localizado, favor entrar em contato com a administração da loja.',
  errorPurchaseNotFinished: 'Erro no pagamento, entrar em contato com a admistração da loja.',
  importList: 'Importar Lista',
  successFile: 'Arquivo enviado com sucesso',
  invalidFile: 'Arquivo inválido',
  dragAndDrop: 'Clique aqui para carregar o arquivo.',
  dragAndDropCartErrorTitle: 'Produtos não adicionados:',
  line: 'Linha',
  send: 'Enviar',
  cartImportStep1: 'Baixe a planilha modelo',
  cartImportStep2: 'Informe os produtos desejados e a quantidade',
  cartImportStep3: 'Depois de informar todos os produtos desejados, importe a planilha na área abaixo',
  cartImportStep4: 'Clique em Enviar',
  cartImportUploadInstruction: 'Faça o upload da planilha no formato XLS:',
  cartImportDownloadInstruction1: 'IMPORTE SUA PLANILHA DE PRODUTOS',
  cartImportDownloadInstruction2: 'Baixe o modelo clicando no link abaixo:',
  cartImportDownloadInstruction3: 'Baixar Planilha Modelo',
  cartImportUploadWaitMessage: 'Por favor, aguarde alguns segundos enquanto a carga é feito',
  offer: 'Oferta',
  awaitPayment: 'Aguardando Pagamento',
  highlighted: 'Destacados', 
  orderPriceLowToHigh: "Precio: do mais baixo ao mais alto",
  orderPriceHighToLow: "Precio: do mais alto ao mais baixo",
  ordenation: 'Ordenar por',
  clickToSupport: 'Click para Suporte',
  supportChannel: 'CANAL DE SUPORTE',
  AddedToCart: 'Adicionado ao carrinho',
  Seq: 'Seq',
  goToCart: 'Ir Ao Carrinho',
  continueShopping: 'Continuar Comprando',
  close: 'Fechar',
  lot:"LOTES/LANCES",
  toBackStore: 'Voltar a loja online',
  detailsEstimate: 'Ver Detalhes do Orçamento',
  followTheSteps: 'SIGA OS PASSOS',
  noAggregatedProductsToCart: "Foram adicionados ao carrinho {?} produto(s).",
  goToCartNow: "Ir ao carrinho agora",
  offers: 'Productos en Liquidación',
  clearanceProducts: "Produtos em Liquidação",
};
