import React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import ProductsFiltersSection from './ProductsFiltersSection';
import ProductsFiltersSectionType from './ProductsFiltersSectionType';
import ProductsFiltersSectionText from './ProductsFiltersSectionText';
import ProductsFiltersOrdination from './ProductsFiltersOrdination';

export class ProductsFilters extends React.Component {

  state = {
    subCategoriesItems: (categories) => categories != null ? categories.sort((a, b) => b.total - a.total) : [],
    productTypeItems: (selectedPdmModifier, pdmsModifiers) => selectedPdmModifier ? pdmsModifiers.sort((a, b) => b.total - a.total).filter(item => item.id === selectedPdmModifier) : pdmsModifiers.sort((a, b) => b.total - a.total),
    brandsItems: (brands) => brands.sort((a, b) => b.total - a.total),
    sortByClickS: 1,
    sortByClickP: 1,
    sortByClickB: 1,
  }

  componentDidMount = () => {
    if (this.props.companyTag === 'chile') {
      this.subCategoriesClick();
      this.productTypeClick();
      this.brandsClick();
    }
  }

  updatePdmParticulars = (particular, value) => {
    const { search } = this.props;
    const newSearch = search.pdmsParticulars || [];

    const particularIndex = newSearch.findIndex(p => p.split(': ')[0] === particular);
    if (particularIndex !== -1) newSearch.splice(particularIndex, 1);

    if (value) newSearch.push(`${particular}: ${value}`);
    this.props.updateSearch({ pdmsParticulars: newSearch });
  };

  subCategoriesClick = () => {
    this.setState({ sortByClickS: this.state.sortByClickS + 1});
    this.state.sortByClickS === 1 ? this.setState({ subCategoriesItems: (categories) => categories != null ? categories.sort((a, b) => a.value < b.value ? -1 : 1) : [] }) : null
    this.state.sortByClickS === 2 ? this.setState({ subCategoriesItems: (categories) => categories != null ? categories.sort((a, b) => a.value > b.value ? -1 : 1) : [] }) : null
    this.state.sortByClickS === 3 ? this.setState({ subCategoriesItems: (categories) => categories != null ? categories.sort((a, b) => b.total - a.total) : [] }) : null
    this.state.sortByClickS >= 3 ? this.setState({ sortByClickS: 1}) : null
  };

  productTypeClick = () => {
    this.setState({ sortByClickP: this.state.sortByClickP + 1});
    this.state.sortByClickP === 1 ? this.setState({ productTypeItems: (selectedPdmModifier, pdmsModifiers) => selectedPdmModifier ? pdmsModifiers.sort((a, b) => a.value < b.value ? -1 : 1).filter(item => item.id === selectedPdmModifier) : pdmsModifiers.sort((a, b) => a.value < b.value ? -1 : 1) }) : null
    this.state.sortByClickP === 2 ? this.setState({ productTypeItems: (selectedPdmModifier, pdmsModifiers) => selectedPdmModifier ? pdmsModifiers.sort((a, b) => a.value > b.value ? -1 : 1).filter(item => item.id === selectedPdmModifier) : pdmsModifiers.sort((a, b) => a.value > b.value ? -1 : 1) }) : null
    this.state.sortByClickP === 3 ? this.setState({ productTypeItems: (selectedPdmModifier, pdmsModifiers) => selectedPdmModifier ? pdmsModifiers.sort((a, b) => b.total - a.total).filter(item => item.id === selectedPdmModifier) : pdmsModifiers.sort((a, b) => b.total - a.total) }) : null
    this.state.sortByClickP >= 3 ? this.setState({ sortByClickP: 1}) : null
  };

  brandsClick = () => {
    this.setState({ sortByClickB: this.state.sortByClickB + 1});
    this.state.sortByClickB === 1 ? this.setState({ brandsItems: (brands) => brands.sort((a, b) => a.value < b.value ? -1 : 1) }) : null
    this.state.sortByClickB === 2 ? this.setState({ brandsItems: (brands) => brands.sort((a, b) => a.value > b.value ? -1 : 1) }) : null
    this.state.sortByClickB === 3 ? this.setState({ brandsItems: (brands) => brands.sort((a, b) => b.total - a.total) }) : null
    this.state.sortByClickB >= 3 ? this.setState({ sortByClickB: 1}) : null
  };

  render() {
    const { filters, search, categories, ordenation } = this.props;
    const { subCategoriesItems, productTypeItems, brandsItems, sortByClickS, sortByClickP, sortByClickB } = this.state;
    const mainCategory = search.categories
    && categories.find(category => search.categories.find(c => c === category.id)
    || search.categories.find(c => category.categories.find(s => s.id === c)));

    const subCategory = mainCategory
    && mainCategory.categories.find(category => search.categories.find(c => c === category.id));

    const pdmsModifiers = (filters.pdms && filters.pdms.modifiers) || [];
    const pdmsParticulars = (filters.pdms && filters.pdms.particulars) || [];
    const selectedPdmModifier = search.pdmsModifiers && search.pdmsModifiers[0];

    let level = 0;
    if (mainCategory && mainCategory.name) this.props.setBreadcrumbItens(++level, mainCategory.name, () => this.props.updateSearch({ categories: [mainCategory.id], pdmsModifiers: [], pdmsParticulars: [], brands: []}));
    if (subCategory && subCategory.name) this.props.setBreadcrumbItens(++level, subCategory.name, () => this.props.updateSearch({ pdmsModifiers: [], pdmsParticulars: [], brands: [] }));
    if (selectedPdmModifier) this.props.setBreadcrumbItens(++level, selectedPdmModifier);
        
    for (var index in search.pdmsParticulars) {
      this.props.setBreadcrumbItens(++level + index, search.pdmsParticulars[index]);      
    }
    if (filters && filters.brands) {
      for (var index in search.brands) {
        const brand = filters.brands.find(b => b.id == search.brands[index]);
        if (brand) this.props.setBreadcrumbItens(++level + index, brand.value);      
      }
    }

    const needReset = !!Object.keys(search || {}).filter(t => t !== 'page' && search[t]).length;
    const { suggest } = this.props;
    return (
      <div className="sidebar-wrapper">

        {needReset && (
        <div
          className="clean-all"
          onClick={this.props.resetSearch}
          onKeyDown={this.props.resetSearch}
          role="presentation"
        >
          <Translate value="cleanFilters" />
        </div>
        )}

        <ProductsFiltersSectionText
          onClick={this.props.updateSearch}
          text={search.text}
          suggest={suggest}
        />

        {(ordenation && <ProductsFiltersOrdination
          onChange={this.props.updateSearch}
          selected={search.sort_selected}
        />)}
        

        <ProductsFiltersSectionType
          onClick={this.props.updateSearch}
          type={search.type}
        />

        <ProductsFiltersSection
          title={I18n.t('categories')}
          items={categories.map(category => ({
            id: category.id,
            value: category.name,
          }))}
          selected={mainCategory && mainCategory.id}
          onClick={this.props.updateSearch}
          clickAttr="categories"
          basicAttrs={{ brands: [], pdmsModifiers: [], pdmsParticulars: [] }}
        />
        {mainCategory && (
        <ProductsFiltersSection
          title={I18n.t('subCategories')}
          items={subCategoriesItems(filters.categories)}
          selected={subCategory && subCategory.id}
          onClick={this.props.updateSearch}
          clickAttr="categories"
          basicAttrs={{ brands: [], pdmsModifiers: [], pdmsParticulars: [] }}
          reset={() => mainCategory && this.props.updateSearch({ categories: [mainCategory.id] })}
          sortOnClick={this.subCategoriesClick}
          sortByClickIcon={sortByClickS}
        />
        )}

        <ProductsFiltersSection
          title={I18n.t('productType')}
          items={productTypeItems(selectedPdmModifier, pdmsModifiers)}
          selected={selectedPdmModifier}
          onClick={this.props.updateSearch}
          clickAttr="pdmsModifiers"
          basicAttrs={{ pdmsParticulars: [] }}
          reset={() => this.props.updateSearch({ pdmsModifiers: [], pdmsParticulars: [] })}
          sortOnClick={this.productTypeClick}
          sortByClickIcon={sortByClickP}
        />

        {selectedPdmModifier && pdmsParticulars.map(pdmsParticular => (
          <div>
            <ProductsFiltersSection
            key={pdmsParticular.id}
            title={pdmsParticular.value}
            items={pdmsParticular.items}
            selected={search.pdmsParticulars && (search.pdmsParticulars
              .find(p => p.split(': ')[1] === pdmsParticular.items[0].id) || '')
              .split(': ')[1]
            }
            onClick={searchValues => this.updatePdmParticulars(pdmsParticular.id, searchValues.pdmsParticulars)}
            clickAttr="pdmsParticulars"
            reset={() => this.updatePdmParticulars(pdmsParticular.id)}
          />
          </div>
          
        ))}

        {filters && filters.brands && (
        <ProductsFiltersSection
          title={I18n.t('brands')}
          items={brandsItems(filters.brands)}
          onClick={this.props.updateSearch}
          selected={search.brands && Number(search.brands[0])}
          clickAttr="brands"
          reset={() => this.props.updateSearch({ brands: [] })}
          sortOnClick={this.brandsClick}
          sortByClickIcon={sortByClickB}
        />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.settings.categories,
  ordenation: state.settings.companyTag == 'chile' && state.contracts.selected,
  companyTag: state.settings.companyTag,
});

export default connect(mapStateToProps)(ProductsFilters);
