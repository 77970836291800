import React from 'react';
import { Link } from 'react-router-dom';

import ROUTES from '../../data/routes';

class HomeCategory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { category } = this.props;
    return (
      <Link to={`${ROUTES.PRODUCTS}?categories=${category.id}`}>
        <div className="member category-main">
          <img src={category.imageUrl} alt={category.name} />
          <h3 className="category-title">
            {category.name}
          </h3>
        </div>
      </Link>
    );
  }
}

export default HomeCategory;
