import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';

import ROUTES from '../../../data/routes';

const HeaderBottom = ({ categories, isAuthenticated, offersUrlSite }) => (
  <div className="header-bottom sticky-header">
    <div className="container">
      <nav className="main-nav">
        <ul className="menu sf-arrows">
          <li className="active"><Link to={ROUTES.HOME}><Translate value="home" /></Link></li>
          <li>
            <Link to={ROUTES.PRODUCTS}>
              {I18n.t('categories')}
            </Link>
            <ul>
              {categories.map(category => (
                <li key={category.id}>
                  <Link to={`${ROUTES.PRODUCTS}?categories=${category.id}`}>{category.name}</Link>
                  <div className="megamenu megamenu-fixed-width" key={category.id}>
                    <div className="row">
                      <div className="col-lg-7">
                        <div className="menu-title">
                          <Link to={`${ROUTES.PRODUCTS}?categories=${category.id}`}>{category.name}</Link>
                        </div>
                        <ul>
                          {category.categories.map(subCategory => (
                            <li key={subCategory.id}>
                              <Link to={`${ROUTES.PRODUCTS}?categories=${subCategory.id}`}>{subCategory.name}</Link>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <div className="col-lg-5 col-brands">
                        {category.brands.filter(brand => brand.imageUrl).map(brand => (
                          <div key={brand.id} className="banner">
                            <Link to={`${ROUTES.PRODUCTS}?brands=${brand.id}`}>
                              <img
                                className="brand-img"
                                src={brand.imageUrl || '/images/product-image-placeholder.png'}
                                alt={brand.description}
                              />
                            </Link>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </li>
          {isAuthenticated && offersUrlSite && <li>
            <a
              href={offersUrlSite}
              rel="noopener noreferrer"
              target="_blank"
            >
              {I18n.t('clearanceProducts')}
            </a>            
          </li>
          }
          {/* <li className="float-right buy-effect"><Link to={ROUTES.LANDING}>
        <span>Baixe nosso App!</span></Link></li> */}
          {isAuthenticated
            ? (
              <li className="float-right buy-effect">
                <Link to={`${ROUTES.PRODUCTS}?type=contract`}><Translate value="contractProducts" /></Link>
              </li>
            )
            : (
              <li className="float-right buy-effect">
                <Link to={`${ROUTES.PRODUCTS}?type=spotlight`}><Translate value="spotlightProducts" /></Link>
              </li>
            )
          }
        </ul>
      </nav>
    </div>
  </div>
);

const mapStateToProps = state => ({
  categories: state.settings.categories,
  isAuthenticated: state.auth.authenticated,
  offersUrlSite: state.settings.offersUrlSite,
});

export default connect(mapStateToProps)(HeaderBottom);
