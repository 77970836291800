/* eslint-disable no-script-url */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import HeaderMiddleRightCart from './cart/HeaderMiddleRightCart';

const HeaderMiddleRight = ({ phone, cartPermission, isAuthenticated, opcoHeaderMessage, opcoUrlSite, company, email }) => (
  <div className="header-right">
    <button className="mobile-menu-toggler" type="button">
      <i className="icon-menu" />
    </button>
    {
        opcoHeaderMessage ? (
          <div className="header-contact-formessageopco">
            <a href={"javascript:javascript:window.open('" + (opcoUrlSite.toLowerCase().indexOf('http') >=0 ? opcoUrlSite : "http://" + opcoUrlSite) + "');"}><div className="header-contact-formessageopco-text"><strong>{opcoHeaderMessage}</strong></div></a>
          </div>
        ) : (
          <div className="header-contact">
            <span><Translate value="contactUs" /></span>
            <a href="tel:#"><strong>{phone}</strong></a>
            {company === 'chile' && email ? <p><a href={"mailto:" + email}><strong>{email}</strong></a></p> : ''}
          </div>
        )
    }

    {((isAuthenticated && cartPermission) || !isAuthenticated) && <HeaderMiddleRightCart />}
  </div>
);

const mapStateToProps = state => ({
  phone: state.settings.phone,
  cartPermission: state.contracts.selected && state.contracts.selected.cartPermission,
  isAuthenticated: state.auth.authenticated,
  opcoHeaderMessage: state.settings.opcoHeaderMessage,
  opcoUrlSite: state.settings.opcoUrlSite,
  company: state.settings.companyTag,
  email: state.settings.email,
});

export default connect(mapStateToProps)(HeaderMiddleRight);
