import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';

import { authenticate } from '../../app/store/actions/auth';

import { history } from '../../routers/AppRouter';

import ROUTES from '../../data/routes';
import inputSize from '../../data/inputs';
import { mergeCart } from '../../app/store/actions/cart';

export class LoginForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      error: '',
    };
  }

  async componentWillReceiveProps(nextProps) {
    const { contractsTotal, isAuthenticated, userState } = nextProps;
    if (isAuthenticated) {
      if (userState) history.push(ROUTES.CHANGE_PASSWORD_RECOVERY);
      else if (contractsTotal > 1) {
        history.push(ROUTES.CONTRACTS);
      } else {
        history.push(ROUTES.HOME);
        this.props.mergeCart();
      }
    }
  }

  onEmailChange = (e) => {
    const email = e.target.value;
    this.setState(() => ({ email }));
  };

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState(() => ({ password }));
  };

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.state.email && this.state.password) {
      try {
        await this.props.authenticate(this.state.email, this.state.password);
        history.push(ROUTES.HOME);
      } catch (error) {
        if (error.response && error.response.status === 429) {
          this.setState({ error: I18n.t('requestLimitExceeded') });
        } else if (error.response && error.response.data.error.code === 209){
          this.setState({ error: I18n.t('userBlocked') });
        } else if (error.response && error.response.data.error.code === 211) {
          this.setState({ error: I18n.t('userHasNoActiveContract') });
        } else if (error.response && error.response.data.error.code === 400) {
          this.setState({ error: I18n.t('userNotFound') });
        } else if (error.response && error.response.data.error.code === 407) {
          this.setState({ error: I18n.t('userInvalidPassword') });
        } else {
          this.setState({ error: I18n.t('errorTryLater') });
        }
      }
    }
  };

  newRegister = () => {
    history.push(ROUTES.NEW_REGISTER);
  };

  solicitation = () => {
    history.push(ROUTES.SOLICITATION);
  };

  render() {
    const { error } = this.state;
    const { onTotvs } = this.props;
    return (
      <div className="login container">
        {error && (
          <div className="alert alert-warning alert-dismissible fade show" role="alert">
            <strong><Translate value="loginError" /></strong>
            {` ${error}`}
          </div>
        )}
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="heading">
              <h2 className="title"><Translate value="requestAccess" /></h2>
              <Link
                to={ROUTES.REGISTER}
                className="forget-pass"
              >
                <Translate value="requestAccessText" />
              </Link>
            </div>
            <div className="heading">
              <h2 className="title"><Translate value="loginTitle" /></h2>
              <p>
                <Translate value="loginText" />
              </p>
            </div>
            <form onSubmit={this.onSubmit}>
              <input
                type="email"
                className="form-control"
                placeholder={I18n.t('emailPlaceholder')}
                value={this.state.email}
                onChange={this.onEmailChange}
                maxLength={inputSize.max}
                required
              />
              <input
                type="password"
                className="form-control"
                placeholder={I18n.t('passwordPlaceholder')}
                value={this.state.password}
                onChange={this.onPasswordChange}
                maxLength={inputSize.maxPassword}
                required
              />
              <div>
                {onTotvs ? (
                  <div style={{ marginBottom: '0px' }} className="form-footer">
                    <button type="submit" className="btn btn-primary"><Translate value="loginButton" /></button>
                    <button
                      type="button"
                      onClick={() => this.newRegister()}
                      className="btn btn-primary"
                    >
                      <Translate value="loginNewRegister" />
                    </button>
                  </div>
                ) : (
                  <div style={{ marginBottom: '0px' }} className="form-footer">
                    <button type="submit" className="btn btn-primary"><Translate value="loginButton" /></button>
                    <button
                      type="button"
                      onClick={() => this.solicitation()}
                      className="btn btn-primary"
                    >
                      <Translate value="loginRegisterSolicitation" />
                    </button>
                  </div>
                )}
              </div>
              <div className="form-footer">
                {' '}
                <Link
                  to={ROUTES.FORGOT_PASSWORD}
                  className="forget-pass"
                >
                  <Translate value="loginForgotPassword" />

                </Link>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.authenticated,
  contractsTotal: state.contracts.list.length,
  userState: state.auth.user && state.auth.user.state,
  onTotvs: !!((state.settings.urlWsTotvs && state.settings.urlWsTotvs !== '')),
});

const mapDispatchToProps = dispatch => ({
  authenticate: (email, password) => dispatch(authenticate(email, password)),
  mergeCart: () => dispatch(mergeCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
