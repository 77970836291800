import React from 'react';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import NewRegisterForm from '../components/newRegister';

const NewRegisterPage = () => (
  <main className="main">
    <Breadcrumb items={[{
      value: I18n.t('loginNewRegister'),
    }]}
    />
    <NewRegisterForm />
  </main>
);
export default NewRegisterPage;
