import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';

import { logout, authenticate } from '../../app/store/actions/auth';
import updatePunchOutData from '../../app/store/actions/punchOut';

import { history } from '../../routers/AppRouter';

import ROUTES from '../../data/routes';

import StringHelper from '../../helpers/string';
import { removeInitLoader } from '../../app/initialization';
import { mergeCart } from '../../app/store/actions/cart';

export class PunchOutLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      error: '',
    };
  }

  async componentDidMount() {
    await this.submit();
  }

  async componentWillReceiveProps(nextProps) {
    const { contractsTotal, isAuthenticated } = nextProps;
    if (isAuthenticated) {
      if (contractsTotal > 1) {
        history.push(ROUTES.CONTRACTS);
      } else {
        history.push(ROUTES.HOME);
        this.props.mergeCart();
      }
    }
  }

  getPunchOutData() {
    return {
      userId: this.props.userId || StringHelper.generateRandomToken(),
      hookUrl: this.props.hookUrl
        || this.props.hookurl
        || this.props.HOOKURL
        || this.props.HOOK_URL
        || this.props.hook_url,
      buyerCookie: this.props.buyerCookie,
      userAgent: this.props.userAgent,
    };
  }

  submit = async () => {
    if (this.props.email && this.props.password) {
      try {
        await this.props.setPunchOutData(this.getPunchOutData());
        await this.props.authenticate(this.props.email, this.props.password);
      } catch (error) {
        if (error.response && error.response.data.error.code === 209) {
          this.setState({ error: I18n.t('userBlocked') });
        } else if (error.response && error.response.data.error.code === 400) {
          this.setState({ error: I18n.t('userNotFound') });
        } else if (error.response && error.response.data.error.code === 407) {
          this.setState({ error: I18n.t('userInvalidPassword') });
        } else {
          this.setState({ error: I18n.t('errorTryLater') });
        }
      } finally {
        removeInitLoader();
      }
    } else {
      this.setState({ error: I18n.t('userInvalid') });
    }
  };

  render() {
    const { error } = this.state;
    return (
      <div className="login container">
        {error && (
          <div className="alert alert-warning alert-dismissible fade show" role="alert">
            <strong><Translate value="loginError" /></strong>
            {` ${error}`}
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.authenticated,
  contractsTotal: state.contracts.list.length,
});


const mapDispatchToProps = dispatch => ({
  logout: () => dispatch(logout()),
  authenticate: (email, password) => dispatch(authenticate(email, password)),
  setPunchOutData: punchOutData => dispatch(updatePunchOutData(punchOutData)),
  mergeCart: () => dispatch(mergeCart()),
});

export default connect(mapStateToProps, mapDispatchToProps)(PunchOutLoginForm);
