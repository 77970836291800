/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const RctSwal = withReactContent(Swal);

// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { faStar } from '@fortawesome/free-solid-svg-icons';

import { cartAddItem, cartAddItemCache } from '../../app/store/actions/cart';

import { history } from '../../routers/AppRouter';

import ProductsUtil from '../../app/utils/products';
import NumberHelper from '../../helpers/number';

import ROUTES from '../../data/routes';
import inputSize from '../../data/inputs';
import PriceComponent from '../price/Price';
import FinalPriceComponent from '../price/FinalPrice';
import validator from '../../helpers/validators';

export class ProductDetailsResume extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      amount: 1,
      multipleQuantity: 1,
      isAmountInvalid: false,
    };
  }

  async componentDidMount() {
    this.updateInterval = setTimeout(this.updatePromotionCoutdown, 1000);
  }

  async componentWillReceiveProps(nextProps) {
    const { multipleQuantity } = this.state;
    if (nextProps.product.multipleQuantity !== multipleQuantity) {
      await this.setState({
        multipleQuantity: nextProps.product.multipleQuantity,
        amount: nextProps.product.multipleQuantity,
      });
    }
  }

  componentWillUnmount() {
    clearTimeout(this.updateInterval);
  }

  updatePromotionCoutdown = async () => {
    const { product = {} } = this.props;
    if (product.price && !product.price.isContract && product.price.finalizedAt) {
      const promotionCoutdown = ProductsUtil.getPromotionCountdown(product.price);
      await this.setState({ promotionCoutdown });
      this.updateInterval = setTimeout(this.updatePromotionCoutdown, 1000);
    }
  };

  formatTotal = value => value && Number(value).toFixed(NumberHelper.countDecimals(this.state.multipleQuantity));

  handleAmount = async (value) => {
    const { amount } = this.state;

    const valDecCount = (amount.toString().split('.')[1] || '').length;
    const stepDecCount = (value.toString().split('.')[1] || '').length;
    const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
    let valInt = parseInt(Number(amount).toFixed(decCount).replace('.', ''), 0);
    valInt = valInt <= 0 ? valInt * -1 : valInt;
    const stepInt = parseInt(Number(value).toFixed(decCount).replace('.', ''), 0);
    const qtd = ((valInt + stepInt) / (10 ** decCount));
    await this.handleSetAmount(qtd);
  };

  handleSetAmount = async (value) => {
    const { allowSellSpotItensWithoutStock, product = {} } = this.props;
    if (value === '') await this.setState({ amount: '' });
    else if (!isNaN(value)) {
      if (NumberHelper.countDecimals(this.state.multipleQuantity) > 0) {
        value = Number(value.toString().replace(/\D+/g, ''))
          / (10 ** NumberHelper.countDecimals(this.state.multipleQuantity));
      }
      value = value < 0 ? 0 : value;
      await this.setState({ amount: value });
    }

    if (validator.isSpotProductAmountInvalid(value,
      product.stock, allowSellSpotItensWithoutStock, product.isSpot, product.price.isContract)) {
      this.setState({
        isAmountInvalid: true,
      });
    } else {
      this.setState({
        isAmountInvalid: false,
      });
    }
  };

  addProductToCart = async () => {
    const { multipleQuantity, amount } = this.state;
    const { product = {}, isAuthenticated } = this.props;
    const isAmountValid = this.validateCardProducts(product.id);
    if (!isAuthenticated) {
      await this.props.addItemCache(product.id, amount);
    } else if (isAmountValid) {
      const value = amount - (this.floatSafeModulus(amount, multipleQuantity));
      if (product.id) { 
        await this.props.addItem(product.id, product.clientCode, product.code, value);
        if (this.props.popupOnAddCart) {
          RctSwal.fire({
            html: `<h3>${I18n.t('AddedToCart')}</h3>`,
            width: 'auto',
            padding: '30px 40px 10px 40px',
          });
        }
      }
      this.setState({
        isAmountInvalid: false,
      });
      history.push(ROUTES.CART);
    } else {
      this.setState({
        isAmountInvalid: true,
      });
    }
  };

  validateCardProducts(id) {
    const { cartItems, product, allowSellSpotItensWithoutStock } = this.props;
    const { amount } = this.state;

    const productInCart = cartItems.find(item => item.product.id === id);

    if (productInCart && validator.isSpotProductAmountInvalid((Number(amount) + Number(productInCart.total)),
      product.stock, allowSellSpotItensWithoutStock, product.isSpot, product.price.isContract)) {
      return false;
    }
    return true;
  }

  floatSafeModulus(val, step) {
    if (val && val !== '0') {
      const valDecCount = (val.toString().split('.')[1] || '').length;
      const stepDecCount = (step.toString().split('.')[1] || '').length;
      const decCount = valDecCount > stepDecCount ? valDecCount : stepDecCount;
      const valInt = parseInt(Number(val).toFixed(decCount).replace('.', ''), 0);
      const stepInt = parseInt(Number(step).toFixed(decCount).replace('.', ''), 0);
      return (((valInt % stepInt) + stepInt) % stepInt) / (10 ** decCount);
    }
    return 1;
  }

  render() {
    const {
      amount, multipleQuantity, promotionCoutdown, isAmountInvalid,
    } = this.state;
    const {
      product = {}, soneparCodeOff,
      leadTimePermission, stockPermission, cartPermission, pricePermission, opicCodePermission, soneparCodePermission,
      allowSellSpotItensWithoutStock, isPunchOut, isAuthenticated,
    } = this.props;

    const isSpotProductInvalidToSell = (!allowSellSpotItensWithoutStock && !product.stock);

    const showBuyButton = isPunchOut || (!product.isSpot
      || product.price.isContract
      || (product.isSpot && !isSpotProductInvalidToSell))
      || !isAuthenticated;

    return (
      <div className="product-single-details">
        <h1 className="product-title">{product.name}</h1>

        {/* <div className="ratings-container">
          <div className="product-ratings" style={{ float: 'left' }}>
            {[1, 2, 3, 4, 5].map(star => (
              <FontAwesomeIcon
                key={star}
                icon={faStar}
                style={{ color: star <= product.stars ? '#706f6c' : '#b3b3b3' }}
              />
            ))}
            <span className="ratings" style={{ width: `${4 * 20}%` }} />
          </div>

          <div className="rating-link">
            {'( '}
            {product.evaluations}
            {' Avaliações ) '}
          </div>
        </div> */}

        {promotionCoutdown && (
          <p className="product-coutdown">
            {promotionCoutdown}
          </p>
        )}

        <div className="price-box">
          {product.price && !product.price.isContract && product.price.oldValue && (
            <span className="old-price">
              <PriceComponent value={product.price.oldValue} labelIVA={true}/>
            </span>
          )}
          {product.price && (
            <span className="product-price">
              {pricePermission && <FinalPriceComponent price={product.price} labelIVA={true}/>}
            </span>
          )}

          {product.price && !product.price.isContract && product.price.discount && (
            <span className="product-label label-hot-details">
              -
              {ProductsUtil.getDiscount(product.price)}
            </span>
          )}
        </div>

        <div className={`product-desc ${cartPermission ? '' : 'no-border'}`}>
          <p>{ProductsUtil.getResume(product)}</p>

          <div className="code-container">
            <div className="info">
              {opicCodePermission && product.code && `( ${I18n.t('code2')} ${product.code} ) `}
              {product.clientCode && `( ${I18n.t('clientCode')} ${product.clientCode} ) `}
              {soneparCodePermission
                && (!soneparCodeOff && product.id) && `( ${I18n.t('soneparCode')} ${product.id} ) `}
            </div>
          </div>

          <div className="product-tags">
            {product.price
              && product.price.isContract
              && product.price.type === 'contract'
              && <span className="product-label"><Translate value="contract" /></span>}

            {product.price
              && product.price.isContract
              && product.price.type === 'agreement'
              && <span className="product-label label-agreement"><Translate value="agreement" /></span>}

            {product && stockPermission && (
              <span className={`product-label label-stock${!product.stock ? ' label-sale' : ''}`}>
                {ProductsUtil.getStockAvailable(product.stock)}
              </span>
            )}

            <br />
            {product && stockPermission && product.stockDetail && product.stockDetail.length > 0
              && product.stockDetail.map((value, row) => {
                if (!value.availableQuantity > 0) return;
                return (
                  <span key={value.stateCode} className={`product-label label-stock${!value.availableQuantity ? ' label-sale' : ''}`}>
                    { `${value.crmTag} (${value.availableQuantity})`}
                  </span>
                );
              }, [])}

            {leadTimePermission && (!product.stock || product.stock < amount) && (
              <span className="product-label label-time">{ProductsUtil.getStockTime(product.leadTime)}</span>
            )}
          </div>
        </div>

        {(isPunchOut || cartPermission || !isAuthenticated) && (
          <div className="product-action">
            {showBuyButton && (
              <div>
                <div className="product-single-qty">
                  <div className="input-group bootstrap-touchspin bootstrap-touchspin-injected">
                    <span className="input-group-btn input-group-prepend">
                      <button
                        className="btn btn-outline btn-down-icon bootstrap-touchspin-down"
                        type="button"
                        onClick={() => this.handleAmount(-multipleQuantity)}
                      />
                    </span>
                    <input
                      className="horizontal-quantity form-control"
                      type="text"
                      maxLength={inputSize.maxDefault}
                      value={this.formatTotal(amount)}
                      onChange={e => this.handleSetAmount(e.target.value)}
                    />
                    <span className="input-group-btn input-group-append">
                      <button
                        className="btn btn-outline btn-up-icon bootstrap-touchspin-up"
                        type="button"
                        onClick={() => this.handleAmount(+multipleQuantity)}
                      />
                    </span>
                  </div>
                </div>

                <button
                  type="button"
                  className="paction add-cart"
                  title="Add to Cart"
                  onClick={this.addProductToCart}
                  disabled={
                    isAuthenticated
                    && (isAmountInvalid
                      || (this.floatSafeModulus(amount, multipleQuantity) !== 0)
                      || !amount || amount === 0)
                  }
                >
                  <span><Translate value="add" /></span>
                </button>
              </div>
            )}

            {isSpotProductInvalidToSell
              && !!product.price && product.price.hasPrice
              && !product.price.isContract
              && (<p className="error-amount">{I18n.t('unavailable')}</p>)}

            {isAuthenticated && isAmountInvalid && (
              <p className="error-amount">
                {`${I18n.t('invalidAmount2') + product.stock} 
                ${product.unit} ${I18n.t('invalidAmount3')} ${this.props.opicPhone}`}
              </p>
            )}

            {this.floatSafeModulus(amount, multipleQuantity) !== 0 && (
              <p className="error-amount">
                {I18n.t('sellMultipleNeeds') + multipleQuantity}
              </p>
            )}

            <p className="unit-options">{ProductsUtil.getUnitOptions(product)}</p>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.authenticated,
  leadTimePermission: state.contracts.selected && state.contracts.selected.leadTimePermission,
  stockPermission: !state.contracts.selected || (state.contracts.selected && state.contracts.selected.stockPermission),
  cartPermission: state.contracts.selected && state.contracts.selected.cartPermission,
  pricePermission: state.contracts.selected && state.contracts.selected.pricePermission,
  opicCodePermission: state.contracts.selected && state.contracts.selected.opicCodePermission,
  soneparCodePermission: state.contracts.selected && state.contracts.selected.soneparCodePermission,
  soneparCodeOff: !!state.settings.soneparCodeOff,
  allowSellSpotItensWithoutStock: state.contracts.selected
    && state.contracts.selected.clientDetails.allowSellSpotItensWithoutStock,
  cartItems: state.cart.items,
  opicPhone: state.settings && state.settings.phone,
  isPunchOut: state.punchOut,
  popupOnAddCart: state.settings && state.settings.popupOnAddCart,
});

const mapDispatchToProps = dispatch => ({
  addItem: (productId, productClientCode, productCode, total) => dispatch(cartAddItem(productId, productClientCode, productCode, total)),
  addItemCache: (productId, total) => dispatch(cartAddItemCache(productId, total)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetailsResume);
