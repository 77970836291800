import React from 'react';

const SolicitationFormInput = ({
  label, minLength, maxLength, value, disabled, onChange,
}) => (
  <input
    type="text"
    className="form-control"
    placeholder={label}
    minLength={minLength}
    maxLength={maxLength}
    value={value}
    onChange={onChange}
    required
    disabled={disabled}
  />
);

export default SolicitationFormInput;
