export default (state = {}, action) => {
  switch (action.type) {
    case 'LOGIN':
      return action.auth;
    case 'LOGOUT':
      return {};
    case 'SAVE_USER':
      return {
        ...state,
        authenticated: true,
        user: action.user,
      };
    case 'UPDATE_TOKEN':
      return {
        ...state,
        access_token: action.accessToken,
        refresh_token: action.refreshToke,
      };

    default:
      return state;
  }
};
