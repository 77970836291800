/* eslint-disable no-empty */
import React from 'react';
import { connect } from 'react-redux';

import { getProductDetails } from '../app/store/actions/products';

import pageTitleService from '../app/services/pageTitle';

import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import ProductDetails from '../components/productDetails/ProductDetails';
import ProductsSimilar from '../components/productsSimilar/ProductsSimilar';
import ProductsCrossSelling from '../components/productsCrossSelling/ProductsCrossSelling';
import ProductsUtil from '../app/utils/products';

export class ProductPage extends React.Component {
  state = {}

  async componentDidMount() {
    const { match, settings } = this.props;
    const { id, code } = match.params;
    const product = await this.props.getProduct(id, code);
    let companyName = '';

    if (settings) {
      if (settings.name) companyName = settings.name;
    }

    const prevHead = {
      title: pageTitleService.getTitle(),
      hardDescription: pageTitleService.getHardDescription(),
      hardKeywords: pageTitleService.getHardKeywords(),
      ogSitename: pageTitleService.getOgSitename(),
      description: pageTitleService.getDescription(),
      image: pageTitleService.getImage(),
    };

    pageTitleService.setTitle(ProductsUtil.getResume(product));
    pageTitleService.setHardDescription(product.textMarketing || product.name);
    pageTitleService.setHardKeywords(ProductsUtil.getKeywords(product));
    pageTitleService.setOgSitename(`${companyName} Checkbuy`.trim());
    pageTitleService.setDescription(product.descriptionEcommerce || product.name);
    pageTitleService.setImage(product.image);

    await this.setState({
      product,
      prevHead,
    });
    window.scrollTo(0, 0);
  }

  async componentWillUnmount() {
    try {
      const { prevHead } = this.state;

      pageTitleService.setTitle(prevHead.title);
      pageTitleService.setHardDescription(prevHead.hardDescription);
      pageTitleService.setHardKeywords(prevHead.hardKeywords);
      pageTitleService.setOgSitename(prevHead.ogSitename);
      pageTitleService.setDescription(prevHead.description);
      pageTitleService.setImage(prevHead.image);
    } catch (e) {}
  }

  render() {
    const { product = {} } = this.state;
    const { category = {} } = product;

    return (
      <main className="main">

        <Breadcrumb items={[{
          link: `/products?categories=${category.id}`,
          value: category.name,
        }, {
          value: product.name,
        }]}
        />

        <ProductDetails
          displayClientDescription={this.props.displayClientDescription}
          product={product}
        />
        {product.id && <ProductsSimilar productId={product.id} />}
        {product.id && <ProductsCrossSelling productId={product.id} />}
      </main>
    );
  }
}

const mapStateToProps = state => ({
  products: state.products.list,
  settings: state.settings,
  displayClientDescription: state.contracts.selected && state.contracts.selected.clientDetails.displayClientDescription,
});

const mapDispatchToProps = dispatch => ({
  getProduct: (productId, code) => dispatch(getProductDetails(productId, code)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductPage);
