import React from 'react';
import { Translate } from 'react-redux-i18n';

const CheckoutPaymentBilling = (props) => {
  const { payementType, selectedContract } = props;
  const type = 'billing';
  const isSelected = payementType === type;

  const paymentTerms = selectedContract.clientDetails && selectedContract.clientDetails.paymentTermsLabel
  && selectedContract.clientDetails.paymentTermsLabel.description;

  return (
    <React.Fragment>
      <button type="button" className="payment-menu" onClick={() => props.selectPayementType(type)}>
        <Translate value="billing" />
        <i className={`fas fa-arrow-${isSelected ? 'down' : 'right'}`} />
      </button>

      {selectedContract && (
        <div
          style={{ flexDirection: 'column', alignItems: 'flex-start' }}
          className={`card-payment ${isSelected ? '' : 'card-display-none'}`}
        >
          {paymentTerms && (
          <p className="invoice-p">
            <strong className="margin-right-5">
              <Translate value="paymentTerm" />
            :
            </strong>
            {paymentTerms}
          </p>
          )}

          <p className="invoice-p" style={{ height: '10px' }} />

          <button
            type="button"
            className="button-action btn btn-secondary"
            onClick={() => props.setPayment(type)}
          >
            <Translate value="billing" />
          </button>
        </div>
      )}
    </React.Fragment>
  );
};

export default CheckoutPaymentBilling;
