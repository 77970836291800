import React from 'react';
import { Translate } from 'react-redux-i18n';

const PanelAccount = () => (
  <div className="col-lg-9 order-lg-last dashboard-content">
    <h2><Translate value="myInformations" /></h2>
    <p>...</p>
  </div>
);

export default PanelAccount;
