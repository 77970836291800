import React from 'react';
import { connect } from 'react-redux';
import InputMask from 'react-input-mask';
import { Translate, I18n } from 'react-redux-i18n';

class CheckoutPaymentDocument extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
    };
  }

  render() {
    const { value, type } = this.props.document;
    const { documentType } = this.props;
    return (
      <React.Fragment>
        {documentType === 'B'
          ? (
            <InputMask
              mask="99.999.999-9"
              value={value}
              onChange={e => this.props.setDocument({ value: e.target.value, type: 'rut' })}
            >
              {inputProps => (
                <input
                  {...inputProps}
                  type="text"
                  className="form-control"
                  style={{ marginTop: '10px' }}
                  placeholder={I18n.t('cnpj')}
                  required
                />
              )}
            </InputMask>
          )
          : (
            <React.Fragment>
              <div className="btn-group btn-group-toggle" data-toggle="buttons">
                <button
                  type="button"
                  onClick={() => this.props.setDocument({ type: 'cpf', value: '' })}
                  className={type === 'cpf' ? 'btn btn-primary active' : 'btn btn-primary'}
                >
                  <input type="radio" name="options" id="option1" autoComplete="off" />
                  {' '}
                  <Translate value="cpf" />
                </button>
                <button
                  type="button"
                  onClick={() => this.props.setDocument({ type: 'cnpj', value: '' })}
                  className={type === 'cnpj' ? 'btn btn-primary active' : 'btn btn-primary'}
                >
                  <input type="radio" name="options" id="option2" autoComplete="off" />
                  {' '}
                  <Translate value="cnpj" />
                </button>
              </div>
              {type === 'cpf'
                ? (
                  <InputMask
                    mask="999.999.999-99"
                    value={value}
                    onChange={e => this.props.setDocument({ value: e.target.value, type: 'cpf' })}
                  >
                    {inputProps => (
                      <input
                        {...inputProps}
                        type="text"
                        className="form-control"
                        style={{ marginTop: '10px' }}
                        placeholder={I18n.t('cpf')}
                        required
                      />
                    )}
                  </InputMask>
                )
                : (
                  <InputMask
                    mask="99.999.999/9999-99"
                    value={value}
                    onChange={e => this.props.setDocument({ value: e.target.value, type: 'cnpj' })}
                  >
                    {inputProps => (
                      <input
                        {...inputProps}
                        type="text"
                        className="form-control"
                        style={{ marginTop: '10px' }}
                        placeholder={I18n.t('cnpj')}
                        required
                      />
                    )}
                  </InputMask>
                )
        }
            </React.Fragment>
          )
        }
      </React.Fragment>
    );
  }
}

const mapStateToProps = state => ({
  documentType: state.settings.documentType,
}
);

export default connect(mapStateToProps)(CheckoutPaymentDocument);
