/* eslint-disable max-len */
import React from 'react';
import { Translate, I18n} from 'react-redux-i18n';

import PurchasesUtils from '../../../../../app/utils/purchases';
import PurchaseData from '../../../../../data/purchases';

const WholeStepComponent = ({
 order, easyConfirm, easyConfirmVersion, settings = {},
}) => {
  const occurrences = (order.invoices && order.invoices[0] && order.invoices[0].occurrences) || {};
  let step = PurchasesUtils.getStatus(order.status);

  if (order.isDeliveryClient == '1') {
    if (order.status === PurchaseData.orderStatus.INVOICES_ISSUED)
      step = 6;
    if (order.status === PurchaseData.orderStatus.INVOICES_ISSUED_PARTIALLY)
      step = 3;     
  }
  
  let [dataCriacao, dataPrevisao, dataEntrega] = [];
  if (easyConfirmVersion && easyConfirmVersion >= '3.0') {
    [dataCriacao, dataPrevisao, dataEntrega] = [occurrences.dataCriacao, occurrences.dataReprogramada || occurrences.dataPrevisao, occurrences.entrega && occurrences.entrega.dataEntrega];
  } else {
    if (occurrences.embarque)
      [dataCriacao, dataPrevisao, dataEntrega] = [occurrences.embarque.dataCriacao['$'], occurrences.embarque.dataPrevisao['$'], occurrences.embarque.dataEntrega['$']];
  }

  if (easyConfirm && occurrences) {   
    if (dataCriacao) {
      step = 4;
    }
    if (dataPrevisao) {
      step = 5;
    }
    if (dataEntrega) {
      step = 6;
    }
  }

  let paymentOk = null;

  if (settings.paymentService === 'pagseguro') {
    paymentOk = order.paymentType === PurchaseData.paymentType.BILLING
    || order.paymentType === PurchaseData.paymentType.ESTIMATE
    || order.paymentStatus === PurchaseData.paymentStatusPagSeguro.PAID
    || order.paymentStatus === PurchaseData.paymentStatusPagSeguro.DEBITED;
  } else {
    paymentOk = order.paymentType === PurchaseData.paymentType.BILLING
      || order.paymentType === PurchaseData.paymentType.ESTIMATE
      || order.paymentStatus === PurchaseData.paymentStatus.AUTHORIZED
      || order.paymentStatus === PurchaseData.paymentStatus.CAPTURED;
  }

  const paymentLabel = paymentOk
    ? I18n.t('paymentMade')
    : order.paymentStatusReasonDesc || `${I18n.t('payment')}\n${I18n.t(PurchasesUtils.paymentStatus(order.paymentStatus))}`;

  const paymentClass = paymentOk ? "fas fa-dollar-sign" : 'fas fa-times-circle';
  const paymentStyleColor = paymentOk ? "" : 'tomato';
  return (
    <div className="order-detail-all align-center">
      <ul className="checkout-progress-bar">
        <li className={step >= 0 ? 'active' : ''}>
          <span><Translate value="orderMade" /></span>
          <i className="fas fa-clipboard-check" />
        </li>
        <li className={step >= 1 ? 'active' : ''}>
          <span style={{color: paymentStyleColor}}>{paymentLabel}</span>
          <i style={{color: paymentStyleColor}} className={paymentClass}/>
        </li>
        <li className={step >= 2 ? 'active' : ''}>
          <span><Translate value="picking" /></span>
          <i className="fas fa-warehouse" />
        </li>
        <li className={step >= 3 ? 'active' : ''}>
          <span><Translate value="invoiceIssued" /></span>
          <i className="fas fa-copy" />
        </li>
        {order.isDeliveryClient === '1'
        && (
        <React.Fragment>
          <li className={step >= 4 ? 'active' : ''}>
            <span><Translate value="availableCustumer" /></span>
            <i className="fas fa-home" />
          </li>
          <li className={step >= 6 ? 'active' : ''}>
            <span><Translate value="delivered" /></span>
            <i className="fas fa-check-circle" />
          </li>
          </React.Fragment>
          )}
        {easyConfirm && order.isDeliveryClient === '0'
        && (
        <React.Fragment>
          <li className={step >= 4 ? 'active' : ''}>
            <span><Translate value="deliveredToCarrier" /></span>
            <i className="fas fa-truck-loading" />
          </li>
          <li className={step >= 5 ? 'active' : ''}>
            <span><Translate value="outForDelivery" />
            {occurrences && dataPrevisao && 
            (' ' + I18n.t('outForDeliveryPrev') + ' ' +   dataPrevisao.substr(0,10))
            }
            </span>
            <i className="fas fa-truck" />
          </li>
          <li className={step >= 6 ? 'active' : ''}>
            <span><Translate value="delivered" /></span>
            <i className="fas fa-check-circle" />
          </li>
        </React.Fragment>
        )
        }
      </ul>
    </div>
  );
};

export default WholeStepComponent;
