import RestService from '../services/rest';

async function buscaCep(cep) {
  return RestService.getRest(`newRegisters/viaCep/?cep=${cep}`);
}

async function checkRegister(email, cnpj, type) {
  return RestService.getRest(`newRegisters/check/?email=${email}&cnpj=${cnpj}&type=${type}`);
}

async function createNewRegister(data) {
  return RestService.postRest('newRegisters/', data);
}

export default {
  buscaCep,
  checkRegister,
  createNewRegister,
};
