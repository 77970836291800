import React from 'react';
import InputMask from 'react-input-mask';
import { I18n, Translate } from 'react-redux-i18n';

import RegexCards from '../../../app/utils/cards';

import StringHelper from '../../../helpers/string';

class CheckoutPaymentCard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cvv: '',
      installments: 1,
      visible: false,
      showModalDelete: false,
    };
  }

  displayCvv = async () => {
    const { visible } = this.state;
    await this.setState({ visible: !visible });
  };

  numberOfInstallments = (total) => {
    const installmentsTotal = [];
    for (let i = 1; i <= total; i += 1) installmentsTotal.push(i);
    return installmentsTotal.map(p => <option key={p} value={p}>{p}</option>);
  };

  removeCreditCardUser = async (card) => {
    await this.setState({ showModalDelete: false });
    await this.props.deleteCard(card);
  };

  render() {
    const { visible, cvv, installments, showModalDelete } = this.state;
    const { card, type, installmentsTotal } = this.props;

    return (
      <div className="col-12">
        <div className={!showModalDelete ? 'card-display-none' : undefined}>
          <button
            type="button"
            onClick={() => this.setState({ showModalDelete: false })}
            className="outer-modal-div"
          />
          <div 
            className="modal-div-cart"
            style={{ position: "absolute" }}
          >

            <div>
              <h3 className="h3-modal-div">{`${I18n.t('wishToDeleteCard')} ${I18n.t(type)}?`}</h3>
              <h4 className="h4-modal-div">{`${card.name} ${card.expirationDate} ${card.number}`}</h4>
              <div className="row body-modal-div">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => this.setState({ showModalDelete: false })}
                >
                  <Translate value="cancel" />
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() =>  this.removeCreditCardUser(card)}
                >
                  <Translate value="deleteCard" />
                </button>
              </div>
            </div>

          </div>
        </div>
        <button
          className="btn btn-primary list-btn-solicitation" 
          type="button"
          style={{ 
            marginTop: '15px', 
            padding: '0.5rem',
            minWidth: '90px',
            letterSpacing: 'unset',
            textTransform: 'unset',
          }}
          onClick={() => this.setState({ showModalDelete: true })}
        >
          <Translate value="deleteCard" />
        </button>
        <button
          type="button"
          className="card-payment"
          onClick={this.displayCvv}
        >
          <div className="row text-card-payment">
            <img
              className="image-card-logo"
              src={RegexCards.getCardsUrl(card.brand) || '/images/product-image-placeholder.png'}
              alt="Card logo"
            />
          </div>
          <div className="row text-card-payment">
            {`${card.name} ${card.expirationDate}`}
          </div>
          <div className="row text-card-payment" style={{ marginLeft: "30px" }}>
            {`${card.number}`}
          </div>          
        </button>
        <div className={visible ? '' : 'card-display-none'}>
          <div className="row">
            <div className="col-6">
              <span>
                <Translate value="securityCode" />
              </span>
              <InputMask
                mask="9999"
                value={this.state.cvv}
                onChange={e => this.setState({ cvv: e.target.value })}
              >
                {inputProps => (
                  <input
                    {...inputProps}
                    type="text"
                    className="form-control"
                    placeholder={I18n.t('cvv')}
                    required
                  />
                )}
              </InputMask>

              {type === 'credit' && (
                <div>
                  <span>
                    <Translate value="installmentsNumber" />
                  </span>
                  <div className="select-custom">
                    <select
                      name="orderby"
                      className={`form-control ${installments}`}
                      defaultValue="num"
                      onChange={e => this.setState({ installments: Number(e.target.value) })}
                    >
                      {this.numberOfInstallments(installmentsTotal)}
                    </select>
                  </div>
                </div>
              )}

              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.props.setPayment(type, {
                  card: {
                    cardId: card.id,
                    brand: card.brand,
                    securityCode: cvv,
                    installments,
                  },
                })}
                disabled={StringHelper.keepOnlyNumber(cvv).length < 3 || StringHelper.keepOnlyNumber(cvv).length > 4}
              >
                <Translate value="confirm" />
              </button>
            </div>
            <div className="col-6">
              <img src="/images/cvv.png" alt="Card back banner" />
            </div>
          </div>
          <hr />
        </div>


      </div>
    );
  }
}


export default CheckoutPaymentCard;
