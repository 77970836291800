import React from 'react';
import { Translate } from 'react-redux-i18n';

import ProductDetailsPictures from './ProductDetailsPictures';
import ProductDetailsResume from './ProductDetailsResume';
import ProductDetailsTabs from './ProductDetailsTabs';

const ProductDetails = ({ product, displayClientDescription }) => (
  <div className="container">
    <div className="product-single-container product-single-default">
      {product && (
        <React.Fragment>
          <div className="row">
            <div className="col-lg-5">
              <ProductDetailsPictures pictures={product.images} product={product} />
            </div>
            <div className="col-lg-7">
              <ProductDetailsResume product={product} />
              <ProductDetailsTabs
                displayClientDescription={displayClientDescription}
                clientDescription={product.clientDescription}
                pdmParticulars={product.pdmParticulars}
                datasheet={product.datasheet}
              />
            </div>
          </div>
          {
            !!(product.textMarketing) && (
              <div className="row">
                <div className="col-lg-12">
                  <div className="divider-list" />
                  <div className="product-tags-content mb-10">
                    <h4><Translate value="description" /></h4>
                    {product.textMarketing}
                  </div>
                </div>
              </div>
            )
          }
        </React.Fragment>
      )}
    </div>
  </div>
);

export default ProductDetails;
