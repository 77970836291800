import React from 'react';
import { Translate } from 'react-redux-i18n';
import { connect } from 'react-redux';
import Carousel from '../multipleCarousel/MultipleCarousel';
import HomeCategory from './HomeCategory';

const HomeCategories = ({ categories }) => (
  <div className="featured-section-white">
    <div className="container">
      <h2 className="carousel-title"><Translate value="categories" /></h2>
      <div className="featured-products">
        <Carousel
          xs={2}
          sm={3}
          md={4}
          lg={5}
          items={categories.map(category => (
            <HomeCategory category={category} />
          ))}
          arrows
        />
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  categories: state.settings.categories,
});

export default connect(mapStateToProps)(HomeCategories);
