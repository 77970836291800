import {
  createStore, combineReducers, applyMiddleware, compose,
} from 'redux';
import { i18nReducer } from 'react-redux-i18n';
import thunk from 'redux-thunk';

import activitiesReducer from './reducers/activities';
import authReducer from './reducers/auth';
import cardsReducer from './reducers/cards';
import cartReducer from './reducers/cart';
import contractsReducer from './reducers/contracts';
import productsReducer from './reducers/products';
import settingsReducer from './reducers/settings';
import loadingReducer from './reducers/loading';
import panelReducer from './reducers/panel';
import punchOutReducer from './reducers/punchOut';
import purchaseReducer from './reducers/purchase';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export default () => {
  const store = createStore(
    combineReducers({
      activities: activitiesReducer,
      auth: authReducer,
      cards: cardsReducer,
      cart: cartReducer,
      contracts: contractsReducer,
      i18n: i18nReducer,
      products: productsReducer,
      settings: settingsReducer,
      loading: loadingReducer,
      panel: panelReducer,
      punchOut: punchOutReducer,
      purchase: purchaseReducer,
    }),
    composeEnhancers(applyMiddleware(thunk)),
  );

  return store;
};
