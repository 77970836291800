import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const LandingHowWorksItems = ({ icon, title, text }) => (
  <div className="item">
    <div className="icon">
      <FontAwesomeIcon icon={icon} />
    </div>
    <div className="texts">
      <h4>{title}</h4>
      <p>{text}</p>
    </div>
  </div>
);


export default LandingHowWorksItems;
