import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

const HeaderMiddleLeft = ({ name, logo }) => (
  <div className="header-left">
    <Link to="/" className="logo">
      <img src={logo} alt={name} />
    </Link>
  </div>
);

const mapStateToProps = state => ({
  name: state.settings.name,
  logo: state.settings.logo,
});

export default connect(mapStateToProps)(HeaderMiddleLeft);
