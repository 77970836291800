import React from 'react';
import { Translate } from 'react-redux-i18n';
import {
  faComments, faCloudUploadAlt, faUsers, faLock,
} from '@fortawesome/free-solid-svg-icons';

import LandingHowWorksItems from './LandingHowWorksItems';

const LandingHowWorks = () => (
  <div className="landing-how-works" id="how-works">
    <div className="content">
      <h2><Translate value="howWorks" /></h2>
      <div className="middle">
        <div className="phone">
          <img src="/images/landing/phone.png" alt="Phone" />
        </div>
        <div className="items">
          <div className="row">
            <div className="col-md-6">
              <LandingHowWorksItems
                icon={faComments}
                title="Chat"
                text="Lorem Ipsum is simply dummy text of the printing"
              />
            </div>
            <div className="col-md-6">
              <LandingHowWorksItems
                icon={faCloudUploadAlt}
                title="Cloud System"
                text="Lorem Ipsum is simply dummy text of the printing"
              />
            </div>
            <div className="col-md-6">
              <LandingHowWorksItems
                icon={faUsers}
                title="Connect Friend"
                text="Lorem Ipsum is simply dummy text of the printing"
              />
            </div>
            <div className="col-md-6">
              <LandingHowWorksItems
                icon={faLock}
                title="Privacy"
                text="Lorem Ipsum is simply dummy text of the printing"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);


export default LandingHowWorks;
