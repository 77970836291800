import React from 'react';

import FooterBottom from './bottom/FooterBottom';
import FooterMiddle from './middle/FooterMiddle';

const Footer = () => (
  <footer className="footer">
    <FooterMiddle />
    <FooterBottom />
  </footer>
);

export default Footer;
