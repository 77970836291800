const initialState = {
  orderList: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PANEL_ORDER_LIST': {
      return {
        ...state,
        orderList: action.orderList,
      };
    }
    case 'PANEL_ORDER_DETAILS': {
      return {
        ...state,
        orderDetails: action.orderDetails,
      };
    }
    default:
      return state;
  }
};
