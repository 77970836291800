/* eslint-disable max-len */
import moment from 'moment-timezone';
import { I18n } from 'react-redux-i18n';

import ROUTES from '../../data/routes';

function getPrice(options) {
  const { value, force, currency } = options;
  if (currency) {
    if (value || force) {
      return `${currency.symbol} ${(value || 0).toLocaleString('pt-BR', {
        minimumFractionDigits: currency.decimals,
        maximumFractionDigits: currency.decimals,
      })}`;
    }
  }
}

function getDiscount(price = {}) {
  return `${Math.ceil(price.discount * 100)}%`;
}

function getPromotionCountdown(price = {}) {
  if (!price.finalizedAt || !price.showTimer) return;

  const finalizedAt = moment(price.finalizedAt);

  const duration = moment.duration(finalizedAt.diff(moment()));
  if (duration > 1000 * 60 * 60 * 24) return `${I18n.t('finalize2')} ${finalizedAt.fromNow()}`;
  return `${I18n.t('finalizeIn')} ${moment.utc(duration.asMilliseconds()).format('HH:mm:ss')}`;
}

function getResume(product) {
  const category = product.category && product.category.name;
  if (!category) return;
  const brand = product.brand && product.brand.description;
  return `${product.pdmModifier} | ${category} | ${brand}`;
}

function getKeywords(product) {
  const category = product.category && product.category.name;
  if (!category) return;
  const brand = product.brand && product.brand.description;
  return `${product.pdmModifier},${category},${brand}`;
}

function getStockAvailable(available) {
  if (!available) return I18n.t('withoutStock');
  return `${I18n.t('available')} (${available})`;
}

function getStockTime(leadTime) {
  if (leadTime === undefined) return I18n.t('consultUsTime');
  if (leadTime === 0) return I18n.t('immediateTime');
  return `${I18n.t('maxTime')} ${leadTime} ${I18n.t('days')}`;
}

function getUnitOptions(product) {
  const { unit, multipleQuantity } = product;
  if (!unit) return;
  return `${I18n.t('unityPrefix')} ${unit} | ${I18n.t('sellMultiple')} ${multipleQuantity.toLocaleString('pt-BR')}`;
}

function getUrl(product = {}) {
  return `${ROUTES.PRODUCTS}/${product.id}/${product.clientCode || 0}/${product.name ? encodeURI(product.name.trim().replace(/[ ]+/g, '_')) : ''}`;
}

function sortItems(items, orderOpts) {
  return items.sort((a, b) => {
    let attrA;
    let attrB;

    const [orderAttr, orderBy] = orderOpts.split('-');

    const multiplier = orderBy === 'asc' ? 1 : -1;


    switch (orderAttr) {
      case 'name':
        attrA = a.product.name;
        attrB = b.product.name;
        return (attrA < attrB ? -1 : 1) * multiplier;
      case 'date':
      default:
        attrA = a.product.index;
        attrB = b.product.index;
        return (attrA < attrB ? -1 : 1) * multiplier;
    }
  });
}

export default {
  getPrice,
  getDiscount,
  getPromotionCountdown,
  getResume,
  getStockAvailable,
  getStockTime,
  getUnitOptions,
  getUrl,
  sortItems,
  getKeywords,
};
