import React from 'react';

import CartSolicitation from './CartSolicitation';
import CartEstimate from './CartEstimate';
import CartProducts from './CartProducts';
import CartEmpty from './CartEmpty';

class Cart extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      view: 'products',
    };
  }

  setView = async (view) => {
    await this.setState({ view });
  };

  render() {
    const { view } = this.state;
    const { cartItems, cartOrderBy, cartLoaded, companyTag } = this.props;

    if (!cartLoaded) {
      return (
        <div className="align-center">
          <i className="spinner-menu-icon fas fa-spinner fa-pulse spinner-cart-icon" />
        </div>
      );
    }

    if (view === 'solicitation') return <CartSolicitation />;
    if (view === 'estimate') return <CartEstimate />;
    if (cartItems.length === 0) return <CartEmpty />;

    return <CartProducts cartItems={cartItems} cartOrderBy={cartOrderBy} setView={this.setView} companyTag={companyTag} />;
  }
}

export default Cart;
