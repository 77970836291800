import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';

import inputSize from '../../../data/inputs';

class StepUserForm extends React.Component {
  state = {
    password: '',
    confirmPassword: '',
    passError: '',
    message: '',
  }

  onSubmit = async (e) => {
    e.preventDefault();

    const { cnpj, email, rut } = this.props.basic;

    const {
      password, confirmPassword,
    } = this.state;

    if (password.length < inputSize.minPassword || password.length > inputSize.maxPassword) {
      this.setState({
        passError: `${I18n.t('passwordErrorMessage2')} ${inputSize.minPassword}
                    ${I18n.t('passwordErrorMessage3')} ${inputSize.maxPassword}`
      });
    } else if (password !== confirmPassword) {
      this.setState({ passError: I18n.t('passwordDifferents') });
    } else {
      this.setValue('password', password);
      await this.props.handleFinalize();
    }
  };

  setValue = async (attr, value) => {
    const { user } = this.props;
    user[attr] = value;
    await this.props.setUser(user);
  }

  onPasswordChange = (e) => {
    const password = e.target.value;
    this.setState(() => ({ password }));
  };

  onConfirmPasswordChange = (e) => {
    const confirmPassword = e.target.value;
    this.setState(() => ({ confirmPassword }));
  };

  render() {
    const { passError, message } = this.state;
    return (
      <form onSubmit={this.onSubmit}>

        <input
          type="password"
          className="form-control"
          placeholder={I18n.t('passwordPlaceholder')}
          value={this.state.password}
          onChange={this.onPasswordChange}
          maxLength={inputSize.maxPassword}
          required
        />

        <input
          type="password"
          className="form-control"
          placeholder={I18n.t('passwordPlaceholder2')}
          value={this.state.confirmPassword}
          onChange={this.onConfirmPasswordChange}
          maxLength={inputSize.maxPassword}
          required
        />

        {passError ? <p className="error-p">{passError}</p> : ''}

        <div className="row justify-content-center margin-top-20 margin-bottom-20 form-footer">
          <button
            type="button"
            onClick={this.props.handleBack}
            className="backButton btn btn-light"
          >
            <Translate value="return" />
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            variant="contained"
          >
            <Translate value="finalize" />
          </button>          
        </div>

        {message ? <p className="error-p">{message}</p> : ''}
      </form>
    );
  }
}

export default StepUserForm;
