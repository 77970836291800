import React from 'react';
import { Translate } from 'react-redux-i18n';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTruckMoving, faStoreAlt } from '@fortawesome/free-solid-svg-icons';
import NUMBER from '../../helpers/number';

import Freight from './freight/Freight';

export default function AddressCard(props) {
  const {
    address, handleCardClick, isDefault, freightShow, setFreight, isWithdraw,
  } = props;
  return (
    <div>
      {isDefault && (<span className="freight-title freight-default-title"><Translate value="defaultAddress" /></span>)}
      <button
        onClick={() => handleCardClick()}
        key={location.nameAbbr}
        type="button"
        className={`card-payment ${isDefault ? 'card-payment-default' : ''}`}
      >
        <FontAwesomeIcon icon={isWithdraw ? faStoreAlt : faTruckMoving} className="freight-icon" />
        <div>
          <div className="row address-sub-card">
            <span className="freight-title"><Translate value={isWithdraw ? 'storeTake' : 'normalDelivery'} /></span>
          </div>
          <div className="row address-sub-card">
            {isWithdraw ? address.name : address.nameAbbr}
          </div>
          <div className="row address-sub-card">
            {address.address}
          </div>
          <div className="row address-sub-card">
            {address.province}
          </div>
          <div className="row address-sub-card">
            {`${address.city} - ${address.state}`}
          </div>
          <div className="row address-sub-card">
            {`${NUMBER.cepMask(address.cep)} - ${address.country}`}
          </div>
        </div>
      </button>

      {freightShow === address.cep && (
        <Freight location={address} setFreight={setFreight} />
      )
            }
    </div>
  );
}
