import React from 'react';
import { connect } from 'react-redux';

import ProductsUtil from '../../app/utils/products';

const PriceComponent = props => (
  <React.Fragment> 
    <span>{(ProductsUtil.getPrice(props) || '')}{props.labelIVA && props.showIVALabel ? <span className="price-iva-label">{props.value ? " + IVA" : ""}</span> : null}</span>
  </React.Fragment>
);

const mapStateToProps = state => ({
  currency: state.contracts && state.contracts.selected.currency,
  showIVALabel: state.settings && state.settings.companyTag == 'chile',
});

export default connect(mapStateToProps)(PriceComponent);
