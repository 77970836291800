import React from 'react';
import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import PanelSidebar from '../components/panel/PanelSidebar';
import Reports from '../components/panel/report/Reports';

const ReportPage = () => (
  <main className="main">
    <div className="container">
      <Breadcrumb items={[{
        value: I18n.t('panel'),
      }, {
        value: I18n.t('report'),
      }]}
      />
      <div className="row">
        <PanelSidebar />
        <Reports />
      </div>
    </div>
  </main>
);

export default ReportPage;
