import React from 'react';
import { Translate } from 'react-redux-i18n';

import PUCHASES from '../../../../../data/purchases';

const EstimateComponent = ({ order }) => (
  <div className="order-detail-estimate">
    <ul className="checkout-progress-bar">
      <li className={order.status === PUCHASES.status.CONFIRMED
        || order.status === PUCHASES.status.FINISHED ? 'active' : ''}
      >
        <span><Translate value="confirmed" /></span>
        <i className="fas fa-clipboard-check" />
      </li>
      <li className={order.status === PUCHASES.status.FINISHED && 'active'}>
        <span><Translate value="finalized" /></span>
        <i className="fas fa-check-circle" />
      </li>
    </ul>
  </div>
);

export default EstimateComponent;
