import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';

import { changePassword } from '../../app/store/actions/auth';

import inputSize from '../../data/inputs';
import PasswordStrengthIndicator from './PasswordStrengthIndicator';

export class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      password: '',
      confirmPassword: '',
      oldPassword: '',
      passwordFocused: false,
      passwordValidity: {
        minChar: null,
        number: null,
        upperCase: null,
        specialChar: null,
      },
      success: '',
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      password, confirmPassword, oldPassword,
      passwordValidity,
    } = this.state;

    if (
      passwordValidity.minChar
      && passwordValidity.number
      && passwordValidity.upperCase
      && passwordValidity.specialChar
      && password === confirmPassword
      && oldPassword.length >= inputSize.minPassword
      && oldPassword.length < inputSize.maxPassword
    ) {
      try {
        await this.props.changePassword(password, oldPassword);
        this.setState({
          success: I18n.t('passwordSuccessChanged'),
          message: '',
          password: '',
          confirmPassword: '',
          oldPassword: '',
          passwordFocused: false,
        });
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error && err.response.data.error.code === 409) {
          this.setState({
            message: I18n.t('passwordInvalid'),
            success: '',
          });
        } else {
          this.setState({ message: I18n.t('errorTryLater'), success: '' });
        }
      }
    }
    if (!passwordValidity.minChar) this.setState({ message: `${I18n.t('passworderrorMessage')}: ${I18n.t('passwordErrorMinChar')}` });
    if (!passwordValidity.number) this.setState({ message: `${I18n.t('passworderrorMessage')}: ${I18n.t('passwordErrorNumber')}` });
    if (!passwordValidity.upperCase) this.setState({ message: `${I18n.t('passworderrorMessage')}: ${I18n.t('passwordErrorUpperCase')}` });
    if (!passwordValidity.specialChar) this.setState({ message: `${I18n.t('passworderrorMessage')}: ${I18n.t('passwordErrorSpecialChar')}` });
    if (password !== confirmPassword) this.setState({ message: `${I18n.t('passworderrorMessage')}: ${I18n.t('passwordErrorNotEqual')}` });
    if (oldPassword.length < inputSize.minPassword || oldPassword.length > inputSize.maxPassword) this.setState({ message: I18n.t('passwordErrorMessage1'), success: '' });
  };

  onFocusPassword = (value, state) => {
    this.setState({ [state]: value });
  }

  onChangePassword = (password) => {
    const characterLengthRegx = /^.{9,}$/;
    const isNumberRegx = /[0-9]/;
    const specialCharacterRegx = /[!@#$%^&*]/;
    const upperCaseCharacterRegx = /[A-Z]/;
    this.setState({ password });
    this.setState({
      passwordValidity: {
        minChar: characterLengthRegx.test(password),
        number: isNumberRegx.test(password),
        upperCase: upperCaseCharacterRegx.test(password),
        specialChar: specialCharacterRegx.test(password),
      },
    });
  }

  render() {
    const {
      message, password, confirmPassword, oldPassword, passwordFocused, passwordValidity, success,
    } = this.state;
    return (
      <div className="login container">
        <div className="row">
          <div className="col-md-6 offset-md-2">
            <div className="heading">
              <h2 className="title">{I18n.t('passwordChange')}</h2>
              <p>{I18n.t('passwordInsert')}</p>
            </div>
            <form onSubmit={this.onSubmit}>
              <input
                type="password"
                className="form-control"
                placeholder={I18n.t('currentPassword')}
                maxLength={inputSize.maxPassword}
                value={oldPassword}
                onChange={e => this.setState({ oldPassword: e.target.value, message: '', success: '' })}
                required
              />
              <input
                type="password"
                className="form-control"
                placeholder={I18n.t('newPassword')}
                maxLength={inputSize.maxPassword}
                value={password}
                onFocus={() => this.onFocusPassword(true, 'passwordFocused')}
                onChange={e => this.onChangePassword(e.target.value)}
                required
              />

              <input
                type="password"
                className="form-control"
                placeholder={I18n.t('confirmNewPassword')}
                maxLength={inputSize.maxPassword}
                value={confirmPassword}
                onFocus={() => this.onFocusPassword(true, 'passwordFocused')}
                onChange={e => this.setState({ confirmPassword: e.target.value })}
                required
              />

              <div className="form-footer">
                <button type="submit" className="btn btn-primary"><Translate value="passwordChange" /></button>
              </div>

              <p className="color-red">{message}</p>
              <p className="color-green">{success}</p>
            </form>
          </div>
          <div className="col-md-3 mt-6">
            {
              passwordFocused && (
                <PasswordStrengthIndicator
                  className="form-control"
                  validity={passwordValidity}
                  passwordEqual={password === confirmPassword && confirmPassword}
                />
              )
            }
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changePassword: (password, oldPassword) => dispatch(changePassword(password, oldPassword)),
});

const mapStateToProps = state => ({
  contractsTotal: state.contracts.list.length,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
