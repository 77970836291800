import React from 'react';
import FileViewer from 'react-file-viewer';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

class PrivacyPolicyPage extends React.PureComponent {
  state = {
    message: '',
  }

  onError(err) {
    this.setState({ message: `${I18n.t('errorOcurred2')}${err}` });
  }

  render() {
    const { message } = this.state;
    const { privacyPolicy } = this.props;
    const type = 'docx';
    return (
      <div className="color-light-gray">
        <FileViewer
          fileType={type}
          filePath={privacyPolicy}
          // errorComponent={CustomErrorComponent}
          onError={this.onError}
        />
        <p className="color-red">{message}</p>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  privacyPolicy: state.settings.privacyPolicy,
});

export default connect(mapStateToProps)(PrivacyPolicyPage);
