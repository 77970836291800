/* eslint-disable no-empty */
import 'normalize.css/normalize.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import TagManager from 'react-gtm-module';

import AppRouter from './routers/AppRouter';
import configureStore from './app/store/configureStore';
import initialization from './app/initialization';
import { loadSettings } from './app/store/actions/settings';

import './styles/styles.scss';

const store = configureStore();

const initTagManager = async (tagManagerId) => {
  const tagManagerArgs = {
    gtmId: tagManagerId
  }
  
  TagManager.initialize(tagManagerArgs)
}

const initRender = async () => {
  try {
    await store.dispatch(loadSettings());
  } catch (e) {}

  const state = await store.getState();

  if (state.settings && state.settings.tagManagerId) {
    initTagManager(state.settings.tagManagerId)  
  }

  const jsx = (
    <Provider store={store}>
      <AppRouter />
    </Provider>
  );
  initialization(store);

  ReactDOM.render(jsx, document.getElementById('app'));
}

initRender();
