/* eslint-disable max-len */
/* eslint-disable no-empty */

import CartServer from '../../server/cart';
import { addLoading, removeLoading } from './loading';
import { getUser } from './auth';
import StorageService from '../../services/storage';

export const setItems = (items, cartId, user) => ({
  type: 'CART_SET_ITEMS',
  items,
  cartId,
  user,
});

export const setObservation = observationText => ({
  type: 'CART_SET_OBSERVATION',
  observationText,
});

export const setFreight = freight => ({
  type: 'CART_SET_FREIGHT',
  freight,
});

export const cartSetClientNumber = clientNumber => ({
  type: 'CART_SET_CLIENT_NUMBER',
  clientNumber,
});

export const cartActiveLoading = () => ({
  type: 'CART_ACTIVE_LOADING',
});

export const cartSortItems = value => ({
  type: 'CART_SET_ORDER_BY',
  value,
});

const addCartItemsIndexes = (items) => {
  items.forEach((item, index) => {
    item.index = index;
  });
};

export const getCart = () => async (dispatch, getState) => {
  const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
  const { cartPermission } = getState().contracts.selected;
  const punchoutUserId = getState().punchOut && getState().punchOut.userId;

  if (!cartPermission) return;

  dispatch(cartActiveLoading());

  const items = await CartServer.getCart(clientId, punchoutUserId);
  addCartItemsIndexes(items);
  dispatch(setItems(items));
};

export const getCartCache = () => async (dispatch) => {
  try {
    const cartItems = StorageService.get('user_cart_cached') || [];

    addCartItemsIndexes(cartItems);
    dispatch(setItems(cartItems));
  } finally {}
};

export const cartAddItem = (productId, productClientCode, productCode, total = 1, doLoading = true, isItemCache = false) => async (dispatch, getState) => {
  const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
  const { cartId, user } = getState().cart;
  const punchoutUserId = getState().punchOut && getState().punchOut.userId;
  if (doLoading) dispatch(addLoading());

  try {
    const item = getState().cart.items
      .find(i => i.product.id === productId && i.product.clientCode === productClientCode);
    if (item) total += item.total;

    const items = await CartServer
      .updateCart(clientId, cartId, productId, productClientCode, productCode, total, punchoutUserId, undefined, isItemCache);
    addCartItemsIndexes(items);

    dispatch(setItems(items, cartId, user));
  } finally {
    if (doLoading) dispatch(removeLoading());
  }
};

export const cartAddItemCache = (productId, total = 1) => async (dispatch) => {
  try {
    total = parseInt(total, 10);
    dispatch(addLoading());
    const cartItems = StorageService.get('user_cart_cached') || [];
    const item = cartItems
      .find(i => i.id === productId);
    if (item) {
      item.quantity += total;
    } else {
      const itemInfo = await CartServer.getCartInformation(productId);

      if (itemInfo && itemInfo[0]) {
        itemInfo[0].quantity = total;
        cartItems.push(itemInfo[0]);
      }
    }

    addCartItemsIndexes(cartItems);
    dispatch(setItems(cartItems));
    StorageService.set('user_cart_cached', cartItems);
  } finally {
    const timeout = setTimeout(() => {
      dispatch(removeLoading());
      clearTimeout(timeout);
    }, 1000);
  }
};

export const cartUpdateItem = (productId, productClientCode, productCode = '', total = 1, xped = '') => async (dispatch, getState) => {
  const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
  const { cartId, user } = getState().cart;
  const punchoutUserId = getState().punchOut && getState().punchOut.userId;
  dispatch(addLoading());

  try {
    const items = await CartServer.updateCart(clientId,
      cartId, productId, productClientCode, productCode, total, punchoutUserId, xped);
    addCartItemsIndexes(items);
    dispatch(setItems(items, cartId, user));
  } finally {
    dispatch(removeLoading());
  }
};

export const getFreight = () => async (dispatch, getState) => {
  const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
  const { cartId } = getState().cart;
  const punchoutUserId = getState().punchOut && getState().punchOut.userId;
  dispatch(setFreight(null));
  dispatch(addLoading());

  try {
    const freight = await CartServer.getFreight(clientId, cartId, punchoutUserId);
    dispatch(setFreight(freight));
  } finally {
    dispatch(removeLoading());
  }
};

export const cartRemoveItem = (productId, productClientCode) => async (dispatch, getState) => {
  const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
  const { cartId, user } = getState().cart;
  const punchoutUserId = getState().punchOut && getState().punchOut.userId;

  dispatch(addLoading());

  try {
    const items = await CartServer.updateCart(clientId, cartId, productId, productClientCode, '', 0, punchoutUserId);
    if (cartId && items.length === 0) await dispatch(getCart());
    else dispatch(setItems(items, cartId, user));
  } finally {
    dispatch(removeLoading());
  }
};

export const cartRemoveItemCache = productId => async (dispatch) => {
  try {
    const cartItems = StorageService.get('user_cart_cached') || [];
    const itemIndex = cartItems
      .findIndex(i => i.id === productId);
    if (itemIndex !== -1) {
      cartItems.splice(itemIndex, 1);
    }

    addCartItemsIndexes(cartItems);
    dispatch(setItems(cartItems));
    StorageService.set('user_cart_cached', cartItems);
  } finally {}
};

export const mergeCart = () => async (dispatch, getState) => {
  try {
    const { cartPermission } = getState().contracts.selected;

    if (!cartPermission) {
      StorageService.set('user_cart_cached', []);
      return;
    }

    const cartItems = StorageService.get('user_cart_cached') || [];
    if (cartItems.length) {
      const promises = [];
      for (let index = 0; index < cartItems.length; index += 1) {
        const item = cartItems[index];
        promises.push(cartAddItem(item.id, undefined, undefined, item.quantity, true, true)(dispatch, getState));
      }

      await Promise.all(promises);
      StorageService.set('user_cart_cached', []);
    }
  } finally {}
};

export const cartCleanItems = () => async (dispatch, getState) => {
  const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
  const { cartId, user } = getState().cart;
  const punchoutUserId = getState().punchOut && getState().punchOut.userId;

  dispatch(addLoading());

  try {
    const items = await CartServer.resetCart(clientId, cartId, punchoutUserId);
    if (cartId) await dispatch(getCart());
    else dispatch(setItems(items, cartId, user));
  } finally {
    dispatch(removeLoading());
  }
};

export const cartSolicitation = () => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    const observation = getState().cart.observation ? getState().cart.observation : '';

    await CartServer.cartSolicitation(clientId, observation);
    dispatch(setItems([]));
    dispatch(setObservation(''));
  } finally {
    dispatch(removeLoading());
  }
};

export const cartsGetListToApprove = filterBy => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    const carts = await CartServer.getCartsToApprove(clientId, filterBy);
    return carts;
  } finally {
    dispatch(removeLoading());
  }
};

export const cartApproveRemove = cartId => async (dispatch, getState) => {
  const { cartId: selectedCartId } = getState().cart;
  const punchoutUserId = getState().punchOut && getState().punchOut.userId;

  dispatch(addLoading());
  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    await CartServer.removeCart(clientId, cartId, punchoutUserId);
    if (selectedCartId) dispatch(getCart());

    await dispatch(getUser());
  } finally {
    dispatch(removeLoading());
  }
};

export const cartApproveApprove = cartId => async (dispatch, getState) => {
  const { cartId: selectedCartId } = getState().cart;
  const punchoutUserId = getState().punchOut && getState().punchOut.userId;

  dispatch(addLoading());
  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    await CartServer.approveCart(clientId, cartId, punchoutUserId);
    if (selectedCartId) dispatch(getCart());

    await dispatch(getUser());
  } finally {
    dispatch(removeLoading());
  }
};

export const cartApproveSelect = (cart, cartId, user) => async (dispatch) => {
  dispatch(setItems(cart, cartId, user));
};

export const cartGetFile = type => async (dispatch, getState) => {
  dispatch(addLoading());
  const { cartId } = getState().cart;
  const punchoutUserId = getState().punchOut && getState().punchOut.userId;

  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    const company = getState().settings && getState().settings.companyTag;
    await CartServer.getCartFile(clientId, cartId, type, punchoutUserId, company);
  } finally {
    dispatch(removeLoading());
  }
};

export const cartsGetSolicitationsList = () => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    const carts = await CartServer.getCartSolicitations(clientId);
    return carts;
  } finally {
    dispatch(removeLoading());
  }
};

export const cartsCopy = purchaseId => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    const punchoutUserId = getState().punchOut && getState().punchOut.userId;

    const items = await CartServer.copyCart(clientId, purchaseId, punchoutUserId);
    dispatch(setItems(items));
  } finally {
    dispatch(removeLoading());
  }
};

export const copyCartCanceled = cartId => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    const items = await CartServer.copyCartCanceled(clientId, cartId);
    dispatch(setItems(items));
  } finally {
    dispatch(removeLoading());
  }
};

export const uploadProductsList = file => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    const { cartId } = getState().cart;
    const result = await CartServer.uploadProductsList(cartId, clientId, file);
    return result;
  } finally {
    dispatch(removeLoading());
    dispatch(getCart());
  }
};

export const getProductListModel = file => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    await CartServer.getXlsxProductListModel();
  } finally {
    dispatch(removeLoading());
  }
};

export const updateDefaultAddress = (cep, nameAbbr) => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    const userId = getState().contracts.selected && getState().contracts.selected.userId;
    await CartServer.updateAddress(clientId, userId, cep, nameAbbr);
    await dispatch(getUser());
  } finally {
    dispatch(removeLoading());
  }
};
