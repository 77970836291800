import React from 'react';
import { connect } from 'react-redux';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faTwitter, faLinkedinIn, faInstagram } from '@fortawesome/free-brands-svg-icons';

const FooterBottom = ({
  facebook, twitter, linkedin, copyright, instagram,
}) => (
  <div className="container">
    <div className="footer-bottom">
      <p className="footer-copyright">
        {copyright}
      </p>
      <div className="footer-social">
        {facebook && (
        <a
          href={facebook}
          className="social-icon"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faFacebookF} />
        </a>
        )}
        {twitter && (
        <a
          href={twitter}
          className="social-icon"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faTwitter} />
        </a>
        )}
        {instagram && (
        <a
          href={instagram}
          className="social-icon"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faInstagram} />
        </a>
        )}
        {linkedin && (
        <a
          href={linkedin}
          className="social-icon"
          rel="noopener noreferrer"
          target="_blank"
        >
          <FontAwesomeIcon icon={faLinkedinIn} />
        </a>
        )}
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  facebook: state.settings.socialFacebook,
  twitter: state.settings.socialTwitter,
  linkedin: state.settings.socialLinkedin,
  instagram: state.settings.socialInstagram,
  copyright: state.settings.copyright,
});

export default connect(mapStateToProps)(FooterBottom);
