import React from 'react';
import { Link } from 'react-router-dom';
import { Translate } from 'react-redux-i18n';

const HeaderTopRightPublic = () => (
  <div className="header-right">
    <div className="header-dropdown dropdown-expanded">
      <div className="header-menu">
        <ul>
          <li>
            <Link to="/login" className="login-link"><Translate value="entry" /></Link>
          </li>
        </ul>
      </div>
    </div>
  </div>
);

export default HeaderTopRightPublic;
