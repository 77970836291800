import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import { history } from '../../routers/AppRouter';

import ROUTES from '../../data/routes';
import { cartSolicitation, cartSetClientNumber, setObservation } from '../../app/store/actions/cart';
import { estimatePurchase } from '../../app/store/actions/purchases';

import InputSize from '../../data/inputs';
import PriceComponent from '../price/Price';
import CartUtil from '../../app/utils/cart';
import validator from '../../helpers/validators';

class CartResume extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      clientNumber: props.clientNumber,
    };
  }

  componentDidMount = () => {
    const valid = (
      this.props.cartItems
      && this.props.cartItems.length
      && this.props.cartItems[0].product
      && this.props.cartItems[0].product.observation
    );
    if (valid) {
      this.props.setObservation(this.props.cartItems[0].product.observation);
    }
  }

  setClientNumber = async (e) => {
    await this.setState({ clientNumber: e.target.value });
  };

  sentSolicitation = async () => {
    await this.props.cartSolicitation();
    await this.props.setView('solicitation');
  };

  estimateSolicitation = async () => {
    await this.props.estimatePurchase();
    await this.props.setView('estimate');
  };

  getObservationByCompany = (empName) => {
    switch (empName) {
      case 'nortel':
        return I18n.t('observationTextNortel');
      default:
        return I18n.t('observationText');
    }
  }

  getObservationMinMax = (empName) => {
    switch (empName) {
      case 'nortel':
        return InputSize.observationNortel;
      default:
        return InputSize.observation;
    }
  }

  goToCheckout = async () => {
    const { clientNumber } = this.state;
    if (clientNumber === '') await this.props.setClientNumber(null);
    else await this.props.setClientNumber(clientNumber);
    history.push(ROUTES.CHECKOUT);
  };

  renderButton = (company, observation, inputObs) => {
    const {
      cartItems, taxesCart, selectedContract, purchasePermission, minimumPrice, minimumProduct, buttonLabel,
      allowSellSpotItensWithoutStock, companyTag,
    } = this.props;
    const { limit } = selectedContract;
    const cartTotal = CartUtil.getCartTotal(cartItems, !taxesCart, undefined, undefined, companyTag);

    const hasItemWithoutStock = cartItems.filter(item => validator.isSpotProductAmountInvalid(item.total,
      item.product.stock, allowSellSpotItensWithoutStock, item.product.isSpot, item.product.price.isContract));


    if (hasItemWithoutStock && hasItemWithoutStock.length > 0) {
      return (
        <p className="color-red">
          <Translate value="invalidItemsCart" />
        </p>
      );
    }

    if ((limit && cartTotal > Number(limit)) || !purchasePermission) {
      return (
        <React.Fragment>
          <div>
            <h4
              className="observation-title"
              style={{ borderBottom: '1px solid #ccc' }}
            >
              <div className="checkout-resume">
                {this.getObservationByCompany(company)}
              </div>
            </h4>
            <textarea
              className="form-control border-radius-4 observation"
              rows={4}
              value={observation}
              onChange={text => this.props.setObservation(text.target.value)}
              minLength={inputObs.min}
              maxLength={inputObs.max}
            />
          </div>
          <button
            type="button"
            className="btn btn-block btn-sm btn-primary"
            onClick={() => this.sentSolicitation()}
          >
            <Translate value="buySolicitation" />
          </button>
        </React.Fragment>
      );
    } if (purchasePermission) {
      if (minimumPrice <= CartUtil.getCartTotal(cartItems, !taxesCart, undefined, undefined, companyTag) && !CartUtil.getCartMinimumProduct(cartItems, minimumProduct)) {
        return (
          <React.Fragment>
            <div>
              <h4
                className="observation-title"
                style={{ borderBottom: '1px solid #ccc' }}
              >
                <div className="checkout-resume">
                  {this.getObservationByCompany(company)}
                </div>
              </h4>
              <textarea
                className="form-control border-radius-4 observation"
                rows={4}
                value={observation}
                onChange={text => this.props.setObservation(text.target.value)}
                minLength={inputObs.min}
                maxLength={inputObs.max}
              />
            </div>
            <button
              type="button"
              className="btn btn-block btn-sm btn-primary"
              onClick={() => this.goToCheckout()}
            >
              {buttonLabel || <Translate value="finalizeBuy" />}
            </button>
          </React.Fragment>
        );
      }
      return (
        <div>
          {
            minimumPrice <= CartUtil.getCartTotal(cartItems, !taxesCart, undefined, undefined, companyTag) ? (
              <span style={{ display: 'none' }} />
            ) : (
              <p className="color-red">
                <Translate value="minimumOf" />
                {' '}
                {<PriceComponent value={minimumPrice} />}
                {' '}
                <Translate value="toBuy" />
              </p>
            )
          }
          {
          !CartUtil.getCartMinimumProduct(cartItems, minimumProduct) ? (
            <span style={{ display: 'none' }} />
          ) : (
            <p className="color-red">
              <Translate value="minimumOf" />
              {' '}
              {<PriceComponent value={minimumProduct} />}
              {' '}
                (
              <Translate value="product" />
                )
              {' '}
              <Translate value="toBuy" />
            </p>
          )
          }
        </div>
      );
    }
  };

  render() {
    const {
      subTotal = 0, discount = 0, hasItems, pricePermission, purchasePermission,
      clientCodePermission, purchaseEstimatePermission, observation, companyTag,
      st, taxName = 'ST', taxesCart, punchOut,
    } = this.props;

    const company = companyTag || '';
    const inputObs = this.getObservationMinMax(company);

    let valueTotal = subTotal - discount;
    if (taxesCart) valueTotal += st;

    const { clientNumber } = this.state;
    return (
      <div className="cart-summary">
        <h3><Translate value="summary" /></h3>

        {pricePermission && (
          <table className="table table-totals">
            <tbody>
              <tr>
                <td><Translate value="subTotal" /></td>
                <td>
                  <PriceComponent value={subTotal} />
                </td>
              </tr>
              <tr>
                <td><Translate value="discount" /></td>
                <td>
                  {<PriceComponent value={discount} /> || '-'}
                </td>
              </tr>
              {taxesCart && (
                <tr>
                  <td>{taxName}</td>
                  <td>
                    {<PriceComponent value={st} /> || 'R$ 0,00'}
                  </td>
                </tr>
              )}
            </tbody>
            <tfoot>
              <tr>
                <td><Translate value="total" /></td>
                <td>
                  <PriceComponent value={valueTotal} />
                </td>
              </tr>
            </tfoot>
          </table>
        )}

        {hasItems && (
          <React.Fragment>
            {!punchOut && clientCodePermission && purchasePermission && (
              <input
                type="text"
                maxLength={InputSize.maxDefault}
                className="form-control"
                placeholder={I18n.t('orderCodeOptional')}
                value={clientNumber || ''}
                onChange={this.setClientNumber}
              />
            )}

            {!punchOut && this.renderButton(company, observation, inputObs)}

            {!punchOut && purchaseEstimatePermission && (
              <button
                type="button"
                className="btn btn-block btn-sm"
                onClick={() => this.estimateSolicitation()}
              >
                <Translate value="estimateSolicitation" />
              </button>
            )}

            {punchOut
              && (
                <Link
                  to={ROUTES.PUNCH_OUT_CHECKOUT}
                  className="btn btn-block btn-sm btn-primary"
                >
                  <Translate value="buyPunchOut" />
                </Link>
              )}

          </React.Fragment>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  purchasePermission: state.contracts.selected && state.contracts.selected.purchasePermission,
  purchaseEstimatePermission: state.contracts.selected && state.contracts.selected.purchaseEstimatePermission,
  pricePermission: state.contracts.selected && state.contracts.selected.pricePermission,
  clientCodePermission: state.contracts.selected && state.contracts.selected.clientCodePermission,
  clientNumber: state.cart.clientNumber,
  minimumPrice: state.contracts.selected.clientDetails && state.contracts.selected.clientDetails.minimumPurchase > 0
    ? Number(state.contracts.selected.clientDetails.minimumPurchase)
    : Number(state.settings.minimumPurchase),
  minimumProduct: state.contracts.selected.clientDetails && state.contracts.selected.clientDetails.minimumProduct > 0
    ? Number(state.contracts.selected.clientDetails.minimumProduct)
    : Number(state.settings.minimumProduct),
  buttonLabel: state.contracts.selected && state.contracts.selected.clientDetails.buyButtonLabel,
  taxesCart: state.settings && !state.settings.taxsCartOff,
  taxName: state.settings && state.settings.taxName,
  punchOut: state.punchOut,
  contracts: state.contracts,
  cartItems: state.cart.items,
  observation: state.cart.observation,
  companyTag: state.settings.companyTag,
  selectedContract: state.contracts.selected,
  allowSellSpotItensWithoutStock: state.contracts.selected
    && state.contracts.selected.clientDetails.allowSellSpotItensWithoutStock,
});

const mapDispatchToProps = dispatch => ({
  cartSolicitation: () => dispatch(cartSolicitation()),
  setClientNumber: clientNumber => dispatch(cartSetClientNumber(clientNumber)),
  estimatePurchase: () => dispatch(estimatePurchase()),
  setObservation: text => dispatch(setObservation(text)),
});

export default connect(mapStateToProps, mapDispatchToProps)(CartResume);
