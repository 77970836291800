import React from 'react';
import { Translate } from 'react-redux-i18n';

const CanceledComponent = () => (
  <div className="order-detail-estimate">
    <ul className="checkout-progress-bar">
      <li className="active">
        <span><Translate value="confirmed" /></span>
        <i className="fas fa-clipboard-check" />
      </li>
      <li className="order-detail-estimate">
        <span><Translate value="canceled" /></span>
        <i className="fas fa-times-circle" />
      </li>
    </ul>
  </div>
);

export default CanceledComponent;
