import React from 'react';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import SolicitationForm from '../components/solicitation';

const SolicitationPage = () => (
  <main className="main">
    <Breadcrumb items={[{
      value: I18n.t('registerSolicitation'),
    }]}
    />
    <SolicitationForm />
  </main>
);
export default SolicitationPage;
