import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import CartUtil from '../../app/utils/cart';

import PriceComponent from '../price/Price';

const CheckoutResumePrice = (props) => {
  const {
    cartItems, taxName = 'ST', hasTax, taxType, taxFixedValue, companyTag,
  } = props;

  const valueItems = CartUtil.getCartTotal(cartItems, true, undefined, undefined, companyTag);

  const taxes = CartUtil.getCartTax(cartItems, taxType, {
    hasTax, taxFixedValue,
  }, companyTag);

  const total = valueItems + taxes;

  return (
    <tfoot>
      <tr>
        <td>
          <Translate value="subTotal" />
          <br />
          {taxes ? taxName : ''}
          <br />
          <strong><Translate value="total" /></strong>
        </td>
        <td>
          <PriceComponent value={valueItems} />
          <br />
          {taxes ? <PriceComponent value={taxes} /> : ''}
          <br />
          <strong><PriceComponent value={total} /></strong>
        </td>
      </tr>
    </tfoot>
  );
};

const mapStateToProps = state => ({
  taxName: state.settings && state.settings.taxName,
  taxType: state.settings && state.settings.taxType,
  companyTag: state.settings && state.settings.companyTag,
  taxFixedValue: Number(state.settings && state.settings.taxFixedValue, 0),
});

export default connect(mapStateToProps)(CheckoutResumePrice);
