
import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';

import { buscaCep } from '../../../app/store/actions/newRegister';
import StringHelper from '../../../helpers/string';

class SetAddressForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      cep: props.address.cep,
      ibge: props.address.ibge,
      street: props.address.street,
      number: props.address.number,
      complement: props.address.complement,
      district: props.address.district,
      city: props.address.city,
      state: props.address.state,
      country: props.address.country,
      validCep: !!props.address.cep,
      error: '',
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const {
      cep, ibge, street, number, complement,
      district, city, state,
      country, validCep,
    } = this.state;

    if (!validCep) {
      await this.setState({ error: I18n.t('invalidCep') });
    } else {
      const address = {
        cep,
        ibge,
        street,
        number,
        complement,
        district,
        city,
        state,
        country,
        validCep,
      };

      await this.props.setAddress(address);

      this.props.handleNext();
    }
  };

  setValue = async (attr, value) => {
    if (attr === 'cep') {
      const newCep = value;
      const cep = this.state;

      if (newCep !== cep) {
        await this.setState({
          error: '',
          [`${attr}`]: value,
        });

        const re = /^([\d]{2})\.*([\d]{3})-*([\d]{3})/;

        if (re.test(newCep)) {
          await this.searchViaCep(newCep);
        } else {
          await this.cleanAddress();
        }
      }
    } else {
      await this.setState({
        error: '',
        [`${attr}`]: value,
      });
    }
  }

  cleanAddress = async () => {
    await this.setState({
      street: '',
      ibge: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
      validCep: false,
    });
  }

  searchViaCep = async (cep) => {
    const address = await this.props.buscaCep(StringHelper.keepOnlyNumber(cep));
    if (address.erro) {
      await this.cleanAddress();
    } else {
      await this.setState({
        street: address.logradouro,
        ibge: address.ibge,
        number: '',
        complement: '',
        district: address.bairro,
        city: address.localidade,
        state: address.uf,
        country: 'Brasil',
        validCep: true,
      });
    }
  }

  getInput = (label, attr, value, disabled = true, required = true, minLength = 1, maxLength = 100) => (
    <input
      type="text"
      className="form-control"
      placeholder={label}
      minLength={minLength}
      maxLength={maxLength}
      value={value}
      onChange={e => this.setValue(attr, e.target.value)}
      required={required}
      disabled={disabled}
    />
  )

  render() {
    const {
      cep, street, number, complement,
      district, city, state, country, error,
    } = this.state;

    return (
      <form onSubmit={this.onSubmit} className="col-md-8">
        <InputMask
          mask="99.999-999"
          value={cep}
          onChange={e => this.setValue('cep', e.target.value)}
        >
          {inputProps => (
            <input
              {...inputProps}
              type="text"
              className="form-control"
              placeholder={I18n.t('cep')}
              required
            />
          )}
        </InputMask>
        {(this.props.companyTag === 'dimensional')
          ? this.getInput(I18n.t('street'), 'street', street, false, true, 1, 60)
          : this.getInput(I18n.t('street'), 'street', street, false)
        }
        {this.getInput(I18n.t('number'), 'number', number, false)}
        {this.getInput(I18n.t('complement'), 'complement', complement, false, false)}
        {this.getInput(I18n.t('district'), 'district', district, false)}
        {this.getInput(I18n.t('city'), 'city', city, true)}
        {this.getInput(I18n.t('state'), 'state', state, true)}
        {this.getInput(I18n.t('country'), 'country', country, true)}

        {error ? <p className="error-p">{error}</p> : ''}

        <div className="row justify-content-center margin-top-20 margin-bottom-20 form-footer">
          <button
            type="button"
            onClick={this.props.handleBack}
            className="backButton btn btn-light"
          >
            <Translate value="return" />
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            variant="contained"
          >
            <Translate value="next" />
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  companyTag: state.settings.companyTag,
});

const mapDispatchToProps = dispatch => ({
  buscaCep: cep => dispatch(buscaCep(cep)),
});

export default connect(mapStateToProps, mapDispatchToProps)(SetAddressForm);
