const initialState = null;

export default (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_PUNCH_OUT':
      return {
        ...state,
        ...action.punchOut,
      };
    default:
      return state;
  }
};
