/* eslint-disable no-extra-boolean-cast */
function get(key) {
  let authCache = localStorage.getItem(key);
  authCache = !!authCache ? authCache : false;
  if (authCache) return JSON.parse(authCache);
}

function set(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
}

function reset(key) {
  localStorage.removeItem(key);
}

export default {
  get,
  set,
  reset,
};
