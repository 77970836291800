import axios from 'axios';
import ReactGA from 'react-ga';
import moment from 'moment-timezone';
import jstz from 'jstimezonedetect';

import {
  loadTranslations,
  setLocale,
  syncTranslationWithStore,
} from 'react-redux-i18n';
import i18nDictionary from '../i18n';

import ErrorService from './services/error';
import AuthService from './services/auth';
import AnalyticsService from './services/analytics';

import PreferencesData from '../data/preferences';

import {
  saveAuthentication, getUser, cleanAuth, refreshToken,
} from './store/actions/auth';
import { loadSettings } from './store/actions/settings';
import { activitiesSetStarted } from './store/actions/activities';

import pageTitleService from './services/pageTitle';

import { history } from '../routers/AppRouter';
import { getCartCache } from './store/actions/cart';

export const axiosInstance = axios.create();

function initializeReactGA(store) {
  const settings = store && store.getState().settings;

  const { analytics, googleAnalytics } = settings;

  if (analytics) {
    window.analyticsEnable = true;
    AnalyticsService.initialize();
    AnalyticsService.sendPage('/');
  } else if (googleAnalytics) {
    ReactGA.initialize(googleAnalytics);
    window.gaEnable = true;
    ReactGA.pageview('/');
  }
}

function addAxiosinterceptor(store) {
  axiosInstance.interceptors.response.use(response => response, async (err) => {
    if (err.response && err.response.data) {
      const error = err.response.data;
      const isRefreshToken = err.response.config.method === 'post'
        && err.response.config.data.substring(0, 13) === 'refresh_token';

      // Authentication Error -> Set Refresh Token
      if (AuthService.isAuthenticated() && ErrorService.isInvalidToken(error) && !isRefreshToken) {
        const token = AuthService.get().refresh_token;
        try {
          await store.dispatch(refreshToken(token));
          const accessToken = AuthService.get().access_token;
          err.response.config.headers.Authorization = `Bearer ${accessToken}`;

          return await axiosInstance(err.response.config);
        } catch (refreshError) {
          store.dispatch(cleanAuth());
          return Promise.reject(refreshError);
        }
      }
    }
    return Promise.reject(err);
  });
}

async function authenticated(store) {
  if (AuthService.isAuthenticated()) {
    await store.dispatch(saveAuthentication(AuthService.get()));
    await store.dispatch(getUser());
  } else {
    await store.dispatch(getCartCache());
  }
}

export function removeInitLoader() {
  const launchLoader = document.getElementById('launch');
  launchLoader.classList.add('hide');
  window.prerenderReady = true;
}

export default async (store) => {
  addAxiosinterceptor(store);

  try {
    await store.dispatch(loadSettings());

    const tz = jstz.determine();
    const timezone = tz.name() || PreferencesData.timezone;
    let language = (store && store.getState().settings && store.getState().settings.language)
      || PreferencesData.language;
    if (!i18nDictionary[language]) ({ language } = PreferencesData);

    // initialize react-redux-i18n
    syncTranslationWithStore(store);
    store.dispatch(loadTranslations(i18nDictionary));
    store.dispatch(setLocale(language));

    moment.tz.setDefault(timezone);
    moment.locale(language);

    initializeReactGA(store);
    pageTitleService.setTitle(`${store && store.getState().settings && store.getState().settings.name} - Checkbuy`);

    if (history.location.pathname !== '/punchout') await authenticated(store);
    else await store.dispatch(cleanAuth());

    await store.dispatch(activitiesSetStarted(true));
  } catch (err) {
    // Redirect to error page
  } finally {
    if (history.location.pathname !== '/punchout') {
      removeInitLoader();
    }
  }
};
