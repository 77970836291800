import uuid from 'uuid/v4';

const keepOnlyNumber = value => value.replace(/\D/g, '');

const generateRandomToken = () => uuid();

const htmlEntities = str => String(str).replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;')
  .replace(/"/g, '&quot;');

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default {
  keepOnlyNumber,
  generateRandomToken,
  htmlEntities,
  capitalizeFirstLetter,
};
