import React from 'react';
import { connect } from 'react-redux';
import { I18n, Translate } from 'react-redux-i18n';

import ROUTES from '../../../data/routes';
import { history } from '../../../routers/AppRouter';

import {
  cartsGetListToApprove, cartApproveRemove, cartApproveSelect, copyCartCanceled, cartApproveApprove,
} from '../../../app/store/actions/cart';

import CartsApproveCart from './CartsApproveCart';
import purchaseData from '../../../data/purchases';

class CartsApprove extends React.Component {
  state = {
    carts: [],
    cart: null,
    showModal: false,
    showModalCanceled: false,
    filterBy: purchaseData.cartOrderTypes.AWAITING_APPROVAL.value,
    cartId: null,
  }

  async componentDidMount() {
    await this.loadCarts();
  }

  loadCarts = async (selectValue) => {
    const { filterBy } = this.state;
    if (selectValue) {
      await this.setState({
        filterBy: selectValue,
      });
    }
    const carts = await this.props.getCarts(selectValue || filterBy);
    await this.setState({ carts });
  };

  removeCart = async (cartId) => {
    await this.props.removeCart(cartId);
    await this.loadCarts(null);
  };

  approveCart = async (cartId) => {
    await this.props.approveCart(cartId);
    await this.loadCarts(null);
  };

  selectCart = async () => {
    const { cart } = this.state;
    if (cart) {
      await this.props.selectCart(cart.products, cart.cartId, cart.user);
      history.push(ROUTES.CART);
    }
  };

  setCart = async (cart) => {
    await this.setState({ cart, showModal: true });
  };

  setCopyCartCanceled = async (cartId) => {
    await this.setState({ cartId, showModalCanceled: true });
  };

  copyCartCanceled = async () => {
    const { cartId } = this.state;
    await this.setState({ cartId: null, showModalCanceled: false });
    await this.props.copyCartCanceled(cartId);
    history.push(ROUTES.CART);
  };

  render() {
    const { carts = [], showModal, showModalCanceled } = this.state;
    const { pricePermission } = this.props;

    return (
      <div className="col-lg-9 order-lg-last dashboard-content">
        <div className={!showModal && 'card-display-none'}>
          <button
            type="button"
            onClick={() => this.setState({ showModal: false, cart: null })}
            className="outer-modal-div"
          />
          <div className="modal-div-cart">

            <div>
              <h3 className="h3-modal-div">{I18n.t('wishToApproveCart')}</h3>
              <h4 className="h4-modal-div">{I18n.t('currentCartWillChange')}</h4>
              <div className="row body-modal-div">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => this.setState({ showModal: false, cart: null })}
                >
                  <Translate value="cancel" />
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.selectCart()}
                >
                  <Translate value="visualizeCart" />
                </button>
              </div>
            </div>

          </div>
        </div>

        <div className={!showModalCanceled && 'card-display-none'}>
          <button
            type="button"
            onClick={() => this.setState({ showModalCanceled: false, cartId: null })}
            className="outer-modal-div"
          />
          <div className="modal-div-cart">

            <div>
              <h3 className="h3-modal-div">{I18n.t('wishToApproveCart')}</h3>
              <h4 className="h4-modal-div">{I18n.t('currentCartWillChange')}</h4>
              <div className="row body-modal-div">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => this.setState({ showModalCanceled: false, cartId: null })}
                >
                  <Translate value="cancel" />
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.copyCartCanceled()}
                >
                  <Translate value="copyOrder" />
                </button>
              </div>
            </div>

          </div>
        </div>


        <h2 className="step-title"><Translate value="cartToApprove" /></h2>
        <div className="container-select">
          <span className="filter-text"><Translate value="filterBy" /></span>

          <select
            name="filterBy"
            className="form-control select-orders"
            defaultValue={purchaseData.cartOrderTypes.AWAITING_APPROVAL.value}
            value={this.state.filterBy}
            onChange={e => this.loadCarts(e.target.value)}
          >
            {Object.keys(purchaseData.cartOrderTypes).map(item => (
              <option value={purchaseData.cartOrderTypes[item].value}>
                {I18n.t(purchaseData.cartOrderTypes[item].label)}
              </option>
            ))}
          </select>
        </div>
        <div className="row margin-bottom-30">
          {carts.map(cart => (
            <CartsApproveCart
              key={cart.cartId}
              cart={cart}
              pricePermission={pricePermission}
              removeCart={this.removeCart}
              approveCart={this.approveCart}
              setCart={this.setCart}
              copyCartCanceled={this.setCopyCartCanceled}
            />
          ))}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getCarts: filterBy => dispatch(cartsGetListToApprove(filterBy)),
  removeCart: cartId => dispatch(cartApproveRemove(cartId)),
  approveCart: cartId => dispatch(cartApproveApprove(cartId)),
  selectCart: (cart, cartId, user) => dispatch(cartApproveSelect(cart, cartId, user)),
  copyCartCanceled: cartId => dispatch(copyCartCanceled(cartId)),
});

const mapStateToProps = state => ({
  cart: state,
  pricePermission: state.contracts.selected && state.contracts.selected.pricePermission,
});

export default connect(mapStateToProps, mapDispatchToProps)(CartsApprove);
