import React from 'react';
import PriceComponent from './Price';

const FinalPriceComponent = ({ price = {}, labelIVA }) => {
  let value;
  if (price.hasPrice) {
    if (price.isContract) value = price.contract;
    else ({ value } = price);
  }

  return <PriceComponent value={value} labelIVA={labelIVA}/>;
};

export default FinalPriceComponent;
