import registersRequests from '../../server/register';
import { addLoading, removeLoading } from './loading';

export const check = (email, cnpj, type) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const res = await registersRequests.check(email, cnpj, type);
    return res;
  } finally {
    dispatch(removeLoading());
  }
};

export const checkRut = (email, rut) => async (dispatch) => {
  dispatch(addLoading());
  try {
    const res = await registersRequests.checkRut(email, rut);
    return res;
  } finally {
    dispatch(removeLoading());
  }
};

export const register = data => async (dispatch) => {
  dispatch(addLoading());
  try {
    await registersRequests.createRegister(data);
  } finally {
    dispatch(removeLoading());
  }
};
