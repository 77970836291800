import ServerData from '../../data/server';

function getBaseUrl() {
  const { hostname } = window.location;
  const url = ServerData[hostname];
  if (url) return url;
  return ServerData.localhost;
}

export default {
  getBaseUrl,
};
