import React from 'react';
import { connect } from 'react-redux';
import {
  Carousel, CarouselItem, CarouselControl, CarouselIndicators,
} from 'reactstrap';

import HomeSliderBanner from './HomeSliderBanner';

export class HomeSlider extends React.Component {
  constructor(props) {
    super(props);
    this.state = { activeIndex: 0 };
  }

  onExiting = () => {
    this.animating = true;
  }

  onExited = () => {
    this.animating = false;
  }

  next = () => {
    const { banners } = this.props;
    const { activeIndex } = this.state;

    if (this.animating) return;
    const nextIndex = activeIndex === banners.length - 1 ? 0 : activeIndex + 1;
    this.setState({ activeIndex: nextIndex });
  }

  previous = () => {
    const { banners } = this.props;
    const { activeIndex } = this.state;

    if (this.animating) return;
    const nextIndex = activeIndex === 0 ? banners.length - 1 : activeIndex - 1;
    this.setState({ activeIndex: nextIndex });
  }

  goToIndex = (newIndex) => {
    if (this.animating) return;
    this.setState({ activeIndex: newIndex });
  }

  render() {
    const { banners = [] } = this.props;
    const { activeIndex } = this.state;

    return (
      <div className="home-slider">
        <Carousel
          activeIndex={activeIndex}
          next={this.next}
          previous={this.previous}
        >
          <CarouselIndicators
            items={banners.map(banner => ({ key: banner.id }))}
            activeIndex={activeIndex}
            onClickHandler={this.goToIndex}
          />
          {banners.map(banner => (
            <CarouselItem
              key={banner.id}
              onExiting={this.onExiting}
              onExited={this.onExited}
            >
              <HomeSliderBanner banner={banner} />
            </CarouselItem>
          ))}
          <CarouselControl direction="prev" directionText="Previous" onClickHandler={this.previous} />
          <CarouselControl direction="next" directionText="Next" onClickHandler={this.next} />
        </Carousel>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  banners: state.settings.home && state.settings.home.banners,
});

export default connect(mapStateToProps)(HomeSlider);
