function getPrice(amount, decimalCount = 2, decimal = ',', thousands = '.', usePrefix = true, language = 'pt-br') {
  if (amount >= 0) {
    try {
      decimalCount = Math.abs(decimalCount);
      // eslint-disable-next-line no-restricted-globals
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? '-' : '';

      const i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)), 0).toString();
      const j = i.length > 3 ? i.length % 3 : 0;

      const price = negativeSign
        + (j ? i.substr(0, j) + thousands : '')
        + i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${thousands}`)
        + (decimalCount
          ? decimal
          + Math.abs(amount - i)
            .toFixed(decimalCount)
            .slice(2)
          : '');

      if (usePrefix) {
        return (language === 'es-ch') ? `$ ${price}`.replace(',', '.') : `R$ ${price}`;
      }

      return price;
    } catch (e) {
      // eslint-disable-next-line no-empty
    }
  }
}

function fixPrice(price) {
  return parseFloat((Number(price)).toFixed(2));
}

function getPriceText(value, usePrefix = true, language = 'pt-br') {
  if (value !== undefined) {
    if (value) {
      return getPrice(value, undefined, undefined, undefined, usePrefix, language);
    }

    if (usePrefix) {
      return (language === 'es-ch') ? '$ 0.00' : 'R$ 0,00';
    }

    return (language === 'es-ch') ? '0.00' : '0,00';
  }
  return 'Sob consulta';
}

function getPriceNumberFormat(value) {
  if (value !== undefined) {
    return value.replace(/\./, '').replace(',', '.');
  }
  return '0.00';
}

export default {
  fixPrice,
  getPrice,
  getPriceText,
  getPriceNumberFormat,
};
