import React from 'react';
import ReactGA from 'react-ga';
import { Router } from 'react-router-dom';
import createHistory from 'history/createBrowserHistory';
import { connect } from 'react-redux';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { I18n } from 'react-redux-i18n';
import TagManager from 'react-gtm-module'

import Loading from '../components/elements/Loading';
import Footer from '../components/footer/Footer';

import AnalyticsService from '../app/services/analytics';

import { getSettingLong } from '../app/store/actions/settings';

import Content from './Content';

export const history = createHistory();

const RctSwal = withReactContent(Swal);

let tagManagerId;

history.listen((location) => {
    if (tagManagerId) {
      try {
        const tagManagerArgs = {
          dataLayer : {
            event: 'routeChanged',
            path: location.pathname,
            data: ""
          }
        }
        TagManager.dataLayer(tagManagerArgs);
      } catch (e) { }
    }

  if (window.analyticsEnable) AnalyticsService.sendPage(location.pathname);
  else if (window.gaEnable) ReactGA.pageview(location.pathname);
});

class AppRouter extends React.PureComponent {
  componentDidMount = async () => {
    let { companyTag, popupBody, popupBodyButtonLabel, popupBodyURL, popupEnabled } = this.props;
    if (companyTag === 'chile' && popupEnabled && popupEnabled.trim() === 'true') {
      popupBody = await this.props.getSettingLong('popupBody');
      if (location.pathname != '/') popupBody = '';
    }    
    if (popupBody && popupBody.trim() && popupEnabled && popupEnabled.trim() === 'true') this.showAlert(popupBody, popupBodyButtonLabel, popupBodyURL);
  }

  showHuggy = (huggy) => {
    const huggyParams = JSON.parse(huggy);

    const script = document.createElement('script');
    script.innerHTML = (
      `var $_Huggy = { 
        defaultCountry: '${huggyParams.default_country}', 
        widget_id: '${huggyParams.widget_id}', 
        company: '${huggyParams.company}' 
      }; 
      
      (function(i,s,o,g,r,a,m){ 
        i[r]={
          context:{id:'${huggyParams.context_id}'}
        };
        a=o;
        o=s.createElement(o); 
        o.async=1;
        o.src=g;
        m=s.getElementsByTagName(a)[0];
        m.parentNode.insertBefore(o,m);
      })
      
      (window,document,'script','https://js.huggy.chat/widget.min.js','pwz');`
    );

    document.body.appendChild(script);
  }

  showZendesk = (zendesk) => {
    const script = document.createElement('script');
    script.src = zendesk;
    script.id = 'ze-snippet';
    script.async = true;
    document.body.appendChild(script);
  }

  showAlert = (popupBody = '', popupBodyButtonLabel = 'OK', popupBodyURL = '') => {
    setTimeout(() => {
      let body = {
        html: popupBody,
        width: 'auto',
        padding: '30px 40px 10px 40px',        
      }
      if (popupBodyURL) {
        Object.assign( body, {
          confirmButtonText: popupBodyButtonLabel,
          showCancelButton: true,
          focusConfirm: false,
          cancelButtonText: `${I18n.t('close')}`,
        })
      }
      RctSwal.fire(body).then((result) => {
        if (result.isConfirmed) {
          if (popupBodyURL)
            window.open(popupBodyURL, '_blank');
        }
      });
    }, 1500);
  }

  render() {
    const { zendesk } = this.props;
    const { huggy } = this.props;

    tagManagerId = this.props.tagManagerId;

    return (
      <Router history={history}>
        <div className="page-wrapper">
          {zendesk && this.showZendesk(zendesk)}
          {huggy && this.showHuggy(huggy)}
          <Loading />
          <Content />
          <Footer />
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => ({
  zendesk: state.settings.zendeskUrlWeb,
  huggy: state.settings.huggyParams,
  popupBody: state.settings.popupBody,
  popupBodyButtonLabel: state.settings.popupBodyButtonLabel,
  popupBodyURL: state.settings.popupBodyURL,
  popupEnabled: state.settings.popupEnabled,
  companyTag: state.settings.companyTag,
  tagManagerId: state.settings.tagManagerId,
});

const mapDispatchToProps = dispatch => ({
  getSettingLong: (tag) => dispatch(getSettingLong(tag)),  
});

export default connect(mapStateToProps, mapDispatchToProps)(AppRouter);
