/* eslint-disable class-methods-use-this */
import React from 'react';
import { connect } from 'react-redux';

import ProductItem from '../productElements/ProductItem';
import ProductModalError from '../modals/ProductAmountErrorModal';

class ProductsList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
    };
  }


  render() {
    const { products = [] } = this.props;
    return (
      <div className="row row-sm">
        <ProductModalError
          showModal={this.state.showModal}
          handleCloseModal={
          () => this.setState({ showModal: false })}
        />
        {products.map(product => (
          <div key={`${product.id}-${product.clientCode}`} className="col-6 col-md-4 col-xl-3">
            <ProductItem
              product={product}
              openProductModal={
              () => this.setState({
                showModal: true,
              })}
            />
          </div>
        ))}
      </div>
    );
  }
}


const mapStateToProps = state => ({
  allowSellSpotItensWithoutStock: state.contracts.selected
    && state.contracts.selected.clientDetails.allowSellSpotItensWithoutStock,
  punchOut: state.punchOut,
});


export default connect(mapStateToProps, null)(ProductsList);
