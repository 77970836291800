
import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';

import SolicitationFormInput from '../elements/input';
import SolicitationFormInputMask from '../elements/inputMask';
import inputSize from '../../../data/inputs';

class StepClientForm extends React.Component {
  onSubmit = async (e) => {
    e.preventDefault();
    this.props.handleNext();
  };

  setValue = async (attr, value) => {
    const { client } = this.props;
    client[attr] = value;
    await this.props.setClient(client);
  }

  getInput = (label, attr, value, minLength, maxLength) => (
    <SolicitationFormInput
      label={label}
      value={value}
      minLength={minLength}
      maxLength={maxLength}
      onChange={e => this.setValue(attr, e.target.value)}
      disabled={this.props.locked}
    />
  )

  render() {
    const { client, locked } = this.props;
    const {
      name, cep, address, province, city, state, country,
    } = client;

    return (
      <form onSubmit={this.onSubmit}>

        {this.getInput(I18n.t('company'), 'name', name, 2, 100)}

        {locked ? this.getInput(I18n.t('cep'), 'cep', cep) : (
          <SolicitationFormInputMask
            label={I18n.t('cep')}
            value={cep}
            mask="99.999-999"
            onChange={e => this.setValue('cep', e.target.value)}
          />
        )}

        {this.getInput(I18n.t('address'), 'address', address, inputSize.address.min, inputSize.address.max)}
        {this.getInput(I18n.t('province'), 'province', province, inputSize.province.min, inputSize.province.max)}
        {this.getInput(I18n.t('city'), 'city', city, inputSize.city.min, inputSize.city.max)}
        {this.getInput(I18n.t('state'), 'state', state, inputSize.state.min, inputSize.state.max)}
        {this.getInput(I18n.t('country'), 'country', country, inputSize.country.min, inputSize.country.max)}


        <div className="row justify-content-center margin-top-20 margin-bottom-20 form-footer">
          <button
            type="button"
            onClick={this.props.handleBack}
            className="backButton btn btn-light"
          >
            <Translate value="return" />
          </button>

          <button
            type="submit"
            className="btn btn-primary"
            variant="contained"
          >
            <Translate value="next" />
          </button>
        </div>
      </form>
    );
  }
}

export default StepClientForm;
