import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { I18n, Translate } from 'react-redux-i18n';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';

import { history } from '../../../routers/AppRouter';

import ROUTES from '../../../data/routes';
import inputSize from '../../../data/inputs';

export class HeaderMiddleCenter extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      text: '',
      category: '',
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const { text, category } = this.state;
    const categories = category ? [Number(category)] : undefined;

    let url = `${ROUTES.PRODUCTS}?`;
    if (text) url += `text=${text}&`;
    if (categories) url += `categories=${category}&`;
    await this.setState({ text: '', category: '' });
    history.push(url);
  };

  render() {
    const { categories, opcoHeaderMessage, phone, email, company } = this.props;

    return (
      <div className="header-center">
        <div className="header-search">
          <Link to={ROUTES.PRODUCTS} className="search-toggle">
            <FontAwesomeIcon icon={faSearch} />
          </Link>
          <form onSubmit={this.onSubmit}>
            <div className="header-search-wrapper">
              <input
                type="search"
                className="form-control"
                maxLength={inputSize.maxDefault}
                placeholder={I18n.t('searchProduct')}
                value={this.state.text}
                onChange={e => this.setState({ text: e.target.value })}
                required
              />
              <div className="select-custom">
                <select
                  value={this.state.category}
                  onChange={e => this.setState({ category: e.target.value })}
                >
                  <option value="">{I18n.t('allCategories')}</option>
                  {categories.map(category => (
                    <React.Fragment key={category.id}>
                      <option value={category.id}>{category.name}</option>
                      {category.categories.map(subCategory => (
                        <option key={subCategory.id} value={subCategory.id}>
                          {'- '}
                          {subCategory.name}
                        </option>
                      ))}
                    </React.Fragment>
                  ))}
                </select>
              </div>
              <button className="btn" type="submit">
                <FontAwesomeIcon icon={faSearch} />
              </button>
            </div>
          </form>
        </div>
        {
          !!opcoHeaderMessage && (
            <div className="header-contact-formessage">
              <span><Translate value="contactUs" /></span>
              <a href="tel:#"><strong>{phone}</strong></a>
              {company === 'chile' && email ? <p><a href={"mailto:" + email}><strong>{email}</strong></a></p> : null}
            </div>
          )
        }
      </div>
    );
  }
}

const mapStateToProps = state => ({
  categories: state.settings.categories,
  opcoHeaderMessage: state.settings.opcoHeaderMessage,
  phone: state.settings.phone,
  email: state.settings.email,
  company: state.settings.companyTag,
});

export default connect(mapStateToProps)(HeaderMiddleCenter);
