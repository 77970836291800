import React from 'react';

const LandingFeaturesItem = ({ image, title, text }) => (
  <div className="item">
    <div className="image">
      <img src={`/images/landing/${image}.png`} alt={title} />
    </div>
    <div className="texts">
      <h4>{title}</h4>
      <p>{text}</p>
    </div>
  </div>
);


export default LandingFeaturesItem;
