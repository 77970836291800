import React from 'react';

import HeaderTopLeft from './HeaderTopLeft';
import HeaderTopRight from './HeaderTopRight';

const HeaderTop = () => (
  <div className="header-top">
    <div className="container">
      <HeaderTopLeft />
      <HeaderTopRight />
    </div>
  </div>
);

export default HeaderTop;
