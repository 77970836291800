import React from 'react';
import { I18n, Translate } from 'react-redux-i18n';

export default function CartAmountButton({
  item, amount, updateProductToCart, removeProductToCart, isAmountInvalid, multipleQuantity,
}) {
  if (isAmountInvalid) {
    return (
      <span title={(amount % multipleQuantity !== 0)
        ? `${I18n.t('sellMultipleNeeds') + multipleQuantity}` : I18n.t('invalidAmount')}
      >
        <button
          className="btn-move disabled"
          type="button"
          onClick={updateProductToCart}
          disabled
        >
          <Translate value="change" />
          {' '}
          (?)
        </button>

      </span>
    );
  }

  if (amount && !isAmountInvalid && parseInt(amount, 0) !== item.total) {
    return (
      <button
        className="btn-move"
        type="button"
        onClick={updateProductToCart}
      >
        <Translate value="change" />
      </button>
    );
  }

  return (
    <button
      className="btn-move"
      type="button"
      onClick={removeProductToCart}
    >
      <Translate value="remove" />
    </button>
  );
}
