import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import { getProductsPromotions } from '../../app/store/actions/products';

import ProductItem from '../productElements/ProductItem';
import Carousel from '../multipleCarousel/MultipleCarousel';

export class ProductsPromotions extends React.Component {
  async componentDidMount() {
    await this.props.getProducts();
  }

  async componentWillReceiveProps(nextProps) {
    if (nextProps.contractId !== this.props.contractId) {
      await this.props.getProducts(true);
    }
  }

  render() {
    const { products = [] } = this.props;

    if (products.length === 0) return null;

    return (
      <div className="featured-section">
        <div className="container">
          <h2 className="carousel-title"><Translate value="productsPromotion" /></h2>
          <div className="featured-products">
            {products.length > 0 && (
            <Carousel
              items={products.map(product => (
                <ProductItem product={product} />
              ))}
              arrows
            />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  products: state.products.promotions,
  contractId: state.contracts.selected && state.contracts.selected.client.id,
});

const mapDispatchToProps = dispatch => ({
  getProducts: force => dispatch(getProductsPromotions(force)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProductsPromotions);
