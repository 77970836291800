import RestService from '../services/rest';

async function recoverPassword(email) {
  return RestService.postRest('users/recover', { email });
}

async function createSolicitation(data) {
  return RestService.postRest('solicitations', data);
}

async function check(email, cnpj) {
  return RestService.getRest(`solicitations/check/?email=${email}&cnpj=${cnpj}`);
}

async function checkRut(email, rut) {
  return RestService.getRest(`solicitations/check/?email=${email}&rut=${rut}`);
}

export default {
  recoverPassword,
  createSolicitation,
  check,
  checkRut,
};
