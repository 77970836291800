import React from 'react';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import ForgotPasswordForm from '../components/forgotPassword/ForgotPasswordForm';

const ForgotPasswordPage = () => (
  <main className="main">
    <Breadcrumb items={[{
      value: I18n.t('forgotPassword2'),
    }]}
    />
    <ForgotPasswordForm />
  </main>
);

export default ForgotPasswordPage;
