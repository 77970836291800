
import React from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import isEmail from 'validator/lib/isEmail';
import { validate } from 'rut.js';

import ValidatorsHelper from '../../../helpers/validators';
import StringHelper from '../../../helpers/string';

import { checkRegister } from '../../../app/store/actions/newRegister';

import inputSize from '../../../data/inputs';

class StepBasicForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      emailError: '',
      cnpjError: '',
      error: '',
      type: 'cnpj',
      cnpj: '',
      email: '',
      rut: '',
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      cnpj, email, rut, type,
    } = this.state;

    if (rut.length > 0) {
      if (!isEmail(email)) {
        await this.setState({ emailError: I18n.t('invalidEmail') });
      } else if (!validate(rut)) {
        await this.setState({ cnpjError: I18n.t('invalidCNPJ') });
      } else if (rut && email) {
        try {
          const hasRegister = await this.props.check(email, rut, type);

          if (!hasRegister) {
            const basic = {
              cnpj,
              rut,
              email,
            };

            await this.props.setBasic(basic);

            this.props.handleNext();
          }
        } catch (err) {
          if (err.response && err.response.data && err.response.data.error) {
            if (err.response.data.error.code === 404 || err.response.data.error.code === 400) {
              await this.setState({ error: I18n.t('emailCnpjMessage4') });
            }
          }
        }
      }
    }

    if (!isEmail(email)) {
      await this.setState({ emailError: I18n.t('invalidEmail') });
    } else if (type === 'cnpj' && !ValidatorsHelper.validarCNPJ(cnpj)) {
      await this.setState({ cnpjError: I18n.t('invalidCNPJ') });
    } else if (type === 'cpf' && !ValidatorsHelper.validarCPF(cnpj)) {
      await this.setState({ cnpjError: I18n.t('invalidCPF') });
    } else if (cnpj && email) {
      try {
        const hasRegister = await this.props.check(email, cnpj, type);

        if (!hasRegister) {
          const basic = {
            cnpj,
            rut,
            email,
          };

          await this.props.setBasic(basic);

          this.props.handleNext();
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          if (err.response.data.error.code === 404 || err.response.data.error.code === 400) {
            await this.setState({ error: I18n.t('emailCnpjMessage4') });
          }
        }
      }
    }
  }

  setValue = async (attr, value) => {
    await this.setState({
      [`${attr}Error`]: '',
      [`${attr}`]: value,
    });
  }

  setType = async (type) => {
    await this.props.setType(type);
  }

  render() {
    const {
      emailError, cnpjError, error, type,
      email, cnpj, rut,
    } = this.state;
    const { documentType, opcoRegisterCPF } = this.props;

    return (
      <form onSubmit={this.onSubmit} className="col-md-8">
        <div>
          <input
            type="email"
            className="form-control"
            placeholder={I18n.t('insertEmail')}
            value={email}
            maxLength={inputSize.max}
            onChange={e => this.setValue('email', e.target.value)}
            required
          />
          {emailError ? <p className="error-p">{emailError}</p> : ''}

          <React.Fragment>
            {documentType === 'B'
              ? (
                <InputMask
                  mask="99.999.999-9"
                  value={rut}
                  onChange={e => this.setValue('rut', e.target.value)}
                >
                  {inputProps => (
                    <input
                      {...inputProps}
                      type="text"
                      className="form-control"
                      style={{ marginTop: '10px' }}
                      placeholder={I18n.t('insertCnpj')}
                      required
                    />
                  )}
                </InputMask>
              )
              : (
                <React.Fragment>
                  {opcoRegisterCPF
                    && (
                    <div className="btn-group btn-group-toggle" data-toggle="buttons">
                      <button
                        type="button"
                        onClick={() => {
                          this.setValue('cnpj', '');
                          this.setState({ type: 'cnpj' });
                          this.setType('cnpj');
                        }}
                        className={type === 'cnpj' ? 'btn btn-primary active' : 'btn btn-primary'}
                      >
                        <input type="radio" name="options" id="option2" autoComplete="off" />
                        {' '}
                        <Translate value="cnpj" />
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          this.setValue('cnpj', '');
                          this.setState({ type: 'cpf' });
                          this.setType('cpf');
                        }}
                        className={type === 'cpf' ? 'btn btn-primary active' : 'btn btn-primary'}
                      >
                        <input type="radio" name="options" id="option1" autoComplete="off" />
                        {' '}
                        <Translate value="cpf" />
                      </button>
                    </div>
                    )
                  }
                  {type === 'cpf'
                    ? (
                      <InputMask
                        mask="999.999.999-99"
                        value={cnpj}
                        onChange={e => this.setValue('cnpj', e.target.value)}
                      >
                        {inputProps => (
                          <input
                            {...inputProps}
                            type="text"
                            className="form-control"
                            style={{ marginTop: '10px' }}
                            placeholder={I18n.t('insertCpf')}
                            required
                          />
                        )}
                      </InputMask>
                    )
                    : (
                      <InputMask
                        mask="99.999.999/9999-99"
                        value={cnpj}
                        onChange={e => this.setValue('cnpj', e.target.value)}
                      >
                        {inputProps => (
                          <input
                            {...inputProps}
                            type="text"
                            className="form-control"
                            style={{ marginTop: '10px' }}
                            placeholder={I18n.t('insertCnpj')}
                            required
                          />
                        )}
                      </InputMask>
                    )
                  }
                </React.Fragment>
              )
            }
          </React.Fragment>
          {cnpjError ? <p className="error-p">{cnpjError}</p> : ''}
          {error ? <p className="error-p">{error}</p> : ''}
        </div>

        <div className="row justify-content-center margin-top-20 margin-bottom-20 form-footer">
          <button
            type="submit"
            className="btn btn-primary"
            variant="contained"
          >
            <Translate value="next" />
          </button>
        </div>
      </form>
    );
  }
}

const mapStateToProps = state => ({
  documentType: state.settings.documentType,
  opcoRegisterCPF: !!((state.settings.opcoRegisterCPF && state.settings.opcoRegisterCPF === 'true')),
});

const mapDispatchToProps = dispatch => ({
  check: (email, cnpj, type) => dispatch(checkRegister(email, StringHelper.keepOnlyNumber(cnpj), type)),
});

export default connect(mapStateToProps, mapDispatchToProps)(StepBasicForm);
