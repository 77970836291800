/* eslint-disable no-console */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';

import { recoverPassword } from '../../app/store/actions/solicitation';

import ROUTES from '../../data/routes';
import inputSize from '../../data/inputs';

export class ForgotPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      email: '',
      message: '',
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();
    if (this.state.email) {
      const res = await this.props.recoverPassword(this.state.email);
      if (res !== undefined && res.response.status === 429) {
        this.setState({ message: I18n.t('requestLimitExceeded') });
      } else {
        this.setState({ message: I18n.t('recoverPasswordResponseMsg') });
      }
    }
  };


  render() {
    const { message } = this.state;
    return (
      <div className="login container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="heading">
              <h2 className="title"><Translate value="recoverPassword" /></h2>
              <p><Translate value="forgotPassword" /></p>
            </div>
            <form onSubmit={this.onSubmit}>
              <input
                type="login"
                className="form-control"
                maxLength={inputSize.max}
                placeholder="Email"
                value={this.state.email}
                onChange={e => this.setState({ email: e.target.value })}
                required
              />
              <div className="form-footer">
                <button type="submit" className="btn btn-primary"><Translate value="recoverPassword" /></button>
                <Link to={ROUTES.LOGIN} className="forget-pass"><Translate value="entry" /></Link>
              </div>
              <p>{message}</p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  recoverPassword: email => dispatch(recoverPassword(email)),
});

export default connect(undefined, mapDispatchToProps)(ForgotPasswordForm);
