import moment from 'moment-timezone';

import RestService from '../services/rest';

async function getProductsReportsPdf(clientId, type = 'pdf') {
  const reportsUrl = `reports/products/pdf?client=${clientId}&type=contract`;
  const response = await RestService.getAuthenticated(reportsUrl, {
    'Content-Type': 'application/json',
    Accept: 'application/pdf',
  }, {
    responseType: 'arraybuffer',
  });
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  const filePath = `produtos-contrato-${moment().toISOString()}.${type}`;
  link.setAttribute('download', filePath);
  document.body.appendChild(link);
  link.click();
}

export default {
  getProductsReportsPdf,
};
