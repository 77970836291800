import React from 'react';

export class ProductDetailsPictures extends React.Component {
  state = {
    index: 0,
  }

  handleIndex = async (index) => {
    await this.setState({ index });
  }

  render() {
    const { index } = this.state;
    const { pictures = [], product = {} } = this.props;

    return (
      <div className="product-single-gallery">
        <div className="product-slider-container product-item">
          <div className="product-single-carousel owl-carousel owl-theme">
            <div className="product-item">
              {pictures.length > 0 && (
              <img
                className="product-single-image"
                src={pictures[index] || '/images/product-image-placeholder.png'}
                alt={product.name}
              />
              )}
            </div>
          </div>

          <span className="prod-full-screen">
            <i className="icon-plus" />
          </span>
        </div>
        <div className="prod-thumbnail row owl-dots" id="carousel-custom-dots">
          {pictures.map((picture, i) => (
            <div
              key={picture}
              className="col-3 owl-dot"
              onClick={() => this.handleIndex(i)}
              onKeyDown={() => this.handleIndex(i)}
              role="presentation"
            >
              <img src={picture || '/images/product-image-placeholder.png'} alt={picture} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default ProductDetailsPictures;
