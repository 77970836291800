import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';

import { selectContract } from '../../app/store/actions/contracts';

import { history } from '../../routers/AppRouter';

import ROUTES from '../../data/routes';
import inputSize from '../../data/inputs';

class ContractsList extends React.Component {
  state = {
    search: '',
  }

  selectContract = async (index) => {
    await this.props.selectContract(index);
    history.push(ROUTES.HOME);
  }

  render() {
    const { contracts = [] } = this.props;
    const { search } = this.state;

    const filteredContracts = contracts.filter((contract) => {
      if (!search) return true;
      let data = '';
      if (contract.clientDetails.nickname) data += ` ${contract.clientDetails.nickname.toLowerCase()}`;
      Object.keys(contract.client).forEach((key) => {
        if (contract.client[key].toLowerCase) data += ` ${contract.client[key].toLowerCase()}`;
      });
      return data.search(search) !== -1;
    });

    return (
      <div>
        <div className="row justify-content-center margin-top-25 margin-bottom-25">
          <div className="col-md-5">
            <input
              type="text"
              className="form-control"
              placeholder={I18n.t('search')}
              maxLength={inputSize.maxDefault}
              value={search}
              onChange={e => this.setState({ search: e.target.value.toLowerCase() })}
            />
          </div>
        </div>

        <div className="row justify-content-center margin-bottom-25 margin-top-25 margin-left-5p">
          {filteredContracts.map(contract => (
            <div
              key={contract.index}
              className="card-contracts col-md-4"
              onClick={() => this.selectContract(contract.index)}
              onKeyDown={() => this.selectContract(contract.index)}
              role="presentation"
            >
              <div className="row">
                <div className="col-9">
                  <h3>{contract.client.name}</h3>
                  <h4>{contract.clientDetails.nickname}</h4>
                  <p className="margin-bottom-0">{contract.client.id}</p>
                  { `${contract.client.city}, ${contract.client.state}` }
                </div>
              </div>
            </div>
          ))}
          {!filteredContracts.length && (
          <p>
            <Translate value="contractNotFound" />
          </p>
          )}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  selectContract: index => dispatch(selectContract(index)),
});

export default connect(undefined, mapDispatchToProps)(ContractsList);
