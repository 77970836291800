
import React, { Component } from 'react';
import Dropzone from 'react-dropzone';
import { Translate, I18n } from 'react-redux-i18n';

class UploadFilesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      file: '',
      name: '',
      show: false,
      success: '',
      message: '',
    };
  }

  sendFile = async (file) => {
    try {
      await this.props.sendFile(file);
      await this.setState({ message: '', success: I18n.t('successFile') });
    } catch (err) {      
      await this.setState(
        { message: I18n.t('invalidFile'), success: '' },
      );
    }
  }

  uploadedFile(files) {
    this.setState({
      name: files[0].name, show: true, file: files[0], success: '', message: '',
    });
  }

  render() {
    const {
      name, show, file, success, message,
    } = this.state;
    const { itemsErrors } = this.props;

    return (
      <React.Fragment>
        <div>
          {this.props.goBack && 
          <button
            type="button"
            onClick={() => this.props.goBack()}
            className="btn btn-success margin-right-15"
          >
            <Translate value="return" />
          </button>
          }
          {this.props.modelDownload &&
          <button
            type="button"
            onClick={() => this.props.modelDownload()}
            className="btn btn-success promotion-btn-warning"
          >
            <Translate value="importModel" />
          </button>}
        </div>
        <Dropzone
          onDrop={acceptedFiles => this.uploadedFile(acceptedFiles)}
        >
          {({ getRootProps, getInputProps }) => (
            <section className="main-drop-down-files">
              <div className="drag-and-drop-container">
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <h5><Translate value="dragAndDrop" /></h5>
                </div>

                {show && (
                  <div>
                    <br />
                    <p className="text-align-center">{name}</p>
                    <br />
                    <button
                      type="button"
                      onClick={() => this.sendFile(file)}
                      className="btn btn-info upload-file-btn"
                    >
                      <Translate value="send" />
                    </button>
                  </div>
                )}

                {success && !itemsErrors.props.errors.length > 0 && 
                <span><br /><p className="color-green text-align-center margin-top-10">{success}</p>
                    <br />
                </span>
                }
                {message && <p className="color-red text-align-center margin-top-10">{message}</p>}

                {itemsErrors}

              </div>
            </section>
          )}
        </Dropzone>
      </React.Fragment>
    );
  }
}

export default UploadFilesComponent;
