import React from 'react';
import { connect } from 'react-redux';

import FooterMiddleLeft from './FooterMiddleLeft';
import FooterMiddleRight from './FooterMiddleRight';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {Translate,  I18n } from 'react-redux-i18n';

import { faHeadset  } from '@fortawesome/free-solid-svg-icons';


const FooterMiddle = ({ name, support, titleSuporte }) => (  
  <div className="footer-middle">        
    <div className="container">
      <div className="footer-ribbon">
        {name}
      </div>
      {support && (
        

        <div className="footer-support">          
            <a
              href={support}
              rel="noopener noreferrer"
              target="_blank"
              title={titleSuporte}
            >
              <span className={"footer-support-container"}> 
                
                <Translate value="supportChannel" />

                {' '}

                <img
                  className="footer-support-image"
                  src={ '/images/icon-soporte-white.svg'}
                  alt={titleSuporte}
                /> 
                
                
              </span>           
            </a>           
        </div>
      )}
      <div className="row">
        <div className="col-lg-4">
          <FooterMiddleLeft />
        </div>
        <div className="col-lg-8">
          <FooterMiddleRight />
        </div>             
      </div>
    </div>    
    
  </div>
);

const mapStateToProps = state => ({
  name: state.settings.name,
  support: state.settings.companyTag == 'chile' && state.settings.supportUrl,
  titleSuporte: I18n.t('clickToSupport')
});

export default connect(mapStateToProps)(FooterMiddle);
