import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import { cartSortItems } from '../../app/store/actions/cart';

export class CartOrderBy extends React.Component {
  changeOrderBy = (value) => {
    this.props.sortItems(value);
  }

  render() {
    const { cartOrderBy } = this.props;

    return (
      <select
        name="orderby"
        className="form-control"
        onChange={e => this.changeOrderBy(e.target.value)}
        value={cartOrderBy}
      >
        <option value="date-asc">{I18n.t('cartOrderByDateAsc')}</option>
        <option value="date-desc">{I18n.t('cartOrderByDateDesc')}</option>
        <option value="name-asc">{I18n.t('cartOrderByNameAsc')}</option>
        <option value="name-desc">{I18n.t('cartOrderByNameDesc')}</option>
      </select>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  sortItems: value => dispatch(cartSortItems(value)),
});

export default connect(undefined, mapDispatchToProps)(CartOrderBy);
