import moment from 'moment-timezone';
import RestService from '../services/rest';
import PURCHASES from '../../data/purchases';

async function getOrderList({ clientId, limit, skip }, filterBy) {
  let url = `purchases?client=${clientId}&limit=${limit}&skip=${skip}`;
  if (filterBy !== PURCHASES.ordersAndEstimatesTypes.SHOW_ALL) url += `&type=${filterBy}`;
  return RestService.getAuthenticated(url);
}

async function getOrderDetails(id, clientId) {
  return RestService.getAuthenticated(`panel/purchases/details?clientId=${clientId}&id=${id}`);
}

async function getConsumption(clientId) {
  const response = await RestService.getAuthenticated(`consumption?clientId=${clientId}&type=csv`, {
    responseType: 'blob',
  });
  const url = window.URL.createObjectURL(new Blob([response]));
  const link = document.createElement('a');
  link.href = url;
  const filePath = `relatorio-de-consumo-${moment().toISOString()}.csv`;
  link.setAttribute('download', filePath);
  document.body.appendChild(link);
  link.click();
}

export default {
  getOrderList,
  getOrderDetails,
  getConsumption,
};
