import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import { getProductsCrossSelling, getProductsPricing } from '../../app/store/actions/products';

import ProductItem from '../productElements/ProductItem';
import Carousel from '../multipleCarousel/MultipleCarousel';

export class ProductsCrossSelling extends React.Component {
  state = {
    products: [],
  }

  async componentDidMount() {
    const { productId } = this.props;

    let products = await this.props.getProducts(productId);
    await this.setState({ products });

    products = await this.props.getPricing(products);
    await this.setState({ products });
  }

  render() {
    const { products = [] } = this.state;

    if (products.length === 0) return null;

    return (
      <div className="featured-section">
        <div className="container">
          <h2 className="carousel-title"><Translate value="crossSelling" /></h2>
          <div className="featured-products">
            {products.length > 0 && (
            <Carousel
              items={products.map(product => (
                <ProductItem product={product} />
              ))}
              arrows
            />
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getProducts: productId => dispatch(getProductsCrossSelling(productId)),
  getPricing: products => dispatch(getProductsPricing(products)),
});

export default connect(undefined, mapDispatchToProps)(ProductsCrossSelling);
