import React from 'react';

import CanceledComponent from './canceled/Canceled';
import EstimateComponent from './estimate/Estimate';
import TrackingComponent from './tracking/Tracking';
import WholeStepComponent from './wholeStep/WholeStep';

import PUCHASES from '../../../../data/purchases';

const WizzardDetails = ({
  order, track, easyConfirm, easyConfirmVersion, settings = {},
}) => {
  if (order.status === PUCHASES.orderStatus.CANCELED) return (<CanceledComponent />);
  if (order.paymentType === PUCHASES.paymentType.ESTIMATE) return (<EstimateComponent order={order} />);
  if (track) return (<TrackingComponent order={order.tracking} />);
  return (
    <WholeStepComponent
      settings={settings}
      easyConfirm={easyConfirm}
      order={order}
      easyConfirmVersion={easyConfirmVersion}
    />
  );
};

export default WizzardDetails;
