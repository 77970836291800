import React from 'react';

import HeaderTop from './top/HeaderTop';
import HeaderMiddle from './middle/HeaderMiddle';
import HeaderBottom from './bottom/HeaderBottom';

const Header = () => (
  <header className="header">
    <HeaderTop />
    <HeaderMiddle />
    <HeaderBottom />
  </header>
);

export default Header;
