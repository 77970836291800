import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { history } from '../../routers/AppRouter';

import ROUTES from '../../data/routes';
import { changePasswordRecovery } from '../../app/store/actions/auth';

import inputSize from '../../data/inputs';

export class ChangePasswordForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      message: '',
      password: '',
      confirmPassword: '',
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();
    const { password, confirmPassword } = this.state;

    if (password.length < inputSize.minPassword || password.length > inputSize.maxPassword) {
      this.setState({ message: I18n.t('passwordErrorMessage') });
    } else if (password !== confirmPassword) {
      this.setState({ message: I18n.t('passwordDifferents') });
    } else {
      try {
        await this.props.changePassword(password);
        if (this.props.contractsTotal > 1) history.push(ROUTES.CONTRACTS);
        else history.push(ROUTES.HOME);
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error && err.response.data.error.code === 408) {
          this.setState({ message: I18n.t('changePasswordIsNotPossible') });
        } else {
          this.setState({ message: I18n.t('errorTryLater') });
        }
      }
    }
  };


  render() {
    const { message, password, confirmPassword } = this.state;
    return (
      <div className="login container">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="heading">
              <h2 className="title"><Translate value="passwordChange" /></h2>
              <p><Translate value="insertNewPassword" /></p>
            </div>
            <form onSubmit={this.onSubmit}>
              <input
                type="password"
                className="form-control"
                maxLength={inputSize.maxPassword}
                placeholder={I18n.t('password')}
                value={password}
                onChange={e => this.setState({ password: e.target.value, message: '' })}
                required
              />
              <input
                type="password"
                className="form-control"
                maxLength={inputSize.maxPassword}
                placeholder={I18n.t('confirmPassword')}
                value={confirmPassword}
                onChange={e => this.setState({ confirmPassword: e.target.value, message: '' })}
                required
              />
              <div className="form-footer">
                <button type="submit" className="btn btn-primary"><Translate value="passwordChange" /></button>
              </div>
              <p className="color-red">{message}</p>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  changePassword: password => dispatch(changePasswordRecovery(password)),
});

const mapStateToProps = state => ({
  contractsTotal: state.contracts.list.length,
});

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);
