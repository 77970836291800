/* eslint-disable max-len */
/* eslint-disable no-undef */
/* eslint-disable no-empty */
import purchasesRequests from '../../server/purchases';
import { addLoading, removeLoading } from './loading';
import { getCart, setItems, setObservation } from './cart';
import { getUser } from './auth';

import CartUtil from '../../utils/cart';
import { loadScript, removeScript } from '../../../components/utils';
import Pagseguro from '../../services/pagseguro';

let scriptName = '';

export const setLastTicket = ( ticket ) => ({
  type: 'PURCHASE_LAST_TICKET',
  ticket,
});

export const initPagSeguroProcess = async () => {
  try {
    const ret = await purchasesRequests.initPagSeguroProcess();

    if (ret && ret.scriptUrl) {
      loadScript(ret.scriptUrl);
      scriptName = ret.scriptUrl;
    }
  } catch (e) { throw e; }
};

export const endPagSeguroProcess = async () => {
  try {
    if (scriptName) removeScript(scriptName);
  } catch (e) { }
};

export const getPagSeguroSessionId = async (dispatch) => {
  try {
    if (dispatch) dispatch(addLoading());
    const ret = await purchasesRequests.getPagSeguroSessionId();

    if (dispatch) dispatch(removeLoading());
    if (ret && ret.sessionId) return ret.sessionId;
  } catch (e) { }

  if (dispatch) dispatch(removeLoading());

  return false;
};

export const finalizePurchase = (location, type, card, document, saveCard = false, paymentInfos = {}) => async (dispatch, getState) => {
  const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
  const company = getState().settings.companyTag;
  const { pricePermission } = getState().contracts.selected;
  const { cartId, clientNumber, observation } = getState().cart;
  const { taxType, taxFixedValue } = getState().settings;
  const { freight, hasTax } = location;

  delete location.freight;
  delete location.hasTax;

  if (clientId) {
    dispatch(addLoading());
    let price;
    if (pricePermission) {
      price = CartUtil.getCartTotal(getState().cart.items, false, taxType, { hasTax, taxFixedValue }, company);
    }

    const punchoutUserId = getState().punchOut && getState().punchOut.userId;

    try {
      if (paymentInfos.paymentService === 'pagseguro') {
        const sessionId = await getPagSeguroSessionId();
        if (!sessionId) throw Error('PS SESSIONID ERROR');
        if (!Pagseguro.setSessionId(sessionId)) throw Error('PS SET SESSIONID ERROR');

        const senderHash = await Pagseguro.getSenderHash();
        if (!senderHash) throw Error('PS SET HASH ERROR');

        paymentInfos.senderHash = senderHash;

        if (type === 'credit' && card.number) {
          paymentInfos.CreditCard = {
            CardNumber: card.number,
            Holder: card.name,
            ExpirationDate: card.expirationDate,
            Brand: card.brand,
          };
          const brandName = await Pagseguro.getBrand(card);
          if (!brandName) throw Error('PS BRAND ERROR');

          const tokenCard = await Pagseguro.createCardToken(card, brandName);
          if (!tokenCard) throw Error('PS CARD TOKEN ERROR');
          paymentInfos.cardToken = tokenCard;
          paymentInfos.CreditCard.CardToken = tokenCard;
        }
      }

      const ticket = await purchasesRequests
        .finalize(clientId, cartId, price, type, card, document, location,
          clientNumber, freight, punchoutUserId, observation, saveCard, paymentInfos);

      if (!cartId) { 
        dispatch(setItems([])); 
        dispatch(setObservation('')); 
      } else await dispatch(getCart());

      if (cartId) await dispatch(getUser());

      return ticket;
    } catch (err) {
      throw err;
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const estimatePurchase = () => async (dispatch, getState) => {
  const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
  const company = getState().settings.companyTag;
  const { cartId } = getState().cart;
  const punchoutUserId = getState().punchOut && getState().punchOut.userId;
  const { pricePermission } = getState().contracts.selected;
  const { taxType } = getState().settings;

  if (clientId) {
    dispatch(addLoading());

    let price;
    if (pricePermission) {
      price = CartUtil.getCartTotal(getState().cart.items, false, taxType, undefined, company);
    }

    try {
      const ticket = await purchasesRequests.estimate(clientId, cartId, punchoutUserId, price);
      dispatch(setLastTicket(ticket));
      if (!cartId) {
        dispatch(setItems([]));
        dispatch(setObservation(''));
      } else await dispatch(getCart());
      return ticket;
    } finally {
      dispatch(removeLoading());
    }
  }
};

export const purchaseGetFile = (id) => async (dispatch, getState) => {
  dispatch(addLoading());
  try {
    const clientId = getState().contracts.selected && getState().contracts.selected.client.id;
    await purchasesRequests.getPurchaseFile(clientId, id);
  } finally {
    dispatch(removeLoading());
  }
};
