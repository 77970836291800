import React from 'react';
import { connect } from 'react-redux';
import { I18n } from 'react-redux-i18n';

import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import Cart from '../components/cart/Cart';

const CartPage = ({
  cartItems, cartOrderBy, cartUser, cartLoaded, companyTag,
}) => {
  const breadcrumb = [{
    value: I18n.t('cart'),
  }];

  if (cartUser) {
    breadcrumb.push({
      value: `${cartUser.name} (${cartUser.email})`,
    });
  }

  return (
    <main className="main">
      <Breadcrumb items={breadcrumb} />
      <Cart cartItems={cartItems} cartOrderBy={cartOrderBy} cartLoaded={cartLoaded} companyTag={companyTag} />
    </main>
  );
};

const mapStateToProps = state => ({
  cartItems: state.cart.items,
  cartOrderBy: state.cart.orderBy,
  cartUser: state.cart.user,
  cartLoaded: state.cart.loaded,
  companyTag: state.settings.companyTag,
});

export default connect(mapStateToProps)(CartPage);
