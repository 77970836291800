/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const RctSwal = withReactContent(Swal);

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { /* faStar, */ faCartPlus } from '@fortawesome/free-solid-svg-icons';

import { cartAddItem, cartAddItemCache } from '../../app/store/actions/cart';

import ProductsUtil from '../../app/utils/products';
import PriceComponent from '../price/Price';

import FinalPriceComponent from '../price/FinalPrice';
import ROUTES from '../../data/routes';
import { history } from '../../routers/AppRouter';

export class ProductItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  async componentDidMount() {
    this.updateInterval = setTimeout(this.updatePromotionCoutdown, 1000);
  }

  componentWillUnmount() {
    clearTimeout(this.updateInterval);
  }

  updatePromotionCoutdown = async () => {
    const { product = {} } = this.props;
    if (product.price && !product.price.isContract && product.price.finalizedAt) {
      const promotionCoutdown = ProductsUtil.getPromotionCountdown(product.price);
      await this.setState({ promotionCoutdown });
      this.updateInterval = setTimeout(this.updatePromotionCoutdown, 1000);
    }
  }

  addProductToCart = async () => {
    const { isAuthenticated } = this.props;
    const { product = {} } = this.props;

    const isProductAmountInvalid = (!product.stock || product.stock === 0)
      && !this.props.allowSellSpotItensWithoutStock
      && product.isSpot
      && !product.price.isContract;
    if (!isAuthenticated) {
      await this.props.addItemCache(product.id);
      this.showPopup();
    } else if (isProductAmountInvalid && !this.props.punchOut) {
      this.props.openProductModal();
    } else if (product.id) {
      await this.props.addItem(product.id, product.clientCode, product.code);
      this.showPopup();
    }
  }

  showPopup = () => {    
    if (this.props.popupOnAddCart) {
      RctSwal.fire({
        html: `<h3>${I18n.t('AddedToCart')}</h3>`,
        width: 'auto',
        padding: '30px 40px 10px 40px',        
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText:
          `<i class="fa fa-shopping-cart"></i>  ${I18n.t('goToCart')}`,
        cancelButtonText: `${I18n.t('continueShopping')}`,
      }).then((result) => {
        if (result.isConfirmed) {
          history.push(ROUTES.CART);
        }
      })
    }
  }

  render() {
    const { promotionCoutdown } = this.state;
    const {
      isAuthenticated, product = {}, cartPermission, pricePermission, stockPermission, allowSellSpotItensWithoutStock,
    } = this.props;

    return (
      <div className="product">
        <figure className="product-image-container">
          <Link to={ProductsUtil.getUrl(product)} className="product-image">
            <div className="product-image-container-ratio">
              <img src={product.image || '/images/product-image-placeholder.png'} alt={product.name} />
            </div>
          </Link>
          <Link to={ProductsUtil.getUrl(product)} className="btn-quickview">
            <Translate value="visualizeDetail" />
          </Link>
          {product.price
            && product.price.isContract
            && product.price.type === 'contract'
            && <span className="product-label"><Translate value="contract" /></span>}

          {product.price
            && product.price.isContract
            && product.price.type === 'agreement'
            && <span className="product-label label-agreement"><Translate value="agreement" /></span>}

          {product.price && !product.price.isContract && product.price.discount && (
            <span className="product-label label-hot">
              -
              {ProductsUtil.getDiscount(product.price)}
            </span>
          )}
        </figure>
        <div className="product-details">
          {/* <div className="ratings-container">
            <div className="product-ratings">
              {[1, 2, 3, 4, 5].map(star => (
                <FontAwesomeIcon
                  key={star}
                  icon={faStar}
                  style={{ color: product.stars >= star ? '#706f6c' : '#b3b3b3' }}
                />
              ))}
              <span className="ratings" style={{ width: `${product.stars * 20}%` }} />
            </div>
          </div> */}
          {promotionCoutdown && (
            <p className="product-coutdown">
              {promotionCoutdown}
            </p>
          )}
          <h4>{product.brand && product.brand.description}</h4>
          <h2 className="product-title">
            <Link to={ProductsUtil.getUrl(product)}>{product.name}</Link>
          </h2>

          {stockPermission && (
            <div className="product-stock-container">
              <span>
                <Translate value="availability" />
              </span>
              <br />
              <span className={`${!product.stock ? ' product-text-label-sale' : ' product-text-label-stock'}`}>
                {ProductsUtil.getStockAvailable(product.stock)}
              </span>
            </div>
          )}

          <div className="price-box">
            {product.price && !product.price.isContract && product.price.oldValue && (
              <span className="old-price">
                <PriceComponent value={product.price.oldValue} labelIVA={true}/>
              </span>
            )}
            <span className="product-price">
              {pricePermission && <FinalPriceComponent price={product.price} labelIVA={true} />}
            </span>
          </div>

          {product.isSpot && !product.price.isContract && !allowSellSpotItensWithoutStock && product.stock === 0 && (
            <span className="product-label label-sale"><Translate value="withoutStock" /></span>
          )}

          {!product.stock && product.price && product.price.contactUs ? (
            <span className="product-label label-time"><Translate value="consultUs" /></span>
          ) : (
            <React.Fragment>
              {((isAuthenticated && cartPermission && product.price && product.price.hasPrice) || (!isAuthenticated)) && (
              <div className="product-action">
                <button
                  type="button"
                  className="paction add-cart"
                  title="Add to Cart"
                  onClick={this.addProductToCart}
                >
                  <FontAwesomeIcon icon={faCartPlus} />
                  <span><Translate value="add" /></span>
                </button>
              </div>
              )}
            </React.Fragment>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  isAuthenticated: state.auth.authenticated,
  cartPermission: state.contracts.selected && state.contracts.selected.cartPermission,
  pricePermission: state.contracts.selected && state.contracts.selected.pricePermission,
  stockPermission: !state.contracts.selected || (state.contracts.selected && state.contracts.selected.stockPermission),
  allowSellSpotItensWithoutStock: state.contracts.selected
    && state.contracts.selected.clientDetails.allowSellSpotItensWithoutStock,
  punchOut: state.punchOut,
  popupOnAddCart: state.settings && state.settings.popupOnAddCart,
});

const mapDispatchToProps = dispatch => ({
  addItem: (productId, productClientCode, productCode) => dispatch(cartAddItem(productId, productClientCode, productCode)),
  addItemCache: productId => dispatch(cartAddItemCache(productId)),
});


export default connect(mapStateToProps, mapDispatchToProps)(ProductItem);
