import React from 'react';
import { Translate, I18n } from 'react-redux-i18n';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';

import inputSize from '../../../data/inputs';
import PasswordStrengthIndicator from '../../changePassword/PasswordStrengthIndicator';

class StepUserForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      firstName: '',
      lastName: '',
      role: '',
      sector: '',
      phone: '',
      password: '',
      confirmPassword: '',
      passwordFocused: false,
      passwordValidity: {
        minChar: null,
        number: null,
        upperCase: null,
        specialChar: null,
      },
      error: '',
      message: '',
    };
  }

  onSubmit = async (e) => {
    e.preventDefault();

    const {
      firstName, lastName, phone, role,
      sector, password, confirmPassword, passwordValidity,
    } = this.state;

    if (
      passwordValidity.minChar
      && passwordValidity.number
      && passwordValidity.upperCase
      && passwordValidity.specialChar
      && password === confirmPassword
    ) {
      this.setValue('password', password);
      this.setValue('passwordFocused', false);
      const user = {
        firstName,
        lastName,
        phone,
        role,
        sector,
        password,
        confirmPassword,
      };

      await this.props.setUser(user);
      await this.props.handleFinalize();
    }
    if (!passwordValidity.minChar) this.setState({ error: `${I18n.t('passworderrorMessage')}: ${I18n.t('passwordErrorMinChar')}` });
    if (!passwordValidity.number) this.setState({ error: `${I18n.t('passworderrorMessage')}: ${I18n.t('passwordErrorNumber')}` });
    if (!passwordValidity.upperCase) this.setState({ error: `${I18n.t('passworderrorMessage')}: ${I18n.t('passwordErrorUpperCase')}` });
    if (!passwordValidity.specialChar) this.setState({ error: `${I18n.t('passworderrorMessage')}: ${I18n.t('passwordErrorSpecialChar')}` });
    if (password !== confirmPassword) this.setState({ error: `${I18n.t('passworderrorMessage')}: ${I18n.t('passwordErrorNotEqual')}` });
  };

  setValue = async (attr, value) => {
    await this.setState({
      error: '',
      [`${attr}`]: value,
    });
  }

  getInput = (label, attr, value, disabled = true, required = true, minLength = 1, maxLength = 100) => (
    <input
      type="text"
      className="form-control"
      placeholder={label}
      minLength={minLength}
      maxLength={maxLength}
      value={value}
      onChange={e => this.setValue(attr, e.target.value)}
      required={required}
      disabled={disabled}
    />
  );

  getSelect = (items, label, selected, onChange) => (
    <div className="select-custom">
      <select name="orderby" className="form-control" defaultValue={selected} onChange={onChange} required>
        {selected === '' && <option value="" disabled>{label}</option>}
        {items.map(item => (
          <option key={item.id} value={item.id}>{item.name}</option>
        ))}
      </select>
    </div>
  );

  onChangePassword = (password) => {
    const characterLengthRegx = /^.{9,}$/;
    const isNumberRegx = /[0-9]/;
    const specialCharacterRegx = /[!@#$%^&*]/;
    const upperCaseCharacterRegx = /[A-Z]/;
    this.setState({ password });
    this.setState({
      passwordValidity: {
        minChar: characterLengthRegx.test(password),
        number: isNumberRegx.test(password),
        upperCase: upperCaseCharacterRegx.test(password),
        specialChar: specialCharacterRegx.test(password),
      },
    });
  }

  render() {
    const {
      error, message, firstName, phone,
      lastName, sector, role, password, confirmPassword, passwordFocused, passwordValidity,
    } = this.state;

    const {
      roles, sectors, type,
    } = this.props;

    return (
      <div className="row">
        <form onSubmit={this.onSubmit} className="col-md-6">
          {this.getInput(I18n.t('firstName'), 'firstName', firstName, false, true, 2, 100)}
          {this.getInput(I18n.t('lastName'), 'lastName', lastName, false, true, 2, 100)}

          {type === 'cnpj'
            ? this.getSelect(roles, I18n.t('job'), role, e => this.setValue('role', e.target.value))
            : ''}
          {type === 'cnpj'
            ? this.getSelect(sectors, I18n.t('sector'), sector, e => this.setValue('sector', e.target.value))
            : ''}

          <InputMask
            mask="(99) 9999-99999"
            value={phone}
            disabled={false}
            onChange={e => this.setValue('phone', e.target.value)}
          >
            {inputProps => (
              <input
                {...inputProps}
                type="text"
                className="form-control"
                placeholder={I18n.t('phone2')}
                required
              />
            )}
          </InputMask>

          <input
            type="password"
            className="form-control"
            placeholder={I18n.t('passwordPlaceholder')}
            value={password}
            onFocus={() => this.setValue('passwordFocused', true)}
            onChange={e => this.onChangePassword(e.target.value)}
            maxLength={inputSize.maxPassword}
            minLength={inputSize.minPassword}
            required
          />

          <input
            type="password"
            className="form-control"
            placeholder={I18n.t('passwordPlaceholder2')}
            value={confirmPassword}
            onFocus={() => this.setValue('passwordFocused', true)}
            onChange={e => this.setValue('confirmPassword', e.target.value)}
            maxLength={inputSize.maxPassword}
            minLength={inputSize.minPassword}
            required
          />

          {error ? <p className="error-p">{error}</p> : ''}

          <div className="row justify-content-center margin-top-20 margin-bottom-20 form-footer">
            <button
              type="button"
              onClick={this.props.handleBack}
              className="backButton btn btn-light"
            >
              <Translate value="return" />
            </button>

            <button
              type="submit"
              className="btn btn-primary"
              variant="contained"
            >
              <Translate value="finalize" />
            </button>
          </div>

          {message ? <p className="error-p">{message}</p> : ''}
        </form>
        <div className="col-md-6">
          {
            passwordFocused && (
              <PasswordStrengthIndicator
                className="form-control"
                validity={passwordValidity}
                passwordEqual={password === confirmPassword && confirmPassword}
              />
            )
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  companyTag: state.settings.companyTag,
});

export default connect(mapStateToProps)(StepUserForm);
