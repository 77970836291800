import React from 'react';
import { connect } from 'react-redux';
import { Translate } from 'react-redux-i18n';

import MySolicitationsCart from './MySolicitationsCart';

import { cartsGetSolicitationsList } from '../../../app/store/actions/cart';

class MySolicitations extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carts: [],
    };
  }

  async componentDidMount() {
    await this.loadCarts();
  }

  loadCarts = async () => {
    const carts = await this.props.getCarts();
    await this.setState({ carts });
  };

  render() {
    const { carts } = this.state;
    const { pricePermission } = this.props;

    return (
      <div className="col-lg-9 order-lg-last dashboard-content">

        <h2 className="step-title"><Translate value="mySolicitations" /></h2>

        <div className="row margin-bottom-30">
          {carts.map(cart => (
            <MySolicitationsCart
              key={cart.cartId}
              cart={cart}
              pricePermission={pricePermission}
            />
          ))}
        </div>
      </div>
    );
  }
}


const mapDispatchToProps = dispatch => ({
  getCarts: () => dispatch(cartsGetSolicitationsList()),
});

const mapStateToProps = state => ({
  pricePermission: state.contracts.selected && state.contracts.selected.pricePermission,
});

export default connect(mapStateToProps, mapDispatchToProps)(MySolicitations);
