export default {
  orderStatus: {
    CONFIRMED: 'confirmed',
    PAID: 'paid',
    PROCESSED: 'processed',
    PICKING: 'picking',
    DELIVERED: 'delivered',
    CANCELED: 'canceled',
    INVOICES_ISSUED: 'invoces_issued',
    INVOICES_ISSUED_PARTIALLY: 'invoces_issued_partially',
    ON_CARRIAGE: 'on_carriage',
    FINISHED: 'finished',
  },
  paymentType: {
    CREDIT: 'credit',
    DEBIT: 'debit',
    BANK_SLIP: 'bank_slip',
    BILLING: 'billing',
    ESTIMATE: 'estimate',
  },
  paymentStatus: {
    NOT_FINISHED: 'not_finished',
    AUTHORIZED: 'authorized',
    NOT_AUTHORIZED: 'not_authorized',
    INDEFINITE: 'indefinite',
    CANCELED: 'canceled',
    CAPTURED: 'captured',
  },
  paymentStatusPagSeguro: {
    AWAIT_PAYMENT: 'Aguardando Pagamento',
    IN_REVIEW: 'Em Análise',
    PAID: 'Paga',
    AVAILABLE: 'Disponível',
    IN_DISPUTE: 'Em Disputa',
    RETURNED: 'Devolvida',
    CANCELED: 'Cancelada',
    DEBITED: 'Debitado',
    TEMPORARY_RETENTION: 'Retenção temporária',
    INDEFINITE: 'indefinite',
  },
  status: {
    PROCESSED: 'processed',
    CONFIRMED: 'confirmed',
    PAID: 'paid',
    FINISHED: 'finished',
  },
  cartOrderTypes: {
    AWAITING_APPROVAL: {
      label: 'awaitingApproval',
      value: 'awaiting_approval',
    },
    DISAPPROVED: {
      label: 'disapproved',
      value: 'disapproved',
    },
  },
  ordersAndEstimatesTypes: {
    SHOW_ALL: {
      label: 'all',
      value: 'show_all',
    },
    AWAITING_NF: {
      label: 'awaitingNf',
      value: 'awaiting_nf',
    },
    TOTAL_BILLED: {
      label: 'totalBilled',
      value: 'total_billed',
    },
    PARCIAL_BILLED: {
      label: 'parcialBilled',
      value: 'parcial_billed',
    },

  },
};
