import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { Translate, I18n } from 'react-redux-i18n';

import PanelAction from '../../../app/store/actions/panel';
import { cartsCopy } from '../../../app/store/actions/cart';
import PUCHASES from '../../../data/purchases';
import ROUTES from '../../../data/routes';
import { history } from '../../../routers/AppRouter';
import PriceComponent from '../../price/Price';

class OrderList extends React.Component {
  state = {
    limit: 10,
    skip: 0,
    showModal: false,
    orderId: '',
    filterBy: PUCHASES.ordersAndEstimatesTypes.SHOW_ALL.value,
  }

  componentDidMount() {
    const { clientId } = this.props;
    const { limit, skip, filterBy } = this.state;
    this.props.getOrderList({ clientId, limit, skip }, filterBy);
  }

  loadOrders = async (e) => {
    const { clientId } = this.props;
    const { limit, skip } = this.state;
    this.props.getOrderList({ clientId, limit, skip }, e);
    await this.setState({
      filterBy: e,
    });
  }

  orderStatus = (status) => {
    if (status === PUCHASES.orderStatus.CONFIRMED) return I18n.t('done');
    if (status === PUCHASES.orderStatus.PAID) return I18n.t('paid');
    if (status === PUCHASES.orderStatus.PROCESSED) return I18n.t('paid');
    if (status === PUCHASES.orderStatus.DELIVERED) return I18n.t('details2');
    if (status === PUCHASES.orderStatus.INVOICES_ISSUED) return I18n.t('paid');
    if (status === PUCHASES.orderStatus.INVOICES_ISSUED_PARTIALLY) return I18n.t('paid');
    if (status === PUCHASES.orderStatus.CANCELED) return I18n.t('canceled');
  }

  paymentType = (payment) => {
    if (payment === PUCHASES.paymentType.CREDIT) return I18n.t('credit');
    if (payment === PUCHASES.paymentType.DEBIT) return I18n.t('debit');
    if (payment === PUCHASES.paymentType.BANK_SLIP) return I18n.t('bankSlip');
    if (payment === PUCHASES.paymentType.BILLING) return I18n.t('billing');
    if (payment === PUCHASES.paymentType.ESTIMATE) return I18n.t('estimate');
  }

  paymentStatus = (payment) => {
    if (payment === PUCHASES.paymentStatus.INDEFINITE) return I18n.t('undefined');
    if (payment === PUCHASES.paymentStatus.AUTHORIZED) return I18n.t('authorized');
    if (payment === PUCHASES.paymentStatus.NOT_AUTHORIZED) return I18n.t('notAuthorized');
    if (payment === PUCHASES.paymentStatus.CANCELED) return I18n.t('canceled');
    if (payment === PUCHASES.paymentStatus.CAPTURED) return I18n.t('captured');
  }

  copyCart = async () => {
    const { orderId } = this.state;
    this.setState({ showModal: false });
    await this.props.copyCart(orderId);
    history.push(ROUTES.CART);
  }


  isInvocesIssued = (order) => {
    if (order.paymentType === PUCHASES.paymentType.ESTIMATE) return false;
    if ([PUCHASES.orderStatus.INVOICES_ISSUED, PUCHASES.orderStatus.FINISHED,
      PUCHASES.orderStatus.DELIVERED].indexOf(order.status) !== -1) return I18n.t('invoiceIssued');
    if (order.status === PUCHASES.orderStatus.INVOICES_ISSUED_PARTIALLY) return I18n.t('invoiceIssuedPartially');
    return false;
  }

  getList = orderList => orderList.map((order) => {
    const date = moment(order.createdAt);
    const day = date.format('DD');
    const month = date.format('MMM');

    let status = this.orderStatus(order && order.status);
    const isInvocesIssued = this.isInvocesIssued(order);
    if (isInvocesIssued) status = `${status} (${isInvocesIssued})`;

    return (
      <div className="relative" key={order.id}>
        {this.props.cartUsersPermission
          && (
            <button
              type="button"
              className="btn btn-primary list-btn-solicitation"
              onClick={() => this.setState({ orderId: order.id, showModal: true })}
            >
              <Translate value="copyOrder" />
            </button>
          )
        }
        <Link
          to={{ pathname: ROUTES.ORDER_DETAILS, search: `?query=${order.id}`, state: 'hellow' }}
          className="product-image"
        >
          <button
            type="button"
            className="card-payment"
          >
            <div className="row">
              <div className="card-date">
                <p>{day}</p>
                <hr />
                <p style={{ fontSize: '20px' }}>{month}</p>
              </div>
              <div className="address-sub-card align-left">
                <p>
                  <Translate value="code2" />
                  {' '}
                  <strong>{order.id}</strong>
                  {' '}
                  {order.sphereNumber ? I18n.t('codeSphere2') : ''}
                  {' '}
                  <strong>{order.sphereNumber || ''}</strong>
                  {order.savedId ? I18n.t('documentStatus2') : ''}
                  {' '}
                  <strong>{order.savedId || ''}</strong>
                </p>
                <p>
                  <Translate value="paymentType2" />
                  {' '}
                  <strong>{this.paymentType(order.paymentType)}</strong>
                  {(order && order.paymentType !== PUCHASES.paymentType.ESTIMATE) && (
                    <React.Fragment>
                      {' '}
                      {order.status ? I18n.t('documentStatus2') : ''}
                      {' '}
                      <strong>{status || ''}</strong>
                    </React.Fragment>
                  )}

                </p>
                {order.paymentType !== PUCHASES.paymentType.ESTIMATE && (
                <p>
                  <Translate value="price2" />
                  {' '}
                  <strong>
                    <PriceComponent value={Number(order.value)} />
                  </strong>
                </p>
                )}
                <p>
                  <Translate value="buyDate2" />
                  {' '}
                  <strong>{date.format('LLL')}</strong>
                </p>
                {order['solicitationUser.email'] && (
                  <p>
                    <Translate value="orderSolicitedBy" />
                    {' '}
                    <strong>{order['solicitationUser.email']}</strong>
                  </p>
                )}
                {order['user.email'] && (
                  <p>
                    <Translate value="orderMadeBy" />
                    {' '}
                    <strong>{order['user.email']}</strong>
                  </p>
                )}
              </div>
            </div>
          </button>
        </Link>
      </div>
    );
  })

  render() {
    const { orderList } = this.props;
    const { showModal } = this.state;
    return (
      <div className="col-lg-9 order-lg-last dashboard-content">
        <div className={!showModal && 'card-display-none'}>
          <button
            type="button"
            onClick={() => this.setState({ showModal: false, orderId: '' })}
            className="outer-modal-div"
          />
          <div className="modal-div-cart">

            <div>
              <h3 className="h3-modal-div">{I18n.t('wishToCopyOrder')}</h3>
              <h4 className="h4-modal-div">{I18n.t('currentCartWillChange')}</h4>
              <div className="row body-modal-div">
                <button
                  type="button"
                  className="btn btn-outline-secondary"
                  onClick={() => this.setState({ showModal: false, orderId: '' })}
                >
                  <Translate value="cancel" />
                </button>
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={() => this.copyCart()}
                >
                  <Translate value="copyOrder" />
                </button>
              </div>
            </div>

          </div>
        </div>

        <button
          type="button"
          className="btn btn-secondary justify-content-end btn-list-download"
          onClick={() => this.props.getConsumption()}
        >
          <Translate value="generateSummary" />
        </button>
        <h2 className="step-title">
          <Translate value="documentList" />
        </h2>
        <div className="container-select">
          <span className="filter-text"><Translate value="filterBy" /></span>

          <select
            name="filterBy"
            className="form-control select-orders"
            defaultValue={PUCHASES.ordersAndEstimatesTypes.TOTAL_BILLED.value}
            value={this.state.filterBy}
            onChange={e => this.loadOrders(e.target.value)}
          >
            {Object.keys(PUCHASES.ordersAndEstimatesTypes).map(item => (
              <option value={PUCHASES.ordersAndEstimatesTypes[item].value}>
                {I18n.t(PUCHASES.ordersAndEstimatesTypes[item].label)}
              </option>
            ))}
          </select>
        </div>
        {orderList && orderList.length > 0
          ? this.getList(orderList)
          : (
            <p>
              <Translate value="noneDocument" />
            </p>
          )}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  clientId: state.contracts.selected.client.id,
  orderList: state.panel.orderList,
  cartUsersPermission: state.contracts.selected && state.contracts.selected.cartUsersPermission,
});

const mapDispatchToProps = dispatch => ({
  getOrderList: (data, filterBy) => dispatch(PanelAction.getOrderList(data, filterBy)),
  getConsumption: () => dispatch(PanelAction.getConsumption()),
  copyCart: id => dispatch(cartsCopy(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(OrderList);
