import React from 'react';
import { Translate } from 'react-redux-i18n';

import PurchasesUtils from '../../../../../app/utils/purchases';

const TrackingComponent = ({ order }) => {
  const step = PurchasesUtils.getStatus(order.status);

  return (
    <div className="order-detail-all">
      <ul className="checkout-progress-bar tracking-checkout">
        <li className={step >= 0 ? 'active' : ''}>
          <span><Translate value="beingPrepared" /></span>
          <i className="fas fa-box-open" />
        </li>

        <li className={step >= 1 ? 'active' : ''}>
          <span><Translate value="readToSend" /></span>
          <i className="fas fa-people-carry" />
        </li>

        <li className={step >= 2 ? 'active' : ''}>
          <span><Translate value="inTransport" /></span>
          <i className="fas fa-truck" />
        </li>
        <li className={step >= 3 ? 'active' : ''}>
          <span><Translate value="delivered" /></span>
          <i className="fas fa-check-square" />
        </li>
      </ul>
    </div>
  );
};

export default TrackingComponent;
