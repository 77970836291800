/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';

import { cartRemoveItem, cartRemoveItemCache } from '../../../../app/store/actions/cart';

import ProductsUtil from '../../../../app/utils/products';

import FinalPriceComponent from '../../../price/FinalPrice';

const HeaderMiddleRightCartItem = ({ item, pricePermission, removeItem, removeItemCache, isAuthenticated,
}) => (
  <div className="product">
    <div className="product-details">
      <h4 className="product-title">
        <Link to={ProductsUtil.getUrl(item.product)}>{item.product.name}</Link>
      </h4>

      <span className="cart-product-info">
        <span className="cart-product-qty">{item.total}</span>
        {'x '}
        {pricePermission && <FinalPriceComponent price={item.product.price} /> }
      </span>
    </div>

    <figure className="product-image-container">
      <Link to={ProductsUtil.getUrl(item.product)} className="product-image">
        <img src={item.product.image || '/images/product-image-placeholder.png'} alt="product" />
      </Link>
      <button
        type="button"
        className="btn-remove"
        onClick={() => (isAuthenticated ? removeItem(item.product.id, item.product.clientCode) : removeItemCache(item.product.id))}
      >
        <FontAwesomeIcon icon={faTimes} />
      </button>
    </figure>
  </div>
);

const mapDispatchToProps = dispatch => ({
  removeItem: (productId, productClientCode) => dispatch(cartRemoveItem(productId, productClientCode)),
  removeItemCache: productId => dispatch(cartRemoveItemCache(productId)),
});

export default connect(undefined, mapDispatchToProps)(HeaderMiddleRightCartItem);
