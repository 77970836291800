/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment-timezone';
import { Translate, I18n } from 'react-redux-i18n';

import WizzardDetails from './wizzard/WizzardDetails';
import OrderAction from '../../../app/store/actions/panel';
import PUCHASES from '../../../data/purchases';

import CartUtil from '../../../app/utils/cart';
import PriceComponent from '../../price/Price';
import DeliveryTypeCard from '../../delivery/DeliveryTypeCard';

class OrderDetails extends React.Component {
  state = {
  };

  componentDidMount() {
    const query = this.props.props.location.search;
    const id = query.replace('?query=', '');
    const { clientId } = this.props;
    this.props.getOrderDetails(id, clientId);
  }

  orderStatus = (status) => {
    if (status === PUCHASES.orderStatus.CONFIRMED) return I18n.t('done');
    if (status === PUCHASES.orderStatus.PAID) return I18n.t('paid');
    if (status === PUCHASES.orderStatus.PROCESSED) return I18n.t('paid');
    if (status === PUCHASES.orderStatus.DELIVERED) return I18n.t('delivered');
    if (status === PUCHASES.orderStatus.CANCELED) return I18n.t('canceled');
  };

  paymentType = (payment) => {
    if (payment === PUCHASES.paymentType.CREDIT) return I18n.t('credit');
    if (payment === PUCHASES.paymentType.DEBIT) return I18n.t('debit');
    if (payment === PUCHASES.paymentType.BANK_SLIP) return I18n.t('bankSlip');
    if (payment === PUCHASES.paymentType.BILLING) return I18n.t('billing');
    if (payment === PUCHASES.paymentType.ESTIMATE) return I18n.t('estimate');
  };

  paymentStatus = (payment) => {
    if (payment === PUCHASES.paymentStatus.INDEFINITE) return I18n.t('undefined');
    if (payment === PUCHASES.paymentStatus.AUTHORIZED) return I18n.t('authorized');
    if (payment === PUCHASES.paymentStatus.NOT_AUTHORIZED) return I18n.t('notAuthorized');
    if (payment === PUCHASES.paymentStatus.CANCELED) return I18n.t('canceled');
    if (payment === PUCHASES.paymentStatus.CAPTURED) return I18n.t('captured');
  };

  render() {
    const { pricePermission, companyTag } = this.props;
    const {
      orderDetails, easyConfirm, taxName, selectedContract, easyConfirmVersion,
      paymentService, settings,
    } = this.props;

    const items = ((orderDetails && orderDetails.items) || []).map(item => ({
      total: item.amount,
      product: {
        price: {
          value: item.price,
          icmsST: item.icmsST || 0,
        },
      },
    }));

    const paymentTerms = selectedContract.clientDetails && selectedContract.clientDetails.paymentTermsLabel
    && selectedContract.clientDetails.paymentTermsLabel.description;

    return (
      <div className="col-lg-10 order-lg-last dashboard-content">
        {orderDetails
          && (
            <div>

              <WizzardDetails
                easyConfirm={easyConfirm}
                order={orderDetails}
                easyConfirmVersion={easyConfirmVersion}
                settings={settings}
              />

              <div className="order-summary">

                {/* <div id="accordion">
              {orderDetails.invoicess && orderDetails.invoices.map(invoice => (
                <div key={invoice.cgc} className="card">
                  <div className="card-header" id="headingOne">
                    <h5 className="mb-0">
                      <div
                        className="btn btn-link"
                        data-toggle="collapse"
                        data-target="#collapseOne"
                        aria-expanded="false"
                        aria-controls="collapseOne"
                      >
                 Nota fiscal emitida
                      </div>
                    </h5>
                  </div>

                  <div id="collapseOne" className="collapse" aria-labelledby="headingOne" data-parent="#accordion">
                    <div className="card-body">
                      <h2 className="tracking-text">Andamento do pedido</h2>
                      <WizzardDetails order={caseTest[1]} track />
                      <p>
                      Empresa responsável pelo transporte :
                        {' '}
                        {invoice.shippingCompany}
                      </p>
                      <div>
                        <p><strong>HISTÓRICO DO PEDIDO</strong></p>
                        {invoice.tracking && invoice.tracking.map((tracking) => {
                          const data = moment(tracking.date).format('DD-MM-YYYY');
                          return (
                            <p key={tracking.type}>
                              {tracking.type}
                              {' '}
                              <strong>{data}</strong>
                            </p>
                          );
                        })}
                      </div>
                      <button type="button" className="btn btn-primary">Imprimir Nota</button>
                    </div>
                  </div>
                </div>
              ))
            }

            </div> */}

                {orderDetails && (
                  <div>
                    <h4>
                      <div className="checkout-resume">
                        <Translate value="details2" />
                      </div>
                    </h4>
                    <p className="margin-bottom-0">
                      <Translate value="code2" />
                      {' '}
                      <strong>{orderDetails.id}</strong>
                    </p>

                    {!!orderDetails.sphereNumber && (
                      <p className="margin-bottom-0">
                        <Translate value="codeSphere" />
                        {' '}
                        <strong>{orderDetails.sphereNumber}</strong>
                      </p>
                    )}

                    {!!orderDetails.savedId && (
                      <p className="margin-bottom-0">
                        <Translate value="codeSolicitation" />
                        {' '}
                        <strong>{orderDetails.savedId}</strong>
                      </p>
                    )}

                    {orderDetails.paymentType && (
                    <p className="margin-bottom-0">
                      <Translate value="paymentType2" />
                      {' '}
                      <strong>{this.paymentType(orderDetails.paymentType)}</strong>
                      {paymentTerms && orderDetails.paymentType === 'billing' ? ` - ${paymentTerms}` : ''}
                    </p>
                    )}

                    {!!orderDetails.status && (orderDetails.paymentType !== PUCHASES.paymentType.ESTIMATE) && (
                      <p className="margin-bottom-0">
                        <Translate value="documentStatus" />
                        :
                        {' '}
                        <strong>{this.orderStatus(orderDetails.status)}</strong>
                      </p>
                    )}

                    {orderDetails.paymentType !== PUCHASES.paymentType.ESTIMATE && (
                      <p className="margin-bottom-0">
                        <Translate value="price2" />
                        <strong>
                          {' '}
                          <PriceComponent value={Number(orderDetails.value)} />
                        </strong>
                      </p>
                    )}
                    <p>
                      <Translate value="buyDate" />
                      :
                      {' '}
                      <strong>{moment(orderDetails.createdAt).format('LLL')}</strong>
                    </p>
                    {orderDetails.paymentType === PUCHASES.paymentType.BANK_SLIP
                      && orderDetails.status === PUCHASES.orderStatus.CONFIRMED
                      && (
                        <p>
                          <button
                            type="button"
                            className="btn-ticket flex-button-center"
                            onClick={
                              () => window.open(orderDetails.paymentBankSlipUrl, '_blank', 'width=500,height=500')
                            }
                          >
                            <i className="fas fa-barcode" />
                            <p><Translate value="bankSlipVisualize" /></p>
                          </button>
                        </p>
                      )}
                    {paymentService === 'pagseguro'
                      && orderDetails.paymentType === PUCHASES.paymentType.DEBIT
                      && orderDetails.status === PUCHASES.orderStatus.CONFIRMED
                      && (
                        <p>
                          <button
                            type="button"
                            className="btn btn btn-primary"
                            onClick={
                              () => window.open(orderDetails.paymentOnlineDebitUrl, '_blank', 'width=500,height=500')
                            }
                          >
                            <Translate value="debitOn" />
                          </button>
                        </p>
                      )}

                    {orderDetails.address && (
                      <React.Fragment>
                        <h4>
                          <div className="checkout-resume">
                            <Translate value="deliveryType" />
                          </div>
                        </h4>
                        <div className="delivery-type">
                          <DeliveryTypeCard isClient={Number(orderDetails.isDeliveryClient)} />
                        </div>
                        <p className="margin-bottom-0">{orderDetails.address.name}</p>
                        <p className="margin-bottom-0">{orderDetails.address.address}</p>
                        <p className="margin-bottom-0">{orderDetails.address.province}</p>
                        <p className="margin-bottom-0">
                          {`${orderDetails.address.city} - ${orderDetails.address.state}`}
                        </p>
                        <p className="margin-bottom-0">{orderDetails.address.cep}</p>
                        <p>{orderDetails.address.country}</p>
                      </React.Fragment>
                    )}

                    <h4>
                      <div className="checkout-resume">
                        {orderDetails.items.length}
                        {' '}
                        <Translate value="productBought" />
                      </div>
                    </h4>
                    <table className="table table-mini-cart">
                      <tbody>
                        {orderDetails.items.map(item => (
                          <tr key={item.purchaseProduct.description}>
                            <td className="product-col">
                              <div>
                                <h2 style={{ marginRight: '90px' }} className="product-title">
                                  {item.purchaseProduct.description}
                                </h2>

                                <span className="product-qty">
                                  <Translate value="quantity2" />
                                  {' '}
                                  {item.amount}
                                </span>

                                {item.billingLeft && (
                                  <span className="product-qty">
                                    <Translate value="billed" />
                                    {' '}
                                    {Number(item.amount) - Number(item.billingLeft)}
                                  </span>
                                )}
                              </div>
                            </td>
                            {pricePermission && (
                              <td className="price-col">
                                <PriceComponent value={Number(item.price * item.amount)} />
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                      {pricePermission && (
                        <tfoot>
                          <tr>
                            <td>
                              <Translate value="subTotal" />
                              <br />
                              {taxName}
                              <br />
                              <strong><Translate value="total" /></strong>
                            </td>
                            <td>
                              <PriceComponent value={CartUtil.getCartTotal(items, true, undefined, undefined, companyTag)} force />
                              <br />
                              <PriceComponent value={CartUtil.getCartSt(items, companyTag)} force />
                              <br />
                              <strong><PriceComponent value={companyTag === 'chile' && orderDetails.paymentType == PUCHASES.paymentType.ESTIMATE ?  Number(orderDetails.value) + CartUtil.getCartSt(items, companyTag) : Number(orderDetails.value)} /></strong>
                            </td>
                          </tr>
                        </tfoot>
                      )}
                    </table>
                  </div>
                )}
              </div>
            </div>
          )}
      </div>
    );
  }
}

const mapDispatchToState = dispatch => ({
  getOrderDetails: (id, clientId) => dispatch(OrderAction.getOrderDetails(id, clientId)),
});

const mapStateToProps = state => ({
  pricePermission: state.contracts.selected && state.contracts.selected.pricePermission,
  clientId: state.contracts.selected.client.id,
  orderDetails: state.panel.orderDetails,
  easyConfirm: state.settings.confirmaFacil,
  easyConfirmVersion: state.settings.confirmaFacilVersion,
  taxName: state.settings && state.settings.taxName,
  selectedContract: state.contracts.selected,
  companyTag: state.settings.companyTag,
  paymentService: state.settings.paymentService,
  settings: state.settings,
});

export default connect(mapStateToProps, mapDispatchToState)(OrderDetails);
