import React from 'react';
import { Translate } from 'react-redux-i18n';

import CheckoutPaymentDocument from './CheckoutPaymentDocument';

import ValidatorsHelper from '../../../helpers/validators';

const CheckoutPaymentBankSlip = (props) => {
  const { payementType, document } = props;
  const type = 'bank_slip';
  const isSelected = payementType === type;

  return (
    <React.Fragment>
      <button type="button" className="payment-menu" onClick={() => props.selectPayementType(type)}>
        Boleto
        <i className={`fas fa-arrow-${isSelected ? 'down' : 'right'}`} />
      </button>
      <div className={`card-payment ${isSelected ? '' : 'card-display-none'}`}>
        <div className="row">
          <div className="col-6">
            <CheckoutPaymentDocument setDocument={props.setDocument} document={document} />
          </div>

          <div className="col-6">
            <p className="invoice-p"><Translate value="bankSlipText1" /></p>
            <p className="invoice-p"><Translate value="bankSlipText2" /></p>
            <p className="invoice-p"><Translate value="bankSlipText3" /></p>
            <p className="invoice-p" style={{ height: '10px' }} />
            <button
              type="button"
              className="button-action btn btn-secondary"
              disabled={!ValidatorsHelper.validDocument(document.type, document.value)}
              onClick={() => props.setPayment(type, { document })}
            >
              <Translate value="payWithBankSlip" />
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};


export default CheckoutPaymentBankSlip;
