import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import { Link } from 'react-router-dom';

import StepBasicForm from './steps/basicForm';
import StepUserForm from './steps/userForm';
import StepAddressForm from './steps/addressForm';
import StepTaxBenefitsForm from './steps/taxBenefitsForm';
import StepCompanyForm from './steps/companyForm';

import StringHelper from '../../helpers/string';

import { newRegister } from '../../app/store/actions/newRegister';

import ROUTES from '../../data/routes';

class NewRegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.cleanData();
  }

  cleanData = () => ({
    activeStep: 0,
    finalize: false,
    finalizeError: false,
    error: '',
    type: 'cnpj',
    basic: {
      cnpj: '',
      email: '',
      rut: '',
    },
    address: {
      cep: '',
      ibge: '',
      street: '',
      number: '',
      complement: '',
      district: '',
      city: '',
      state: '',
      country: '',
    },
    user: {
      firstName: '',
      lastName: '',
      role: '',
      sector: '',
      phone: '',
      password: '',
      confirmPassword: '',
    },
    company: {
      name: '',
      ie: '',
      purpose: '',
      business: '',
    },
    taxBenefits: {
      naoContribIcms: false,
      icmsIsento: false,
      icmsDiferido: false,
      pisCofins: false,
      ipi: false,
      proEmprego: false,
    },
  })

  setBasic = async (basic) => {
    await this.setState({ basic });
  };

  setAddress = async (address) => {
    await this.setState({ address });
  };

  setUser = async (user) => {
    await this.setState({ user });
  };

  setCompany = async (company) => {
    await this.setState({ company });
  };

  setTaxBenefits = async (taxBenefits) => {
    await this.setState({ taxBenefits });
  };

  setType= async (type) => {
    await this.setState({ type });
  };

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    if (activeStep === 1) this.setState(this.cleanData());
    else this.setState({ activeStep: activeStep - 1 });
  };

  handleFinalize = async () => {
    const {
      basic, user, address, company,
      taxBenefits, type,
    } = this.state;

    try {
      const register = {
        cnpj: StringHelper.keepOnlyNumber(basic.cnpj),
        type,
        rut: StringHelper.keepOnlyNumber(basic.rut),
        email: basic.email,
        cep: StringHelper.keepOnlyNumber(address.cep),
        ibge: address.ibge,
        address: address.street,
        number: address.number,
        complement: address.complement,
        district: address.district,
        city: address.city,
        state: address.state,
        country: address.country,
        firstName: user.firstName,
        lastName: user.lastName,
        role: user.role,
        sector: user.sector,
        phone: user.phone,
        password: user.password,
        companyName: company.name,
        stateRegistration: company.ie,
        purpose: company.purpose,
        business: company.business,
        naoContribIcms: taxBenefits.naoContribIcms,
        icmsIsento: taxBenefits.icmsIsento,
        icmsDiferido: taxBenefits.icmsDiferido,
        pisCofins: taxBenefits.pisCofins,
        ipi: taxBenefits.ipi,
        proEmprego: taxBenefits.proEmprego,
      };

      const newRegisterCheckBuy = await this.props.newRegister(register);

      if (newRegisterCheckBuy) {
        if (newRegisterCheckBuy.status === 'finish') {
          await this.setState({ finalize: true, error: '' });
        } else {
          await this.setState({
            finalizeError: true,
            error: '',
          });
        }
      } else {
        await this.setState({
          finalizeError: true,
          error: '',
        });
      }
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        await this.setState({ error: `${I18n.t('code2')} ${err.response.data.error.code}` });
      }
    }
  }

  getStepContent = () => {
    const {
      activeStep, type,
      basic, user, address,
      company, taxBenefits,
    } = this.state;

    const {
      roles, sectors, business,
    } = this.props;

    const purposes = [
      {
        id: 'consumption',
        name: `${I18n.t('consumption')}`,
      }, {
        id: 'industrialization',
        name: `${I18n.t('industrialization')}`,
      }, {
        id: 'resale',
        name: `${I18n.t('resale')}`,
      },
    ];

    switch (activeStep) {
      case 0:
        return (
          <StepBasicForm
            basic={basic}
            setBasic={this.setBasic}
            setType={this.setType}
            handleNext={this.handleNext}
          />
        );
      case 1:
        return (
          <StepAddressForm
            address={address}
            setAddress={this.setAddress}
            handleBack={this.handleBack}
            handleNext={this.handleNext}
          />
        );
      case 2: {
        if (type === 'cnpj') {
          return (
            <StepCompanyForm
              company={company}
              setCompany={this.setCompany}
              purposes={purposes}
              selecBusiness={business}
              handleBack={this.handleBack}
              handleNext={this.handleNext}
            />
          );
        }
        return (
          <StepUserForm
            user={user}
            type={type}
            setUser={this.setUser}
            roles={roles}
            sectors={sectors}
            handleBack={this.handleBack}
            handleFinalize={this.handleFinalize}
          />
        );
      }
      /* case 3:
        return (
          <StepTaxBenefitsForm
            taxBenefits={taxBenefits}
            setTaxBenefits={this.setTaxBenefits}
            state={address.state}
            handleBack={this.handleBack}
            handleNext={this.handleNext}
          />
        ); */
      case 3:
        return (
          <StepUserForm
            user={user}
            type={type}
            setUser={this.setUser}
            roles={roles}
            sectors={sectors}
            handleBack={this.handleBack}
            handleFinalize={this.handleFinalize}
          />
        );
      default:
        return I18n.t('errorOcurred');
    }
  }

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const {
      activeStep, finalize, error, type,
      finalizeError,
    } = this.state;

    /* Step Beneficios Fiscais
    <li className={activeStep >= 3 ? 'active' : ''}>
      <span><Translate value="taxBenefits" /></span>
    </li>
    */

    return (
      <div className="login container">
        <div className="row">
          <div className="root">
            <h2 className="h2-solicitation"><Translate value="loginNewRegister" /></h2>
            <div className={type === 'cpf' ? 'align-wizzard-center' : ''}>
              {type === 'cnpj' ? (
                <ul className="checkout-progress-bar">
                  <li className={activeStep >= 0 ? 'active' : ''}>
                    <span><Translate value="emailCnpjCpf" /></span>
                  </li>
                  <li className={activeStep >= 1 ? 'active' : ''}>
                    <span><Translate value="address" /></span>
                  </li>
                  <li className={activeStep >= 2 ? 'active' : ''}>
                    <span><Translate value="companyData" /></span>
                  </li>
                  <li className={activeStep >= 3 ? 'active' : ''}>
                    <span><Translate value="contactData" /></span>
                  </li>
                </ul>
              ) : (
                <ul className="checkout-progress-bar">
                  <li className={activeStep >= 0 ? 'active' : ''}>
                    <span><Translate value="emailCnpjCpf" /></span>
                  </li>
                  <li className={activeStep >= 1 ? 'active' : ''}>
                    <span><Translate value="address" /></span>
                  </li>
                  <li className={activeStep >= 2 ? 'active' : ''}>
                    <span><Translate value="personalData" /></span>
                  </li>
                </ul>
              )}
            </div>

          </div>
          <div className="col-md offset-md-3">
            <main style={{ marginBottom: '40px' }} className="main">
              <div>
                {finalize || finalizeError ? (
                  <div>
                    {finalize ? (
                      <div className="row justify-content-center">
                        <div className="col-8 text-align-center margin-20">
                          <i className="fas fa-check-circle register-icon-f" />
                          <h3><Translate value="successNewRegister" /></h3>
                          <div className="heading">
                            <Link
                              to={ROUTES.LOGIN}
                              className="forget-pass"
                            >
                              <Translate value="successNewRegisterText" />
                            </Link>
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className="row justify-content-center">
                        <div className="col-8 text-align-center margin-20">
                          <i className="fas fa-check-circle register-icon-f" />
                          <h3><Translate value="errorNewRegister" /></h3>
                          <p><Translate value="errorNewRegisterText" /></p>
                        </div>
                      </div>
                    )}
                  </div>
                ) : (
                  <div className="instruction">
                    {this.getStepContent()}
                  </div>
                )}
                {error && (
                <p>
                  <Translate value="errorOcurred" />
                  <br />
                  {error}
                </p>
                )}
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  newRegister: data => dispatch(newRegister(data)),
});

const mapStateToProps = state => ({
  roles: state.settings.contact && state.settings.contact.roles,
  sectors: state.settings.contact && state.settings.contact.sectors,
  business: state.settings.contact && state.settings.contact.business,
});
export default connect(mapStateToProps, mapDispatchToProps)(NewRegisterForm);
