export default {
  image: {
    jcb: '/images/cards/jcb.jpg',
    discover: '/images/cards/discover.jpg',
    visa: '/images/cards/visa.png',
    mastercard: '/images/cards/mastercard.png',
    amex: '/images/cards/american.png',
    diners: '/images/cards/dinner.png',
    elo: '/images/cards/elo.png',
    hipercard: '/images/cards/hipercard.png',
    invalid: '/images/cards/invalid.png',
  },
  name: {
    jcb: 'Jcb',
    discover: 'Discover',
    visa: 'Visa',
    mastercard: 'Master',
    amex: 'Amex',
    diners: 'Diners',
    elo: 'Elo',
    hipercard: 'Hipercard',
    invalid: 'invalid',
  },
};
