import ProductsUtils from '../../utils/products';

const initialState = {
  items: [],
  clientNumber: null,
  loaded: false,
  observation: '',
  orderBy: 'date-asc',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'CART_SET_ITEMS': {
      const items = action.items.map(item => ({
        total: item.quantity,
        product: item,
        xped: item.xped ? item.xped : '',
      }));
      return {
        ...state,
        cartId: action.cartId,
        user: action.user,
        loaded: true,
        items: ProductsUtils.sortItems(items, state.orderBy),
      };
    }
    case 'CART_CLEAN_ITEMS': {
      return {
        ...state,
        items: [],
        cartId: undefined,
        user: undefined,
        observation: '',
      };
    }
    case 'CART_SET_FREIGHT': {
      return {
        ...state,
        freight: action.freight,
      };
    }
    case 'CART_SET_CLIENT_NUMBER': {
      return {
        ...state,
        clientNumber: action.clientNumber,
      };
    }
    case 'CART_SET_OBSERVATION': {
      return {
        ...state,
        observation: action.observationText,
      };
    }
    case 'CART_SET_ORDER_BY': {
      return {
        ...state,
        orderBy: action.value,
        items: [...ProductsUtils.sortItems(state.items, action.value)],
      };
    }
    default:
      return state;
  }
};
