import RestService from '../services/rest';

async function getSettings() {
  return RestService.getRest('settings');
}

async function getSettingLong(tag) {
  return RestService.getAuthenticated(`settingsLong/get?tag=${tag}`);
}

export default {
  getSettings,
  getSettingLong,
};
