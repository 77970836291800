import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';

import ProductsUtil from '../../app/utils/products';
import PriceComponent from '../price/Price';

import { setObservation, cartUpdateItem } from '../../app/store/actions/cart';
import { isXpedCharacterValid } from '../../app/utils/cart';

class CartResumeItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      xpedValue: this.props.item.xped,
    };
  }

 handleSetXped = async (value) => {
   if (isXpedCharacterValid(value)) {
     await this.setState({ xpedValue: value });
   }
 };

 updateProductToCart = async (product, amount, xped = '') => {
   const { id, clientCode, code } = product;
   if (product) await this.props.updateItem(id, clientCode, code, amount, xped);
 };

 render() {
   const { item, visibleResume, pricePermission } = this.props;
   return (
     <tr key={item.product.id}>
       <td className="product-col">
         <figure className="product-image-container">
           <Link to={ProductsUtil.getUrl(item.product)} className="product-image">
             <img src={item.product.image || '/images/product-image-placeholder.png'} alt={item.product.name} />
           </Link>
         </figure>
         <div>
           <h2 className="product-title">
             <Link to={ProductsUtil.getUrl(item.product)}>{item.product.name}</Link>
           </h2>

           <span className="product-qty">
             {I18n.t('quantity2')}
             {' '}
             {item.total}
           </span>
         </div>
       </td>
       {visibleResume && item.xped && (
       <td>
         <div className="xped-col-checkout">
           <span className="xped-title">xPed:</span>
           <div style={{ textAlign: 'center' }}>
             <input
               className="input-xped form-control"
               type="text"
               maxLength={16}
               value={this.state.xpedValue}
               onChange={e => this.handleSetXped(e.target.value)}
             />

             {item.xped && item.xped !== this.state.xpedValue && (
             <button
               className="btn-move"
               type="button"
               onClick={() => this.updateProductToCart(item.product, item.total, this.state.xpedValue)}
             >
               <Translate value="change" />
             </button>
             )}

           </div>
           {' '}
           <span
             className="tooltip-xped"
             title={I18n.t('xpedTooltipText')}
           >
             (?)
           </span>

         </div>
       </td>
       )}
       {pricePermission && (
       <td className="price-col">
         <PriceComponent value={item.total * item.product.price.value} />
       </td>
       )}
     </tr>
   );
 }
}

const mapStateToProps = (state, ownProps) => ({
  clientNumber: state.cart.clientNumber,
  orderObservation: state.cart.observation,
  address: ownProps.address && (
    ownProps.address.isClient ? state.contracts.selected.clientDetails.location
      : state.contracts.selected.client.locations.find(a => a.nameAbbr === ownProps.address.nameAbbr)
  ),
  pricePermission: state.contracts.selected && state.contracts.selected.pricePermission,
  deliveryDays: Number(state.settings.deliveryDays),
});

const mapDispatchToProps = dispatch => ({
  setObservation: text => dispatch(setObservation(text)),
  updateItem: (productId, productClientCode, productCode = '', total, xped = '') => dispatch(cartUpdateItem(productId,
    productClientCode, productCode, total, xped)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(CartResumeItem);
