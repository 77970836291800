/* eslint-disable max-len */
import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Translate, I18n } from 'react-redux-i18n';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';

import ROUTES from '../../../../data/routes';

import HeaderMiddleRightCartItem from './HeaderMiddleRightCartItem';

import CartUtil from '../../../../app/utils/cart';
import validator from '../../../../helpers/validators';

import PriceComponent from '../../../price/Price';

const HeaderMiddleRightCart = ({
  cartItems, cartLoaded, pricePermission, purchasePermission, isAuthenticated,
  minimumPrice, minimumProduct, buttonLabel, taxesCart, taxName = 'ST', punchOut, selectedContract, allowSellSpotItensWithoutStock, companyTag,
}) => {
  function renderButton() {
    const cartTotal = CartUtil.getCartTotal(cartItems, !taxesCart, undefined, undefined, companyTag);

    const hasItemWithoutStock = cartItems.filter(item => validator.isSpotProductAmountInvalid(item.total,
      item.product.stock, allowSellSpotItensWithoutStock, item.product.isSpot, item.product.price.isContract));

    if (!isAuthenticated) {
      return <Link to={ROUTES.LOGIN} className="btn btn-block"><Translate value="buySolicitation" /></Link>;
    }
    if (hasItemWithoutStock && hasItemWithoutStock.length > 0) {
      return (
        <p className="color-red">
          <Translate value="invalidItemsCart" />
        </p>
      );
    }
    if (!punchOut && ((selectedContract && selectedContract.limit && cartTotal > Number(selectedContract.limit)) || !purchasePermission)) {
      return <Link to={ROUTES.CART} className="btn btn-block"><Translate value="buySolicitation" /></Link>;
    } if (!punchOut && purchasePermission && minimumPrice <= CartUtil.getCartTotal(cartItems, !taxesCart, undefined, undefined, companyTag) && !CartUtil.getCartMinimumProduct(cartItems, minimumProduct)) {
      return <Link to={ROUTES.CHECKOUT} className="btn btn-block">{buttonLabel || 'Finalizar Compra'}</Link>;
    }
  }

  return (    
    <div className="dropdown cart-dropdown">      
      <div className="dropdown-toggle">
        <FontAwesomeIcon icon={faShoppingCart} />
        {cartItems.length > 0 && <span className="cart-count">{cartItems.length}</span>}

      </div>

      <div className="dropdown-menu">
        <div className="dropdownmenu-wrapper">
          {!cartLoaded
            ? (
              <div className="loader-cart-div">
                <i className="spinner-menu-icon fas fa-spinner fa-pulse" />
              </div>
            )
            : (
              <React.Fragment>
                <div className="dropdown-cart-header">
                  <span>
                    {cartItems.length}
                    {' '}
                    {I18n.t('items')}
                  </span>
                  {isAuthenticated && cartItems.length > 0 && (<Link to={ROUTES.CART}><Translate value="visualizeCart" /></Link>)}
                </div>

                <div className="dropdown-cart-products">
                  {cartItems.length > 0 ? (cartItems.map(item => (
                    <HeaderMiddleRightCartItem
                      key={item.product.id}
                      item={item}
                      pricePermission={pricePermission}
                      isAuthenticated={isAuthenticated}
                    />
                  ))) : (
                    <div className="checkout-finalize-hover">
                      <i className="gray fas fa-shopping-cart" />
                      <h2><Translate value="cartEmpty" /></h2>
                      <br />
                      <div style={{display:'grid' }}>
                        {isAuthenticated &&
                          <Link
                            to={ROUTES.CART_IMPORT}
                            className="btn btn-outline-secondary"
                          >
                            <Translate value="importList" />
                          </Link>}
                        <br />
                        <Link
                          to={ROUTES.PRODUCTS}
                          className="btn btn-outline-secondary"
                        >
                          <Translate value="continueBuy" />
                        </Link>
                      </div>
                    </div>
                  )}
                </div>

                {pricePermission && cartItems.length > 0 && (
                  <div className="dropdown-cart-total">
                    <span>
                      <Translate value="total" />
                      {' '}
                      {taxesCart && <Translate value="totalCartDetails" tax={taxName} />}
                    </span>

                    <span className="cart-total-price">
                      <PriceComponent value={CartUtil.getCartTotal(cartItems, !taxesCart, undefined, undefined, companyTag)} />
                    </span>
                  </div>
                )}

                {cartItems.length > 0 && (
                  <div className="dropdown-cart-action">
                    {punchOut ? (
                      <Link
                        to={ROUTES.PUNCH_OUT_CHECKOUT}
                        className="btn btn-block"
                      >
                        <Translate value="buyPunchOut" />
                      </Link>
                    ) : renderButton()}
                  </div>
                )}
              </React.Fragment>
            )}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => ({
  purchasePermission: state.contracts.selected && state.contracts.selected.purchasePermission,
  isAuthenticated: state.auth.authenticated,
  pricePermission: state.contracts.selected && state.contracts.selected.pricePermission,
  buttonLabel: state.contracts.selected && state.contracts.selected.clientDetails && state.contracts.selected.clientDetails.buyButtonLabel,
  cartItems: state.cart.items,
  cartLoaded: state.cart.loaded,
  minimumPrice: state.contracts.selected && state.contracts.selected.clientDetails && state.contracts.selected.clientDetails.minimumPurchase > 0
    ? Number(state.contracts.selected.clientDetails.minimumPurchase)
    : Number(state.settings.minimumPurchase),
  minimumProduct: state.contracts.selected && state.contracts.selected.clientDetails && state.contracts.selected.clientDetails.minimumProduct > 0
    ? Number(state.contracts.selected.clientDetails.minimumProduct)
    : Number(state.settings.minimumProduct),
  taxesCart: state.settings && !state.settings.taxsCartOff,
  taxName: state.settings && state.settings.taxName,
  companyTag: state.settings && state.settings.companyTag,
  punchOut: state.punchOut,
  selectedContract: state.contracts.selected,
  allowSellSpotItensWithoutStock: state.contracts.selected
    && state.contracts.selected.clientDetails.allowSellSpotItensWithoutStock,
});

export default connect(mapStateToProps)(HeaderMiddleRightCart);
