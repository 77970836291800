export default {
  localhost: 'http://localhost:3333/',
  'shop.nortel.com.br': 'https://api-shop.nortel.com.br/',
  'shop.eletricadw.com.br': 'https://api-shop.eletricadw.com.br/',
  'shop.centelhario.com.br': 'https://api-shop.centelhario.com.br/',
  'shop.sandler.com.br': 'https://api-shop.sandler.com.br/',
  'shop.dimensional.com.br': 'https://api-shop.dimensional.com.br/',
  'shop.electrafk.cl': 'https://api-shop.electrafk.cl/',
  'shop.etil.com.br': 'https://api-shop.etil.com.br/',
  'homolog-shop.nortel.com.br': 'https://homolog-api-shop.nortel.com.br/',
  'homolog-shop.eletricadw.com.br': 'https://homolog-api-shop.eletricadw.com.br/',
  'homolog-shop.centelhario.com.br': 'https://homolog-api-shop.centelhario.com.br/',
  'homolog-shop.sandler.com.br': 'https://homolog-api-shop.sandler.com.br/',
  'homolog-shop.dimensional.com.br': 'https://homolog-api-shop.dimensional.com.br/',
  'homolog-shop.electrafk.cl': 'https://homolog-api-shop.electrafk.cl/',
  'homolog-shop.etil.com.br': 'https://homolog-api-shop.etil.com.br/',
  'testeshop.electrafk.cl': 'https://api-compras.electrafk.cl/',
};
