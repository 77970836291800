const initialState = {};

export default (state = initialState, action) => {
  switch (action.type) {
    case 'PRODUCTS_LIST_SET':
      state.list = action.products;
      if (action.filters) state.filters = action.filters;
      if (action.suggest) state.suggest = action.suggest;
      return { ...state };
    case 'PRODUCTS_FEATURED_SET':
      state.featured = action.products;
      return { ...state };
    case 'PRODUCTS_PROMOTIONS_SET':
      state.promotions = action.products;
      return { ...state };
    case 'PRODUCTS_PRICING_SET':
      if (!state[action.listType]) return;
      state[action.listType] = action.products;
      return { ...state };
    case 'PRODUCTS_RESET':
      return {};
    default:
      return state;
  }
};
