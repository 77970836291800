import AuthRequests from '../../server/auth';
import AuthService from '../../services/auth';

import { saveContracts, cleanContracts } from './contracts';
import { saveCards, cleanCards } from './cards';
import { addLoading, removeLoading } from './loading';
import { mergeCart, getCartCache } from './cart';

export const resetAuthentication = () => ({
  type: 'LOGOUT',
});

export const saveUser = user => ({
  type: 'SAVE_USER',
  user,
});

export const saveAuthentication = authData => ({
  type: 'LOGIN',
  auth: authData,
});

export const updateAuthentication = (authData = {}) => ({
  type: 'UPDATE_TOKEN',
  accessToken: authData.accessToken,
  refreshToke: authData.refreshToke,
});

export const cleanAuth = () => async (dispatch) => {
  dispatch(resetAuthentication());
  AuthService.reset();
  dispatch(getCartCache());
};

export const getUser = () => async (dispatch) => {
  dispatch(addLoading());

  try {
    const { user, contracts = [], cards = [] } = await AuthRequests.getUser();
    dispatch(saveUser(user));
    dispatch(saveContracts(contracts));
    dispatch(saveCards(cards));
  } catch (err) {
    dispatch(cleanAuth());
  } finally {
    dispatch(removeLoading());
  }
};

export const getUserCards = () => async (dispatch) => {
  dispatch(addLoading());

  try {
    const { cards = [] } = await AuthRequests.getUser();
    dispatch(saveCards(cards));
  } catch (err) {
    dispatch(saveCards([]));
  } finally {
    dispatch(removeLoading());
  }
};

export const authenticate = (email, password) => async (dispatch) => {
  dispatch(addLoading());

  try {
    const auth = await AuthRequests.authenticate(email, password);
    AuthService.create(auth);
    dispatch(saveAuthentication(auth));
    await dispatch(getUser());
  } finally {
    dispatch(removeLoading());
  }
};

export const changePasswordRecovery = password => async (dispatch, getState) => {
  dispatch(addLoading());
  const { email } = getState().auth.user;

  try {
    await AuthRequests.changePasswordRecovery(password);
    const auth = await AuthRequests.authenticate(email, password);
    AuthService.create(auth);
    dispatch(updateAuthentication(auth));
  } finally {
    dispatch(removeLoading());
  }
};

export const changePassword = (password, oldPassword) => async (dispatch, getState) => {
  dispatch(addLoading());
  const { email } = getState().auth.user;

  try {
    await AuthRequests.changePassword(password, oldPassword);
    const auth = await AuthRequests.authenticate(email, password);
    AuthService.create(auth);
    dispatch(updateAuthentication(auth));
  } finally {
    dispatch(removeLoading());
  }
};

export const refreshToken = token => async (dispatch) => {
  const auth = await AuthRequests.refreshToken(token);
  AuthService.create(auth);
  dispatch(updateAuthentication(auth));
};

export const logout = () => async (dispatch) => {
  dispatch(addLoading());
  try {
    await AuthRequests.logout();
  } finally {
    dispatch(cleanAuth());
    dispatch(cleanCards());
    dispatch(cleanContracts());
    dispatch(removeLoading());
  }
};
