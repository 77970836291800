import React from 'react';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import ChangePasswordForm from '../components/changePasswordRecovery/ChangePasswordRecovery';

const changePasswordRecoveryPage = () => (
  <main className="main">
    <Breadcrumb items={[{
      value: I18n.t('passwordChange'),
    }]}
    />
    <ChangePasswordForm />
  </main>
);

export default changePasswordRecoveryPage;
