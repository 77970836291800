
import React from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import isEmail from 'validator/lib/isEmail';
import { validate } from 'rut.js';

import ValidatorsHelper from '../../../helpers/validators';
import StringHelper from '../../../helpers/string';

import { check, checkRut } from '../../../app/store/actions/register';
import inputSize from '../../../data/inputs';

class StepBasicForm extends React.Component {
  state = {
    emailError: '',
    cnpjError: '',
    error: '',
    type: 'cnpj',
  }

  onSubmit = async (e) => {
     e.preventDefault();

    const { cnpj, email, rut } = this.props;
    const { type } = this.state;

    if (rut.length > 0) {
      if (!isEmail(email)) {
        await this.setState({ emailError: I18n.t('invalidEmail') });
      } else if (!validate(rut)) {
        await this.setState({ cnpjError: I18n.t('invalidCNPJ') });
      } else {
        try {
          const contact = await this.props.checkRut(email, rut);
          if (contact) {
            await this.props.setUser(contact);
          }
        } catch (err) {
          if (err.response && err.response.data && err.response.data.error) {
            if (err.response.data.error.code === 500) {
              await this.setState({ error: I18n.t('emailCnpjMessage3') });
            } else if (err.response.data.error.code === 600) {
              await this.setState({ error: I18n.t('emailCnpjMessage2') });
            } else {
              await this.setState({ error: I18n.t('emailCnpjMessage') });
            }
          }
        }
      }
    }

    if (!isEmail(email)) {
      await this.setState({ emailError: I18n.t('invalidEmail') });
    } else if (type === 'cnpj' && !ValidatorsHelper.validarCNPJ(cnpj)) {
      await this.setState({ cnpjError: I18n.t('invalidCNPJ') });
    } else if (type === 'cpf' && !ValidatorsHelper.validarCPF(cnpj)) {
      await this.setState({ cnpjError: I18n.t('invalidCPF') });
    } else if (cnpj && email) {
      try {
        const contact = await this.props.check(email, cnpj, type);
        if (contact) {
          await this.props.setUser(contact);
        }
      } catch (err) {
        if (err.response && err.response.data && err.response.data.error) {
          if (err.response.data.error.code === 500) {
            await this.setState({ error: I18n.t('emailCnpjMessage3') });
          } else if (err.response.data.error.code === 600) {
            await this.setState({ error: I18n.t('emailCnpjMessage2') });
          } else {
            await this.setState({ error: I18n.t('emailCnpjMessage') });
          }
        }
      }
    }
  }

  setValue = async (attr, value) => {
    await this.setState({ [`${attr}Error`]: '' });
    await this.props.setValue(attr, value);
  }

  render() {
    const { emailError, cnpjError, error, type } = this.state;
    const {
      email, cnpj, documentType, rut
    } = this.props;

    return (
      <form onSubmit={this.onSubmit}>
        <div>
          <input
            type="email"
            className="form-control"
            placeholder={I18n.t('insertEmail')}
            value={email}
            maxLength={inputSize.max}
            onChange={e => this.setValue('email', e.target.value)}
            required
          />
          {emailError ? <p className="error-p">{emailError}</p> : ''}

          <React.Fragment>
            {documentType === 'B'
              ? (
                <InputMask
                  mask="99.999.999-9"
                  value={value}
                  onChange={e => this.setValue('rut', e.target.value)}
                >
                  {inputProps => (
                    <input
                      {...inputProps}
                      type="text"
                      className="form-control"
                      style={{ marginTop: '10px' }}
                      placeholder={I18n.t('insertCnpj')}
                      required
                    />
                  )}
                </InputMask>
              )
              : (
                <React.Fragment>
                  <div className="btn-group btn-group-toggle" data-toggle="buttons">
                    <button
                      type="button"
                      onClick={() => {
                        this.setValue('cnpj', '');
                        this.setState({ type: 'cnpj' });
                      }}
                      className={type === 'cnpj' ? 'btn btn-primary active' : 'btn btn-primary'}
                    >
                      <input type="radio" name="options" id="option2" autoComplete="off" />
                      {' '}
                      <Translate value="cnpj" />
                    </button>
                    <button
                      type="button"
                      onClick={() => {
                        this.setValue('cnpj', '');
                        this.setState({ type: 'cpf' });
                      }}
                      className={type === 'cpf' ? 'btn btn-primary active' : 'btn btn-primary'}
                    >
                      <input type="radio" name="options" id="option1" autoComplete="off" />
                      {' '}
                      <Translate value="cpf" />
                    </button>
                  </div>
                  {type === 'cpf'
                    ? (
                      <InputMask
                        mask="999.999.999-99"
                        value={cnpj}
                        onChange={e => this.setValue('cnpj', e.target.value)}
                      >
                        {inputProps => (
                          <input
                            {...inputProps}
                            type="text"
                            className="form-control"
                            style={{ marginTop: '10px' }}
                            placeholder={I18n.t('insertCpf')}
                            required
                          />
                        )}
                      </InputMask>
                    )
                    : (
                      <InputMask
                        mask="99.999.999/9999-99"
                        value={cnpj}
                        onChange={e => this.setValue('cnpj', e.target.value)}
                      >
                        {inputProps => (
                          <input
                            {...inputProps}
                            type="text"
                            className="form-control"
                            style={{ marginTop: '10px' }}
                            placeholder={I18n.t('insertCnpj')}
                            required
                          />
                        )}
                      </InputMask>
                    )
            }
          </React.Fragment>
        )
      }
    </React.Fragment>
          {cnpjError ? <p className="error-p">{cnpjError}</p> : ''}
          {error ? <p className="error-p">{error}</p> : ''}
        </div>

        <div className="row justify-content-center margin-top-20 margin-bottom-20 form-footer">
          <button
            type="submit"
            className="btn btn-primary"
            variant="contained"
          >
            <Translate value="next" />
          </button>
        </div>
      </form>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  check: (email, cnpj, type) => dispatch(check(email, StringHelper.keepOnlyNumber(cnpj), type)),
  checkRut: (email, rut) => dispatch(checkRut(email, rut)),
});

const mapStateToProps = state => ({
  documentType: state.settings.documentType,
});

export default connect(mapStateToProps, mapDispatchToProps)(StepBasicForm);
