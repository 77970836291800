import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import CreditCardInput from 'react-credit-card-input';
import { validate } from 'rut.js';

import CheckoutPaymentDocument from './CheckoutPaymentDocument';
import RegexCards from '../../../app/utils/cards';

import StringHelper from '../../../helpers/string';
import ValidatorsHelper from '../../../helpers/validators';
import CARDS from '../../../data/cards';
import inputSize from '../../../data/inputs';

class PaymentComponentCardNew extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      installments: 1,
      securityCode: '',
      number: '',
      name: '',
      expirationDate: '',
      brand: '',
      brandErr: false,
      saveCard: false,
    };

    if (props.values) {
      this.state.installments = props.values.installments;
      this.state.securityCode = props.values.securityCode;
      this.state.number = props.values.number;
      this.state.name = props.values.name;
      this.state.expirationDate = props.values.expirationDate;
      this.state.brand = props.values.brand;
    }
  }

  numberOfInstallments = (total) => {
    const installmentsTotal = [];
    for (let i = 1; i <= total; i += 1) installmentsTotal.push(i);
    return installmentsTotal.map(p => <option key={p} value={p}>{p}</option>);
  }

  handleCardNumberChange = (e) => {
    const { brandsAllowed } = this.props;
    const { value } = e.target;
    const brand = RegexCards.getCardsName(StringHelper.keepOnlyNumber(value));

    let brandErr = false;
    if (brand !== CARDS.name.invalid && brandsAllowed.indexOf(brand) === -1) brandErr = true;

    this.setState({ number: value, brand, brandErr });
  }

  handleCardExpirationDateChange = (e) => {
    this.setState({ expirationDate: e.target.value });
  }

  handleCardCVVChange = (e) => {
    this.setState({ securityCode: e.target.value });
  }

  isValid = () => {
    const {
      securityCode, number, name, expirationDate, brand, brandErr,
    } = this.state;
    const { document } = this.props;

    if (brandErr || brand === CARDS.name.invalid) return;
    if (document.type === 'rut') {
      if (!validate(document.value)) return;
    } else if (!ValidatorsHelper.validDocument(document.type, document.value)) return;
    if (StringHelper.keepOnlyNumber(securityCode).length < 3
      || StringHelper.keepOnlyNumber(securityCode).length > 4) return;
    if (!number || !name || !expirationDate) return;
    return true;
  }

  setPayment = () => {
    const {
      securityCode, number, name, expirationDate, brand, installments, saveCard,
    } = this.state;
    const { type, document } = this.props;

    const brandErr = RegexCards.getCardsName(StringHelper.keepOnlyNumber(number));
    if (brandErr === 'invalid') {
      this.setState({ message: I18n.t('cardInvalid') });
      return;
    }
    this.props.setPayment(type, {
      card: {
        securityCode,
        number: StringHelper.keepOnlyNumber(number),
        name,
        expirationDate: expirationDate.split(' / ').join('/20'),
        document,
        brand,
        installments,
        saveCard,
      },
      document,
    }, saveCard);
  }

  render() {
    const {
      number, name, securityCode, expirationDate, installments, brandErr, message,
    } = this.state;
    const {
      type, canBack, document, installmentsTotal,
    } = this.props;

    return (
      <div
        className="row"
        style={{ marginBottom: '20px' }}
      >
        <div className="col-6">
          <div className="row">

            <div className="col-12">
              <input
                type="text"
                className="form-control width-98-p border-radius-4"
                placeholder={I18n.t('cardName')}
                value={name}
                maxLength={inputSize.max}
                onChange={e => this.setState({ name: e.target.value })}
                required
              />
            </div>

            <div className="col-12">
              <CreditCardInput
                cardNumberInputProps={{ value: number, onChange: this.handleCardNumberChange }}
                cardExpiryInputProps={{ value: expirationDate, onChange: this.handleCardExpirationDateChange }}
                cardCVCInputProps={{ value: securityCode, onChange: this.handleCardCVVChange }}
                fieldClassName="form-control"
                customTextLabels={{
                  invalidCardNumber: I18n.t('cardNumberInvalid'),
                  expiryError: {
                    invalidExpirationDateDate: I18n.t('expirationDateInvalid'),
                    monthOutOfRange: I18n.t('expirationMonthInvalid'),
                    yearOutOfRange: I18n.t('expirationYearInvalid'),
                    dateOutOfRange: I18n.t('expirationDateInvalid'),
                  },
                  invalidCvc: ('cvvInvalid'),
                  cardNumberPlaceholder: I18n.t('cardNumber'),
                  expiryPlaceholder: I18n.t('expirationPlaceholder'),
                  cvvPlaceholder: I18n.t('cvv'),
                }}
              />

              {brandErr && (
                <div className="alert alert-warning alert-dismissible fade show" role="alert">
                  <strong><Translate value="error" /></strong>
                  <Translate value="flagInvalid" />
                </div>
              )}

            </div>

            {type === 'credit' && (
              <div className="col-6">
                <span>
                  <Translate value="installmentsNumber" />
                </span>
                <div className="select-custom">
                  <select
                    name="orderby"
                    className={`form-control ${installments}`}
                    defaultValue="num"
                    onChange={e => this.setState({ installments: Number(e.target.value) })}
                  >
                    {this.numberOfInstallments(installmentsTotal)}
                  </select>
                </div>
              </div>
            )}


            <div
              className="col-12 col-card-checkbox"
            >
              <label htmlFor="saveCard">
                <input
                  id="saveCard"
                  type="checkbox"
                  value={this.state.saveCard}
                  className="margin-right-5"
                  onChange={e => this.setState({ saveCard: e.target.checked })}
                />

                <Translate value="saveCardText" />
              </label>
            </div>

            <div className="col-12 padding-right-6p">
              <CheckoutPaymentDocument setDocument={this.props.setDocument} document={document} />
            </div>

            <div className="col-12">
              {canBack && (
                <button
                  type="button"
                  className="btn btn-light"
                  onClick={() => this.props.back()}
                >
                  <Translate value="return" />
                </button>
              )}


              <button
                type="button"
                className="btn btn-primary"
                onClick={() => this.setPayment(this.state)}
                disabled={!this.isValid()}
              >
                <Translate value="confirm" />
              </button>
              <p className="color-red">{message}</p>
            </div>
          </div>
        </div>
        <div className="col-6">
          <img src="/images/card.png" alt="Card banner" />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => ({
  brandsAllowed: state.settings.paymentMethods[ownProps.type],
});

export default connect(mapStateToProps)(PaymentComponentCardNew);
