const xpedRegex = /[a-z]|[A-Z]|[0-9]|[,]|[\\]|[/]|[-]|[_]/g;

export const isXpedCharacterValid = (value) => {
  if ((value.length === 0) || (value.slice(-1).match(xpedRegex))) return true;
  return false;
};

function getCartSt(items = [], companyTag) {
  return items.reduce((total, item) => {
    if (companyTag === 'nortel') {
      return (item.product.price.icmsST ? (item.product.price.icmsST) + total : total);
    }
    return (item.product.price.icmsST ? (item.product.price.icmsST * item.total) + total : total);
  },
  0);
}

function getCartFixed(items = [], hasTax, taxFixedValue) {
  if (!hasTax) return 0;
  return items.reduce((total, item) => total + (
    item.product.price.value * item.total * taxFixedValue),
  0);
}

function getCartTax(items = [], type, opts = {}, companyTag) {
  switch (type) {
    case 'fixed': return getCartFixed(items, opts.hasTax, opts.taxFixedValue);
    default: return getCartSt(items, companyTag);
  }
}

function getCartTotal(items = [], onlyProductPrice, type, opts = {}, companyTag) {
  const itemsValues = items.reduce((total, item) => total
    + parseFloat((item.product.price.value * item.total).toFixed(2)),
  0);
  if (onlyProductPrice) return itemsValues;

  const taxes = getCartTax(items, type, opts, companyTag);

  return taxes + itemsValues;
}

function getCartMinimumProduct(items = [], minimumPriceProduct = '') {
  const product = items.find(item => (item.product.price.total + item.product.price.icmsST) < minimumPriceProduct && item.product.price.hasPrice);
  return product !== undefined;
}

export default {
  getCartMinimumProduct,
  getCartTotal,
  getCartSt,
  getCartFixed,
  getCartTax,
};
