import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';

const ProductsFiltersSectionType = ({
  type, onClick, contractSelected, spotPermission, user, hideAgreement, companyTag
}) => (
  <div className="widget">
    <h3 className="widget-title">
      {I18n.t('products')}
    </h3>
    <div className="collapse show" id="widget-body-3">
      <div className="widget-body">
        <ul className="config-size-list">
          <li className={!type || type === '' ? '' : 'disable'}>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => onClick({ type: '' })}
            >
              <Translate value="all" />
            </button>
          </li>
          {contractSelected && (
          <li className={type === 'contract' ? '' : 'disable'}>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => onClick({ type: 'contract' })}
            >
              <Translate value="contract" />
            </button>
          </li>
          )}
          {spotPermission && (
          <li className={type === 'spot' ? '' : 'disable'}>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => onClick({ type: 'spot' })}
            >
              <Translate value={companyTag == 'chile' ? "offer" : "spot"} />
            </button>
          </li>
          )}
          <li className={type === 'spotlight' ? '' : 'disable'}>
            <button
              type="submit"
              className="btn btn-primary"
              onClick={() => onClick({ type: 'spotlight' })}
            >
              <Translate value="spotlight" />
            </button>
          </li>
          {user && !hideAgreement && (
            <li className={type === 'agreement' ? '' : 'disable'}>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={() => onClick({ type: 'agreement' })}
              >
                <Translate value="agreement" />
              </button>
            </li>
          )}
        </ul>
      </div>
    </div>
  </div>
);

const mapStateToProps = state => ({
  spotPermission: state.contracts.selected && state.contracts.selected.spotPermission,
  contractSelected: state.contracts && state.contracts.selected,
  user: state.auth.user,
  hideAgreement: state.settings.companyTag == 'chile',
  companyTag: state.settings.companyTag,
});

export default connect(mapStateToProps)(ProductsFiltersSectionType);
