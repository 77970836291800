import React from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';

import StepBasicForm from './steps/basicForm';
import StepUserForm from './steps/userForm';

import StringHelper from '../../helpers/string';

import { register } from '../../app/store/actions/register';

class RegisterForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.cleanData();
  }

  cleanData = () => ({
    activeStep: 0,
    finalize: false,
    basic: {
      cnpj: '',
      email: '',
      rut: '',
    },
    user: {
      password: '',
      confirmPassword: '',
    },
  })

  setBasic = async (attr, value) => {
    const { basic } = this.state;
    basic[attr] = value;
    await this.setState({ basic });
  };

  setUser = async (user) => {
    await this.setState({ activeStep: 1, user });
  };

  handleNext = () => {
    const { activeStep } = this.state;
    this.setState({ activeStep: activeStep + 1 });
  };

  handleBack = () => {
    const { activeStep } = this.state;
    if (activeStep === 1) this.setState(this.cleanData());
    else this.setState({ activeStep: activeStep - 1 });
  };

  handleFinalize = async () => {
    const { basic, user } = this.state;
    const { cnpj, email, rut } = basic;
    const { password } = user;

    try {
      await this.props.register({
        cnpj: StringHelper.keepOnlyNumber(cnpj),
        rut,
        email,
        password,
      });

      await this.setState({ finalize: true, activeStep: 2, error: '' });
    } catch (err) {
      if (err.response && err.response.data && err.response.data.error) {
        await this.setState({ error: `${I18n.t('code2')} ${err.response.data.error.code}` });
      }
    }
  }

  getStepContent = () => {
    const {
      activeStep,
      basic, user,
    } = this.state;

    switch (activeStep) {
      case 0:
        return (
          <StepBasicForm
            email={basic.email}
            cnpj={basic.cnpj}
            rut={basic.rut}
            setValue={this.setBasic}
            setUser={this.setUser}
            handleNext={this.handleNext}
          />
        );
      case 1:
        return (
          <StepUserForm
            user={user}
            basic={basic}
            setUser={this.setUser}
            handleBack={this.handleBack}
            handleFinalize={this.handleFinalize}
          />
        );
      default:
        return I18n.t('errorOcurred');
    }
  }

  handleReset = () => {
    this.setState({
      activeStep: 0,
    });
  };

  render() {
    const { activeStep, finalize, error } = this.state;

    return (
      <div className="login container">
        <div className="row">
          <div className="root">
            <h2 className="h2-solicitation"><Translate value="accessRequest" /></h2>
            <div className="align-wizzard-center">
              <ul className="checkout-progress-bar">
                <li className={activeStep >= 0 ? 'active' : ''}>
                  <span><Translate value="emailCnpjCpf" /></span>
                </li>
                <li className={activeStep >= 1 ? 'active' : ''}>
                  <span><Translate value="password" /></span>
                </li>
              </ul>
            </div>

          </div>
          <div className="col-md-6 offset-md-3">
            <main style={{ marginBottom: '40px' }} className="main">
              <div>
                {finalize ? (
                  <div className="row justify-content-center">
                    <div className="col-8 text-align-center margin-20">
                      <i className="fas fa-check-circle register-icon-f" />
                      <h3><Translate value="successRegister" /></h3>
                      <p><Translate value="successRegisterText" /></p>
                    </div>
                  </div>
                ) : (
                  <div className="instruction">
                    {this.getStepContent()}
                  </div>
                )}
                {error && (
                <p>
                  <Translate value="errorOcurred" />
                  <br />
                  {error}
                </p>
                )}
              </div>
            </main>
          </div>
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  register: data => dispatch(register(data)),
});

export default connect(undefined, mapDispatchToProps)(RegisterForm);
