import React from 'react';

import HomeSlider from '../components/home/HomeSlider';
import HomeBoxesInfo from '../components/home/HomeBoxesInfo';
import HomeCategories from '../components/home/HomeCategories';
import ProductsFeatured from '../components/productsFeatured/ProductsFeatured';
import ProductsPromotions from '../components/productsPromotions/ProductsPromotions';

const HomePage = () => (
  <main className="main">
    <div className="home-top-container">
      <div className="container">
        <HomeSlider />
      </div>
    </div>
    <HomeBoxesInfo />
    <HomeCategories />
    <ProductsFeatured />
    <ProductsPromotions />
  </main>
);

export default HomePage;
