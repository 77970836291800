import React from 'react';

import LandingHeader from '../components/landing/LandingHeader';
import LandingFeatures from '../components/landing/LandingFeatures';
import LandingHowWorks from '../components/landing/LandingHowWorks';

const LandingPage = () => (
  <React.Fragment>
    <LandingHeader />
    <LandingFeatures />
    <LandingHowWorks />
  </React.Fragment>
);

export default LandingPage;
