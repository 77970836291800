import React from 'react';
import queryString from 'query-string';

import { I18n } from 'react-redux-i18n';
import Breadcrumb from '../components/breadcrumb/Breadcrumb';
import PunchOutLoginForm from '../components/punchOut/PunchOutLoginForm';

const PunchOutPage = props => (
  <main className="main">
    <Breadcrumb items={[{
      value: I18n.t('punchOut'),
    }]}
    />
    <PunchOutLoginForm {...queryString.parse(props.location.search)} />
  </main>
);

export default PunchOutPage;
