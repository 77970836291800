/* eslint-disable no-nested-ternary */
import React from 'react';
import { I18n } from 'react-redux-i18n';

const PasswordStrengthIndicator = ({
  validity: {
    minChar, number, upperCase, specialChar,
  },
  passwordEqual,
}) => (
  <div className="text-left">
    <h3 className="text-primary m-0 p-0">
      {I18n.t('passwordIndicatorTitle' /* Política de Senha */)}
    </h3>
    <p className="text-primary m-0 p-0">
      {I18n.t('passwordIndicatorSubTitle' /* A senha deverá conter */)}
    </p>
    <ul
      className="text-danger"
    >
      <PasswordStrengthIndicatorItem
        isValid={minChar}
        text={I18n.t('passwordIndicatorMinChar' /* No mínimo 12 caracteres */)}
      />
      <PasswordStrengthIndicatorItem
        isValid={number}
        text={I18n.t('passwordIndicatorNumber' /* Dígitos (0-9) */)}
      />
      <PasswordStrengthIndicatorItem
        isValid={upperCase}
        text={I18n.t('passwordIndicatorUpperCase' /* Pelo menos uma letra maiúscula (A-Z) */)}
      />
      <PasswordStrengthIndicatorItem
        isValid={specialChar}
        text={I18n.t('passwordIndicatorSpecialChar' /* Pelo menos um caracter especial (!@#) */)}
      />
      <PasswordStrengthIndicatorItem
        isValid={passwordEqual}
        text={I18n.t('passwordIndicatorPasswordEqual' /* Senhas iguais */)}
      />
    </ul>
  </div>
);

const PasswordStrengthIndicatorItem = ({ isValid, text }) => {
  let highlightClass;
  if (isValid) highlightClass = 'text-success';
  return <li style={{ listStyle: isValid ? 'none' : 'inside' }} className={`${highlightClass}`}>{text}</li>;
};

export default PasswordStrengthIndicator;
