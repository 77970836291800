import React from 'react';
import { Translate } from 'react-redux-i18n';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGenderless } from '@fortawesome/free-solid-svg-icons';

const ProductDetailsTabs = ({
  pdmParticulars = [], datasheet = [], clientDescription, displayClientDescription,
}) => (
  <div className="product-single-tabs">
    <ul className="nav nav-tabs" role="tablist">
      <li className="nav-item">
        <a
          className="nav-link active"
          id="product-tab-desc"
          data-toggle="tab"
          href="#product-desc-content"
          role="tab"
          aria-controls="product-desc-content"
          aria-selected="true"
        >
          <Translate value="productInformation" />
        </a>
      </li>
    </ul>
    <div className="tab-content">
      <div
        className="tab-pane fade show active"
        id="product-desc-content"
        role="tabpanel"
        aria-labelledby="product-tab-desc"
      >
        <div className="product-tags-content">
          {displayClientDescription && clientDescription && (
            <React.Fragment>
              <h4><Translate value="clientDescription" /></h4>
              <p>{clientDescription}</p>
              <br />
            </React.Fragment>
          )}
          <h4><Translate value="especification" /></h4>
          <ul>
            {pdmParticulars.map(pdmParticular => (
              <li key={pdmParticular.id + pdmParticular.value}>
                <FontAwesomeIcon icon={faGenderless} />
                {' '}
                {pdmParticular.id}
                {': '}
                {pdmParticular.value}
              </li>
            ))}
          </ul>
          {datasheet.map(file => (
            <form target="_blank" method="get" action={file} key={file}>
              <button
                type="submit"
                className="btn btn-primary"
              >
                <Translate value="downloadPdf" />
              </button>
            </form>
          ))}
        </div>
      </div>

    </div>
  </div>
);

export default ProductDetailsTabs;
