import React from 'react';

import { Translate } from 'react-redux-i18n';

export default function ModalDefaultAddress({
  showModal, handleCloseModal, handleSetFreightCart, handleUpdateDefaultAddress,
}) {
  return (
    <div className="dashboard-content">
      <div className={!showModal && 'card-display-none'}>
        <button
          type="button"
          onClick={() => handleCloseModal()}
          className="outer-modal-div"
        />
        <div className="modal-div-cart">

          <div>
            <h2 className="h3-modal-div"><Translate value="attention" /></h2>
            <h3 className="h3-modal-div"><Translate value="selectDefaultAddress" /></h3>
            <div className="row body-modal-div">
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => {
                  handleUpdateDefaultAddress();
                  handleSetFreightCart();
                  handleCloseModal();
                }}
              >
                <Translate value="yes" />
              </button>
              <button
                type="button"
                className="btn btn-outline-secondary"
                onClick={() => {
                  handleSetFreightCart();
                  handleCloseModal();
                }}
              >
                <Translate value="no" />
              </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}
